// keys for preferences 
export const CONTENTS_SORTING_FIELD = 'CONTENTS_SORTING_FIELD';
export const CONTENTS_SORTING_ORDER_BY = 'CONTENTS_SORTING_ORDER_BY';

export const SCREENS_GROUP_SORTING_FIELD = 'SCREENS_GROUP_SORTING_FIELD';
export const SCREENS_GROUP_SORTING_ORDER_BY = 'SCREENS_GROUP_SORTING_ORDER_BY';

export const SCREENS_SORTING_FIELD = 'SCREENS_SORTING_FIELD';
export const SCREENS_SORTING_ORDER_BY = 'SCREENS_SORTING_ORDER_BY';

export const PLAYLISTS_SORTING_FIELD = 'PLAYLISTS_SORTING_FIELD';
export const PLAYLISTS_SORTING_ORDER_BY = 'PLAYLISTS_SORTING_ORDER_BY';

export const SCHEDULES_SORTING_FIELD = 'SCHEDULES_SORTING_FIELD';
export const SCHEDULES_SORTING_ORDER_BY = 'SCHEDULES_SORTING_ORDER_BY';

export const getContentsSortingField = () => {
    if (localStorage.CONTENTS_SORTING_FIELD) {
        return localStorage.CONTENTS_SORTING_FIELD
    }
    return "updated_at" //default value return
};
export const setContentsSortingField = (name) => {
    localStorage.CONTENTS_SORTING_FIELD = name
};
export const getContentsSortingOrderBy = () => {
    if (localStorage.CONTENTS_SORTING_ORDER_BY) {
        return localStorage.CONTENTS_SORTING_ORDER_BY
    }
    return "desc" //default value return
};
export const setContentsSortingOrderBy = (name) => {
    localStorage.CONTENTS_SORTING_ORDER_BY = name
};

export const getScreenGroupSortingField = () => {
    if (localStorage.SCREENS_GROUP_SORTING_FIELD) {
        return localStorage.SCREENS_GROUP_SORTING_FIELD
    }
    return "updated_at" //default value return
};
export const setScreenGroupSortingField = (name) => {
    localStorage.SCREENS_GROUP_SORTING_FIELD = name
};
export const getScreenGroupSortingOrderBy = () => {
    if (localStorage.SCREENS_GROUP_SORTING_ORDER_BY) {
        return localStorage.SCREENS_GROUP_SORTING_ORDER_BY
    }
    return "desc" //default value return
};
export const setScreenGroupSortingOrderBy = (name) => {
    localStorage.SCREENS_GROUP_SORTING_ORDER_BY = name
};

export const getScreenSortingField = () => {
    if (localStorage.SCREENS_SORTING_FIELD) {
        return localStorage.SCREENS_SORTING_FIELD
    }
    return "device_name" //default value return
};
export const setScreenSortingField = (name) => {
    localStorage.SCREENS_SORTING_FIELD = name
};
export const getScreenSortingOrderBy = () => {
    if (localStorage.SCREENS_SORTING_ORDER_BY) {
        return localStorage.SCREENS_SORTING_ORDER_BY
    }
    return "desc" //default value return
};
export const setScreenSortingOrderBy = (name) => {
    localStorage.SCREENS_SORTING_ORDER_BY = name
};

export const getPlaylistsSortingField = () => {
    if (localStorage.PLAYLISTS_SORTING_FIELD) {
        return localStorage.PLAYLISTS_SORTING_FIELD
    }
    return "updated_at" //default value return
};
export const setPlaylistsSortingField = (name) => {
    localStorage.PLAYLISTS_SORTING_FIELD = name
};
export const getPlaylistsSortingOrderBy = () => {
    if (localStorage.PLAYLISTS_SORTING_ORDER_BY) {
        return localStorage.PLAYLISTS_SORTING_ORDER_BY
    }
    return "desc" //default value return
};
export const setPlaylistsSortingOrderBy = (name) => {
    localStorage.PLAYLISTS_SORTING_ORDER_BY = name
};

export const getSchedulesSortingField = () => {
    if (localStorage.SCHEDULES_SORTING_FIELD) {
        return localStorage.SCHEDULES_SORTING_FIELD
    }
    return "updated_at" //default value return
};
export const setSchedulesSortingField = (name) => {
    localStorage.SCHEDULES_SORTING_FIELD = name
};
export const getSchedulesSortingOrderBy = () => {
    if (localStorage.SCHEDULES_SORTING_ORDER_BY) {
        return localStorage.SCHEDULES_SORTING_ORDER_BY
    }
    return "desc" //default value return
};
export const setSchedulesSortingOrderBy = (name) => {
    localStorage.SCHEDULES_SORTING_ORDER_BY = name
};
export const getChannelsSortingField = () => {
    if (localStorage.CHANNELS_SORTING_FIELD) {
        return localStorage.CHANNELS_SORTING_FIELD
    }
    return "updated_at" //default value return
};
export const setChannelsSortingField = (name) => {
    localStorage.CHANNELS_SORTING_FIELD = name
};
export const getChannelsSortingOrderBy = () => {
    if (localStorage.CHANNELS_SORTING_ORDER_BY) {
        return localStorage.CHANNELS_SORTING_ORDER_BY
    }
    return "desc" //default value return
};
export const setChannelsSortingOrderBy = (name) => {
    localStorage.CHANNELS_SORTING_ORDER_BY = name
};