<template>
<b-list-group>
    <b-list-group-item class="p-0">
        <b-button variant="outline" @click="insertText"  :class="{ btn_selected: isTextInsert }">
            <i id="toolbar-text"></i>
            Text
        </b-button>
    </b-list-group-item>

    <bgcolor :canvas="canvas" :properties="properties" />

    <image-upload :canvas="canvas" :properties="properties" />

    <line-shape :canvas="canvas" :properties="properties" :selectedTool="this.selectedTool" @changed="update_selected"/>

    <arrow-shape :canvas="canvas" :properties="properties" :selectedTool="this.selectedTool" @changed="update_selected" />

    <circle-shape :canvas="canvas" :properties="properties" :selectedTool="this.selectedTool" @changed="update_selected" />

    <box-shape :canvas="canvas" :properties="properties" :selectedTool="this.selectedTool" @changed="update_selected"/>

    <brush :canvas="canvas" :properties="properties" :selectedTool="this.selectedTool" @changed="update_selected"/>

</b-list-group>
</template>

<script>
import CircleShape from './shapes/Circle.vue'
import BoxShape from './shapes/Box.vue'
import LineShape from './shapes/Line.vue'
import ArrowShape from './shapes/Arrow.vue'
import Bgcolor from './Bgcolor.vue'
import ImageUpload from './Image.vue'
import Text from './text/Text.js'
import Brush from './shapes/Brush.vue'

export default {
    props: {
        widget: {
            type: Object,
            default: {}
        },
        canvas: null,
        properties: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            selectedTool: null,
            isTextInsert: false
        }
    },

    components: {
        CircleShape,
        BoxShape,
        LineShape,
        ArrowShape,
        Bgcolor,
        ImageUpload,
        Brush
    },

    mixins: [Text],

    methods: {
        update_selected: function(tool_type){
            this.selectedTool = tool_type
            this.$emit("selected", tool_type)
        }
    }

}
</script>

<style lang="scss">
#toolbar-text {
    width: 100%;
    height: 50px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;

    .zmdi {
        font-size: 32px;
        color: #5e6ebe;
    }
}

.btn-bg {
    .btn-swatch {
        margin: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 99%;
        height: 99%;

        .color-swatch,
        .color-swatch-bg {
            opacity: 0;
        }
    }

    .vc-chrome {
        left: 30px;
        right: auto;
    }
}

input[type=file] {
    opacity: 0;
    width: 90px;
    position: absolute;
    top: 0px;
    z-index: 1020;
    left: 5px;
    height: 100%;
    cursor: pointer;
}

.btn_selected{
    background-color: #d7d7d7;
    
}
.list-group-item{
    .btn{
        width: 100%;
    }
}
</style>