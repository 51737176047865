<template>

    <div>
        <form ref="form"   method="post" @submit.prevent="validate">
            <h4 class="form-signin-heading">{{$t('login.sign_in.sign_in_title')}}</h4>
            <div class="form-group" v-show="error != null">
                <p class="text-danger text-center">{{error}}</p>
            </div>
            <div class="form-group"
                :class="{'has-danger': errors.has('email') }">
                <input type="email"
                       class="form-control"
                       id="email"
                       :placeholder="$t('login.sign_in.email')"
                       name="user[email]"
                       v-validate="'required'"
                       data-vv-name="email"
                       data-vv-as="Email">

                <span v-show="errors.has('email')" class="text-danger">{{ errors.first('email') }}</span>
            </div>
            <div class="form-group"
                       :class="{'has-danger': errors.has('password') }">
                <input type="password"
                       class="form-control"
                       id="password"
                       :placeholder="$t('login.sign_in.password')"
                       name="user[password]"
                       v-validate="'required|min:8'"
                       data-vv-name="password"
                       data-vv-as="Password">
                <span v-show="errors.has('password')" class="text-danger">{{ errors.first('password') }}</span>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" value="remember-me"> {{$t('login.sign_in.remember_me')}}
              </label>
            </div>
            <br/>
            <b-button :class="{disabled: !this.isFormDirty}"
                      variant="primary"
                      type="submit">
                      {{$t('login.sign_in.sign_in_title')}}
            </b-button>

            <div class="float-right mt-2 no-account">
              <span class="float-left">{{$t('login.sign_in.no_account')}}</span>
              <router-link :to="{ name: 'new-signup' }" class="btn btn-link p-0" >{{$t('login.sign_in.signup_button')}}</router-link>
          </div>
            <br/><br/>
            <div class=" p-0">
              <router-link :to="{ name: 'forgot-password' }" class="btn btn-link p-0" >{{$t('login.sign_in.forgot_password')}}</router-link>
            </div>
        </form>
    </div>

</template>

<script>
    import Csrf from '../utils/Csrf';

    export default {
        name: 'Login',

        data () {
            return {
                error: "",
            }
        },

        mounted () {

        },

        computed: {
            isFormDirty() {
              return Object.keys(this.dataFields).some(key => this.dataFields[key].dirty);
            }
        },

        methods: {
            onValidationSuccess () {
                let formData = new FormData(this.$refs.form);
                Csrf.append(formData);
                this.$http.interceptors.response = []
                let _this = this
                this.$http
                    .post('/users/sign_in', formData)
                    .then(response => {
                        // redirect to home page
                        console.log("success");
                        window.location.href = "/";
                    })
                    .catch(error => {
                        if (error.response) {
                            // HTTP error
                            _this.error = error.response.data.error;
                        } else if (error.request) {
                            _this.error = 'No Network available.';
                        } else {
                            _this.error = error;
                        }
                    })
            },

            onValidationFailure () {

            },
            /**
             * Performs validation before the form gets submitted. For it to work, it needs to be
             * set as the event handler like this `@submit.prevent="validate"`
             */
            validate () {
                this.$validator.validateAll().then((result) => {
                    return result ? this.onValidationSuccess() : this.onValidationFailure();
                });
            },

        }
    }
</script>
<style>

</style>
