<template>
  <div :class="cssClass" v-click-outside="hidePopover">
    <b-link :id="'pp-1-' + this.device.id" variant="">
        <i class="zmdi zmdi-menu"></i>
    </b-link>
    <b-popover :target="'pp-1-' + this.device.id" triggers="click"
                ref="popover" placement="bottom">
        <template #title></template>
        <b-list-group>
          <b-link class="list-group-item "
                    variant="none"
                    @click.prevent="rename_device">
             {{$t('common_words.rename')}}
          </b-link>
          <b-link class="list-group-item "
                    variant="none"
                    @click.prevent="live_mode"
                    v-if="device.preview_mode">
             {{$t('screens.details.set_live_mode')}}
          </b-link>

          <b-link class="list-group-item "
                    variant="none"
                    @click.prevent="view_report">
             {{$t('common_words.view_report')}}
          </b-link>
          <b-link :id="'pp-2-' + this.device.id"
                  class="list-group-item "
                  variant="none" >
                {{$t('screens.details.rotation')}}<span class="caret right ml-2"></span>
          </b-link>
          <b-link class="list-group-item "
                  variant="none"
                  @click.prevent="refresh" >
                {{$t('screens.details.refresh')}}
          </b-link>
          <b-link class="list-group-item text-danger"
                    variant="none"
                    @click.prevent="delete_device">
             {{$t('common_words.delete')}}
          </b-link>
        </b-list-group>
        <b-popover :target="'pp-2-' + this.device.id" triggers="hover" offset="80" placement:="[right,left]">
          <b-list-group class="border-0">
            <b-link class="list-group-item sub-item"
                               button action
                               @click="save_rotation(['zero', '0'])">
                {{ $t('screens.landscape_left')}}<span>&#176;</span>
                <i class="zmdi zmdi-check float-right green" v-show="device.rotation == 0 "></i>
            </b-link>
            <b-link class="list-group-item sub-item"
                               @click="save_rotation(['ninty', '90'])">
              {{ $t('screens.portrait')}}<span>&#176;</span>
              <i class="zmdi zmdi-check float-right green" v-show="device.rotation == 90"></i>
            </b-link>
            <b-link class="list-group-item sub-item"
                               @click="save_rotation(['one_eighty', '180'])">
              {{ $t('screens.landscape_right')}}<span>&#176;</span>
            <i class="zmdi zmdi-check float-right green" v-show="device.rotation == 180"></i>
            </b-link>
            <b-link class="list-group-item sub-item"
                               @click="save_rotation(['two_seventy', '270'])">
              {{ $t('screens.upside_down')}}<span>&#176;</span>
              <i class="zmdi zmdi-check float-right green" v-show="device.rotation == 270"></i>
            </b-link>
          </b-list-group>
        </b-popover>

    </b-popover>
  </div>
</template>

<script>

    export default {
        props: {
          cssClass: {
            type: String,
            default: ''
          },
          device: {}
        },

        methods: {
          delete_device: function() {
            this.hidePopover()
            this.$emit('delete');
          },
          save_rotation: function(rotation){
            this.hidePopover()
            this.$emit('save', rotation)

          },
          refresh: function(){
            this.hidePopover()
            this.$emit('refresh-device', this.device)
          },
          rename_device: function(){
            this.hidePopover()
            this.$emit('rename-device', this.device)
          },
          hidePopover: function(){
            if(this.$refs.popover != null){
              this.$refs.popover.doClose()
            }
          },
          view_report: function(){
             this.$router.push({
                name: "reports",
                query: { device_id: this.device.id },
              })
          },

          live_mode: function(){
            this.$emit('live-mode', this.device)
          }
        }
    }
</script>
<style lang="scss">
  .list-group{
    a{
      &:hover{
        background-color: #5e6ebe;
        color: #FFF;
        text-decoration: none;
      }
    }
  }
  .popover{
    .list-group-item{
      width: 150px;
      padding: 10px 20px;
    }
    .popover-body{
      padding: 0px;
    }
    .bs-popover-right{
      margin-top: -5px;
    }
  }
</style>
