export default {
  data() {
    return {
      drawnObj: null,
      isMouseDown: false,
    }
  },

  methods: {
    disableDraw: function () {
      this.canvas.off('mouse:down');
      this.canvas.off('mouse:move');
      this.canvas.off('mouse:up');

      this.canvas.selection = true;
      this.canvas.forEachObject(function (o) {
        o.selectable = true;
        o.evented = true;
      });
    },

    enableDraw: function () {
      this.canvas.selection = false;
      this.start_drag = false;
      this.canvas.forEachObject(function (o) {
        o.selectable = false;
        o.evented = false;
      });
    },

    drawObj: function (objType) {
      let _this = this
      this.canvas.isDrawingMode = false
      this.cancelTextInsert()
      this.cancelDraw()
      this.isDraw[objType] = true
      this.$emit('changed', objType)
      this.enableDraw()
      // Esc key handler
      $(document).on("keyup", this.drawEscHandler);
      if (objType === 'arrow') {
        _this.drawArrow()
        return
      }

      this.start_drag = false;


      this.canvas.on('mouse:down', function (o) {
        // Unregister escape key handler
        $(document).off("keyup", this.drawEscHandler);
        _this.isMouseDown = true;
        let pointer = _this.canvas.getPointer(o.e);
        if (objType === 'line') {
          let points = [pointer.x, pointer.y, pointer.x, pointer.y];
          _this.drawnObj = new fabric.Line(points, {
            fill: _this.properties.fill_color.hex8,
            stroke: _this.properties.stroke_color.hex8,
            originX: 'center',
            originY: 'center'
          });
        } else if (objType === 'rect') {
          _this.drawnObj = new fabric.Rect({
            width: 0,
            height: 0,
            top: pointer.y,
            left: pointer.x,
            rx: _this.properties.corner_radius,
            ry: _this.properties.corner_radius,
            fill: _this.properties.fill_color.hex8
          });
        } else if (objType === 'circle') {
          _this.drawnObj = new fabric.Circle({
            radius: 0,
            top: pointer.y,
            left: pointer.x,
            fill: _this.properties.fill_color.hex8
          });
        }
        _this.drawnObj.set('stroke', _this.properties.stroke_color.hex8)
        _this.drawnObj.set('strokeWidth', _this.properties.stroke_width)
        _this.canvas.add(_this.drawnObj);
      });

      this.canvas.on('mouse:move', function (o) {
        if (_this.isMouseDown) {
          let pointer = _this.canvas.getPointer(o.e);
          if (objType === 'line') {
            _this.drawnObj.set({ x2: pointer.x, y2: pointer.y });
          } else if (objType === 'rect' || objType === 'rounded-rect') {
            let newWidth = (_this.drawnObj.left - pointer.x) * -1;
            let newHeight = (_this.drawnObj.top - pointer.y) * -1;
            _this.drawnObj.set({ width: newWidth, height: newHeight });
          } else if (objType === 'circle') {
            let x = _this.drawnObj.left - pointer.x;
            let y = _this.drawnObj.top - pointer.y;
            let diff = Math.sqrt(x * x + y * y);
            _this.drawnObj.set({ radius: diff / 2.3 });
          }

          _this.canvas.renderAll();
        }
      });

      this.canvas.on('mouse:up', function (o) {
        _this.isMouseDown = false;

        // Fix upside-down rect
        if (objType === 'rect' || objType === 'rounded-rect') {
          if (_this.drawnObj.width < 0) {
            let newLeft = _this.drawnObj.left + this.drawnObj.width;
            let newWidth = Math.abs(_this.drawnObj.width);
            _this.drawnObj.set({ left: newLeft, width: newWidth });
          }

          if (_this.drawnObj.height < 0) {
            let newTop = _this.drawnObj.top + this.drawnObj.height;
            let newHeight = Math.abs(_this.drawnObj.height);
            _this.drawnObj.set({ top: newTop, height: newHeight });
          }
        }

        // Delete the object if it's tiny, otherwise select it
        if (_this.drawnObj.height !== 0 || _this.drawnObj.width !== 0) {
          if (_this.start_drag) {
            _this.canvas.defaultCursor = 'grab';
          } else {
            _this.canvas.defaultCursor = 'default';
          }

          // Fix selection bug by selecting and deselecting all objects
          _this.selectAll();
          _this.canvas.discardActiveObject();

          // Select the object
          _this.canvas.setActiveObject(_this.drawnObj).renderAll();

          // Set per-pixel dragging rather than bounding-box dragging
          _this.drawnObj.perPixelTargetFind = true;
          _this.drawnObj.targetFindTolerance = 4;

          // Disable drawing
          _this.disableDraw();

          // Push the this.canvas state to history
          _this.canvas.fire( "object:statechange");
        } else {
          _this.canvas.remove(this.drawnObj);
        }
        _this.cancelDraw()
        _this.drawnObj = null
      });

    },


    drawArrow: function () {
      let fromx, fromy, tox, toy;
      let _this = this
      this.canvas.on('mouse:down', function (event) {
        let pointer = _this.canvas.getPointer(event.e);
        fromx = pointer.x;
        fromy = pointer.y;
      });
      this.canvas.on('mouse:up', function (event) {
        let pointer = _this.canvas.getPointer(event.e);
        tox = pointer.x;
        toy = pointer.y;
        //this.drawArrow(startX, startY, endX, endY);

        let angle = Math.atan2(toy - fromy, tox - fromx);

        let headlen = 10;  // arrow head size

        // bring the line end back some to account for arrow head.
        tox = tox - (headlen) * Math.cos(angle);
        toy = toy - (headlen) * Math.sin(angle);

        // calculate the points.
        let points = [
          {
            x: fromx,  // start point
            y: fromy
          }, {
            x: fromx - (headlen / 4) * Math.cos(angle - Math.PI / 2),
            y: fromy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
          }, {
            x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2),
            y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
          }, {
            x: tox - (headlen) * Math.cos(angle - Math.PI / 2),
            y: toy - (headlen) * Math.sin(angle - Math.PI / 2)
          }, {
            x: tox + (headlen) * Math.cos(angle),  // tip
            y: toy + (headlen) * Math.sin(angle)
          }, {
            x: tox - (headlen) * Math.cos(angle + Math.PI / 2),
            y: toy - (headlen) * Math.sin(angle + Math.PI / 2)
          }, {
            x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
            y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
          }, {
            x: fromx - (headlen / 4) * Math.cos(angle + Math.PI / 2),
            y: fromy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
          }, {
            x: fromx,
            y: fromy
          }
        ];

        let pline = new fabric.Polyline(points, {
          fill: _this.properties.fill_color.hex8,
          stroke: _this.properties.stroke_color.hex8,
          opacity: 1,
          strokeWidth: _this.properties.stroke_width,
          originX: 'center',
          originY: 'center',
          selectable: true
        });

        _this.canvas.add(pline);

        this.isDown = false;
        this.off('mouse:down').off('mouse:move').off('mouse:up')

        _this.canvas.renderAll();
        // Push the this.canvas state to history
        _this.canvas.fire( "object:statechange");
      });
    },

    cancelDrawInsert: function () {
      this.canvas.defaultCursor = 'auto';
      this.disableDraw();
    },

    // Cancel text insertion
    drawEscHandler: function (e) {
      if (e.keyCode == 27) {
        this.cancelDrawInsert();

        // Unregister escape key handler
        $(document).off("keyup", this.drawEscHandler);
      }
    },

    setShapeStyle: function () {
      let object = this.canvas.getActiveObject();
      if (object != null) {
        object.set('fill', this.properties.fill_color.hex8)
        object.set('stroke', this.properties.stroke_color.hex8)
        object.set('strokeWidth', this.properties.stroke_width)
        this.canvas.renderAll()
      }
    },

    updateShape: function (selected_opts) {
      this.properties.fill_color = selected_opts.fill_color
      this.properties.stroke_color = selected_opts.stroke_color
      this.properties.stroke_width = selected_opts.stroke_width
      this.setShapeStyle()
    },

    cancelTextInsert: function () {
      this.canvas.defaultCursor = 'auto';
      this.disableDraw();
      $(document).off("mouse:down");
    },

    selectAll: function () {
      this.canvas.discardActiveObject();
      let sel = new fabric.ActiveSelection(this.canvas.getObjects(), {
        canvas: this.canvas,
      });
      this.canvas.setActiveObject(sel);
      this.canvas.requestRenderAll();
    },

    cancelDraw: function () {
      this.isDraw[Object.keys(this.isDraw)[0]] = false
      this.$emit('changed', null)
    },
  },


  watch: {
    selectedTool: {
      handler: function (new_tool_type) {
        if(this.isDraw[new_tool_type] === undefined){
          this.isDraw[Object.keys(this.isDraw)[0]] = false
        }else{
          this.isDraw[new_tool_type] = true
        }
      },
      deep: true
    },

  }
}
