<template>
  <div>
    <div class="dz-image rounded-circle ">
      <div data-dz-thumbnail-bg=""
           class="thumbnail-bg"
           :style="'background-image: url(' + get_img(this.content) + ')'">
      </div>
    </div>
    <div class="dz-details m-1 pt-1">
      <div class="dz-filename">
        <div v-b-tooltip.hover :title="content.name" class="col-11 text-truncate p-0">
          {{content.name}}
        </div>
      </div>
      <div class="dz-size">
        <label> {{$t('content.size')}}:</label>
        <span>
          {{content.size | formatFileSize}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import ContentThumbnailImg from "../mixins/content_thumbnail_img";


export default {

  props:{
    content: {
      type: Object,
      default:{
        id: null,
        filename: '',
        size: '',
        img_url: '',
        content_type: '',
        previewable: false
      }
    }
  },

  data() {
    return {
        audio_img: require('../images/file_format/audio_file.jpg'),
        pdf_img: require('../images/file_format/pdf_file.jpg'),
        word_img: require('../images/file_format/word_file.jpg'),
        unknown_img: require('../images/file_format/unknown_file.jpg'),
        options: ['cover', 'center', 'contain', 'stretch' ],
        word_docs: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/msword', 'application/vnd.oasis.opendocument.text']

      }
  },

  mounted() {
  },

  mixins: [ContentThumbnailImg],
}
</script>

<style lang="scss">
 .cont-preview{
   width: 200px !important;
   float: left;
   .dz-image{
      width: 60px !important;
      height: 60px !important;
      border-color: rgba(63, 63, 63, 0.12) !important;
      overflow: hidden;
    }
    .dz-size{
      color: #b5b5b5;
      font-size: 12px;
    }
    .dz-filename{
      div.text-truncate{
        width: 145px;
      }
    }
 }
</style>
