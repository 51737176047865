<template>
    <div class="report-downloadable-main-container">
    </div>
  </template>
  <script>
  export default {
  };
  </script>
  <style scoped>
  .report-downloadable-main-container {
    display: flex;
    flex: 0;
    padding: 16px;
    border-bottom: 1px solid rgb(194, 194, 194);
  }
  .report-downloadable-main-container:nth-child(odd) {
    background-color:rgba(7, 209, 240, 0.037)
  }
  .report-downloadable-main-container:nth-child(even) {
    background-color:  rgb(255, 255, 255)
  }
 
  </style>
  