<template>
  <div class="microsooft-login-main-container">
      <b-link
      href="#"
      @click="login()"
    >
      <img :src="microsooftLoginImg" class="microsooft-login-button " />
    </b-link>
  </div>
</template>

<script>
import EventBus from "../../../../core/event-bus";
export default {
  props: {
    element: {
      type: Object,
    },
  },
  data() {
    return {
      microsooftLoginImg: require("../../../../images/third_party/ms-login.png"),
    };
  },
  mounted() {
    let _this = this;
    window.set_data = function(data) {
      _this.$eventBus.$emit("google_auth", data);
    };
    this.$eventBus.$on("google_auth", function(data) {
      _this.setMetadata(data);      
    });
  },
  methods: {
    login: function() {
      let screenX     = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft,
      screenY     = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop,
      outerWidth  = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.body.clientWidth,
      outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : (document.body.clientHeight - 22),
      left        = parseInt(screenX + ((outerWidth - 500) / 2), 10),
      top         = parseInt(screenY + ((outerHeight - 100) / 2.5), 10),
      features    = ('width=' + 70% + ',height=' + 80% + ',left=' + left + ',top=' + top);

      this.newwindow = window.open('/users/auth/microsoft_office365', 'Login', features);
      this.newwindow.focus();
      return false;
    },
   setMetadata: function(data) {
      var hasmap = {}
      hasmap["provider"] = data["provider"];
      hasmap["calendar_name"] = data["name"];
      hasmap["calendar_id"] = data["calendar_id"];
      hasmap["access_token"] = data["access_token"];
      hasmap["refresh_token"] = data["refresh_token"];
      hasmap["expires_at"] = data["expires_at"];
      hasmap["uid"] = data["uid"];
      hasmap["email"] = data["email"];
      hasmap["isAuthenticated"] = true;
      this.element.model = hasmap
      EventBus.$emit("login_authenticated");
    },
  },
};
</script>

<style scoped>
.microsooft-login-main-container {
    display: flex;
    flex: 1;
    justify-content: space-around;
}
  .microsooft-login-button {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
</style>