<template>
  <div class="side_toolbar">
    <font-family  :canvas="canvas" :properties="properties"/>

    <div class="border-top my-3"></div>

    <div class="col-12 p-4 mb-2 mt-2 border-bottom">
      <label>Font Style:</label>
      <text-bold  :canvas="canvas" :properties="properties"/>

      <text-italics :canvas="canvas" :properties="properties"/>

      <text-underline :canvas="canvas" :properties="properties"/>
    </div>

    <font-color  :canvas="canvas" :properties="properties"/>

    <div class="border-top my-3"></div>

    <back-front :canvas="canvas"/>

 </div>
</template>
<script>
import ColorPicker       from '../../components/ColorPicker'
import ContentService    from '../../services/ContentService'
import TextBold          from './text/Bold.vue'
import TextItalics       from './text/Italic.vue'
import TextUnderline     from './text/Underline.vue'
import FontColor         from './text/FontColor.vue'
import FontFamily        from './text/FontFamily.vue'
import BackFront         from './properties/BackFront.vue'

  export default {
      props: {
        canvas: null,
        properties: {
          type: Object,
          default: {}
        },
      },

      data(){
        return {
          font_sizes: Array.from(Array(20), (_, i) => i + 10),
          font_family_list: ['Sans-Serif', 'Arial', 'Helvetica', 'Gill Sans', 'Lucida', 'Helvetica Narrow',
                      'sans-serif', 'Serif', 'Times', 'Times New Roman', 'Palatino', 'Bookman',
                      'New Century Schoolbook', 'serif', 'Monospace','Andale Mono', 'Courier New',
                      'Courier', 'Lucidatypewriter', 'Fixed', 'monospace'],
          showPicker: false,
          color: {
            red: 255,
            green: 0,
            blue: 0,
            alpha: 1
          },
        }
      },
      components: {
        ColorPicker,
        TextBold,
        TextItalics,
        TextUnderline,
        FontColor,
        FontFamily,
        BackFront
      },

      computed: {

      },

      methods: {
      },

  }
</script>

<style lang="scss">
  .side_toolbar{
    width: 100%;
    .picker {
      opacity: 1;
      margin: 5px 0px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      transform: scale(1.5);
    }
    .btn {
      padding: 3px 7px 0px 8px;
      margin-right: 5px;
      height: 35px;
      .zmdi{
        font-size: 30px;
      }
    }
    label{
      margin-right: 2px;
    }

  }

</style>
