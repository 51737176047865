<template>
    <div class="everyday" v-if="dailySchedule">
        <div>EveryDay</div>
        <div>
            <i class="zmdi zmdi-time zmdi-hc-lg"></i>
            <div>All Day</div>
            <div class="action" v-if="!readOnly">
                <button @click.stop="deleteSchedule" v-if="canDelete">
                    <i class="zmdi zmdi-delete"></i>
                </button>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
    export default {
        props: {
            schedule: {
                type: Object,
                default: {},
            },
            canDelete: {
                type: Boolean,
                default: false,
            },
            readOnly:{
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {};
        },

        mounted: function () {},

        computed: {
            dailySchedule: function () {
                if ((this.schedule.schedule_type == "daily") ||
                    this.schedule["_destroy"] != null ||
                    (this.schedule.week_days != null &&
                        (Object.keys(this.schedule.week_days).length == 0 ||
                            !Object.values(this.schedule.week_days).includes(
                                false
                            )) &&
                        Object.keys(this.schedule.dates).length == 0 &&
                        this.schedule.times.length == 0)
                ) {
                    return true;
                }
                return false;
            },
        },

        methods: {
            editSchedule: function () {
                this.$emit("change", this.schedule);
            },

            deleteSchedule: function () {
                if (this.canDelete) {
                    this.$set(this.schedule, "_destroy", true);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.schedule-composition-container {
    flex: 1;
    div.everyday {
        flex: 1;
        border: 1px solid #dfdfdf;
        padding: 2px 8px;
        margin: 2px 5px;
        border-radius: 5px;
        cursor: pointer;
        div:nth-child(1) {
            padding: 2px;
            text-align: center;
            background-color: #049d04;
            border-radius: 5px;
            color: #fff;
            margin: 4px 0px;
            > div {
                margin: 0px 0px 0px 5px;
            }
        }
        div:nth-child(2) {
            flex: 1 0.6;
            display: flex;
            margin: 0px 4px;
            line-height: 16px;
            .action {
                margin-left: auto;
                button {
                    border: 0px none;
                    border-radius: 3px;
                    padding: 0px 4px;
                    background: none;
                }
            }
        }
    }
}
</style>
