<template>
  <div class="custom-alert-main-container">
    <div class="custom-alert-header-container">
      <NotificationHeader />
    </div>
    <div class="custom-alert-items-container" v-if="notifications.length > 0">
      <NotificationItem
        v-for="notification in notifications"
        :notification="notification"
        :key="notification.id"
      />
    </div>
    <div class="custom-alert-items-container" v-else>
      <span>{{ $t('user.notification.no_data')}}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NotificationService from "../../services/NotificationService";
import NotificationItem from "./NotificationItem.vue";
import NotificationHeader from "./NotificationHeader.vue";
export default {
  props: {},
  data() {
    return {
      notifications: [],
      fields: ["Conditions", "created_at", "View"],
    };
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    getNotifications: function () {
      let _this = this;
      NotificationService.list()
        .then((response) => {
          _this.notifications = response.data;
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
    formatTime: function (time) {
      return moment(time).format("DD-MMM-YYYY h:mm A zz");
    },

  },
  components: {
    NotificationItem,
    NotificationHeader
  },
};
</script>
<style scoped>
.custom-alert-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
.custom-alert-header-container {
  display: flex;
  flex: 0;
}
.custom-alert-items-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

</style>
