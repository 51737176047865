<template>
    <div class="card shadow-sm col-lg-12 h-100 overflow-hidden right-side-container">
        <div class="header shadow-sm col-lg-12 p-2 text-right border-bottom">
            <h3 class="float-left mt-1 mb-0">
                {{ $t("subscription.title") }}
            </h3>
        </div>
        <subscription :subscription="subscription"
                      @updated="reload_subscription()"
                      v-if="subscription != null"
                    />
        
    </div>

</template>

<script>
    import Subscription from "./Subscription";
    import SubscriptionService from "../../services/SubscriptionService";
    export default {
        data() {
            return {
                subscription: null,
                sub_session_id: null,
                total_pages: 0,
                page: 1,
                loading: false,
                currencies: ["USD", "INR"],
                selected_currency: null,
                currency_switch: true,
            };
        },

        components: {
            Subscription,
        },

        mounted() {
            this.get_subscription();
        },

        methods: {
            get_subscription: function () {
                let _this = this;
                _this.loading = true;
                SubscriptionService.get()
                    .then((response) => {
                        _this.subscription = response.data
                        _this.loading = false;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },
            reload_subscription: function () {
                this.get_subscription();
            },
        },
    };
</script>

<style lang="scss">
.plan {
    .card-body {
        padding: 0;
    }
}
.action-container {
    position: absolute;
    right: 100px;
    top: 10px;
    .switch {
        position: relative;
        display: inline-block;
        width: 90px;
        height: 34px;
    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        font-weight: 500;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #4354a6;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #4354a6;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(55px);
        -ms-transform: translateX(55px);
        transform: translateX(55px);
    }

    /*------ ADDED CSS ---------*/
    .btn-color-mode-switch{
        display: inline-block;
        margin: 0px;
        position: relative;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner{
        margin: 0px;
        width: 150px;
        height: 36px;
        background: #E0E0E0;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
        display: block;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
        content: attr(data-on);
        position: absolute;
        font-weight: 500;
        top: 7px;
        right: 20px;

    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
        content: attr(data-off);
        width: 70px;
        height: 30px;
        background: #fff;
        border-radius: 5px;
        position: absolute;
        left: 3px;
        top: 3px;
        text-align: center;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 6px -2px #FFF;
        padding: 5px 0px;
        font-weight: 600;
    }


    .btn-color-mode-switch input[type="checkbox"]{
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
        background: #DFDFDF;
        color: #000;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
        content: attr(data-on);
        left: 68px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
        content: attr(data-off);
        right: auto;
        left: 20px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{

        color: #000;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked ~ .alert{
        display: block;
    }

}
</style>

