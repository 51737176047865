import EventBus from '../core/event-bus'
/**
 * Response interceptor which checks the response status code and if it is 401 (i.e Unauthenticated) then
 * attempts to redirect the user to login screen.
 */
const Authorization = {
	accept(config) {
		EventBus.$emit('progress', false)
		return config;
	},

	reject(error) {
		EventBus.$emit('progress', false)
		if (401 === error.response.status) {
			window.location = '/';
		} else if (402 === error.response.status) {
			EventBus.$emit("inactive");
		} else {
			return Promise.reject(error);
		}
	}
};


export default {

	/**
	 * Sets up response interceptors
	 * @param axios
	 */
	use(axios) {
		axios.interceptors.response.use(Authorization.accept, Authorization.reject);
	}
}
