<template>
  <div
    class="file-item-container"
    :class="`${uploadState != 2 ? 'success' : 'failed'}`"
  >
    <div class="left-section">
      <div class="left-section-status">
        <b-spinner
          v-if="uploadState == 0"
          small
          variant="success"
          label="Small Spinning"
        ></b-spinner>
        <i
          v-if="uploadState == 1"
          class="zmdi zmdi-check-circle file-upload-success-icon"
        ></i>
        <b-button
          v-if="uploadState == 2"
          variant="primary"
          @click="retryUpload()"
        >
          <i class="zmdi zmdi-replay"></i>
        </b-button>
      </div>

      <div class="left-section-info">
        <div class="file-name-container">
          <span class="file-name">{{ file.name }}</span>
        </div>
        <span class="file-size">{{
          $t("upload.upload_size", { size: fileSize })
        }}</span>
        <span v-show="uploadState == 2" class="file-upload-failed-label">{{
          $t("upload.upload_failed")
        }}</span>
      </div>
    </div>
    <div class="right-section">
      <span>{{ uploadPercentage }}%</span>
    </div>
  </div>
</template>
<script>
import EventBus from "../../../core/event-bus";
import Csrf from "../../../utils/Csrf";
import axios from "axios";
import DirectUploadWithPresign from "../../mixins/DirectUploadWithPresign"

//uploadState => 0 upload pending, 1 upload success, 2 upload failed
export default {
  props: {
    file: {
      type: File,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      uploadPercentage: 0,
      uploadState: 0,
      isUploadRetrying: false,
    };
  },
  computed: {
    fileSize: function () {
      if (this.file.size == 0) {
        return "0.00 B";
      }
      var e = Math.floor(Math.log(this.file.size) / Math.log(1024));
      return (
        (this.file.size / Math.pow(1024, e)).toFixed(2) +
        " " +
        " KMGTP".charAt(e) +
        "B"
      );
    },
  },
  mounted() {
    this.upload();
  },

  mixins: [DirectUploadWithPresign],

  methods: {
    retryUpload: function () {
      this.uploadState = 0;
      this.isUploadRetrying = true;
      this.upload();
    },

    upload: function(){
        this.uploadFile(this.file, this.index, this.saveContent)
    },

    saveContent: function( blob_signed_id) {
          let url = "user/contents/";
          let token = Csrf.token();
          let fileData = new FormData();
          fileData.append("content[file]", blob_signed_id);
          fileData.append("content[name]", this.file.name);
          fileData.append("content[size]", this.file.size);
          fileData.append("content[content_type]", this.file.type);
          let _this = this;
    
          axios
            .post(url, fileData, {
              headers: {
                "Content-Type": "multipart/form-data",
                "X-CSRF-Token": token,
              }
            })
            .then(function () {
              _this.uploadState = 1;
              EventBus.$emit("uploadSuccess", _this.index);
            })
            .catch(function () {
              _this.uploadState = 2;
              if (_this.isUploadRetrying == false) {
                EventBus.$emit("uploadFailed", _this.index);
              }
            });
        },
  },
};
</script>
<style scoped>
.file-item-container {
  display: flex;
  flex: 0;
  justify-content: space-between;
  align-items: center;

  margin-left: 16px;
  margin-right: 16px;
  margin-top: 4px;
  margin-bottom: 4px;

  padding: 4px;
  border-radius: 4px;
}

.file-item-container.success {
  background-color: rgba(7, 209, 240, 0.132);
  border: 1px solid rgba(7, 209, 240, 0.544);
}

.file-item-container.failed {
  background-color: rgba(192, 50, 3, 0.037);
  border: 1px solid rgba(247, 64, 3, 0.905);
}

.left-section {
  display: flex;
  flex: 0.95;
  flex-direction: row;
}

.left-section-status {
  display: flex;
  flex: 0;
  justify-content: space-around;
  align-items: center;
}

.left-section-info {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}
.right-section {
  display: flex;
  flex: 0.05;

  justify-content: space-around;
  align-items: center;
}
.file-name-container {
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: pre-wrap
}

.file-name {
  font-size: 18px;
  font-weight: 500;
}

.file-size {
  font-size: 16px;
  color: #918e8e;
}
.file-upload-failed-label {
  font-size: 16px;
  color: red;
}

.file-upload-success-icon {
  color: green;
}
</style>
