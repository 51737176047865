<template>
<div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <div class="header shadow-sm col-lg-12 p-2 text-right border-bottom">
        <h3 class='float-left'> {{$t('widget.template.title')}}({{total_count}}) </h3>
    </div>
    <scroll-pagination :scrollFunction="handleScroll" :loading="loading" :page="page" :total_pages="total_pages" v-show="!loading && widgets.length > 0">

        <custom-template :widget="widget" v-for="widget in this.widgets" :key="'widget' + widget.id" @click="show_template" />

    </scroll-pagination>
    <add-template :widget="selected_widget" :contentData="getContent" ref="add_template" @close="selected_widget = null" v-if="selected_widget != null">
    </add-template>
</div>
</template>

<script>
import WidgetService from '../services/WidgetService'
import CustomTemplate from './Template'
import AddTemplate from './add_widget_forms/AddTemplate'

export default {

    data() {
        return {
            widgets: [],
            loading: false,
            selected_widget: null,
            total_count: null,
            total_pages: null,
            page: 1,
        }
    },

    components: {
        CustomTemplate,
        AddTemplate
    },

    mounted() {
        this.getWidgets()
    },

    computed: {
        getContent: function () {
            return {
                id: null,
                name: 'New Template',
                content_type: 'Widget',
                duration: 3,
                resize_mode: 'cover',
                metadata_hash: {
                    json: ''
                },
                widget: this.selected_widget
            }
        }
    },

    methods: {
        getWidgets() {
            let _this = this;
            _this.loading = true
            WidgetService.list({ page: this.page, template: true })
                .then(response => {
                    _this.widgets = _this.widgets.concat(response.data['widgets'])
                    _this.loading = false
                    _this.total_pages = response.data.total_pages
                    _this.total_count = response.data.total_count

                })
                .catch(error => {
                    _this.handleError(error)
                })
        },

        async show_template(widget) {
            await this.setSelectedWidget(widget)
            let dialog_id = "form_" + this.selected_widget.id
            $("#" + dialog_id).addClass('d-block');
            this.$refs.add_template.showModal()
        },

        setSelectedWidget: function (widget) {
            this.selected_widget = widget
        },

        handleScroll: function () {
            this.page++
            this.getWidgets()
        }
    }
}
</script>
