import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function(value) {
	if (value) {
		return moment(String(value)).format('MM/DD/YYYY, hh:mm a')
	}
})

Vue.filter('nextBillingDateFormat', function(value) {
	if (value) {
		return moment(String(value)).format('DD MMMM YYYY, hh:mm a')
	}
})

Vue.filter('formatDateOnly', function(value) {
	if (value) {
		const date = new Date(value)
		return moment(date.toISOString()).format('DD-MMM-YYYY')
	}
})
