import Vue from 'vue';
import Http from '../core/http';

const NotificationService = {
	list() {
		return Http.get('account/notifications.json');
	},

};

export default NotificationService;
