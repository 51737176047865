<template>
  <div class="form-group file-input">
      <label for="firstName">{{ label }}</label>
      <input type="file"
             ref="fileInput"
             class="form-control"
             @change="handleChange"/>
      <b-button class="position-absolute float-left" variant="primary">
                {{ $t('branding.select_logo')}}
      </b-button>
  </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            validate: {
              type: String,
              default: ''
            }
        },

        inject: ['$validator'],

        methods: {
          handleChange(e) {
            this.emitValue(e.target.files[0]);
          },
          emitValue(file) {
            this.$emit('input', file);
          },
        }
    }
</script>
<style lang="scss">
  .file-input{
    input{
      opacity: 0;
      z-index: 999;
      margin: 0px -2px;
      width: 130px;
      height: 40px;
      cursor: pointer;
      position: relative;
    }
    button{
      z-index: 1;
      margin: -40px 0px;
    }
  }
</style>
