<template>
  <scroll-pagination
    :scrollFunction="handleScroll"
    :loading="loading"
    :page="page"
    :total_pages="total_pages"
    :key="shouldRerenderList"
    v-if="contents.length > 0"
  >
    <library-content
      :content="content"
      v-for="content in this.contents"
      v-show="contents.length"
      :key="content.id"
      @deleted="reload_contents"
      @updated="reload_contents"
      @click="listenAction"
    />

    <edit-file
      :content_id="selected_content.id"
      @updated="reload_contents"
      v-if="selected_content != null"
    />
    <expiration
      :content_id="selected_content.id"
      @updated="reload_contents"
      v-if="selected_content != null"
    />
  </scroll-pagination>

  <div class="d-flex h-100 m-auto" v-else>
    <no-content
      :title="$t('content.no_content_title')"
      :imageName="'no_content.png'"
      :actionText="$t('content.no_content')"
      :nextSteptText="$t('content.no_content_next_step')"
      v-if="
        !loading &&
        !this.contents.length &&
        !this.queryParam.deleted &&
        this.queryParam.query.length == 0
      "
      @clicked="showUploadDialog"
    />
    <no-search-result
      :searchText="this.queryParam.query"
      v-if="
        !this.loading &&
        !this.contents.length &&
        this.queryParam.query.length > 0
      "
    />
  </div>
</template>

<script>
import EventBus from "../core/event-bus";
import NoContent from "../components/NoContent";
import ContentService from "../services/ContentService";
import NoSearchResult from "../components/NoSearchResult";
import EditFile from "./Edit";
import Expiration from "./Expiration.vue";
import { showDeleteConfirmation } from "../utils/DeleteConfirmation";
import LibraryContent from "./LibraryContent.vue";
import ActionCableListener from "../mixins/ActionCableListener";

export default {
  mixins: [ActionCableListener],
  props: {
    queryParam: {
      Type: Object,
      default: null,
    },
  },
  data() {
    return {
      contents: [],
      loading: false,
      selected_content: null,
      page: 1,
      height: 0,
      total_pages: 0,
      shouldRerenderList: false,
    };
  },
 
  components: {
    NoContent,
    NoSearchResult,
    Expiration,
    EditFile,
    LibraryContent,
  },

  mounted() {
    let _this = this;
    if (this.queryParam.deleted == false) {
      EventBus.$on("newFilesAvailable", function () {
        _this.reload_contents();
      });
    }
    this.fetchContents(this.queryParam);

    this.startActionCableListener(function (data) {
      let updatedContent = data["content"];
      let index = _this.contents.findIndex(function (file) {
        return file.id === updatedContent.id;
      });
      let content = _this.contents[index];
      if (index > -1 && updatedContent.id == content.id) {
        content.file_url = updatedContent.file_url;
        content.img_url = updatedContent.thumbnail_url;
        content.duration = updatedContent.duration;
        content.content_type = updatedContent.content_type;
        content.status = updatedContent.status;
        //set force to rerender
        _this.shouldRerenderList = !_this.shouldRerenderList
      }
    });
  },

  beforeDestroy() {
    if (this.queryParam.deleted == false) {
      EventBus.$off("newFilesAvailable");
    }
    this.stopActionCableListener();
  },

  methods: {
    fetchContents: function (query_params) {
      this.loading = true;
      ContentService.list(query_params)
        .then((response) => {
          this.contents = response.data.contents;
          this.loading = false;
          this.total_pages = response.data.total_pages;
          let contentsCount = response.data.total_count;
          if (this.queryParam.deleted == false) {
            EventBus.$emit("contentsCountUpdated", contentsCount);
          } else {
            EventBus.$emit("deletedContentsCountUpdated", contentsCount);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    fetchNextPageContents() {
      let query_param = { ...this.queryParam }; //deep copy
      query_param.page = this.page;
      let _this = this;
      this.loading = true;
      ContentService.list(query_param)
        .then((response) => {
          if (this.page == 1) {
            _this.contents = [];
          }
          _this.contents.push(...response.data.contents);
          _this.loading = false;
          if (this.height != null) {
            setTimeout(function () {
              _this.resize_container();
            }, 200);
          }
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },

    reload_contents() {
      this.selected_content = null;
      this.fetchContents(this.queryParam);
    },
    showUploadDialog() {
      EventBus.$emit("showUploadSelectionModel");
    },

    set_expiration() {
      let _this = this;
      this.$bvModal.show("expire_content" + _this.selected_content.id);
    },

    setSelectedContent: function (content) {
      this.selected_content = content;
    },

    resize_container: function () {
      let active_tab = document.getElementsByClassName("tab-pane active")[0];
      let elements = active_tab.getElementsByClassName(
        "content-thumbnail-image"
      );
      if (elements.length) {
        let width = elements[0].offsetWidth;
        this.height = width * 0.53;
      }
    },

    handleScroll: function () {
      this.page++;
      this.fetchNextPageContents();
    },

    async listenAction(options) {
      await this.setSelectedContent(options.content);
      this.$nextTick(() => {
        this[options.link["action"]]();
      });
    },

    edit: function () {
      if (this.selected_content.type == "File") {
        this.$bvModal.show("edit_file_" + this.selected_content.id);
      } else if (this.selected_content.widget.template) {
        this.$router.push({
          name: "editTemplate",
          params: {
            content_id: this.selected_content.id,
          },
        });
      } else {
        this.$router.push({
          name: "editapp",
          params: {
            content_id: this.selected_content.id,
          },
        });
      }
    },

    delete_file() {
      let _this = this;
      showDeleteConfirmation(
        _this,
        _this.$t("confirmation_alert.content_delete_message")
      ).then((result) => {
        if (result.value) {
          ContentService.destroy(_this.selected_content.id)
            .then((response) => {
              _this.reload_contents();
            })
            .catch((error) => {
              _this.handleError(error);
            });
        }
      });
    },
    showPreviewMenuItemClick: function () {
      this.$emit("click", this.selected_content);
    },
    view_report: function () {
      this.$router.push({
        name: "reports",
        query: { content_id: this.selected_content.id },
      });
    },
    restore_content: function () {
      ContentService.update(this.selected_content.id, {
        expires_at: null,
      })
        .then((response) => {
          this.reload_contents();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    showPreview: function (op) {
      this.$emit("click", this.selected_content);
    },
  },
};
</script>
