<template>
  <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
     <div class="header shadow-sm col-lg-12 p-2 text-right p-2 border-bottom">
       <h3 class='float-left'> {{$t('user.title')}} </h3>
      </div>
      <div class="row m-2" v-show="this.getUsers().length > 0">
          <user :user="user"
                  v-for="user in this.getUsers()"
                  :key="'user' + user.id"/>
      </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import User                     from './User'
  import EditProfile              from './EditProfile'

  export default {

      data () {
          return {
            loaded: false,
          }
      },

      components:{
        User,
        EditProfile
      },

      computed: {
        ...mapGetters('userStore',['user'])
      },

      methods: {
        getUsers() {
          return [this.user]
        },

        reload_page: function(){
          window.location.reload()
        },

      }
  }
</script>
<style>

</style>
