import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const SubscriptionService = {

	list(params) {
	  let query_string = queryString.stringify(params)
		return Http.get('user/subscriptions.json?' +  query_string);
	},

	new(params) {
		return Http.get('user/subscriptions/new.json',{
		  params: params
		});
	},

	create(params) {
		return Http.post('user/subscriptions.json', {
			subscription: params
		});
	},

	update(id, params) {
		return Http.put('user/subscriptions.json', {
			subscription: params
		});
	},

	get() {
		return Http.get('user/subscriptions.json');
	},

	add_remove_device(options) {
		return Http.post('user/subscriptions/add_remove_device.json', options);
	},

	pause() {
		return Http.post('user/subscriptions/pause.json');
	},

	resume() {
		return Http.post('user/subscriptions/resume.json');
	},

	upgrade() {
		return Http.post('user/subscriptions/upgrade.json');
	},

	downgrade() {
		return Http.post('user/subscriptions/downgrade.json');
	},

	refresh() {
		return Http.get('user/subscriptions/refresh.json');
	},

};

export default SubscriptionService;
