<template>
  <b-button class="btn-swatch rounded-circle "
            variant="outline"
            v-click-outside="hidePicker"
            @click="showColorPicker">
     <div class="color-swatch-bg"/>
     <div class="color-swatch" :class="swatchClass" :style="btnTextColor"/>
     <color-picker v-model="color"
                   value:="value.hex8"
                   v-show="showPicker"
                   :disableAlpha="disableAlpha"
                   @input="emitChange"
                   style="'top:' + this.top ';left:'+ this.left">
     </color-picker>
  </b-button>
</template>

<script>
import ColorPicker   from 'vue-color/src/components/Chrome.vue'

export default {

  props:{
    disableAlpha: false,
    value: {
      type: Object,
      default: { hex8: "00000000"}
    },
    swatchClass: ''
  },

  components: {
    ColorPicker
  },

  data()  {
    return {
      showPicker: false,
      top: 0,
      left: 0,
      color: ''
    }
  },

  computed: {
    btnTextColor: function(){
      if(this.value != null) {
        return {
          backgroundColor: this.value.hex8
        }
      }else{
        return {
          backgroundColor: '#00000000'
        }
      }
    },
  },

  methods: {

    emitChange: function(){
      this.$emit("input", this.color)
    },

    showColorPicker: function(event){
      this.$emit("show")
      let bonding = event.target.getBoundingClientRect().top
      this.top = bonding.top
      this.left = bonding.left + (bonding.width / 2)
      this.showPicker = true
      
    },

    hidePicker: function(){
      if(this.showPicker){
        this.showPicker = false
      }
    },
  },

  watch: {
    'value': {
      handler: function (new_value, old_value) {
        this.color = new_value.hex8;
      },
      deep: true
    },
  }

}
</script>
<style lang="scss">
  .color-swatch{
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 50%;
    top: 0px;
    left: 0px;
    position: absolute;
    border: 1px solid #5e6ebe;
  }
  .color-swatch-bg{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC);
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .btn-swatch{
    position: relative;
    width: 35px;
    height: 35px;
    margin: -5px 20px;
  }
  .vc-picker{
    margin: -30px 30px;
  }
  .vc-chrome{
    right: 20px;
    position: fixed;
    opacity: 1;
    z-index: 1024;
  }
  .vc-chrome-fields{
     .vc-input__input {
       font-size: 16px;
     }
  }
</style>
