<template>

    <div>
        <form ref="form" method="post" @submit.prevent="validate">
            <h4>{{$t('login.forgot_password.reset_your_password')}}</h4>
            <div class="form-group">
                <p>
                  {{$t('login.forgot_password.send_reset_link')}}
                </p>
            </div>
            <div class="form-group">
                <input type="email"
                    autocomplete="email"
                       class="form-control"
                       id="email"
                       :placeholder="$t('login.forgot_password.your_email')"
                       name="user[email]"
                       v-validate="'required'"
                       data-vv-name="email"
                       data-vv-as="Email">

            </div>
            <b-button :class="{disabled: !this.isFormDirty}"
                      variant="primary"
                      type="submit">
                      {{$t('login.forgot_password.reset_password')}}
            </b-button>
            <div class="float-right mt-2 no-account">
                <span class="float-left">{{$t('login.sign_up.already_have_account')}}</span>
                <router-link :to="{ name: 'login' }"
                             class="text-capitalize font-weight-bold pl-2">{{$t('login.sign_in.sign_in_title')}}
                </router-link>
            </div>
        </form>
    </div>

</template>

<script>
    import Csrf      from '../utils/Csrf';

    export default {
        data () {
            return {
                result: null,
            }
        },

        computed: {
            isFormDirty() {
              return Object.keys(this.dataFields).some(key => this.dataFields[key].dirty);
            }
        },

        methods: {
            onValidationSuccess () {
                let formData = new FormData(this.$refs.form);
                Csrf.append(formData);
                let _this = this
                this.$http
                    .post('/users/password/', formData)
                    .then(response => {
                      this.$eventBus.$emit('alert', {variant: 'success', message: _this.$t('login.forgot_password.success_message'), duration: -1 })
                    })
                    .catch(error => {
                        if (error.response) {
                            // HTTP error
                            let data = error.response.data;
                            if (data.error) {
                                this.result = data.error;
                            }
                        } else if (error.request) {
                            this.result = 'No Network available.';
                        } else {
                            this.result = error.message;
                        }
                    })
            },

            /**
             * Performs validation before the form gets submitted. For it to work, it needs to be
             * set as the event handler like this `@submit.prevent="validate"`
             */
            validate () {
                this.$validator.validateAll().then((result) => {
                    return result ? this.onValidationSuccess() : this.onValidationFailure();
                });
            },

        }
    }
</script>
<style>
</style>
