<template>
  <b-button variant="outline-primary" class="btn-sm" :class="{'active' : this.bold }"
          @click="toggle_text_bold" v-observe-visibility="visibilityChanged">
    <i class="zmdi zmdi-format-bold"></i>
  </b-button>
</template>
<script>
import Text from './Text.js'
export default {
  props: {
    canvas: {type: Object, default: {}},
    properties: {type:Object, default: {} }  
  },

  data() {
    return{
      bold: this.properties.bold
    }
  },

  mixins: [Text],
  
  methods: {
    visibilityChanged: function(isVisible) {
      if(isVisible){
        let object = this.canvas.getActiveObject()
        this.bold = object['fontWeight'] == 'bold' ? true : false
      }
    },

    toggle_text_bold: function(){
      this.bold = !this.bold
      this.toggleBold(this.bold);
      this.returnFocus();
    },

    toggleBold: function(isBlod) {
      let button = $("#toolbar-bold");
      let obj = this.canvas.getActiveObject();
      this.setStyle(obj, 'fontWeight', isBlod ? 'bold' : '');
      this.canvas.renderAll();
      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },
  }
}
</script>
