<template>
  <div class="form-element-container">
    <label class="form-element-label">
      {{ $t(element.field_label_text) }}
      <i class="form-element-sublable-hint-text">
        {{ $t(element.field_label_hint_text) }}
      </i>
    </label>
    <div class="form-element-control-container">
      <div class="form-element-required-container">
        <input
          :tabindex="this.index"
          type="number"
          class="form-control form-element-field"
          v-model="element.model"
          min="1"
          v-debounce:800ms.lock="onChange"
        />
        <span v-if="isRequiredElement" class="form-element-required-text">*</span>
      </div>
      <span v-if="isValid == false" class="text-danger">
        {{ $t(element.validation_error_message) }}
      </span>
    </div>
  </div>
</template>

<script>
import Element       from '../form_elements/helper/Element'
import EventBus from "../../../core/event-bus";
import { isElmentInputValid } from "./helper/validations";

export default {
  mixins: [Element],

  data() {
    return {
      isValid: true,
    };
  },
  computed: {
    isRequiredElement: function() {
      return this.element.validation === "required"
    },
  },
  created: function() {
    let _this = this;
    EventBus.$on("validate_widget_form", function() {
      _this.checkValidation();
    });
  },
   beforeDestroy(){
    EventBus.$off('validate_widget_form')
  },
  methods: {
    checkValidation: function() {
      this.isValid = isElmentInputValid(this.element);
    },
  },
};
</script>

<style></style>
