import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const ReportService = {
	list() {
		return Http.get('user/reports.json');
	},

	create(params) {
		return Http.post('user/reports.json', params);
	},

};

export default ReportService;
