import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Brandings from './Brandings';
import AddEditBranding from './AddEditBranding'

let routes = [{
	path: '/user/brandings',
	name: 'brandings',
	component: Brandings
},
{
	path: '/user/brandings/:id/add_branding',
	name: 'AddEditBranding',
	component: AddEditBranding
}];

export default routes;
