<template>
  <!-- The Modal -->
  <div ref="myModal" class="modal">
    <!-- Modal content -->
    <div class="previewer-aspect-ratio-box">
      <div class="header-box">
        <div class="header-content-name-container">
          <span class="content-previewing-label">
          {{ $t("common_words.previewing") }}
        </span>
        <span class="content-name-text">
          {{ this.content.name }}
        </span>
        </div>
        <b-button class="close-button" @click="hideModel" variant="danger">
          <i class="zmdi zmdi-close zmdi-hc-lg"></i>
        </b-button>
      </div>
      <div class="content-box">
        <ImagePreviewer
          v-if="getContentType() == 'image'"
          :content="this.content"
        />
        <widget-previewer
          v-else-if="getContentType() == 'widget'"
          :content="this.content"
        />
        <PDFPreviewer
          v-else-if="getContentType() == 'pdf'"
          :content="this.content"
        />
        <VideoPreviewer
          v-else-if="getContentType() == 'video'"
          :content="this.content"
        />
        <AudioPreviewer
          v-else-if="getContentType() == 'audio'"
          :content="this.content"
        />
        <NotSupportedPreviewer v-else :content="this.content" />
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../core/event-bus";
import AudioPreviewer from "./types/AudioPreviewer.vue";
import ImagePreviewer from "./types/ImagePreviewer.vue";
import NotSupportedPreviewer from "./types/NotSupportedPreviewer.vue";
import PDFPreviewer from "./types/PDFPreviewer.vue";
import VideoPreviewer from "./types/VideoPreviewer.vue";
import WidgetPreviewer from "./types/WidgetPreviewer.vue";
export default {
  data() {
    return {
      content: {
        type: Object,
        default: null,
      },
    };
  },

  components: {
    ImagePreviewer,
    WidgetPreviewer,
    PDFPreviewer,
    VideoPreviewer,
    NotSupportedPreviewer,
    AudioPreviewer,
  },

  created: function () {
    let _this = this;
    EventBus.$on("showModel", function (selectedContent) {
      _this.showModel(selectedContent);
    });
  },
  beforeDestroy() {
    EventBus.$off("showModel");
  },
  methods: {
    getContentType: function () {
      if (this.content.type == "Widget") {
        return "widget";
      } else {
        //file type again has sub types
        if (this.content.type == "File") {
          if (this.content.content_type.startsWith("image")) {
            return "image";
          } else if (this.content.content_type == "application/pdf") {
            return "pdf";
          } else if (this.content.content_type.startsWith("video")) {
            return "video";
          } else if (this.content.content_type.startsWith("audio")) {
            return "audio";
          } else {
            return "other";
          }
        } else {
          return "other";
        }
      }
    },
    showModel: function (selectedContent) {
      this.content = selectedContent;
      if(this.getContentType() == "widget") {
        EventBus.$emit("showWidgetPrevieModel", this.content.id);
      }
      this.$refs.myModal.style.display = "block";
    },
    hideModel: function () {
      this.$refs.myModal.style.display = "none";
      if(this.getContentType() == "audio") {
        EventBus.$emit("audioModelClosed");
      }
      if(this.getContentType() == "video") {
        EventBus.$emit("videoModelClosed");
      }
      if(this.getContentType() == "widget") {
        EventBus.$emit("widgetPrevieModelClosed");
      }
    },
  },
};
</script>
<style scoped>
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding: 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

.previewer-aspect-ratio-box {
  width: 90vw; /* 90% of viewport vidth */
  height: 50.625vw; /* ratio = 9/16 * 90 = 50.625 */
  max-height: 90vh;
  max-width: 160vh; /* 16/9 * 90 = 160 */
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.header-box {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 60px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #686666;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-box {
  position: absolute;
  top: 60px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #bebebe;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header-content-name-container {
  margin-left: 16px;
}
.preview-text {
  color: black;
  font-weight: 500;
  font-size: 30px;
}
.content-previewing-label {
  font-size: 20px;
  font-weight: 500;
  color: rgb(19, 19, 19);
}
.content-name-text {
  margin-left: 2px;
  font-size: 20px;
  font-weight: 400;
  color: rgb(63, 63, 63);
}
.close-button {
  margin-right: 16px;
}
</style>
