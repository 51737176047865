<template>
    <div class="form-container">
        <ScheduleWeeklyForm
            :schedule="newSchedule"
            ref="weekly"
            v-if="loaded && newSchedule.schedule_type == 'weekly'"
        />

        <ScheduleSpecificDateForm
            :schedule="newSchedule"
            ref="specific_date"
            v-if="loaded && newSchedule.schedule_type == 'specific_date'"
        />
    </div>
</template>
<script>
    import ModalDialog from "../../components/ModalDialog";
    import Tabs from "../../components/Tabs.vue";
    import ScheduleSpecificDateForm from "./ScheduleFormComposition/ScheduleSpecificDateForm.vue";
    import ScheduleWeeklyForm from "./ScheduleFormComposition/ScheduleWeeklyForm.vue";

    export default {
        props: {
            schedule: {
                type: Object,
                default: {
                    weekdays: {},
                    dates: [],
                    times: [],
                },
            },
        },
        data() {
            return {
                newSchedule: { dates: [], times: [], week_days: {} },
                error: null,
                open: false,
                schedule_types: ["daily", "weekly", "specific_date"],
                display_schedule_types: this.$t(
                    "channel.media.schedule.schedule_types"
                ),
                loaded: false
            };
        },

        components: {
            ModalDialog,
            Tabs,
            ScheduleSpecificDateForm,
            ScheduleWeeklyForm,
        },

        beforeMount: function () {
            if(this.schedule.dates != null){
                this.newSchedule.dates = [...this.schedule.dates]
            }
            if(this.schedule.times != null){
                this.newSchedule.times = [...this.schedule.times]
            }
            this.newSchedule.schedule_type = this.schedule.schedule_type
            this.newSchedule.week_days = Object.assign({}, this.schedule.week_days)
            this.newSchedule.id = this.schedule.id
            this.loaded = true
        },

        methods: {
            get_form: function () {
                return this.$refs[this.newSchedule.schedule_type];
            },

            getScheduleType: function () {
                if (
                    Object.keys(this.newSchedule.week_days).length == 0 &&
                    this.newSchedule.dates.length == 0 &&
                    this.newSchedule.times.length == 0
                ) {
                    return "daily";
                } else if (Object.keys(this.schedule.week_days).length > 0) {
                    return "weekly";
                } else {
                    return "specific_date";
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.header-name-container {
    flex: 0.8;
    align-items: center;
    display: flex;
}
.header-close-container {
    flex: 0.2;
    display: flex;
    justify-content: flex-end;
}
</style>
