<template>
    <div class="wizard-main-container">
        <header-toolbar
            :canvas="canvas"
            :content="content"
            :layout="layout"
            :widget="widget"
            :properties="properties"
            @dragStart="setDrag(true)"
            @dragStop="setDrag(false)"
            v-if="this.canvas != null"
        />
        <div id="editor" class="col-lg-12 p-0">
            <div class="row m-0">
                <div class="sidebar-lhs">
                    <main-toolbar
                        :widget="widget"
                        :canvas="canvas"
                        :properties="properties"
                        v-if="this.canvas != null"
                        @selected="updateSelectedTool"
                    />
                </div>
                <div class="editor-container">
                    <div
                        class="main-canvas-container"
                        :class="{ 'grab-bing': this.startDrag }"
                        v-dragscroll="this.startDrag"
                    >
                        <canvas :id="editor_id"></canvas>
                    </div>
                </div>
                <div class="sidebar-rhs">
                    <text-toolbar
                        v-if="checkActiveObjectText"
                        :canvas="canvas"
                        :properties="properties"
                    />
                    <img-toolbar
                        :canvas="canvas"
                        @cropStarted="handleImageCrop(true)"
                        @cropCompleted="handleImageCrop(false)"
                        v-show="checkActiveObjectImage"
                    />
                    <prop-toolbar
                        :canvas="canvas"
                        :properties="properties"
                        :selectedTool="selectedTool"
                        v-if="this.canvas != null"
                    />
                </div>
            </div>
            <select-layout v-on="{ 'layout.change': setLayout }" />
            <spinner :show_loader="show_loader" />
        </div>
    </div>
</template>

<script>
    import { dragscroll } from 'vue-dragscroll'
    import Spinner from '../../components/Spinner'
    import MainToolbar from './MainToolbar'
    import TextToolbar from './TextToolbar'
    import ImgToolbar from './ImgToolbar'
    import PropToolbar from './PropToolbar'
    import State from './State.js'
    import Text from './text/Text.js'
    import Utils from './Utils.js'
    import HeaderToolbar from './HeaderToolbar.vue'
    import SelectLayout from './SelectLayout.vue'

    export default {
        props: {
            content: { type: Object, default: {} },
            editor_id: { type: String, default: 'canvas' },
            zoom: { type: Number, default: 0 },
            widget: { type: Object, default: {} }
        },

        data() {
            return {
                canvas: null,
                block: null,
                loaded: false,
                activeObject: null,
                show_loader: false,
                cropEnable: false,
                startDrag: false,
                layout: null,
                isDrawingMode: false,
                selectedTool: '',
                properties: {
                    font_size: 22,
                    font_family: 'arial',
                    bold: false,
                    italics: false,
                    underline: false,
                    text_color: { hex8: '#000000FF' },
                    image: '',
                    fill_color: { hex8: '#DFDFDF' },
                    stroke_color: { hex8: '#FF0000' },
                    stroke_width: 5,
                    scale_value: 1,
                    bold: false,
                    italics: false,
                    underline: false,
                    corner_radius: 0
                }
            }
        },

        directives: {
            dragscroll: dragscroll
        },
        mixins: [State, Text, Utils],

        components: {
            MainToolbar,
            TextToolbar,
            ImgToolbar,
            PropToolbar,
            HeaderToolbar,
            SelectLayout,
            Spinner
        },
        computed: {
            checkActiveObjectImage() {
                if (this.canvas != null) {
                    this.activeObject = this.canvas.getActiveObject()
                    if (this.activeObject == null) {
                        return false
                    }
                    return (
                        (this.activeObject.type == 'image' || this.cropEnable) &&
                        !this.canvasDrawingMode
                    )
                } else {
                    return false
                }
            },

            checkActiveObjectText() {
                if (this.canvas != null) {
                    this.activeObject = this.canvas.getActiveObject()
                    return (
                        this.activeObject != null &&
                        this.activeObject.type == 'i-text' &&
                        !this.canvasDrawingMode
                    )
                } else {
                    return false
                }
            },

            textColor() {
                if (this.canvas != null) {
                    if (this.activeObject != null) {
                        return this.activeObject.fill
                    }
                    return this.properties.text_color
                } else {
                    return false
                }
            },

            canvasDrawingMode: function () {
                return this.canvas != null && this.canvas.isDrawingMode
            }
        },

        mounted() {
            let _this = this
            this.$eventBus.$on('show_loader', function (show_loader) {
                _this.show_loader = show_loader
            })
            window.addEventListener('resize', function () {
                _this.setDimension()
            })
        },

        methods: {
            init: function () {
                if (this.layout == null) {
                    this.getLayout()
                    return
                }
                let canvas = $('#editor').find('canvas#' + this.editor_id)
                this.canvas = new fabric.Canvas(canvas[0], {
                    preserveObjectStacking: true
                })
                let _this = this
                fabric.Object.prototype.transparentCorners = false
                fabric.Object.prototype.cornerColor = 'blue'
                fabric.Object.prototype.cornerStyle = 'square'
                this.addEventListener()
            },

            addEventListener: function () {
                let _this = this
                this.canvas.on('object:statechange', function () {
                    _this.activeObject = _this.canvas.getActiveObject()
                })

                this.canvas.on('selection:created', function () {
                    _this.activeObject = _this.canvas.getActiveObject()
                })
                this.canvas.on('selection:updated', function (event) {
                    _this.activeObject = _this.canvas.getActiveObject()
                })
                this.canvas.on('selection:created', function () {
                    _this.deleteHandler()
                    $(document).off('keydown', this.deleteHandler)
                    _this.activeObject = _this.canvas.getActiveObject()
                })
                this.canvas.on('selection:cleared', function () {
                    _this.deleteHandler()
                    _this.activeObject = null
                })
                this.canvas.on('selection:updated', function (event) {
                    _this.deleteHandler()
                    _this.newActiveObject = _this.canvas.getActiveObject()
                    _this.activeObject = null
                    _this.$nextTick(() => {
                        _this.activeObject = _this.newActiveObject
                    })
                })
            },

            setDimension: function () {
                let width = 1024
                let height = 1920
                if (this.layout == 'landscape') {
                    width = 1920
                    height = 1024
                }
                this.canvas.setWidth(width)
                this.canvas.setHeight(height)
                this.properties.scale_value = this.scaleValue(width, height)
                this.zoomCanvas(0)
            },

            scaleValue: function (width, height) {
                let canvas_width = $(
                    this.canvas.wrapperEl.parentElement
                ).innerWidth()
                let canvas_height = $(
                    this.canvas.wrapperEl.parentElement
                ).innerHeight()
                let width_scale_value = canvas_width / width
                let height_scale_value = canvas_height / height
                return Math.min(width_scale_value, height_scale_value)
            },

            loadData: function () {
                if (this.layout != null) {
                    this.canvas.clear()
                    let _this = this
                    if (this.content.id != null) {
                        this.canvas.loadFromJSON(this.content.metadata['json'])
                    } else if (this.widget != null) {
                        if (this.widget.metadata != null) {
                            this.canvas.loadFromJSON(
                                JSON.stringify(this.widget.metadata['json'])
                            )
                        }
                    }
                }
                this.canvas.renderAll()
            },

            setDrag: function (startDrag) {
                this.startDrag = startDrag
            },

            getLayout: function () {
                let layout = null
                if (this.content.metadata == null && this.widget.metadata != null) {
                    layout = this.widget.metadata['layout']
                } else if (this.content.metadata != null) {
                    layout = this.layout = this.content.metadata['layout']
                }
                if (layout == null) {
                    this.$bvModal.show('select_layout')
                } else {
                    this.setLayout(layout)
                }
            },

            setLayout: function (layout) {
                this.layout = layout
                this.init()
                this.setDimension()
                this.loadData()
            },

            handleImageCrop: function (cropEnable) {
                this.cropEnable = cropEnable
            },

            updateSelectedTool: function (tool_type) {
                this.selectedTool = tool_type
            },

            deleteHandler: function () {
                let _this = this
                // Handler for the delete and backspace keys
                $(document).on('keyup', function (e) {
                    if (e.which == 46 || e.which == 8) {
                        // Block the functionality if user is entering text
                        var active = $(document.activeElement)
                        if (
                            active.is(
                                'input,textarea,text,password,file,email,search,tel,date'
                            )
                        ) {
                            return
                        }

                        _this.deleteSelected()
                        e.preventDefault()
                    }
                })
            },

            deleteSelected: function () {
                // Delete the current object(s)
                var activeObjects = this.canvas.getActiveObjects()
                this.canvas.discardActiveObject()
                if (activeObjects.length) {
                    this.canvas.remove.apply(this.canvas, activeObjects)
                }
            }
        }
    }
</script>

<style lang="scss">
#editor {
    .sidebar-lhs {
        width: 110px;
        height: 100%;
        background-color: #f7f7f7;

        .list-group {
            color: #5e6ebe;

            .zmdi {
                color: #5e6ebe;
            }
        }
    }

    .editor-container {
        width: calc(100% - 400px);
        float: left;
        height: calc(100vh - 79px);
        display: flex;
        background: #bebebe;

        .selected {
            cursor: grabbing;
            cursor: -webkit-grabbing;
        }

        .main-canvas-container {
            margin: 20px;
            width: 100%;
            display: flex;
            overflow: hidden;
            position: relative;
            align-items: center;
            justify-content: center;
        }
    }

    .canvas-container {
        background: #ffffff;
        position: absolute !important;
        top: 0px;
        transform-origin: top center !important;
    }

    .sidebar-rhs {
        width: 290px;
        height: 100%;
        background-color: #f7f7f7;
        top: 0px;
        right: 0px;
        border-left: 1px solid #dfdfdf;
    }

    .grab-bing {
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: -o-grab;
        cursor: grab;
    }

    .grab-bing:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: -o-grabbing;
        cursor: grabbing;
    }

    .list-group-item {
        background: transparent;

        .zmdi {
            font-size: 34px;
        }
    }
}
textarea[data-fabric-hiddentextarea] {
    top: 20px !important;
    left: 100px !important;
}
</style>
