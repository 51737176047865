<template>
<b-button variant="outline-primary" class="btn-sm" :class="{ 'active': this.underline }" @click="toggle_text_underline" v-observe-visibility="visibilityChanged">
    <i class="zmdi zmdi-format-underlined"></i>
</b-button>
</template>

<script>
import Text from './Text.js'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} }
    },

    data() {
        return {
            underline: this.properties.underline
        }
    },

    mixins: [Text],

    methods: {
        toggle_text_underline: function () {
            this.underline = !this.underline
            this.toggleUnderline(this.underline);
            this.returnFocus();
        },

        visibilityChanged: function (isVisible) {
            if (isVisible) {
                let object = this.canvas.getActiveObject()
                this.underline = object['underline']
            }
        },

        toggleUnderline: function (isUnderline) {
            var button = $("#toolbar-underline");
            var obj = this.canvas.getActiveObject();
            this.setStyle(obj, 'underline', isUnderline);
            this.canvas.renderAll();
            // Push the this.canvas state to history
            this.canvas.fire("object:statechange");
        },

    }
}
</script>
