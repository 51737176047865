<template>
    <div class="date-input-container">
        <VueMaterialDateTimePicker
            v-model="date_time"
            :value="date_time"
            :valueFormatted="formated_date_time"
            :is-date-only="date_only"
            :placeholder="$t('content.expiration_time_placeholder')"
            @input="emitChange"
        />
    </div>
</template>

<script>
    import VueMaterialDateTimePicker from "vue-material-date-time-picker";
    import EventBus from "../core/event-bus";

    import moment from "moment";

    export default {
        components: {
            VueMaterialDateTimePicker,
        },
        props: {
            datetime: {
                type: String,
                default: null,
            },
            date_only: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                date_time: this.datetime != null ? new Date(this.datetime) : null,
                loaded: false,
            };
        },

        mounted: function () {},

        computed: {
            formated_date_time: function () {
                return this.date_time;
            },
        },

        methods: {
            emitChange: function () {
                let changed_datetime =
                    this.date_time == null ? null : this.date_time.toUTCString();
                this.$emit("input", changed_datetime);
                this.$emit("date_input_change");
            },
        },
    };
</script>


<style lang="scss">
div.date-input-container {
    input {
        display: flex;
        flex: 1;
        color: black;
        font-size: 18px !important;
        background-color: white !important;
        border: none !important;
        padding: 0px !important;
    }

    input:focus-visible {
        border: 2px dashed crimson;
        border-radius: 3px;
        outline: none;
    }
    label {
        margin: 0;
        padding-left: 0px;
        width: 100%;
        border: none !important;
    }
}
</style>
