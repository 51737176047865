<template>
    <b-modal
        id="select_layout"
        class="modal layout-modal"
        size="lg"
        :title="$t('widget.template.select_layout')"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
    >
        <div class="layout-container col-12 p-4 d-flex">
            <button
                class="
                    btn btn-secondary
                    col-5
                    p-4
                    border
                    text-center
                    float-left
                    m-auto
                    mt-4
                "
                :disabled="disabled"
                @click="setLayout('landscape')"
            >
                <i class="zmdi zmdi-smartphone-landscape font-14"></i>
                <div>Landscape</div>
                <div>size: 1920 x 1024</div>
            </button>
            <button
                class="
                    btn btn-secondary
                    col-5
                    p-4
                    border
                    text-center
                    float-right
                    m-auto
                "
                :disabled="disabled"
                @click="setLayout('portrait')"
            >
                <i class="zmdi zmdi-smartphone-iphone"></i>
                <div>Portrait</div>
                <div>size: 1024 x 1920</div>
            </button>
        </div>
    </b-modal>
</template>

<script>
    export default {
        data() {
            return {
                disabled: false
            }
        },

        methods: {
            setLayout(layout) {
                this.disabled = true
                this.$emit('layout.change', layout)
                this.hideModal()
            },

            hideModal: function () {
                this.$bvModal.hide('select_layout')
                this.$emit('selected')
            }
        }
    }
</script>

<style lang="scss" scoped>
.layout-container {
    min-height: 350px;

    .zmdi {
        font-size: 120px;
    }
}

#select_layout___BV_modal_outer_ {
    z-index: 1050 !important;
}
</style>
