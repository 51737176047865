<template>
  <div class="container-fluid p-0">
    <component :is="layout"> </component>
    <vue-topprogress color="#4bc74e" :height="4" ref="topProgress">
    </vue-topprogress>
    <notifications
      group="alert"
      position="top left"
      width="auto"
      animation-name="v-fade-left"
      :speed="1000"
    >
      <template slot="body" slot-scope="props">
        <div class="d-flex alert" :class="props.item.type">
          <div class="notification-content">
            <div class="notification-text" v-html="props.item.text"></div>
          </div>
          <div class="notification-close" @click="props.close">
            <i class="zmdi zmdi-close"></i>
          </div>
        </div>
      </template>
    </notifications>
    <account-inactive />
    <UploadingWindow />
  </div>
</template>

<script>
import Vue from "vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import NoSidebarLayout from "./layouts/NoSidebarLayout";
import EventBus from "./core/event-bus";
import { vueTopprogress } from "vue-top-progress";
import Alert from "./components/Alert";
import AccountInactive from "./components/AccountInactive";
import UploadingWindow from "./upload/UploadingWindow";

export default {
  name: "App",
  metaInfo: {
    title: 'CMS Signage',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Digital signage CMS software' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    DefaultLayout,
    NoSidebarLayout,
    vueTopprogress,
    Alert,
    AccountInactive,
    UploadingWindow,
  },
  data() {
    return {
      user: this.user || {},
    };
  },

  created: function () {
    if (typeof DATA != "undefined") {
      this.$store.dispatch("userStore/setUser", DATA.user);
    }
    let _this = this;
    EventBus.$on("progress", function (progress) {
      if (progress) {
        _this.$refs.topProgress.start();
      } else {
        _this.$refs.topProgress.done();
      }
    });
    EventBus.$on("alert", function (options) {
      let duration = 5000;
      if (options["duration"] != null) {
        duration = options["duration"];
      }
      Vue.notify({
        group: "alert",
        text: options["message"],
        type: "alert-" + options["variant"],
        duration: duration,
      });
    });

    EventBus.$on("inactive", function () {
      _this.$bvModal.show("account_inactive");
    });

    EventBus.$on("startUploadingFiles", function (files) {
      EventBus.$emit("uploadFiles", files);
    });
  },

  computed: {
    layout: function () {
      if (this.$route.meta != null && this.$route.meta.layout != null) {
        return this.$route.meta.layout;
      } else {
        return "DefaultLayout";
      }
    },
  },

  watch: {
    progress: function (val) {},
  },
};
</script>
<style lang="scss"></style>
