import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const PlanService = {

	get(params) {
		let query_string = queryString.stringify(params)
		return Http.get('plans.json?' +  query_string);
	},

};

export default PlanService;
