<template>
  <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <b-tabs
      card
      active-nav-item-class="font-weight-bold text-uppercase h-100"
      nav-class="col-lg-10"
      content-class="col-lg-12 p-0"
    >
      <b-tab class="p-0 h-100">
        <template #title>
          {{ $t("reports.title") }}
        </template>
        <div class="tab-item-container">
          <content-played-report-home :content_id="content_id" :device_id="device_id" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import ContentPlayedReportHome from "./ContentPlayedReport/ContentPlayedReportHome";
import DashboardHome from './Dashboard/DashboardHome.vue';

export default {
  props: {
    device_id: {
      type: Number,
      default: null,
    },
    content_id: {
      type: Number,
      default: null,
    },
  },
  components: { ContentPlayedReportHome, DashboardHome },
};
</script>
