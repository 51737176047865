/**
 * Sets up HTTP adapter and the necessary configurations
 */

import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import RequestInterceptors from './RequestInterceptors'
import ResponseInterceptors from './ResponseInterceptors'



const http = axios.create({
	// baseURL: '/', // coming from a constant defined in the main HTML layout
	headers: {
		'Cache-Control': 'no-cache'
	},
})

// attach interceptors
RequestInterceptors.use(http);
ResponseInterceptors.use(http);

export default http
