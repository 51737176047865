<template>
    <div class="channel-composition-container">
        <SelectedChannelContents
            :media_list="channel.media_list"
            :zones="channel.layout.zones"
            @change="updateChannel"
            @delete="deleteMedia"
        />
        <channel-media-list :channel="channel" />
    </div>
</template>

<script>
    import ChannelMediaList from "./ChannelMediaList.vue";
    import SelectedChannelContents from "./SelectedChannelContents.vue";

    export default {
        components: { SelectedChannelContents, ChannelMediaList },
        props: {
            channel: {
                type: Object,
                default: { id: 1, media_list: [] },
            },
        },
        data() {
            return {};
        },

        mounted: function () {},

        methods: {
            updateChannel: function (media_list) {
                this.$set(this.channel, 'media_list', media_list)
                this.$emit("change", this.channel.media_list);
            },

            deleteMedia: function(media){
                this.$emit("delete", media);
            }

        },
    };
</script>

<style lang="scss" scoped>
.channel-composition-container {
    display: flex;
    flex: 1;
    background-color: aqua;
    .name-div {
        flex: 0.2;
        line-height: 12px;
        padding: 0px 10px;
    }
}
</style>
