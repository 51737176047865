<template>
  <div class="list-view-header-container">
    <div class="filter-item">
      <span class="header-text">
        {{ $t("reports.filters", {count: filtersCount}) }}
      </span>
    </div>
    <div class="created-at">
      <span class="header-text">{{ $t('reports.created_at')}}</span>
    </div>
    <div class="download-button">
      <span class="header-text">{{ $t('reports.download')}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filtersCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>
<style>
.filter-item {
  display: flex;
  flex: 0.6;
  flex-direction: column;
}
.created-at {
  display: flex;
  flex: 0.2;
  align-items: center;
}
.download-button {
  display: flex;
  flex: 0.2;
  align-items: center;
}
.header-text {
  font-size: 18px;
  font-weight: 500;
}
</style>
