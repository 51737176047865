<template>
  <div class="form-group">
      <label for="firstName">{{ label }}</label>
      <input :type="type"
             v-validate="'required'"
             :placeholder="placeholder"
             :name="input_name"
             class="form-control"
             :class="{ 'is-invalid': errors.has(input_name) }"
             data-vv-name="input_name"
             data-vv-as="Email" />
      <div v-if="errors.has(input_name)" class="invalid-feedback">
        {{ errors.first(input_name) }}
      </div>
  </div>
</template>

<script>
    export default {
        props: {
            type: {
              type: String,
              default: 'text'
            },
            label: {
                type: String,
                default: ''
            },
            input_name:{
              type: String,
              default: ''
            },
            validate: {
              type: String,
              default: ''
            },
            placeholder: {
              type: String,
              default: ''
            },
            value: {
              type: String,
              default: ''
            }
        },

        inject: ['$validator'],
    }
</script>
