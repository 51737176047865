export default {
  data() {
    return {
        audio_img: require('../images/file_format/audio_file.jpg'),
        pdf_img: require('../images/file_format/pdf_file.jpg'),
        word_img: require('../images/file_format/word_file.jpg'),
        unknown_img: require('../images/file_format/unknown_file.jpg'),
        word_docs: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/msword', 'application/vnd.oasis.opendocument.text'],

      }
  },

	methods: {
    get_img: function (content) {
      if ( content.type == "File") {
        if (content.content_type != null && content.content_type.startsWith("audio")) {
          return this.audio_img;
        }else if (content.content_type != null && content.content_type == "application/pdf") {
          return this.pdf_img;
        }else if(content.content_type != null && this.word_docs.includes(content.content_type)){
          return this.word_img;
        } else {
          if (content.img_url === null) {
            return this.unknown_img;
          } else {
            return content.img_url;
          }
        }
      } else {
        if (content.widget == null){
          if(content.img_url == null){
            let channel_img = require("../images/no_content_images/no-playlist.png")
            return channel_img;
          }else{
            return  content.img_url;
          }
        }else if (content.widget.template) {
          return content.img_url;
        } else {
          let widget_img = require("../images/widget_icons/" +
            content.widget.image +
            ".jpg");
          return widget_img;
        }
      }
    },
	}
}
