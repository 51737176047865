<template>
    <div class="col-12 p-4" v-observe-visibility="resetFillColor">
        <label class="float-left"> Fill: </label>
        <div class="d-flex pl-4">
            <color-picker v-model="fill_color" @input="changeFillColor" />
        </div>
    </div>
</template>

<script>
    import ColorPicker from '../../../components/ColorPicker'
    export default {
        props: {
            canvas: { type: Object, default: {} },
            properties: { type: Object, default: {} }
        },

        data() {
            return {
                fill_color: this.properties.stroke_color
            }
        },

        components: {
            ColorPicker
        },

        mounted() {
            let _this = this
            this.canvas.on('selection:updated', function () {
                _this.resetFillColor()
            })
        },

        methods: {
            resetFillColor: function () {
                let _this = this
                let object = this.canvas.getActiveObject()
                if (object != null) {
                    this.fill_color = { hex8: object['fill'] }
                }
            },

            changeFillColor: function (color) {
                let object = this.canvas.getActiveObject()
                object.set('fill', color.hex8)
                this.canvas.renderAll()
                this.canvas.fire('object:statechange')
            }
        }
    }
</script>
