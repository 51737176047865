var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zone-division-box",style:('left:' +
    _vm.zoneDivisionBox.dimensions.x_offset_percentage +
    '%; top:' +
    _vm.zoneDivisionBox.dimensions.y_offset_percentage +
    '%; width:' +
    _vm.zoneDivisionBox.dimensions.width_percentage +
    '%; height:' +
    _vm.zoneDivisionBox.dimensions.height_percentage +
    '%;  ')})}
var staticRenderFns = []

export { render, staticRenderFns }