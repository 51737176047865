<template>
  <div class="list-view-item">
    <div class="filter-item">
      <div>
        <span class="filter-bolder-text">{{ $t('custom_alert.label.name_alert_item')}} </span>
          {{ custom_alert.name }} <br/>
        <span class="filter-bolder-text">{{ $t('custom_alert.label.last_seen_alert_item')}} </span>
          {{ $t('custom_alert.label.days', {days: custom_alert.metadata['last_seen'] }) }} <br/>
        <span class="filter-bolder-text">{{ $t('reports.screens')}} </span>
          {{ this.device_ids.join(", ") || "All Screens" }}
      </div>
    </div>
    <div class="created-at">
      <span> {{ $t("custom_alert.label." + custom_alert.schedule.replace("@", '')) }}</span>
    </div>
    <div class="created-at">
      <span>{{ formatTime(custom_alert.created_at) }}</span>
    </div>
    <div class="download-button">
      <b-button
        variant="secondary"
        size="sm"
        class="ml-4 mr-4"
        @click="delete_alert"
      >
        <i class="zmdi zmdi-delete delete-icon"></i>
      </b-button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CustomAlertService from "../../services/CustomAlertService";

import { showDeleteConfirmation } from "../../utils/DeleteConfirmation"
export default {
  props: {
    custom_alert: {
      type: Object,
      default: {},
    },
  },

  computed: {
    device_ids: function(){
      return this.custom_alert.device_ids || []
    }
  },

  methods: {
    formatTime: function (time) {
      return moment(time).format("DD-MMM-YYYY h:mm A zz");
    },

    delete_alert: function(){
      let _this = this
      showDeleteConfirmation(
        _this,
        _this.$t("custom_alert.delete_message")
      ).then((result) => {
        if (result.value) {
          CustomAlertService.destroy(this.custom_alert.id)
            .then((response) => {
              _this.$emit("deleted")
            })
            .catch((error) => {
              this.handleError(error)
            })
        }
      })
    }
  },
}
</script>
<style scoped>
.filter-bolder-text {
  font-weight: 600;
}
</style>
