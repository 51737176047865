<template>
<div class="col-3 hover-actions-card p-2 mb-3">
    <div class="shadow-sm rounded border p-0 m-0 ">
        <div class="header  col-lg-12 text-left p-3 border-bottom">
            <h4 class="mb-0 text-truncate" v-b-tooltip.hover :title="schedule.name">{{ schedule.name }}</h4>
        </div>
    </div>
    <div class="card-block">
        <b-list-group-item>
            {{$t('schedules.playlist_count')}} {{ schedule.schedules.length }}
        </b-list-group-item>
        <b-list-group-item>
            {{$t('common_words.last_edited')}}
            <span class="text-primary" v-b-tooltip.hover :title="this.last_edited_date_time">
                {{ last_edited }}
            </span>
        </b-list-group-item>

        <div class="actions">
            <action :schedule="schedule" @edit="show_edit_dialog" @deleted="$emit('deleted')" />
        </div>
    </div>
</div>
</template>

<script>
import Action from "./ScheduleAction"
import moment from 'moment';

export default {
    props: {
        schedule: {
            type: Object,
        },
    },
    components: {
        Action,
    },
    computed: {
        last_edited: function () {
            if (this.schedule.updated_at != '' && this.schedule.updated_at != null) {
                return moment(this.schedule.updated_at).fromNow()
            } else {
                return 'N/A'
            }
        },

        last_edited_date_time: function () {
            if (this.schedule.updated_at != '' && this.schedule.updated_at != null) {
                return moment(this.schedule.updated_at).format("DD-MMM-YYYY h:mm A zz");
            } else {
                return 'N/A'
            }
        },
    },
    methods: {
        show_edit_dialog: function () {
            this.$emit('edit', this.playlist)
        },
    }
}
</script>