<template>
    <div class="tabs" @click.prevent="">
        <ul>
            <li
                v-for="(tab, index) in tabs"
                :key="index"
                :class="tab == activeTab ? 'active' : ''"
                @click.stop="change(tab)"
            >
                <label :for="`${tab}${index}`" v-text="display_schedule_types[tab]" />
                <input
                    :id="`${tab}${index}`"
                    type="radio"
                    :name="`${tab}-tab`"
                    :value="tab"
                    v-model="activeTab"
                    @change="$emit('input', activeTab)"
                />
            </li>
        </ul>

        <template v-for="(tab, index) in tabs">
            <div class="tabs-details" :key="index" v-if="tab == activeTab">
                <slot :name="tab" ref="activeTab" />
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            tabs: {
                type: Array,
                required: true,
            },
            display_schedule_types:{
                type: Object,
                required: true
            },
            selectedTab: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                activeTab: null,
            };
        },

        mounted() {
            this.activeTab = this.selectedTab;
        },

        methods: {
            change: function (tab) {
                this.$emit("input", this.activeTab);
            },
        },
    };
</script>
<style lang="scss" scoped>
.tabs {
    flex: 1;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    ul {
        display: flex;
        flex: 1;
        padding: 0;
        margin: -1px -2px 0px -2px;
        li {
            flex: 0.3;
            flex: 1 1;
            list-style: none;
            text-transform: capitalize;
            background: #ffffff;
            border: 1px solid #dfdfdf;

            padding: 5px 12px;
            border-radius: 5px;
            position: relative;
            margin-right: -1px;
            input {
                opacity: 0;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }
            label {
                margin: auto;
            }
        }
    }

    li.active {
        background: #dfdfdf;
        border-bottom: 0px;
    }
}

div.tabs-details {
    padding: 10px 10px;
    border-top: 0px;
    > div {
        flex: 1;
        display: flex;
        padding: 20px 10px;
    }
}
</style>