<template>
  <b-modal :id="this.getId()"
           :title="$t('content.edit_file')"
           modal-class="modal-container"
           @shown="showModal()">
       <form ref="form"  method="post" @submit.prevent="validate">
          <div class="form-group mb-0" v-if="error">
               <p class="text-danger text-center">{{error}}</p>
          </div>

          <div class="form-group email required user_email">
               <label> {{$t('playlist.resize_mode')}} </label>
               <b-form-select v-model="content.resize_mode"
                           :options="options"/>
          </div>

       </form>
       <div slot="modal-footer" class="w-100 text-right">
         <b-button size="sm" class="float-right ml-3" variant="success" @click="update()">
           {{$t('common_words.save')}}
         </b-button>
         <b-button size="sm" variant="" @click="hideModal()">
           {{$t('common_words.cancel')}}
         </b-button>
       </div>
  </b-modal>
</template>

<script>
import DurationInput       from '../components/DurationInput';
import ContentService      from '../services/ContentService';
export default {

  props: {
    content_id: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      content: {
          id: null,
          filename: '',
          duration: 3,
          resize_mode: 'cover'
      },
      success_message: "",
      error: null,
      options: ['cover', 'center', 'contain', 'stretch' ]
    }
  },

  components:{
    DurationInput
  },

  methods: {
    getId() {
      return "edit_file_" + this.content_id
    },

    showModal(){
      if(this.content_id != null){
        this.find()
      }
    },

    hideModal() {
      this.resetForm();
      this.$bvModal.hide(this.getId())
    },

    durationReadOnly(){
      if(this.file != null && this.file.content_type != null){
        return (this.file.content_type.startsWith("audio") || this.file.content_type.startsWith("video"))
      }else {
        return false
      }
    },

    find(){
      let _this = this
      ContentService.show(this.content_id)
                     .then(response => {
                        _this.content = response.data
                     })
                     .catch(error => {
                       _this.handleError(error)
                     })
    },

    update(){
      let _this = this
      ContentService.update(this.content.id, this.content)
                    .then(response => {
                      _this.content = response.data;
                      _this.hideModal();
                      _this.$emit('updated')
                    })
                    .catch(error => {
                      _this.handleError(error)
                    })
    },

    resetForm() {
      Object.keys(this.content).forEach(key => {
        this.content[key] = null
      })
      this.$validator.reset()
    }

  },
}
</script>
