<template>
<div class="col-3 hover-actions-card p-2 mb-3">
    <div class="shadow-sm rounded border p-0 m-0 ">
        <div class="header  col-lg-12 text-left p-3 border-bottom" v-b-tooltip.hover :title="playlist.name">
            <h4 class="mb-0 text-truncate">{{ playlist.name }}</h4>
        </div>
        <div class="card-block">
            <b-list-group-item>{{ $t("playlist.contents_count") }}
                {{ playlist.media_count }}
            </b-list-group-item>
            <b-list-group-item>
                {{ $t("common_words.last_edited") }}
                <span class="text-primary" v-b-tooltip.hover :title="this.last_edited_date_time">
                    {{ last_edited }}
                </span>
            </b-list-group-item>
            <b-list-group-item v-if="this.playlist.included_in.length > 0">{{ $t("playlist.used_in") }}
                <span class="text-primary" v-b-tooltip.hover :title="playlist.included_in.join(', ')">
                    {{ this.included_in_playlist() }}
                </span>
            </b-list-group-item>
            <b-list-group-item v-else>{{ $t("playlist.used_in") }}
                <span>
                    {{ $t('common_words.none') }}
                </span>
            </b-list-group-item>
        </div>

        <div class="actions">
            <action :playlist="playlist" @edit="show_edit_dialog" @deleted="$emit('deleted')" />
        </div>
    </div>
</div>
</template>

<script>
import Action from "./PlaylistAction";
import moment from "moment";

export default {
    props: {
        playlist: {
            type: Object,
        },
    },
    computed: {
        last_edited: function () {
            if (this.playlist.updated_at != "" && this.playlist.updated_at != null) {
                return moment(this.playlist.updated_at).fromNow();
            } else {
                return "N/A";
            }
        },

        last_edited_date_time: function () {
            if (this.playlist.updated_at != "" && this.playlist.updated_at != null) {
                return moment(this.playlist.updated_at).format("DD-MMM-YYYY h:mm A zz");
            } else {
                return "N/A";
            }
        },
    },
    components: {
        Action,
    },
    methods: {
        show_edit_dialog: function () {
            this.$emit("edit", this.playlist);
        },

        included_in_playlist: function () {
            let playlists = this.playlist.included_in
            let text = "N/A"
            if (this.playlist.included_in.length) {
                text = playlists.slice(0, 2).join(', ')
            }
            if (playlists.length > 2) {
                text += " +" + (playlists.length - 2) + "more"
            }
            return text
        }
    },
};
</script>
