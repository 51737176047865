import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Playlists from './Playlists'
import PlaylistContents from './PlaylistContents'

let routes = [{
	path: '/user/playlist',
	name: 'playlists',
	component: Playlists
}, {
	path: '/user/playlist/:id/contents',
	name: 'contents',
	component: PlaylistContents
}];

export default routes;
