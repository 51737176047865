<template>
  <div class="sort mr-3">
    <b-dropdown :text="sort_order['order']['label']"
                 :dropup="sort_order['order_by'] == 'asc'"
                 @click="selection_change(option)">

      <b-dropdown-item v-for="option in sort_list"
                       :key="'sort' + option['value']"
                    @click="selection_change(option)">
                  <span>{{option.label}}</span>
                  <i class="zmdi zmdi-check float-right check"
                        v-show="sort_order['order']['value'] == option['value']">
                  </i>
    </b-dropdown-item>

    <b-dropdown-divider></b-dropdown-divider>

    <b-dropdown-item @click="order_change(true)">
      <span>Ascending</span>
      <i class="zmdi zmdi-check float-right check"
          v-show="selected_sort_order['order_by'] == 'asc'">
      </i>
    </b-dropdown-item>

    <b-dropdown-item @click="order_change(false)">
      <span>Descending</span>
      <i class="zmdi zmdi-check float-right check"
         v-show="selected_sort_order['order_by'] == 'desc'">
      </i>
    </b-dropdown-item>

    </b-dropdown>
  </div>
</template>
<script>
export default {

    props:{
      sort_list: {
        type: Array,
        default() {
          return [{'value' : 'name', label: 'Name' }, {'value' : 'updated_at', label: 'Last updated'}]
        },
      },
      sort_order: {
        type: Object,
        default() {
          return {'order' : {'value': 'updated_at', 'label': 'Last updated' }, 'order_by' : 'desc'}
        }
      }
    },

    data() {
      return {
        selected_sort_order: this.sort_order
      }
    },
    mounted() {
      this.selected_sort_order = this.sort_order
    },

    methods: {
      order_change(isAscending){
        if(!isAscending){
          this.selected_sort_order['order_by'] = 'desc'
        }else{
          this.selected_sort_order['order_by'] = 'asc'
        }
        this.$emit("change", this.selected_sort_order)
      },

      selection_change(option){
        this.selected_sort_order['order'] = option
        this.$emit("change", this.selected_sort_order)
      },
    },

     watch: {
        sort_list: function (newVal) {
          this.sort_list = newVal
        },

        sort_order: function (newVal) {
          this.selected_sort_order = newVal
        }
    }
}
</script>

<style lang="scss">
  .check{
    font-weight: 600;
    color: #41bd41;
  }
  #dropdown-sort{
    ul {
      width: 180px;
    }
  }
</style>
