<template>
  <div class="list-view-header-container">
    <div class="filter-item">
      <span class="header-text">
        {{ $t("custom_alert.conditions", {count: alertCount}) }}
      </span>
    </div>
     <div class="created-at">
      <span class="header-text">{{ $t('custom_alert.label.schedule')}}</span>
    </div>
    <div class="created-at">
      <span class="header-text">{{ $t('custom_alert.label.created_at')}}</span>
    </div>
    <div class="download-button">
      <span class="header-text">{{ $t('common_words.actions')}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    alertCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>
<style>
.filter-item {
  display: flex;
  flex: 0.6;
  flex-direction: column;
  padding: 0px 20px;
}
.created-at {
  display: flex;
  flex: 0.2;
  align-items: center;
}
.download-button {
  display: flex;
  flex: 0.2;
  align-items: center;
}
.header-text {
  font-size: 18px;
  font-weight: 500;
}
</style>
