<template>
<div class="card border rounded-lg text-center p-1 m-2 fix-size-card">

    <img class="card-img-top" :src="get_img()" alt="Card image cap">
    <div class="card-body d-flex flex-column">
        <h4 class="card-title">{{$t(widget.title)}}</h4>
        <p class="card-text">{{$t(widget.description)}}</p>
        <b-button @click="showWizard" variant="primary" class="mt-auto">
            {{$t('widget.add_widget')}}
        </b-button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        widget: {
            type: Object,
            default: {
                title: '',
                description: '',
                image: '',
                page_route: '',
                component: ''
            }
        },
        token: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            key_index: 0,
            loaded: false,
        }
    },

    beforeMount: function () {
        if( this.widget.locale != null) {
            this.$i18n.mergeLocaleMessage(this.$i18n.locale, JSON.parse(this.widget.locale)[this.$i18n.locale])
        }
        
    },

    methods: {
        showWizard: function () {
            this.$router.push({
                name: 'createapp',
                params: {
                    widget_id: this.widget.id
                }
            })
        },
        get_img: function () {
            let widget_img = require('../images/widget_icons/' + this.widget.image + '.jpg')
            return widget_img
        },
    }
}
</script>

<style scoped>
.fix-size-card {
    width: 250px;
    height: 300px
}

.card-img-top {
    height: 120px;
    margin: auto;
    width: auto;
}
</style>
