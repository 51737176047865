import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const GroupService = {
	get(params) {
		let query_string = queryString.stringify(params)
		return Http.get('user/groups.json?' +  query_string);
	},

	create(params) {
		return Http.post('user/groups.json', params);
	},

	default_group() {
		return Http.get('user/groups/default.json');
	},

	destroy(id) {
		return Http.delete('user/groups/' + id + '.json');
	},

	add_device(id, params) {
		return Http.post('/user/groups/' + id + '/add_device.json ', params);
	},

	set_media(id, params){
		return Http.post('/user/groups/' + id + '/media/set_media.json ', params);
	},

	update(id, params){
		return Http.put('user/groups/' + id + '.json', params);
	},

};

export default GroupService;
