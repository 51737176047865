import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const LayoutService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('layouts.json?' + query_string);
	},

	get(id) {
		return Http.get('layouts/' + id + '.json');
	},

	create(params) {
		return Http.post('layouts.json', params);
	},


	update(id, params){
		return Http.put('layouts/' + id + '.json', params);
	},

	destroy(id) {
		return Http.delete('layouts/' + id + '.json');
	}

};

export default LayoutService;
