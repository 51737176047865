<template>
<div class="side_toolbar p-2">
    <div class="col-12 p-2 pt-4" v-if="!this.cropStarted">
        <label class="float-left mr-2 mt-2"> Start Cropping: </label>
        <div class="d-flex">
            <b-button variant="none" class="btn-link btn-sm float-left p-2 btn-crop" @click="startCrop('circle')" title="Circular cropping" :class="{ 'active': shape == 'circle' }">
                <i class="zmdi zmdi-circle-o"></i>
            </b-button>
            <b-button variant="none" class="btn-link btn-sm float-left p-2 btn-crop" @click="startCrop('rectangle')" title="Rectangle cropping" :class="{ 'active': shape == 'rectangle' }">
                <i class="zmdi zmdi-square-o"></i>
            </b-button>
        </div>
    </div>

    <div class="col-12 p-2 pt-4" v-if="this.cropStarted">
        <label class="float-left mr-2 mt-2"> Crop Image: </label>
        <div class="d-flex">
            <b-button variant="none" class="btn-link btn-sm float-left p-0" @click="cropImage()" title="Circular cropping">
                <i class="zmdi zmdi-crop"></i>
            </b-button>
        </div>
    </div>

    <div class="border-top my-3"></div>
    <back-front :canvas="canvas" />

</div>
</template>

<script>
import BackFront from './properties/BackFront.vue'
import { mapGetters } from 'vuex';
export default {
    props: {
        canvas: {
            type: Object,
            default: null
        },
    },

    data() {
        return {
            shape: {
                type: String,
                default: null
            },
            cropElement: null,
            selected_image: null,
            cropStarted: false,
        }
    },

    components: {
        BackFront
    },

    computed: {

        cropEnabled: function () {
            return this.cropElement != null
        }
    },

    methods: {
        crop: function () {
            this.shape = null
            this.$emit('cropCompleted')
        },

        lockMovement: function () {
            let obj = this.canvas.getActiveObject()
            obj.lockMovementX = true
            obj.lockMovementY = true
        },

        unlockMovement: function () {
            let obj = this.canvas.getActiveObject()
            obj.lockMovementX = false
            obj.lockMovementY = false
        },

        startCrop: function (shape) {
            if (this.cropElement == null) {
                this.enableCrop()
                this.cropStarted = true
                if (shape == 'circle') {
                    this.drawCircularSelector();
                } else {
                    this.drawRectSelector();
                }
                this.canvas.sendBackwards(this.selected_image)
                this.canvas.bringForward(this.cropElement);
            }
        },

        cropImage: function () {
            if (this.cropElement.type == 'rect') {
                this.cropRectImage()
            } else if (this.cropElement.type == 'circle') {
                this.cropCircleImage()
            }
            this.cropStarted = false
        },

        cropRectImage: function () {
            if (this.selected_image && this.cropElement) {
                let _this = this
                let width = ((this.cropElement.width) * this.cropElement.scaleX)
                let height = ((this.cropElement.height) * this.cropElement.scaleY)
                let cropOptions = {
                    left: this.cropElement.left - this.selected_image.left,
                    top: this.cropElement.top - this.selected_image.top,
                    width: width,
                    height: height,
                    scaleX: this.selected_image.scaleX,
                    scaleY: this.selected_image.scaleY
                }
                let cropURL = _this.selected_image.toDataURL(cropOptions)
                fabric.Image.fromURL(cropURL, function (myImg) {
                    let object = myImg.set({
                        left: _this.cropElement.left,
                        top: _this.cropElement.top,
                        width: width,
                        height: height
                    })
                    _this.canvas.remove(_this.selected_image).add(object);
                    _this.canvas.renderAll()
                    _this.unlockMovement()
                    _this.disableCrop()
                });
            }
        },

        cropCircleImage: function () {
            if (this.selected_image && this.cropElement) {
                let _this = this
                let width = ((this.cropElement.width) * this.cropElement.scaleX)
                let height = ((this.cropElement.height) * this.cropElement.scaleY)
                let cropOptions = {
                    left: this.cropElement.left - this.selected_image.left,
                    top: this.cropElement.top - this.selected_image.top,
                    width: width,
                    height: height,
                    scaleX: this.selected_image.scaleX,
                    scaleY: this.selected_image.scaleY
                }
                let cropURL = _this.selected_image.toDataURL(cropOptions)
                fabric.Image.fromURL(cropURL, function (myImg) {
                    let object = myImg.set({
                        left: _this.cropElement.left,
                        top: _this.cropElement.top,
                        width: width,
                        height: height
                    })
                    object.set({
                        clipPath: new fabric.Circle({
                            radius: _this.cropElement.getScaledWidth() / 2,
                            top: -(object.height / 2),
                            left: -(object.width / 2)
                        })
                    })
                    _this.canvas.remove(_this.selected_image).add(object);
                    _this.canvas.renderAll()
                    _this.unlockMovement()
                    _this.disableCrop()
                });
            }
        },

        drawRectSelector: function () {
            if (this.cropElement) return
            this.cropElement = new fabric.Rect({
                fill: '#12060687',
                stroke: '#FFFFFF',
                strokeDashArray: [1, 1],
                left: this.selected_image.left,
                top: this.selected_image.top,
                width: this.selected_image.width * this.selected_image.scaleX,
                height: this.selected_image.height * this.selected_image.scaleY,
                lockRotation: true
            });
            this.canvas.add(this.cropElement);
            this.canvas.bringForward(this.cropElement);
            this.canvas.setActiveObject(this.cropElement)
            this.activeObject = this.cropElement
        },

        drawCircularSelector: function () {
            if (this.cropElement) return
            let radius = this.selected_image.getScaledWidth() / 2
            if (this.selected_image.width > this.selected_image.height) {
                radius = this.selected_image.getScaledHeight() / 2
            }
            this.cropElement = new fabric.Circle({
                left: this.selected_image.left,
                top: this.selected_image.top,
                radius: radius,
                fill: '#12060687',
                stroke: '#FFFFFF',
                strokeDashArray: [1, 1],
                lockRotation: true,
                lockSkewingX: true,
                lockSkewingY: true,
                lockUniScaling: true
            });
            this.canvas.add(this.cropElement);
            this.canvas.bringForward(this.cropElement);
            this.canvas.bringForward(this.cropElement);
            this.canvas.setActiveObject(this.cropElement)
            this.activeObject = this.cropElement
        },

        disableCrop: function () {
            this.selected_image = null
            this.canvas.remove(this.cropElement)
            this.canvas.renderAll()
            this.cropElement = null
        },

        enableCrop: function () {
            this.selected_image = this.canvas.getActiveObject();
            this.selected_image.set({ hoverCursor: "move" })
            this.lockMovement();
            this.$emit('cropStarted')
        }

    }
}
</script>
<Style lang="scss">
  .selected{
    background-color: #efefef;
    border: 1px solid #dcdcdc;
  }
  .btn-crop{

    i{
      margin-top: 3px;
    }
  }
</style>
