<template>
  <div :class="'inline-input d-flex ' + inputClass">
      <input
        v-if="editing"
        type="text"
        :value="value"
        :placeholder="placeholder"
        ref="inputEl"
        v-on:keyup.enter="handleEnter">


      <h4 v-else
          class="float-left m-0"
          @click="toggle()">
        {{ value }}
      </h4>

      <b-button variant="link"
                class="float-left p-0"
                @click="toggle()">
        <i class="zmdi zmdi-edit p-0 pl-2"
           v-if="!editing">
        </i>
      </b-button>
      <b-button variant="outline-primary"
                class="btn-sm"
                @click="handleClick"
                v-if="editing">
        {{ $t('common_words.save')}}
      </b-button>
  </div>
</template>
<script>
export default {
  name: 'InlineInput',
  props: {
    value: {
      type:  String,
      default: ''
    },
    placeholder: {
      type:  String,
      default: ''
    },
    inputClass:  {
      type:  String,
      default: ''
    },
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
  },

  methods: {
  	toggle() {
      this.editing = !this.editing;

      if (this.editing) {
        this.$nextTick(() => {
          this.$refs.inputEl.focus();
        });
      }
    },
    handleEnter() {
      this.$refs.inputEl.blur();
    },
    handleClick() {
      this.toggle();
      this.emitValue();
      this.$emit('blur', this.getValue());
    },
    handleInput() {
      this.emitValue();
    },
    handleChange(e) {
      this.selectedIndex = this.placeholder ? e.target.selectedIndex - 1 : e.target.selectedIndex;
      this.emitValue();
    },
    emitValue() {
      this.$emit('input', this.getValue());
    },
    getValue() {
      return this.isNumber ? +this.$refs.inputEl.value : this.$refs.inputEl.value;
    }
  }
};
</script>
<style lang="scss">
  .inline-input{
    input{
      border-radius: 5px;
      padding: 2px 10px;
      border: 1px solid #DFDFDF;
      font-size: 20px;
    }
  }
</style>
