<template>
  <div class="col-lg-12 p-0 screen-grp-nodata h-75 text-center">
    <h3 class="text-center mb-4">
      {{$t('screens.title')}}
    </h3>
    <div class="col-lg-12 d-flex">

        <div class="grp-block shadow-sm rounded border p-0 m-2">
          <div class="col-lg-12  border-bottom d-inline-block mb-2">
            <h4 class='text-center p-2'> {{$t('screens.step1.title')}} </h4>
           </div>
           <span class="text-center" v-html="$t('screens.step1.heading')" />
           <div class="card-body ">
             <a href='https://play.google.com/store/apps/details?id=com.opiekun.cmssignage&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img  height="90" width="280" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
             <a href='https://www.amazon.com/CMS-Signage-Free-Fire-Digital/dp/B084L7MVX2' target="_blank"><img  height="65" width="240" alt='Get it on amazon store' :src="amazon_store"/></a>
           </div>
        </div>
        <div class="grp-block shadow-sm rounded border p-0 m-2">
          <div class="col-lg-12 border-bottom d-inline-block mb-2">
            <h4 class='text-center p-2'>{{$t('screens.step2.title')}} </h4>
           </div>
           <span class="text-center"> {{$t('screens.step2.heading')}}</span>
           <div class="card-body h-75 pt-2 pb-2 d-flex">
             <img class="m-auto mb-3" :src="tv_otp"/>
           </div>
        </div>
        <div class="grp-block shadow-sm rounded border p-0 m-2">
          <div class="col-lg-12 border-bottom d-inline-block mb-2">
            <h4 class='text-center p-2'>{{$t('screens.step3.title')}} </h4>
           </div>
           <span class="text-center" v-html="$t('screens.step3.heading')" />

              <div class="card-body pt-2 pb-2 h-75 mb-3 d-flex">
                <div class="mt-auto mb-auto col-lg-12 p-0 no-content-grp">
                    <group :group="group" cssClass="grp"
                          v-show="typeof group != 'undefined' && group != null"
                          @update="group_updated"/>
                </div>
              </div>

        </div>
    </div>
    <span class="no-content-next-step" v-html="$t('screens.no_screens_next_step')"></span>
  </div>
</template>
<script>

import Group           from  './Group'
export default {

  props:{
    group: null
  },

  data() {
      return {
          tv_otp: require('../images/no_content_images/tv_otp.png'),
          amazon_store: require('../images/no_content_images/amazon_store.jpg')
      }
  },

  components:{
      Group
  },

  methods: {
    group_updated(){
      this.$emit('group_updated')
    }
  }
}
</script>

<style lang="scss">
  .screen-grp-nodata div.block{
    width: 30%;
    padding: 0px;
    margin-right: 55px;
    border-radius:20px;
    border: 1px solid #000;
    float: left
  }
  .screen-grp-nodata .grp-block{
    width: 100%;
    margin-right: 0px !important;
  }
  .no-content-grp{
    div.grp{
      width: 100% !important;
    }
  }

</style>
