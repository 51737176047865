<template>
    <div class="custom-select" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected_option[textField] }}
        </div>
        <div class="options" v-show="open">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="selection_change"
            >
                {{ option[textField] }}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            options: {
                type: Array,
                default() {
                    return [];
                },
            },
            default_selected_option: {
                type: String,
                default: "",
            },
            valueField: {
                type: String,
                default: "value",
            },
            textField: {
                type: String,
                default: "text",
            },
        },

        data() {
            return {
                selected_option: (this.default_selected_option || this.options[0] ),
                open: false
            };
        },

        mounted(){

        },

        methods: {
            selection_change(option) {
                this.selected_option = option;
                this.$emit("change", option[this.valueField]);
            },
        },
    };
</script>
<style lang="scss" scoped>
.custom-select {    
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0px;
  .open {
    border: 1px solid #DFDFDF;
    border-radius: 6px 6px 0px 0px;
    padding: 5px 10px;
  }
  .selected{
    padding: 5px 10px;
  }
}
</style>
