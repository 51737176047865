<template>
<b-list-group-item class="p-0 text-center">
    <b-button variant="outline">
        <i class="zmdi zmdi-cloud-upload"></i>
        <div>Image</div>
        <input type="file" name="defaults.image" value="defaults.image" @change="upload($event)" />
    </b-button>
</b-list-group-item>
</template>

<script>
import ContentService from '../../services/ContentService'
import MediaFileService from '../../admin/services/MediaFileService'

import { mapGetters } from 'vuex';
export default {
    props: {
        canvas: { type: Object, default: {} },
    },

    computed: {
        ...mapGetters('userStore', ['is_admin']),
    },

    methods: {
        upload(event) {
            let _this = this
            let image = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                var imgObj = new Image();
                imgObj.src = event.target.result;
                imgObj.onload = function () {
                    var image = new fabric.Image(imgObj);
                    image.scaleToWidth(_this.canvas.width - 100)
                    image.scaleToHeight(_this.canvas.height - 100)
                    _this.canvas.centerObject(image);
                    _this.canvas.add(image);
                    _this.bringForward();
                    _this.canvas.fire("object:added");
                    _this.$eventBus.$emit('show_loader', false);
                }
            }
            reader.readAsDataURL(image);
        },

        // content_upload: function (image) {
        //     let _this = this
        //     this.$eventBus.$emit('show_loader', true);
        //     ContentService.upload(image)
        //         .then(response => {
        //             _this.content = response.data;
        //             _this.insertSvg(response.data.img_url)
        //         })
        //         .catch(error => {
        //             _this.handleError(error)
        //         })
        // },

        // widget_upload: function (image) {
        //     let _this = this
        //     MediaFileService.upload(image)
        //         .then(response => {
        //             _this.insertSvg(response.data.file_url)
        //         })
        //         .catch(error => {
        //             _this.handleError(error)
        //         })
        // },

        insertBase64: function (base64String) {
            let _this = this
            let imgbase64 = new Image()
            imgbase64.src = base64String
            imgbase64.style.minWidth = 50
            imgbase64.style.minHeight = 50
            let fabricImg = new fabric.Image(imgbase64, {
                left: 10,
                top: 10
            })
            if (_this.canvas.width < imgbase64.width && imgbase64.width > imgbase64.height) {
                fabricImg.scaleToWidth(_this.canvas.width - 100)
            } else if (_this.canvas.height < imgbase64.height) {
                fabricImg.scaleToHeight(_this.canvas.height - 100)
            }
            _this.canvas.add(fabricImg);
            _this.bringForward();
            _this.canvas.fire("object:added");
            _this.$eventBus.$emit('show_loader', false);
        },

        bringForward: function () {
            var activeObject = this.canvas.getActiveObject();
            if (activeObject) {
                this.canvas.bringForward(activeObject);
                // Push the this.canvas state to history
                this.canvas.fire("object:statechange");
            }
        },

        // insertSvg: function (url) {
        //     let _this = this;
        //     let img = new Image()
        //     img.onload = function () {
        //         let imgbase64 = new Image()
        //         imgbase64.src = _this.getBase64Image(img)
        //         imgbase64.width = img.width
        //         imgbase64.height = img.height
        //         let fabricImg = new fabric.Image(imgbase64, {
        //             left: 10,
        //             top: 10
        //         })
        //         if (_this.canvas.width < imgbase64.width && imgbase64.width > imgbase64.height) {
        //             fabricImg.scaleToWidth(_this.canvas.width - 100)
        //         } else if (_this.canvas.height < imgbase64.height) {
        //             fabricImg.scaleToHeight(_this.canvas.height - 100)
        //         }
        //         _this.canvas.add(fabricImg);
        //         _this.canvas.fire("object:added");
        //         _this.$eventBus.$emit('show_loader', false);
        //     }
        //     img.src = url

        // },

        // insertSvg: function (url) {
        //     let _this = this;
        //     let img = new Image()
        //     img.src = url
        //     img.onload = function () {
        //         let fabricImg = new fabric.Image(img, {
        //             left: 10,
        //             top: 10
        //         })
        //         if (_this.canvas.width < img.width && img.width > img.height) {
        //             fabricImg.scaleToWidth(_this.canvas.width - 100)
        //         } else if (_this.canvas.height < img.height) {
        //             fabricImg.scaleToHeight(_this.canvas.height - 100)
        //         }
        //         _this.canvas.add(fabricImg);
        //         _this.canvas.fire('object:statechange')
        //         _this.canvas.renderAll()
        //     }

        // },

        getBase64Image: function (img) {
            let canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL("image/png");
            return dataURL
        },
    }
}
</script>
