<template>
  <div class="content-box-widget-previewer">
    <iframe
      v-if="isPreviewUrlAvailable"
      :src="clientPreviewURL"
      width="100%"
      height="100%"
      frameBorder="0"
    >
    </iframe>
    <h1 v-else class="preview-loading-text">{{$t('common_words.loading')}}</h1>
  </div>
</template>

<script>
import ContentService from "../../services/ContentService";
import EventBus from "../../core/event-bus";

export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
 
  data() {
    return {
      clientPreviewURL: {
        type: String,
        default: "about:blank",
      },
      isPreviewUrlAvailable: false
    };
  },
  mounted() {
    let _this = this;
    EventBus.$on("widgetPrevieModelClosed", function () {
      this.clientPreviewURL = "about:blank"
      this.isPreviewUrlAvailable = false
    });
    EventBus.$on("showWidgetPrevieModel", function (contentID) {
      _this.getWidgetPreviewUrl(contentID);
    });
    this.getWidgetPreviewUrl(this.content.id)
  },

  beforeDestroy() {
    EventBus.$off("showWidgetPrevieModel");
    EventBus.$off("widgetPrevieModelClosed");
  },
  methods: {
    getWidgetPreviewUrl: function (contentID) {
      let _this = this;
      ContentService.show(contentID)
        .then((response) => {
          this.clientPreviewURL = response.data.page_url;
          this.isPreviewUrlAvailable = true
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
  },
};
</script>
<style scoped>
.content-box-widget-previewer {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.preview-loading-text {
  color: black;
  font-weight: 500;
  font-size: 30px;
}
</style>
