<template>
  <div class="top-banner-container shadow-sm" :class="`${variant}-banner`">
    <div class="left-texts-items">
      <span class="title-text text-color">{{ title }}</span>
      <span v-if="description != null" class="description-text">{{ description }}</span>
    </div>
    <div class="right-close-button">
      <i
        class="zmdi zmdi-close-circle zmdi-hc-lg close-icon"
        @click="closeBanner"
      ></i>
    </div>
  </div>
</template>
<script>
import { title } from 'process';

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'info',
    },
  },
  methods: {
    closeBanner: function () {
      this.$emit("closeButtonClicked");
    },
  },
};
</script>

<style scoped>
.top-banner-container {
  display: flex;
  flex: 0;
  padding: 8px;
  margin-top: 4px;
  border-radius: 8px;
}
.left-texts-items {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.right-close-button {
  display: flex;
  flex: 0;
  align-items: center;
}

.info-banner {
  background-color: rgb(2, 107, 2);
  color: white;
}

.warning-banner {
  background-color: rgb(255, 200, 2);
  color: black;
}
.error-banner {
  background-color: red;
  color: white;
}
.title-text {
  font-weight: bold;
}
.description-text {
  font-size: 14px;
}
.close-icon {
  color: white;
}
.primary-action-button {
    display: flex;
    flex: 0;
}
</style>
