import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const ChannelService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('account/channels.json?' + query_string);
	},

	get(id) {
		return Http.get('account/channels/' + id + '.json');
	},

	create(params) {
		return Http.post('account/channels.json', params);
	},


	update(id, params){
		return Http.put('account/channels/' + id + '.json', params);
	},

	destroy(id) {
		return Http.delete('account/channels/' + id + '.json');
	},

	duplicate(params) {
		return Http.post('account/channels/duplicate.json', params);
	},
};

export default ChannelService;
