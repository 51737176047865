<template>
<scroll-pagination :scrollFunction="handleScroll" :loading="loading" :page="page" :total_pages="total_pages">
    <group v-for="group in groups" :group="group" :key="'grp_' + group.id" @destroy="delete_group(group)" @added="updateScreen" @deleted="updateScreen" @updated="updateScreen" @rename="rename_group" v-show="(groups.length > 1 || device_count > 0 || search_text != '')" />
    <no-content :group="groups[0]" v-if="!loading && groups.length >= 0 && groups.length < 2 && device_count < 1 && search_text == ''" @group_updated="$emit('updated')" />

    <no-search-result :searchText="search_text" v-if="!loading && groups.length < 1 && search_text != ''" />

    <create-edit-group :group="selected_group" @created="updateScreen" @updated="updateScreen" />

</scroll-pagination>
</template>

<script>
import GroupService from '../services/GroupService'
import Group from './Group'
import NoContent from './NoContent'
import CreateEditGroup from './CreateEditGroup'
import { showDeleteConfirmation } from "../utils/DeleteConfirmation"

import NoSearchResult from '../components/NoSearchResult'
import ScrollPagination from '../components/ScrollPagination.vue'

export default {

    props: {
        device_count: 0,
        search_text: '',
        sort_order: null,
    },

    data() {
        return {
            groups: [],
            copy_success: false,
            dismissSecs: 4,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            page: 1,
            total_pages: 1,
            selected_group: { name: '' },
            loading: false,
            group_count: null
        }
    },

    components: {
        NoContent,
        Group,
        CreateEditGroup,
        NoSearchResult,
        ScrollPagination
    },

    methods: {
        get_groups: function () {
            let _this = this;
            this.loading = true
            let query_params = { page: this.page, query: this.search_text }
            let order = this.sort_order == null ? {} : { order: this.sort_order['order']['value'], order_by: this.sort_order['order_by'] }
            GroupService.get(Object.assign(query_params, order))
                .then(response => {
                    if (this.page == 1) {
                        _this.groups = []
                    }
                    _this.total_pages = response.data.total_pages
                    _this.group_count = response.data.total_count
                    _this.groups = _this.groups.concat(response.data.groups)
                    _this.$emit('update', _this.group_count)
                    _this.loading = false

                })
                .catch(error => {
                    _this.handleError(error)
                })
        },

        delete_group: function (group) {
            let _this = this;
            showDeleteConfirmation(_this, _this.$t("confirmation_alert.screen_group_delete_message")).then((result) => {
                if (result.value) {
                    _this.$emit("progress", true)
                    GroupService.destroy(group.id)
                        .then(response => {
                            _this.page = 1
                            _this.get_groups()
                        })
                        .catch(error => {
                            this.handleError(error)
                        })
                } else {
                    _this.$emit('progress', false);
                }
            })

        },

        countDownChanged: function (dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        create_group: function () {
            $bvModal.show('create_group')
        },

        updateScreen: function () {
            this.page = 1
            this.selected_group = { name: '' }
            this.$nextTick(() => {
                this.get_groups(this.page)
            })
        },

        reload_data: function(){
            this.updateScreen()
            this.$emit("added")
        },

        async rename_group(group) {
            await this.set_selected_group(group)
            this.$bvModal.show("create_group")
        },

        set_selected_group: function (group) {
            this.selected_group = Object.assign({}, group)
        },

        handleScroll: function () {
            this.page++
            this.get_groups()
        }

    },
}
</script>
