import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Schedules from './Schedules';
import AddContents from './AddContents'

let routes = [{
	path: '/user/schedules',
	name: 'schedules',
	component: Schedules
},
{
	path: '/user/schedules/:id/add_content',
	name: 'add_content',
	component: AddContents
}];

export default routes;
