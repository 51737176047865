import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import ReportHome from './ReportHome';

let routes = [{
	path: '/user/reports',
	name: 'reports',
	component: ReportHome,
	props: route => Object.assign({},castRouteParams(route), route.params)
},];

function castRouteParams(route) {
	return {
	  device_id: Number(route.query.device_id) || null,
	  content_id: Number(route.query.content_id) || null,
	};
  }

export default routes;
