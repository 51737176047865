<template>
    <div
        class="schedule-composition-container"
        v-if="schedule['_destroy'] == null"
    >
        <Daily
            :schedule="schedule"
            :canDelete="canDelete"
            @change="change"
            :readOnly="readOnly"
        />
        <Weekly
            :schedule="schedule"
            :canDelete="canDelete"
            @change="change"
            :readOnly="readOnly"
        />
        <SpecificDate
            :schedule="schedule"
            :canDelete="canDelete"
            @change="change"
            :readOnly="readOnly"
        />
    </div>
</template>

<script>
    import Daily from "./EveryDay";
    import Weekly from "./Weekly";
    import SpecificDate from "./SpecificDate.vue";
    export default {
        props: {
            schedule: {
                type: Object,
                default: () => {},
            },
            canDelete: {
                type: Boolean,
                default: false,
            },

            readOnly: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            Daily,
            Weekly,
            SpecificDate,
        },

        mounted: function () {
        },

        beforeMount() {
            let _this = this
        },

        methods: {
            addSchedule: function () {
                this.$emit("create");
            },
            change: function () {
                this.$emit("change", this.schedule);
            },

        },
    };
</script>

<style lang="scss" scoped>
.schedule-composition-container {
    flex-basis: 100%;
    margin-left: 3px;
    margin-bottom: 5px;
    div.everyday {
        flex: 1;
        border: 1px solid #dfdfdf;
        padding: 2px 8px;
        margin: 2px 5px;
        border-radius: 5px;
        div:nth-child(1) {
            padding: 2px;
            text-align: center;
            background-color: #049d04;
            border-radius: 5px;
            color: #fff;
            margin: 4px 0px;
        }
        div:nth-child(2) {
            flex: 1 0.6;
        }
    }
}
</style>
