import Vue from 'vue'
import Router from 'vue-router'
import HomePageRoutes from './home/HomePageRoutes'
import PlaylistRoutes from './playlist/PlaylistRoutes'
import ChannelRoutes from './channel/ChannelRoutes'
import ScheduleRoutes from './schedule/ScheduleRoutes'
import BrandingRoutes from './branding/BrandingRoutes'
import UserRoutes from './user/UserRoutes'
import BillingRoutes from './billing/BillingRoutes'
import FaqsRoutes from './faqs/FaqsRoutes'
import LibraryRoutes from './library/LibraryRoutes'
import WidgetsRoutes from './widgets/WidgetRoutes'
import HtmlTemplatesRoutes from './templates/HtmlTemplatesRoutes'
import ReportsRoutes from './reports/ReportsRoutes'

import UserNotificationsRoutes from './user-notifications/UserNotificationsRoutes'
import VueMeta from 'vue-meta'

Vue.use(Router)
Vue.use(VueMeta)

var allRoutes = []
allRoutes = allRoutes.concat(HomePageRoutes, PlaylistRoutes,ChannelRoutes,
	ScheduleRoutes, BrandingRoutes,BillingRoutes,UserRoutes,FaqsRoutes, LibraryRoutes,
	WidgetsRoutes, HtmlTemplatesRoutes, ReportsRoutes, UserNotificationsRoutes)

const routes = allRoutes

export default new Router({
	routes
})
