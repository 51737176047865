<template>
    <div class="col-12 p-4" v-observe-visibility="resetStrokeColor">
        <label class="float-left"> Stroke: </label>
        <div class="d-flex">
            <color-picker v-model="stroke_color" @input="changeStrokeColor" />
        </div>
    </div>
</template>

<script>
    import ColorPicker from '../../../components/ColorPicker'
    export default {
        props: {
            canvas: { type: Object, default: {} },
            properties: { type: Object, default: {} }
        },

        data() {
            return {
                stroke_color: this.properties.stroke_color
            }
        },
        components: {
            ColorPicker
        },
        mounted() {
            let _this = this
            this.canvas.on('selection:updated', function () {
                _this.resetStrokeColor()
            })
        },

        methods: {
            resetStrokeColor: function () {
                let _this = this
                let object = this.canvas.getActiveObject()
                if (object != null) {
                    this.stroke_color = { hex8: object['stroke'] }
                }
            },

            changeStrokeColor: function (color) {
                var object = this.canvas.getActiveObject()
                object.set('stroke', color.hex8)
                this.canvas.renderAll()
                this.canvas.fire('object:statechange')
            }
        }
    }
</script>
