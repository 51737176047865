import { render, staticRenderFns } from "./Weekly.vue?vue&type=template&id=6129a1f6&scoped=true&"
import script from "./Weekly.vue?vue&type=script&lang=js&"
export * from "./Weekly.vue?vue&type=script&lang=js&"
import style0 from "./Weekly.vue?vue&type=style&index=0&id=6129a1f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6129a1f6",
  null
  
)

export default component.exports