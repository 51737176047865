import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const CustomAlertService = {
	list() {
		return Http.get('account/custom_alerts.json');
	},

	create(params) {
		return Http.post('account/custom_alerts.json', params);
	},

	destroy(id) {
		return Http.delete('account/custom_alerts/' + id + '.json');
	},

};

export default CustomAlertService;
