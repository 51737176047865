<template>
    <div class="select-zone-container" >
        <ScreenZone :channel="channel"
            v-for="layout in layouts"
            :layout="layout"
            :key="layout.id"
            @selected="setLayout"
        />
    </div>
</template>

<script>
    import ScreenZone from "./ScreenZone.vue";
    import LayoutService from "../../services/LayoutService";

    export default {
        props:{
            channel: {
            },
        },

        data() {
            return {
                layouts: [],
            };
        },
        components: { ScreenZone },

        mounted() {
            this.getLayouts();
        },

        methods: {
            getLayouts: function () {
                let _this = this
                LayoutService.list()
                    .then((response) => {
                        if (response.data != null) {
                            _this.layouts = response.data.layouts;
                        }
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },
            setLayout: function(layout){
                this.$emit('selected', layout)
            }
        },

    };
</script>

<style scoped>
.select-zone-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow-y: scroll;
}

</style>
