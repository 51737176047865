<template>
    <div class="spefic-date-form-container" v-if="loaded">
        <div
            class="alert-danger"
            v-if="newSchedule.dates > 0 && errorDate.length > 0"
        >
            {{ errorDate.join(",") }}
        </div>
        <div class="date-container">
            <div v-for="(date, index) in newSchedule.dates" :key="'dd' + index">
                <div class="start-date-container">
                    <label>
                        {{ $t("channel.media.schedule.start_date") }}
                    </label>
                    <DatePicker
                        v-model="date['start']"
                        calendar-class="datepicker_calendar"
                        input-class="datepicker_input"
                        :disabledDates="disabledDates"
                        @input="setDateToUnixTimestamp('start', index,...arguments)"
                    />
                </div>
                <div class="end-date-container">
                    <label>
                        {{ $t("channel.media.schedule.end_date") }}
                    </label>
                    <DatePicker
                        v-model="date['end']"
                        calendar-class="datepicker_calendar"
                        input-class="datepicker_input"
                        :disabledDates="disabledDates"
                        @input="setDateToUnixTimestamp('end', index, ...arguments)"
                    />
                </div>
                <div class="action">
                    <button
                        @click="deleteDate(index)"
                        v-if="newSchedule.dates.length > 1"
                    >
                        <i class="zmdi zmdi-delete"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="add_date">
            <button class="btn" @click="addDate">
                <i class="zmdi zmdi-plus-circle"></i>
                {{ $t("common_words.add") }}
            </button>
        </div>

        <div class="time-container">
            <div
                class="alert-danger"
                v-if="newSchedule.times.length && errorTime.length > 0"
            >
                {{ errorTime.join(",") }}
            </div>
            <div v-for="(time, index) in newSchedule.times" :key="'tt' + index">
                <ScheduleTime
                    v-model="newSchedule.times[index]"
                    :canDelete="newSchedule.times.length > 1"
                    @delete="deleteTime(index)"
                />
            </div>
        </div>
        <div class="add_time">
            <button class="btn" @click="addTime">
                <i class="zmdi zmdi-plus-circle"></i>
                {{ $t("common_words.add") }}
            </button>
        </div>
    </div>
</template>
<script>
    import DatePicker from "vuejs-datepicker";
    import TimeVue from "../../../components/Time.vue";
    import ScheduleTime from "./ScheduleTimeComposition";
    export default {
        props: {
            schedule: {
                type: Object,
                default: {},
            },
        },

        data() {
            return {
                newSchedule: {},
                weekdays: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
                selected_weekdays: [],
                loaded: false,
                disabledDates: {
                    customPredictor: function (date) {
                        let current_date = new Date()
                        current_date = current_date.setDate(current_date.getDate() - 1);
                        if (date < current_date) {
                            return true;
                        }
                    },
                },
            };
        },

        components: {
            DatePicker,
            TimeVue,
            ScheduleTime,
        },

        computed: {
            errorDate: function () {
                return this.newSchedule.dates
                    .filter(function (value) {
                        return value["error"] != undefined;
                    })
                    .map(function (value) {
                        return value["error"];
                    });
            },

            errorTime: function () {
                return this.newSchedule.times
                    .filter(function (value) {
                        return value["error"] != undefined;
                    })
                    .map(function (value) {
                        return value["error"];
                    });
            },
        },

        mounted() {
            this.newSchedule = Object.assign({}, this.schedule);
            if (this.newSchedule.dates.length < 1) {
                this.newSchedule.week_days = {};
                this.newSchedule.dates = [
                    {
                        start: new Date().setUTCHours(0, 0, 0, 0),
                        end: new Date().setUTCHours(0, 0, 0, 0),
                    },
                ];
            }
            if (this.newSchedule.times.length < 1) {
                this.newSchedule.times = [
                    {
                        start: 0,
                        end: 82859,
                    },
                ];
            }
            this.loaded = true;
        },

        methods: {
            addDate: function () {
                this.newSchedule.dates.push({
                    start: new Date().setUTCHours(0, 0, 0, 0),
                    end: new Date().setUTCHours(0, 0, 0, 0),
                });
            },

            addTime: function () {
                this.newSchedule.times.push({
                    start: 0,
                    end: 82859,
                });
            },

            validate: function () {
                let _this = this
                let schedule = this.newSchedule;
                return new Promise((resolve, reject) => {
                    let errors = _this.checkSchedule(schedule);
                    _this.newSchedule = Object.assign({}, {});
                    _this.newSchedule = Object.assign({}, schedule);
                    if (errors.length < 1) {
                        resolve(_this.newSchedule);
                    } else {
                        reject(errors);
                    }
                });
            },

            checkSchedule: function (schedule) {
                let errors = [];
                let _this = this;
                if (Object.keys(schedule.dates).length != 0) {
                    if (schedule.dates["start"] > schedule.dates["end"]) {
                        _this.$set(
                            schedule.dates,
                            "error",
                            "End data should be greater than Start Date"
                        );
                        errors.push(schedule.dates["error"]);
                    } else if (schedule.dates["error"] != null) {
                        delete schedule.dates["error"];
                    }
                }
                if (schedule.times.length) {
                    schedule.times.forEach(function (t, index) {
                        if (t["start"] >= t["end"]) {
                            _this.$set(
                                schedule.time[index],
                                "error",
                                "End time should be greater than Start time"
                            );
                            errors.push(t["error"]);
                        } else if (t["error"] != null) {
                            delete t["error"];
                        }
                    });
                }
                return errors;
            },

            deleteTime: function (index) {
                this.newSchedule.times.splice(index, 1);
            },

            deleteDate: function (index) {
                this.newSchedule.dates.splice(index, 1);
            },

            setDateToUnixTimestamp(date_field, index, date) {
                this.newSchedule.dates[index][date_field] = date.getTime();
            },
        },
    };
</script>
<style lang="scss">
.spefic-date-form-container {
    display: flex;
    line-height: 36px;
    flex-wrap: wrap;
    .alert-danger {
        flex: 1 1;
        padding: 0px 10px;
        border-radius: 5px;
    }
    .date-container {
        flex: 1;
        display: flex;
        flex-basis: 100%;
        padding: 20px 10px 0px;
        max-height: 30vh;
        overflow-y: auto;
        flex-wrap: wrap;
        > div {
            flex: 1;
            display: flex;
            flex-basis: 100%;
            .start-date-container,
            .end-date-container {
                flex: 0.5;
                display: flex;
            }
            label {
                padding-right: 8px;
            }
        }
        .action {
            flex: 0;
            margin: -2px 0px 2px -10px;
            button {
                border: 0px none;
                border-radius: 3px;
                background: none;
            }
        }
    }
    .time-container {
        max-height: 30vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 15px;
        > div {
            flex: 1;
            display: flex;
            .time-box {
                border: 1px solid #dfdfdf;
                line-height: 36px;
                border: 1px solid #d2d2d2ba;
                border-radius: 5px;
                padding: 8px;
                margin-bottom: 5px;
            }
        }
    }
}
.vdp-datepicker {
    flex: 1;
    display: flex;
}
.datepicker_calendar {
    position: fixed !important;
    margin-top: 35px;
    border-radius: 5px;
}

.datepicker_input {
    box-sizing: border-box;
    line-height: 12px;
    border: 1px solid #d2d2d2 !important;
    border-radius: 5px !important;
    margin-left: 2px !important;
    padding: 8px 12px;
    width: 90%;
}
</style>
