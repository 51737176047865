<template>
    <div class="not-supported-previewer">
        <h1> Not supported preview </h1>
    </div>
</template>

<script>

export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
.not-supported-previewer {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

</style>
