import moment from "moment"

export default {

	methods: {

    build_event: function(schedule){
        if(schedule.schedule_type == 'week_view'){
            return this.build_week_event(schedule)
        }else{
            return this.build_date_event(schedule)
        }
    },

    build_week_event: function(schedule){
        let event = {}
        let _this = this
        event.id = schedule.id
        event.playlist_id = schedule.playlist_id
        let start_date = moment(
            _this.$refs.fullCalendar.getApi().state.dateProfile.activeRange.start
          )
        if (schedule.start_time != null) {
          event.start = moment(start_date.toDate())
          event.start = moment(
            event.start
              .add(schedule.weekday, "days")
              .add(schedule.start_time, "seconds")
          ).toDate()
        }
        if (schedule.end_time != null) {
          event.end = moment(start_date.toDate())
          event.end = moment(
            event.end
              .add(schedule.weekday, "days")
              .add(schedule.end_time, "seconds")
          ).toDate()
        }
        event.overlap = false
        event.schedule_type = this.weekView
        event.title = schedule.playlist_name
        event.backgroundColor = this.weekEventColor
        return event
    },

    build_date_event: function (schedule){
      let event = {}
      event.id = schedule.id
      event.playlist_id = schedule.playlist_id
      if (schedule.start_date != null) {
        event.start = moment(schedule.start_date).toDate()
      }
      if (schedule.end_date != null) {
        event.end = moment(schedule.end_date).toDate()
      }
      event.overlap = false
      event.schedule_type = this.dateView
      event.title = schedule.playlist_name
      event.backgroundColor = this.dateEventColor
      return event
  },

    build_schedule: function(event){
      let schedule = {}

      if(event.extendedProps.schedule_type == this.dateView){
        schedule = this.build_date_schedule(event)
      }else{
        schedule = this.build_week_schedule(event)
      }
      schedule.id = event.id
      schedule.playlist_id = event.extendedProps.playlist_id
      schedule.schedule_type = event.extendedProps.schedule_type
      return schedule
    },

    build_week_schedule: function(event){
      let schedule = {}
      schedule.start_time = moment(event.start).format(
        "hh:mm a"
      )
      schedule.end_time = moment(event.end).format("hh:mm a")
      schedule.weekday = parseInt(
        moment(event.start).format("e")
      )
      return schedule
    },

    build_date_schedule: function(event){
      let schedule = {}
      if (event.start != null) {
        schedule.start_date = new Date(event.start.toUTCString().replace("GMT", ''))
      }
      if (event.end != null) {
        schedule.end_date = new Date(event.end.toUTCString().replace("GMT", ''))
      }
      return schedule
    }
  }
}
