<template>
    <b-list-group-item class="p-0">
        <b-button
            variant="outline"
            @click="startDraw"
            :class="{ btn_selected: this.isDraw['brushSelected'] }"
        >
            <i class="zmdi zmdi-brush"></i>
            <div>Brush</div>
        </b-button>
    </b-list-group-item>
</template>

<script>
    import Drawing from '../Drawing.js'
    export default {
        props: {
            canvas: { type: Object, default: {} },
            properties: { type: Object, default: {} },
            selectedTool: { type: String, default: null }
        },

        data() {
            return {
                isDrawing: false,
                isDraw: {
                    brushSelected: false
                }
            }
        },

        mixins: [Drawing],

        methods: {
            startDraw: function () {
                let _this = this
                this.cancelDrawInsert()
                if (!this.canvas.isDrawingMode) {
                    _this.$emit('changed', 'brushSelected')

                    this.isDraw.brushSelected = true
                    this.canvas.discardActiveObject()
                    this.canvas.renderAll()
                    this.canvas.isDrawingMode = true
                    this.canvas.freeDrawingBrush.width =
                        this.properties.stroke_width
                    this.canvas.freeDrawingBrush.color =
                        this.properties.stroke_color.hex8
                    _this.canvas
                        .on('mouse:down', function ({ e }) {
                            _this.isDrawing = true
                            _this.onMouseDown(e)
                        })
                        .on('mouse:up', function ({ e }) {
                            _this.isDrawing = false
                            _this.onMouseUp(e)
                        })
                } else {
                    
                    this.stopDraw()
                    _this.canvas.off('mouse:down').off('mouse:up')
                }
            },

            stopDraw: function () {
                this.isDraw.brushSelected = false
                this.isDrawing = false
                this.canvas.isDrawingMode = false
                this.canvas.renderAll()
                this.$emit('changed', 'brushSelected')
            },

            onMouseDown: function (event) {},

            onMouseUp: function (event) {
                
                this.canvas.fire('object:statechange')
            },

            drawRealTime: function (event, pointer) {
                this.canvas.freeDrawingBrush.onMouseMove(pointer, event)
            }
        }
    }
</script>
