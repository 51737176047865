<template>
  <div class="col-xl-3 col-lg-5 col-md-4 p-2 m-0 float-left">
    <b-card footer-tag="footer" class="plan h-100">
      <div class="body p-3">
        <h2 class="card-title text-center font-weight-bold">
          {{ plan.name }}
        </h2>
        <div class="mt-3 mb-3 pt-3 pb-3">
          <h2 class="text-center mt-3 font-weight-bold">
            <money-format
              :value="plan.amount['cents']"
              locale="en"
              :currency-code="plan.amount['currency_iso']"
              :subunits-value="true"
            >
            </money-format>
          </h2>
          <p class="text-center mb-3">{{ $t("plan.per_screen") }}</p>
        </div>
        <div class="text-center mt-3">
          <b-button variant="primary" @click="show_dialog">
            {{ $t("plan.subscribe") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <PlanPurchaseDialog :subscription="subscription" />
  </div>
</template>
<script>
import PlanPurchaseDialog from "./PlanPurchaseDialog";
import MoneyFormat from "vue-money-format";
export default {
  props: {
    plan: {},
  },

  data() {
    return {
      subscription: {
        processor_plan_id: this.plan.id,
        quantity: null,
        plan: this.plan,
      },
    };
  },

  components: {
    PlanPurchaseDialog,
    MoneyFormat,
  },

  methods: {
    show_dialog() {
      this.$bvModal.show("subscription_form_plan" + this.plan.id);
    },
  },
};
</script>
