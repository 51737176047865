<template>
<div class="col-12 p-4" v-observe-visibility="visibilityChanged">
    <label>Font Family:</label>
    <b-form-select v-model="font_family" @change="setFont">
        <option v-for="font in font_family_list" :key="font" :value="font.toLowerCase()">
            {{font}}
        </option>
    </b-form-select>
</div>
</template>

<script>
import Text from './Text.js'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} }
    },

    data() {
        return {
            font_family_list: ['Sans-Serif', 'Arial', 'Helvetica', 'Gill Sans', 'Lucida', 'Helvetica Narrow',
                'sans-serif', 'Serif', 'Times', 'Times New Roman', 'Palatino', 'Bookman',
                'New Century Schoolbook', 'serif', 'Monospace', 'Andale Mono', 'Courier New',
                'Courier', 'Lucidatypewriter', 'Fixed', 'monospace'
            ],
            font_family: this.properties.font_family
        }
    },

    mixins: [Text],

    methods: {
        visibilityChanged: function (isVisible) {
            if (isVisible) {
                let object = this.canvas.getActiveObject()
                this.font_family = object.fontFamily
            }
        },

        setFont: function (font) {
            this.canvas.getActiveObject().fontFamily = font.toLowerCase();
            this.canvas.renderAll();
            this.canvas.fire("object:statechange");
        },
    }
}
</script>
