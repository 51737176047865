<template>
<b-button variant="outline-primary" class="btn-sm" :class="{ 'active': this.italic }" @click="toggle_text_italic" v-observe-visibility="visibilityChanged">
    <i class="zmdi zmdi-format-italic"></i>
</b-button>
</template>

<script>
import Text from './Text.js'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} }
    },

    data() {
        return {
            italic: this.properties.italic
        }
    },

    mixins: [Text],

    methods: {
        visibilityChanged: function (isVisible) {
            if (isVisible) {
                let object = this.canvas.getActiveObject()
                this.italic = object['fontStyle'] == 'italic' ? true : false
            }
        },

        toggle_text_italic: function () {
            this.italic = !this.italic
            this.toggleItalic(this.italic);
            this.returnFocus();
        },

        toggleItalic: function (isItalic) {
            var button = $("#toolbar-italics");
            var obj = this.canvas.getActiveObject();
            this.setStyle(obj, 'fontStyle', isItalic ? 'italic' : 'normal');
            this.canvas.renderAll();
            // Push the this.canvas state to history
            this.canvas.fire("object:statechange");
            this.returnFocus();
        },
    }
}
</script>
