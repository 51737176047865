<template>
  <b-modal id="rename_device"
           :title="$t('rename_device_dialog.rename')"
           class="modal-container">
      <form ref="form"  method="post" @submit.prevent="save()">
          <div class="form-group" v-if="error">
              <p class="text-danger text-center">{{error}}</p>
          </div>
          <div class="form-group"
              :class="{'has-danger': errors.has('device_name') }">
              <label>
                {{$t('rename_device_dialog.device_name')}}
              </label>
              <input type="name"
                     class="form-control"
                     v-model="device.device_name"
                     v-validate="'required'"
                     data-vv-name="device_name"
                     data-vv-as="device_name">

              <span v-show="errors.has('device_name')" class="text-danger">{{ errors.first('device_name') }}</span>
          </div>
      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" variant="primary" @click="save()">
          {{$t('common_words.save')}}
        </b-button>
        <b-button size="sm" variant="outline-default" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import DeviceService    from '../services/DeviceService';
export default {
  props: {
    device: null
  },

  data() {
    return {
      success_message: "",
      error: null
    }
  },

  methods: {
    save(){
      let _this = this
      this.$validator
          .validateAll()
          .then((result) => {
              if(!result){
                _this.error = "Error in updating screen"
                  return;
              }
              _this.rename_device()
          }).catch((error) => {
            _this.handleError(error)
          });

    },

    rename_device(){
      let _this = this
      DeviceService.update(this.device.id, this.device)
                  .then(response => {
                      _this.$emit("updated")
                      this.hideModal();
                  })
                  .catch(error => {
                      _this.handleError(error)
                  })
    },

    hideModal() {
      this.$bvModal.hide('rename_device')
    },

  },
}
</script>
