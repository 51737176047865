<template>
<div class="col-xl-2 col-lg-4 col-md-3 col-sm-8 branding m-2">
    <b-card footer-tag="footer">

        <template v-slot:header>
            <h4 class="m-0">{{branding.name}}</h4>
        </template>
        <div class="body p-3" :style="{ 'backgroundColor': primaryBackgroundColor }">
            <b-img-lazy v-bind="mainProps" :src="getImageUrl()" alt="Image 1" rounded fluid v-if="getImageUrl() != ''">
            </b-img-lazy>
            <h4 class="card-title" :style="{ 'color': titleTextColor, 'fontFamily': titleFontFamily }">
                Title text sample</h4>
            <p class="card-text" :style="{ 'color': bodyTextColor, 'fontFamily': bodyFontFamily }">Body text sample</p>
            <b-progress class="mb-3" height="10px" v-bind:style="{ 'backgroundColor': secondaryBackgroundColor }"></b-progress>

            <div class="actions">
                <action :branding="branding" @edit="show_edit_dialog" @deleted="$emit('deleted')" />
            </div>
        </div>
    </b-card>
</div>
</template>

<script>
import Action from './BrandingAction'
export default {
    props: {
        branding: {
            type: Object,
        }
    },
    data() {
        return {
            loaded: false,
            color: 'blue',
            mainProps: { width: 25, height: 25 },
        }
    },

    components: {
        Action
    },

    computed: {
        primaryBackgroundColor() {
            return this.branding.primary_bg_color
        },
        secondaryBackgroundColor() {
            return this.branding.secondary_bg_color
        },
        titleTextColor() {
            return this.branding.title_text_color
        },
        bodyTextColor() {
            return this.branding.body_text_color
        },
        titleFontFamily() {
            return this.branding.font_family
        },
        bodyFontFamily() {
            return this.branding.font_family
        },
    },

    methods: {
        getImageUrl: function () {
            if (typeof this.branding.logo_url == "object") {
                return URL.createObjectURL(this.branding.logo_url)
            } else {
                return this.branding.logo_url
            }
        },

        show_edit_dialog: function () {
            this.$emit('edit', this.branding)
        },

        setDefault: function () {
            this.$emit('setDefault', this.branding)
        }
    }
}
</script>

<style lang="scss">
.branding {
    overflow: hidden;

    .card-body {
        padding: 0px;
    }

    div.actions {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        width: 80px;
        display: none;
    }

    &:hover {
        div.actions {
            display: block;
        }
    }
}
</style>
