<template>
  <div class="card shadow-sm col-lg-12 m-0 p-0 h-100">
    <div class="card-header shadow-sm text-left border-bottom">
      <input
        v-model="grouped_schedule.name"
        class=" p-2 ml-3 rounded border float-left"
      />
      <div class="d-flex float-right cal-view-container">
        <div class="float-left pr-2" v-if="schedule_type=='date_view'">
          <span class="pr-2"> Week</span>
          <b-button variant="secondary" @click="change_week(-1)" :disabled="prev_btn_enabled"> Previous</b-button>
          <b-button variant="secondary" @click="change_week(1)"> Next</b-button>
        </div>
        <b-form-select v-model="schedule_type" @change="set_schedule_type" :options="schedule_types"/>
        <b-button
          variant="primary"
          size="sm"
          class="btn btn float-right btn-primary mr-3 mt-1"
          @click="edit_schedule"
        >
          {{ $t("common_words.done") }}
        </b-button>
        
      </div>
    </div>
    <div class="scroll-container pt-1 p-0">
      <div class="col-lg-12 p-0 text-right border-bottom d-flex">
        <FullCalendar
          class="calendar"
          ref="fullCalendar"
          :header="false"
          :selectable="true"
          :selectOverlap="true"
          defaultView="timeGridWeek"
          @select="createEditEvent"
          :plugins="calendarPlugins"
          :weekends="calendarWeekends"
          :events="calendarEvents"
          :displayEventTime="false"
          timeZone="UTC"
          :allDaySlot="false"
          :rerenderDelay="0"
          :columnHeaderFormat="columnHeaderFormat"
          :eventRender="eventRender"
          @eventClick="createEditEvent"
          :eventColor="eventColor"
        />
      </div>
      <add-content
        modal_id="create_schedule"
        :schedule="schedule"
        @eventCreated="find"
        @eventUpdated="find"
      />
    </div>
  </div>
</template>

<script>
import InlineInput from "../components/InlineInput"
import FullCalendar from "@fullcalendar/vue"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import SelectBox from "../components/SelectBox.vue"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import GroupedScheduleService from "../services/GroupedScheduleService"
import ScheduleService from "../services/ScheduleService"
import AddContent from "./AddContent"
import moment from "moment"
import Schedule from "./ScheduleEvent.js"
import { BIconSkipStart } from 'bootstrap-vue'

export default {
  data() {
    return {
      schedules: [],
      config: {
        locale: "en",
      },
      schedule: {},
      start_time: null,
      end_time: null,
      grouped_schedule: {
        dirty: false
      },
      dates: [],
      weekDays: [],
      calendarPlugins: [
        // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin, // needed for dateClick
      ],
      calendarWeekends: true,
      calendarEvents: [],
      columnHeaderFormats: [{ weekday: "long" }, { year: 'numeric', month: 'short', day: '2-digit' }],
      columnHeaderFormat: { weekday: "long" },
      calendarView: "timeGridWeek",
      weekFormat: { weekday: "short" },
      dateFormat: { year: 'numeric', month: 'short', day: '2-digit' },
      schedule_type: 'week_view',
      weekView: 'week_view',
      dateView: 'date_view',
      schedule_types: [{ text: this.$t("schedules.schedule_types.date_view"), value: 'date_view'},
                       { text: this.$t("schedules.schedule_types.week_view"), value: 'week_view'}],
      dateEventColor: '#25B959',
      weekEventColor: '#3788d8',
      eventColor: this.weekEventColor,
      calendarStartDate: null,
    }
  },

  components: {
    FullCalendar,
    AddContent,
    InlineInput,
    SelectBox
  },

  mounted() {
    moment.tz.setDefault("UTC")
    this.find()
  },

  mixins: [Schedule],

  computed: {
    prev_btn_enabled: function(){
       return !((new Date()) < this.calendarStartDate)
    }
  },

  methods: {
    show_modal(modal_name) {
      this.$bvModal.show(modal_name)
    },

    find() {
      let _this = this
      GroupedScheduleService.get(this.$route.params.id)
        .then((response) => {
          if(!_this.grouped_schedule.dirty){
            _this.grouped_schedule = response.data
          }
          _this.schedules = response.data.schedules
          _this.setSchedule(_this.schedules)
          _this.loaded = true
        })
        .catch((error) => {
          _this.handleError(error)
        })
    },

    getSchedules() {
      let _this = this
      ScheduleService.list({ obj_type: "event" })
        .then((response) => {
          if (response.data.length) {
            _this.schedules = response.data
          }
        })
        .catch((error) => {
          _this.handleError(error)
        })
    },

    createEditEvent(schedule) {
      if (schedule.jsEvent.target.parentElement.className == 'delete') {
        this.delete_schedule(schedule.event.id)
      } else {
        if(schedule.event == null){
          schedule.event = schedule
          schedule.event.extendedProps = { schedule_type: this.schedule_type}
        }
        this.schedule = this.build_schedule(schedule.event)
        this.schedule.overlap = false
        this.schedule.grouped_schedule_id = this.grouped_schedule.id
        this.show_modal("create_schedule")
      }
    },

    eventRender: function(schedule) {
      let _this = this
      let btn = document.createElement("button")
      btn.classList="delete"
      btn.type = "button"
      btn.title= this.$t("schedules.button.delete_tooltip") 
      let itag = document.createElement("i")
      itag.classList = "zmdi zmdi-delete"
      btn.appendChild(itag)
      schedule.el.title = this.$t("schedules.edit_title")

      // edit button
      let btn1 = document.createElement("button")
      btn1.type = "button"
      btn1.classList = "edit"
      btn1.title= this.$t("schedules.button.edit_tooltip") 
      let itag1 = document.createElement("i")
      itag1.classList = "zmdi zmdi-edit"
      btn1.appendChild(itag1)
      schedule.el.title = this.$t("schedules.edit_title")
      schedule.el.appendChild(btn1)
      schedule.el.appendChild(btn)
    },

    setSchedule(schedules) {
      let _this = this
      let events = []
      schedules.forEach(function(schedule, index, schedules) {
        let schedule_event = {}
        events.push(_this.build_event(schedule))
      })
      this.calendarEvents = events
      this.$refs.fullCalendar.getApi().needsFullRerender = true
    },

    formatDuration(value) {
      var sec_num = parseInt(value, 10) // don't forget the second param
      var hours = sec_num >= 3600 ? Math.floor(sec_num / 3600) : 0
      var minutes = Math.floor((sec_num - hours * 3600) / 60)
      var seconds = sec_num - hours * 3600 - minutes * 60
      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      return hours + ":" + minutes
    },

    delete_schedule(schedule_id) {
      let _this = this
      ScheduleService.destroy(schedule_id)
        .then((response) => {
          let event = _this.$refs.fullCalendar
            .getApi()
            .getEventById(schedule_id)
          event.remove()
        })
        .catch((error) => {
          this.handleError(error)
        })
    },

    edit_schedule() {
      let _this = this
      GroupedScheduleService.update(this.grouped_schedule.id, {
        schedule: this.grouped_schedule,
      })
        .then((response) => {
          _this.navigate_to_schedules()
        })
        .catch((error) => {
          _this.handleError(error)
        })
    },

    navigate_to_schedules: function() {
      this.$router.push({ name: "schedules" })
    },

    selectAllow: function(selectInfo) {
      return selectInfo.start.day == selectInfo.end.day
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    set_schedule_type: function(option){
      let _this = this
      this.schedule_type = option
      if(option == "week_view"){
        this.columnHeaderFormat = this.weekFormat
        this.eventColor = this.weekEventColor
        this.headerToolBar = false
      }else {
        this.columnHeaderFormat = this.dateFormat
        this.eventColor = this.dateEventColor
        this.headerToolBar = this.dateToolBar
      }
      setTimeout(function(){
        _this.setSelectColor()
      }, 10)
      // this.find()
    },

    setSelectColor: function(options){
      if(this.columnHeaderFormat == this.dateFormat){
        document.getElementsByClassName("fc-view-container")[0].classList.add("dateview")
      }else{
        document.getElementsByClassName("fc-view-container")[0].classList.remove("dateview")
      }
    },


    change_week: function(num){
      let _this = this
      if(num < 0){
        this.$refs.fullCalendar.getApi().prev()
      }else{
        this.$refs.fullCalendar.getApi().next()
        this.setSchedule(this.schedules)
      }
      setTimeout(function(){
        _this.calendarStartDate = _this.$refs.fullCalendar.getApi().view.currentStart
      }, 10);
      
    }
  },

  watch: {
    grouped_schedule: function (newVal) {
      this.grouped_schedule.dirty = true
    }
  }
}
</script>
<style lang="scss">
// you must include each plugins' css
// paths prefixed with ~ signify node_modules

.calendar {
  z-index: 0;
  .fc-time-grid-event {
    margin-bottom: -1px;
  }
  .fc-time-grid-event {
    cursor: pointer;
    .fc-content {
      margin-right: 10px;
      color: #ffffff;
    }
    &:hover {
      button {
        display: block;
      }
    }
    button {
      display: none;
      position: absolute;
      cursor: pointer;
      line-height: 16px;
      left: 2px;
      bottom: 3px;
      border-radius: 3px;
      z-index: 99;
      width: 10px;
      border: 1px solid transparent;
      background: #ffffff;
    }
    .delete {
      left: 32px;
      background: #f9a6a6;
      border: 1px solid #f9a6a6;
    }
  }
  .fc-highlight {
    background-color: #3788d8;
    opacity: 1;
    border-radius: 3px;
  }
  .fc-title {
    font-weight: 500;
    padding: 0px 2px;
  }
  .fc-today {
    background-color: transparent !important;
  }
  .fc-event-container {
    margin: 0px !important;
  }
  .fc-time-grid-container{
    overflow: hidden !important;
  }
  
  .fc-toolbar.fc-header-toolbar {
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  .fc-head-container.fc-widget-header {
      position: sticky;
      top: 1px;
      z-index: 10;
  }
}

.dateview{
    .fc-highlight {
      background-color: #25B959;
    }
}
.fc-scroller{
  height: auto !important;
}
.fc-divider{
  display: none;
}
.cal-view-container {
  div{
    width: 250px
  }
  select{
    width: 150px;
    margin-right: 20px;
  }
}
</style>
