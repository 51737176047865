<template>
    <div class="selected-contents-container">
        <div class="card shadow-sm p-0 rounded-0">
            <div class="header shadow-sm p-2 border-bottom">
                <h3 class="channel-header-1">Selected contents</h3>
                <div class="channel-header-2">
                    <b-form-select
                        v-model="selected_zone_id"
                        :options="zones"
                        value-field="id"
                        text-field="name"
                        @change="changeZone"
                    />
                </div>
            </div>

            <div class="media-list-container">
                <draggable
                    class="select-media-list"
                    :class="{'no-content-flex': !this.new_media_list.length }"
                    v-model="new_media_list"
                    group="media"
                    @change="updateIndex"
                >
                    <channel-media
                        v-for="(media, index) in new_media_list"
                        :key="
                            'channel-media-' +
                            media.zone_id +
                            '-' +
                            media.position +
                            index
                        "
                        :media="media"
                        :index="index + 1"
                        :readOnly="false"
                        @updated="update"
                        @delete="deleteMedia"
                        v-show="!media['_destroy'] == true"
                    />
                    <no-channel-media
                        :message="no_content_msg"
                        v-if="this.new_media_list.length == 0"
                        style="margin: auto;"
                    />
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
    import Draggable from "vuedraggable";
    import EventBus from "../../core/event-bus";
    import ChannelMedia from "./ChannelMedia.vue";
    import NoChannelMedia from "./NoChannelMedia";

    import ContentService from "../../services/ContentService";
    import Search from "../../components/Search";

    export default {
        props: {
            media_list: {
                type: Array,
                default: () => [],
            },
            zones: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                loaded: true,
                no_content_msg: this.$t("channel.media.no_data"),
                selected_zone_id: null,
                new_media_list: [],
                selected_schedule: null,
            };
        },

        components: {
            Draggable,
            NoChannelMedia,
            ChannelMedia,
        },

        computed: {
            getMediaList: function () {
                return this.new_media_list[this.selected_zone_id];
            },
            media_count: function () {
                let _this = this;
                return Object.keys(this.new_media_list).map(function (key, value) {
                    return _this.new_media_list[key];
                });
            },
        },

        mounted() {
            let _this = this;
            this.selected_zone_id = this.zones[0].id;
            this.new_media_list = this.media_list.filter(function (media) {
                return media.zone_id == _this.selected_zone_id;
            });
        },

        methods: {
            updateIndex: function () {
                let _this = this;
                this.new_media_list.forEach((content, index) => {
                    content.zone_id = _this.selected_zone_id;
                    content.position = index;
                });
                let media_list = this.media_list.filter(function (media) {
                    return media.zone_id != _this.selected_zone_id;
                });
                media_list = media_list.concat(this.new_media_list);
                this.$emit("change", media_list);
            },

            update: function (content) {
                if (content.position != null) {
                    this.media_list[content.position] = content;
                }
            },

            setZone: function () {
                let last_index = this.media_list.length - 1;
                this.media_list[last_index].zone_id = this.selected_zone_id;
            },

            changeZone: function (zone_id) {
                let _this = this;
                this.selected_zone_id = zone_id;
                this.new_media_list = this.media_list.filter(function (media) {
                    return media.zone_id == _this.selected_zone_id
                });
            },

            deleteMedia: function (option) {
                this.changeZone(this.selected_zone_id)
            },

        },
    };
</script>

<style lang="scss" scoped>
.selected-contents-container {
    flex: 0.4;
    display: flex;
    .card {
        flex: auto;
        h3.channel-header-1 {
            flex: 0.2;
            min-width: 200px;
            line-height: 34px;
        }

        div.channel-header-2 {
            flex: 0.4;
            min-width: 200px;
            line-height: 34px;
            margin: auto;
            margin-right: 0;
            select {
                flex: 0.5;
                margin: auto;
                margin-right: 0;
                display: flex;
            }
        }

        .media-list-container {
            flex: 1;
            overflow: auto;
            display: flex;
            min-width: 550px;
        }
        .select-media-list {
            flex: 1;
            min-width: 500px;
            .sortable-ghost{
                flex: 1;
                display: flex;
            }
        }
        .no-content-flex{
            display: flex;
            flex-direction: column;
        }
    }
    .sortable-ghost{
        flex: 1;
    }
}
</style>
