<template>
  <div>
    <sorting
      class="float-right ml-2"
      @change="sortingChanged"
      :sort_list="this.sort_options"
      :sort_order="this.selectedSorting"
    />
  </div>
</template>
<script>
import Sorting from "../components/Sorting";
import {
  getContentsSortingField,
  setContentsSortingField,
  getContentsSortingOrderBy,
  setContentsSortingOrderBy,
} from "../core/UserPreferences";

import {getSortingLabel} from "../core/HelperMethods";

export default {
  data() {
    return {
      selectedSorting: {
        order: {
          value: getContentsSortingField(),
          label: getSortingLabel(getContentsSortingField()),
        },
        order_by: getContentsSortingOrderBy(),
      },
      sort_options: [
        { value: "name", label: "Name" },
        { value: "updated_at", label: "Last updated" },
      ],
    };
  },
  components: {
    Sorting,
  },
  methods: {
    sortingChanged(sort_option) {
      this.selectedSorting = sort_option;
      setContentsSortingField(sort_option.order.value);
      setContentsSortingOrderBy(sort_option.order_by);
      
      this.$emit("change", this.selectedSorting)
    },
  },
};
</script>
