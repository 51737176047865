<template>
    <div class="week-form-container" v-if="loaded">
        <div class="week-box-container" v-if="newSchedule.week_days != null">
            <div
                v-for="weekday in Object.keys(newSchedule.week_days)"
                :class="newSchedule.week_days[weekday] ? 'active' : ''"
                :key="weekday"
                @click="update_week_days(weekday)"
            >
                <span>{{ weekday }}</span>
            </div>
        </div>
        <div class="alert-danger" v-if="errorTime.length > 0">
            {{ errorTime.join(",") }}
        </div>
        <div class="time-container">
            <div
                v-for="(time, index) in newSchedule.times"
                :key="'s' + newSchedule.media_id + index"
            >
                <ScheduleTime
                    v-model="newSchedule.times[index]"
                    :canDelete="newSchedule.times.length > 1"
                    @delete="deleteTime(index)"
                />
            </div>
        </div>
        <div class="add_time">
            <button class="btn" @click="addTime">
                <i class="zmdi zmdi-plus-circle"></i>
                {{ $t("common_words.add") }}
            </button>
        </div>
    </div>
</template>
<script>
    import TimeVue from "../../../components/Time.vue";
    import ScheduleTime from "./ScheduleTimeComposition";
    export default {
        props: {
            schedule: {
                type: Object,
                default: {},
            },
        },
        data() {
            return {
                newSchedule: JSON.parse(JSON.stringify(this.schedule)),
                weekdays: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
                selected_weekdays: [],
                times: [],
                loaded: false,
            };
        },

        components: {
            TimeVue,
            ScheduleTime,
        },

        computed: {
            errorTime: function () {
                return this.newSchedule.times
                    .filter(function (value) {
                        return value["error"] != undefined;
                    })
                    .map(function (value) {
                        return value["error"];
                    });
            },
        },

        beforeMount() {
            this.newSchedule = Object.assign({}, this.schedule)
            if (Object.keys(this.schedule.week_days).length == 0) {
                this.newSchedule.week_days = {
                    mon: true,
                    tue: true,
                    wed: true,
                    thu: true,
                    fri: true,
                    sat: true,
                    sun: true,
                };
                this.newSchedule.times = [
                    {
                        start: 0,
                        end: 82859,
                    },
                ];
            }
            this.loaded = true
        },

        methods: {
            update_week_days: function (weekday) {
                this.newSchedule.week_days[weekday] =
                    !this.newSchedule.week_days[weekday];
            },

            addTime: function () {
                this.newSchedule.times.push({
                    start: 0,
                    end: 82859,
                });
            },

            validate: function () {
                let _this = this;
                let schedule = this.newSchedule;
                return new Promise((resolve, reject) => {
                    let errors = _this.checkSchedule(schedule);
                    _this.$set(_this.newSchedule, "time", schedule.time);
                    if (errors.length < 1) {
                        resolve(_this.newSchedule);
                    } else {
                        reject(errors);
                    }
                });
            },

            checkSchedule: function (schedule) {
                let _this = this;
                let errors = [];
                if (schedule.times.length) {
                    schedule.times.forEach(function (t, index) {
                        if (t["start"] >= t["end"]) {
                            _this.$set(
                                schedule.time[index],
                                "error",
                                "End time should be greater than Start time"
                            );
                            errors.push(t["error"]);
                        } else if (t["error"] != null) {
                            delete t["error"];
                        }
                    });
                }
                return errors;
            },

            deleteTime: function (index) {
                this.newSchedule.times.splice(index, 1);
            },

            formattedTime(time) {
                let timeStr = new Date(time)
                    .toLocaleString("en-US", {
                        dateStyle: "short",
                        timeStyle: "medium",
                        hour12: true,
                    })
                    .split(",")[1];
                return (timeStr || "").trim();
            },
        },
    };
</script>
<style lang="scss">
.week-form-container {
    display: flex;
    line-height: 36px;
    padding: 5px 10px;
    flex-wrap: wrap;
    .week-box-container {
        flex: 1;
        display: flex;
        flex-basis: 100%;
        margin-bottom: 20px;
        div {
            flex: 0.14;
            margin: 5px;
            background: #dfdfdf;
            padding: 2px 5px;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            text-transform: capitalize;
        }
        .active {
            background: #049d04;
        }
    }
    .alert-danger {
        border-radius: 5px;
        padding: 0px 10px;
        margin-bottom: 10px;
        flex: 1;
    }
    .time-container {
       > div {
            flex: 1;
            display: flex;
            flex-basis: 100%;
        }
        max-height: 40vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
