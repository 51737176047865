<template>
  <div class="alert-form-main-container">
    <div class="input-controls">
      <div class="input-control">
        <span class="field-label-text">{{ $t("custom_alert.label.name") }}</span>
        <b-input
          v-model="custom_alert.name"
          type="text"
          :placeholder="$t('custom_alert.label.alert_name_placeholder')"
        />
      </div>
      <div class="input-control">
        <span class="field-label-text">{{ $t('custom_alert.label.screens') }}</span>
        <tags-input
          element-id="tags-2"
          v-model="selected_device"
          placeholder="Filter screens (Default all)"
          :typeahead-url="device_search_url"
          id-field="id"
          text-field="device_name"
          typeahead-style="dropdown"
          :typeahead="true"
        >
          <template v-slot:selected-tag="{ tag, index, removeTag }">
            <span>{{ tag["device_name"] }}</span>

            <a
              class="tags-input-remove"
              @click="removeSelectedTag(index, removeTag)"
            >
            </a>
          </template>
        </tags-input>
      </div>
      <div class="input-control">
        <span class="field-label-text">{{ $t("custom_alert.label.last_seen") }}</span>
        <b-input
          v-model="custom_alert.metadata.last_seen"
          type="number"
          min="1"
        />
      </div>
      <div class="input-control">
        <span class="field-label-text">{{ $t("custom_alert.label.schedule") }}</span>
        <b-form-select
          v-model="custom_alert.schedule"
          :options="schedule_options"
        />
      </div>
    </div>
    <div class="button-container">
      <button
        class="btn btn-primary btn-lg set-alert-button"
        @click="create_custom_alert"
      >
        {{ $t("custom_alert.save_button") }}
      </button>
    </div>
    <hr />
  </div>
</template>

<script>
import CustomAlertServices from "../../services/CustomAlertService.js";
import TagsInput from "@voerro/vue-tagsinput";
import "../../../../node_modules/@voerro/vue-tagsinput/dist/style.css";

export default {
  props: {},

  data() {
    return {
      fields: [
        { key: "content", label: "Content name" },
        { key: "played_count", label: "Played count" },
        { key: "total_duration", label: "Total duration" },
        { key: "device", label: "Device" },
      ],
      message: "Loading...",
      device_search_url: "/user/devices/search?per_page=10&query=:search",
      selected_device: null,
      schedule_options: [
        { text: this.$t("custom_alert.label.daily"), value: "@daily" },
        { text: this.$t("custom_alert.label.weekly"), value: "@weekly" },
        { text: this.$t("custom_alert.label.monthly"), value: "@monthly" },
      ],
      custom_alert: {
        metadata: { device_ids: null, last_seen: 3 },
        last_seen: 3,
        schedule: "@daily",
        name: "",
        type: "Account::CustomAlert::DeviceLastSeenAlert",
      },
    };
  },

  components: {
    TagsInput,
  },

  methods: {
    create_custom_alert: function () {
      let _this = this;
      _this.loaded = false;
      this.setMetadata();
      let params = { custom_alert: this.custom_alert };
      CustomAlertServices.create(params)
        .then((response) => {
          _this.$emit("created");
          _this.$notify({
            group: "alert",
            text: "Alert created",
            type: "alert-success",
            duration: 5000,
          });
          _this.resetForm();
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },

    validate_params(metadata) {
      if (metadata["device_ids"].length == 0) {
        return false;
      }
      return true;
    },

    get_device_name: function (device_names) {
      if (device_names != null) {
        let names = device_names.split(",");
        let display_names_text = names.slice(0, 1).join(",");
        if (names.length > 2) {
          display_names_text += " + " + (names.length - 2) + "more";
        }
        return display_names_text;
      } else {
        return "N/A";
      }
    },

    setMetadata: function () {
      if (this.selected_device != null) {
        this.custom_alert.metadata["device_ids"] = this.selected_device.map(
          function (device) {
            return device.id;
          }
        );
      }
    },

    removeSelectedTag: function (index, removeTag) {
      setTimeout(function () {
        removeTag(index);
      }, 100);
    },

    resetForm: function () {
      this.selected_device = null;
      this.custom_alert.name = null;
    },
  },
};
</script>

<style scoped>
.alert-form-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}
.input-controls {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.input-control {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.set-alert-button {
  max-width: 200px;
}
.field-label-text {
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
}
</style>
