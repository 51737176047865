<template>
    <div class="screen-zone-container" @click="select_zone_pressed">
        <b-button
            class="btn float-left btn-secondary btn-sm"
            :class="{
                selected:
                    this.channel.layout != null &&
                    this.channel.layout.id == this.layout.id,
            }"
        >
            <span
                v-if="
                    this.channel.layout == null ||
                    this.channel.layout.id != this.layout.id
                "
            >
                Select
            </span>
            <span
                v-if="
                    this.channel.layout != null &&
                    this.channel.layout.id == this.layout.id
                "
                style="font-weight: 600"
            >
                <i class="zmdi zmdi-check pr-2" />Selected
            </span>
        </b-button>
        <div class="screen-layout-section">
            <div
                class="zone-layout-box-container"
                :style="
                    'width:' +
                    layout.width +
                    'px ; height:' +
                    layout.height +
                    'px ;'
                "
            >
                <zone-division-box
                    v-for="zone in layout.zones"
                    :zoneDivisionBox="zone"
                    :key="zone.id"
                />
            </div>
        </div>
        <div class="screen-metadata-section">
            <span>{{ layout.name }}</span>
        </div>
    </div>
</template>
<script>
    import ZoneDivisionBox from "./ZoneDivisionBox.vue";
    import EventBus from "../../core/event-bus";

    export default {
        components: { ZoneDivisionBox },
        props: {
            channel: {
                type: Object,
                default: {},
            },

            layout: {
                type: Object,
                default: {},
            },
        },

        methods: {
            select_zone_pressed() {
                this.$emit("selected", this.layout);
            },
        },
    };
</script>

<style scoped>
.screen-zone-container {
    display: flex;
    background-color: #ecf0f1;
    height: 300px;
    width: 300px;
    margin: 16px;
    border: 1px rgb(194, 194, 194) solid;
    border-radius: 8px;
    flex-direction: column;
    cursor: pointer;
}
.screen-layout-section {
    display: flex;
    flex: 0.8;
    justify-content: center;
    align-items: center;
}
.screen-metadata-section {
    display: flex;
    flex: 0.2;
    justify-content: center;
    align-items: center;
}
.zone-layout-box-container {
    background-color: rgba(235, 15, 15, 0.618);
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.selected {
    background-color: #969696;
    color: #525151;
}

.zmdi-check {
    font-size: 16px;
}
</style>
