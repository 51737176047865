<template>
  <div class="col-md-6 col-lg-8 form-preview">
    <div class="col-sm-8 col-6 preview-box">
      <div class="preview-cont"
           :style="{backgroundColor: branding.primary_bg_color}">
           <b-img-lazy class="ml-4 mt-3" v-bind="mainProps"
                       :src="getImageUrl()" alt="Image 1" rounded fluid
                       v-if="getImageUrl() != '' ">
           </b-img-lazy>
           <h2 class="card-title ml-4 mt-4"
              v-bind:style="{'color': branding.title_text_color, 'fontFamily': branding.title_font_family}">
              Title text sample</h2>
           <div class="card-text ml-4 mt-2"
              v-bind:style="{'color': branding.body_text_color, 'fontFamily': branding.body_font_family}">
              Body text sample
            </div>
           <b-progress class="m-4"
                       height="15px"
                       v-bind:style="{'backgroundColor':  branding.secondary_bg_color}">
           </b-progress>
     </div>
   </div>
  </div>
</template>
<script>
  export default {
    props: {
      branding: {
        type: Object
      }
    },

    data() {
      return {
        mainProps: { width: 25, height: 25},
      }
    },

    methods: {
      getImageUrl: function() {
        if(typeof this.branding.logo_url == "object"){
          return URL.createObjectURL(this.branding.logo_url)
        }else{
          return this.branding.logo_url
        }
      },
    }
  }
</script>
<style lang="scss">
  .preview-box{
    margin: auto;
    padding: 0px;
    border: 8px solid #3a3838;
    border-radius: 8px;
    background-position: left;
    box-shadow: 0px 1px 7px black;
    div.preview-cont{
      padding: 15px;
      height: 100%;
    }
  }
</style>
