<template>
    <editor
        :content="content"
        :widget="widget"
        ref="editor"
    />
</template>

<script>
    import ContentService from '../../services/ContentService'
    import WidgetService from '../../services/WidgetService'
    import DurationInput from '../../components/DurationInput'
    import Editor from './Editor'
    import Spinner from '../../components/Spinner'
    import HeaderToolbar from './HeaderToolbar.vue'

    export default {
        data() {
            return {
                content: {
                    id: null,
                    name: 'New Template',
                    metadata: null
                },
                zoom: -3,
                success_message: '',
                error: null,
                start_drag: false,
                contentId: null,
                widgetId: null,
                widget: {},
                content: {}
            }
        },

        components: {
            DurationInput,
            Editor,
            Spinner,
            HeaderToolbar
        },

        mounted() {
            let _this = this
            this.contentId = this.$route.params.content_id
            this.widgetId = this.$route.params.widget_id
            this.get_data()
        },

        methods: {
            resetForm: function () {
                this.$emit('editor.close')
            },

            get_data: function () {
                let _this = this
                if (this.widgetId != null) {
                    WidgetService.show(this.widgetId)
                        .then((response) => {
                            _this.widget = response.data
                            _this.loaded = true
                            _this.$nextTick(() => {
                                _this.$refs.editor.init()
                            })
                        })
                        .catch((error) => {
                            _this.handleError(error)
                        })
                } else {
                    ContentService.show(this.contentId)
                        .then((response) => {
                            _this.content = response.data
                            _this.widget = response.data.widget
                            _this.loaded = true
                            _this.$nextTick(() => {
                                _this.$refs.editor.init()
                            })
                        })
                        .catch((error) => {
                            _this.handleError(error)
                        })
                }
            }
        }
    }
</script>

<style lang="scss">
#editor-header {
    .label {
        float: left;
        line-height: 40px;
        margin-right: 10px;
    }

    .input-sm {
        float: left;
    }

    .selected {
        background: #dfdfdf;
        color: #fff;
    }

    .editor-header-btn {
        .btn {
            padding: 3px 15px 3px 15px;
        }
    }

    .zmdi {
        font-size: 32px;
        color: #5e6ebe;
    }
}

</style>
