
import Vue from 'vue';
import Http from '../../core/http';

const DirectUploadService = {

	post(params) {
		return Http.post('presigned_url', params);
	},

};

export default DirectUploadService;
