<template>
    <div
        class="card shadow-sm col-lg-12 h-100 overflow-hidden right-side-container"
    >
        <div class="header shadow-sm col-lg-12 p-2 text-right border-bottom">
            <h3 class="float-left mt-1 mb-0">
                {{ $t("billing.title") }}
            </h3>

            <div
                class="action-container"
                v-show="tabIndex == 0"
                v-if="selected_currency != null"
            >
                <label class="switch btn-color-mode-switch">
                    <input
                        type="checkbox"
                        name="color_mode"
                        id="color_mode"
                        v-model="currency_switch"
                        @change="set_selected_currency"
                    />
                    <label
                        for="color_mode"
                        data-on="INR"
                        data-off="USD"
                        class="btn-color-mode-switch-inner"
                    ></label>
                </label>
            </div>
        </div>
        <br />
        <div class="security-info-container">
            <img :src="secure_icon" height="32px" width="32px" />
            <span
                >Your payment details are secure with
                <a href="https://stripe.com/docs/security" target="blank"
                    >Stripe.com</a
                >, even we at CMS Signage don't have access to these details,
                read more
                <a
                    href="https://stripe.com/en-in/guides/pci-compliance#how-stripe-helps-organizations-achieve-and-maintain-pci-compliance"
                    target="blank"
                    >stripe security compliance</a
                ></span
            >
        </div>
        <br />
        <div>
            <plan v-for="plan in plans" :plan="plan" :key="'plan' + plan.id" />
            <plan-custom />
        </div>
    </div>
</template>

<script>
    import PlanCustom from "./plans/PlanCustom";
    import Plan from "./plans/Plan";
    import PlanService from "../services/PlanService";
    import EventBus from "../core/event-bus";
	import { mapGetters } from 'vuex'

    export default {
        props: {
            tab: { type: String, default: "" },
        },

        data() {
            return {
                tabIndex: 0,
                plans: [],
                total_pages: 0,
                page: 1,
                loading: false,
                currencies: ["USD", "INR"],
                selected_currency: null,
                currency_switch: true,
                secure_icon: require("../images/billing/secure.png"),
            };
        },

        components: {
            Plan,
            PlanCustom,
        },

        computed: {
            ...mapGetters("userStore", ["user"]),
        },

        mounted() {
			if(this.user.account.subscription != null){
				this.$router.push({ name: 'subscription' })
			}else{
				this.get_plans();
			}
        },

        methods: {
            get_plans: function (selected_currency) {
                let _this = this;
                EventBus.$emit("progress", true);
                let params = {};
                if (this.selected_currency != null) {
                    params = { currency: this.selected_currency };
                }
                PlanService.get(params)
                    .then((response) => {
                        if (response.data != null) {
                            _this.plans = response.data.plans;
                            _this.selected_currency = response.data.currency;
                            _this.set_currency_switch();
                        }
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            handleScroll: function () {
                this.page++;
                this.get_subscription();
            },
            set_selected_currency: function () {
                if (this.currency_switch == true) {
                    this.selected_currency = "INR";
                } else {
                    this.selected_currency = "USD";
                }
                this.get_plans();
            },

            set_currency_switch: function () {
                if (this.selected_currency == "USD") {
                    this.currency_switch = false;
                } else {
                    this.currency_switch = true;
                }
            },
        },
    };
</script>

<style lang="scss">
.plan {
    .card-body {
        padding: 0;
    }
}
.security-info-container {
    opacity: 0.8;
}

.action-container {
    min-width: 180px;
    float: right;
    .switch {
        position: relative;
        width: 90px;
        height: 34px;
        display: block;
    }

    .switch input {
        display: none;
    }

    .slider {
        cursor: pointer;
        font-weight: 500;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #4354a6;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #4354a6;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(55px);
        -ms-transform: translateX(55px);
        transform: translateX(55px);
    }

    /*------ ADDED CSS ---------*/
    .btn-color-mode-switch {
        margin: 0px;
        position: relative;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner {
        margin: 0px;
        width: 150px;
        height: 36px;
        background: #e0e0e0;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
        display: block;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
        content: attr(data-on);
        position: absolute;
        font-weight: 500;
        top: 7px;
        right: 20px;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
        content: attr(data-off);
        width: 70px;
        height: 30px;
        background: #fff;
        border-radius: 5px;
        position: absolute;
        left: 3px;
        top: 3px;
        text-align: center;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 6px -2px #fff;
        padding: 5px 0px;
        font-weight: 600;
    }

    .btn-color-mode-switch input[type="checkbox"] {
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }

    .btn-color-mode-switch
        input[type="checkbox"]:checked
        + label.btn-color-mode-switch-inner {
        background: #dfdfdf;
        color: #000;
    }

    .btn-color-mode-switch
        input[type="checkbox"]:checked
        + label.btn-color-mode-switch-inner:after {
        content: attr(data-on);
        left: 68px;
    }

    .btn-color-mode-switch
        input[type="checkbox"]:checked
        + label.btn-color-mode-switch-inner:before {
        content: attr(data-off);
        right: auto;
        left: 20px;
    }

    .btn-color-mode-switch
        input[type="checkbox"]:checked
        + label.btn-color-mode-switch-inner {
        color: #000;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
        display: block;
    }
}
</style>
