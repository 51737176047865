<template>
    <div class="form-container">
        <div class="schedule_type_heading">
            {{ $t("channel.media.schedule.select_type_heading") }}
        </div>
        <div class="button-container">
            <button
                v-for="schedule_type in schedule_types"
                @click="$emit('selected', schedule_type)"
                :key="schedule_type"
                class="btn btn-outline-success"
            >
                {{ display_schedule_types[schedule_type] }}
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        props: {},

        data() {
            return {
                schedule_types: ["daily", "weekly", "specific_date"],
                display_schedule_types: this.$t(
                    "channel.media.schedule.schedule_types"
                ),
                selected_schedule_type: null,
            };
        },
    };
</script>

<style lang="scss" scoped>
.form-container {
    display: flex;
    flex-wrap: wrap;
    margin: 15px;
    .schedule_type_heading {
        justify-content: center;
        font-size: 18px;
        flex: 1;
        flex-basis: 100%;
        display: flex;
        margin: 20px;
    }
    .button-container {
        flex: 1;
        flex-basis: 100%;
        justify-content: center;
        display: flex;
        .btn {
            margin: 0px 20px 0px 20px;
        }
    }
}
</style>