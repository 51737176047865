<template>
<div class="cont-loader" v-show="show_loader">
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="loader-overlay"></div>
</div>
</template>

<script>
export default {
  name: 'Spinner',

  props: {
    show_loader: {
      type: Boolean,
      default: true
    },

  }
}
</script>
<style lang="scss">
.loader-overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1100;
    background-color: #807f7f;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    overflow: hidden;
}

.cont-loader {
    position: fixed;
}

.cont-loader {
    z-index: 1050;
}
</style>
