<template>
  <div class="input-colorpicker" v-click-outside="hidePicker">
    <b-input-group >
      <template v-slot:prepend>
        <div class="color-swatch"
             :class="swatchClass"
             :style="btnTextColor"
             @click="showPicker=true"/>
        <color-picker :value="color"
                      @input="updateValue"
                      v-show="showPicker"
                      :disableAlpha="disableAlpha">
        </color-picker>
      </template>
      <input class="form-control" v-model="color.hex8" @click="showPicker=true"/>
     </b-input-group>

  </div>
</template>

<script>
import ColorPicker   from 'vue-color/src/components/Chrome.vue'
const hexRgb = require('hex-rgb');

export default {
  name: 'App',

  props:{
    value:{
      type: String,
      default: '#000000FF'
    },
    disableAlpha: false,
    swatchClass: ''
  },

  components: {
    ColorPicker
  },

  data()  {
    return {
      color: {},
      showPicker: false
    }
  },

  mounted: function(){
    let rgba = hexRgb(this.value, {format: 'array'})
    this.color = {
      hex8: this.value,
      rgba: { r: 25, g: 77, b: 51, a: 1 },
    }
  },

  computed: {
    btnTextColor: function(){
      return {
        backgroundColor: this.color.hex8
      }
    },
  },

  methods: {

    emitChange: function(){
      this.$emit("input", this.color.hex8)
    },

    showColorPicker: function(){
      this.showPicker = true
    },

    hidePicker: function(){
      if(this.showPicker){
        this.showPicker = false
      }
    },

    updateValue: function(color){
      this.color = color
      this.emitChange()
    }
  },

}
</script>
<style lang="scss">
  .input-colorpicker{
    .vc-chrome{
      top: 20px;
      left: 10%;
      position: absolute;
      opacity: 1;
      z-index: 1024;
    }
    .input-group{
      .form-control{
        padding-left: 45px !important;
        margin-left: 3px;
        z-index: 10;
      }
      .input-group-prepend{
        .color-swatch{
          height: 38px;
          border-radius: 5px;
          z-index:99;
          border: 1px solid #DFDFDF;
        }
      }
    }
  }
</style>
