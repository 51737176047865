<template>
  <b-modal
    id="change_password"
    :title="$t('user.change_password')"
    class="modal-container"
    @shown="showModal()"
  >
    <form ref="profileform" method="post" @submit.prevent="validate">
      <div class="form-group" v-if="error">
        <p class="text-danger text-center">{{ error }}</p>
      </div>

      <div class="form-group  required ">
        <label class="form-control-label  required">
          {{ $t("user.old_password") }}
          <abbr title="required" class="required-input">*</abbr>
        </label>
        <input
          type="password"
          class="form-control"
          v-model="user.current_password"
        />
      </div>

      <div class="form-group">
        <label for="firstName">
          {{ $t("user.new_password") }}
          <abbr title="required" class="required-input">*</abbr>
        </label>
        <input
          type="password"
          required
          v-model="user.password"
          name="user[password]"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="firstName">
          {{ $t("user.confirm_password") }}
          <abbr title="required" class="required-input">*</abbr>
        </label>
        <input
          type="password"
          required
          v-model="user.confirm_password"
          name="user[confirm_password]"
          class="form-control"
        />
      </div>
    </form>
    <div slot="modal-footer" class="w-100 text-right">
      <b-button size="sm" variant="primary" @click="save()">
        {{ $t("common_words.save") }}
      </b-button>
      <b-button size="sm" variant="outline-default" @click="hideModal()">
        {{ $t("common_words.cancel") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import ProfileService from "../services/ProfileService";
import LabelInput from "../components/LabelInput";
import Csrf from "../utils/Csrf";

export default {
  props: {
    user: {
      type: Object,
      default: {
        email: null,
        org_name: null,
        timezone: null,
      },
    },
  },

  components: {},

  data() {
    return {
      success_message: "",
      error: null,
      selected_timezone: "",
      options: this.$moment.tz.names(),
    };
  },

  mounted() {
    if (this.user.timezone == null) {
      this.selected_timezone = this.$moment.tz.guess();
    } else {
      this.selected_timezone = this.user.timezone;
    }
  },

  methods: {
    showModal: function() {},

    save() {
      let _this = this;
      this.user.timezone = this.selected_timezone;
      this.$validator
        .validateAll()
        .then((result) => {
          if (!result) {
            return;
          }
          _this.change_password();
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },

    change_password() {
      let _this = this;
      ProfileService.change_password({ user: this.user })
        .then((response) => {
          _this.$eventBus.$emit("alert", {
            variant: "success",
            message: _this.$t("user.password_changed_succcess_msg"),
          });
          _this.hideModal();
        })
        .catch((resp) => {
          this.error = resp.response.data.error;
        });
    },

    hideModal() {
      this.error = null;
      this.user.current_password = "";
      this.user.password = "";
      this.user.password_confirmation = "";
      this.$bvModal.hide("change_password");
    },
  },
};
</script>
