<template>
    <div class="thumbnail-card card rounded shadow-sm m-2">
        <div class="card-header thumbnail-header-main">
            <div class="thumbnail-header-left">
                <div class="thumbnail-header-left-infobox">
                     <i
                        style="color: red"
                        class="zmdi zmdi-timer zmdi-hc-lg pr-1"
                        v-if="
                            this.content.expires_at != null &&
                            !this.content.is_expired
                        "
                    ></i>
                    <span
                        class="title-font"
                        v-b-tooltip.hover
                        :title="content.name"
                    >
                        {{ content.name }}
                    </span>
                    <br />
                    <span class="body-font-dimmed">
                        {{
                            $t("common_words.type") +
                            " " +
                            $t(this.contentTypeText)
                        }}
                        |
                        {{ $t("content.created_at") }}
                        {{ formatTime(this.content.created_at) }}
                    </span>
                </div>
            </div>

            <div class="thumbnail-header-right" v-show="show_action">
                <dropdown-links
                    :link_id="link_id"
                    :dropdownLinks="dropdownLinkOptions"
                    @click="listenAction"
                />
            </div>
        </div>

        <div class="thumbnail-preview-container" @click="showPreview">
            <content-thumbnail :content="content" />
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import DropdownLinks from "../components/DropdownLinks";
    import ContentThumbnail from "../components/ContentThumbnail";
    export default {
        props: {
            content: {
                type: Object,
            },
            show_action:{
                type: Boolean,
                default: true
            }
        },

        components: {
            DropdownLinks,
            ContentThumbnail,
        },

        computed: {
            contentTypeText: function () {
                if (this.content.type == "File") {
                    return "common_words.file";
                } else {
                    if (this.content.widget ==null){
                        return "common_words.channel";
                    }else if(this.content.widget.template) {
                        return "common_words.template";
                    } else {
                        return "common_words.app";
                    }
                }
            },
            link_id: function () {
                return `${this.content.id}`;
            },
            dropdownLinkOptions: function () {
                if (this.content.is_expired == true) {
                    return [
                        {
                            name: "Restore",
                            icon_class: "zmdi zmdi-time-restore",
                            link_class: "",
                            action: "restore_content",
                        },
                        {
                            name: "Preview",
                            icon_class: "zmdi zmdi-eye",
                            link_class: "",
                            action: "showPreviewMenuItemClick",
                        },
                        {
                            name: "Delete",
                            icon_class: "zmdi-delete zmdi danger",
                            link_class: "text-danger",
                            action: "delete_file",
                        },
                    ];
                } else {
                    return [
                        {
                            name: "Edit",
                            icon_class: "zmdi-edit zmdi",
                            link_class: "",
                            action: "edit",
                        },
                        {
                            name:
                                this.content.expires_at != null
                                    ? "Edit Expiration"
                                    : "Set expiration",
                            icon_class: "zmdi  zmdi-timer",
                            link_class: "",
                            action: "set_expiration",
                        },
                        {
                            name: "Preview",
                            icon_class: "zmdi zmdi-eye",
                            link_class: "",
                            action: "showPreviewMenuItemClick",
                        },
                        {
                            name: "View Report",
                            icon_class: "zmdi zmdi-graphic-eq",
                            link_class: "",
                            action: "view_report",
                        },
                        {
                            name: "Delete",
                            icon_class: "zmdi-delete zmdi danger",
                            link_class: "text-danger",
                            action: "delete_file",
                        },
                    ];
                }
            },
        },

        mounted() {},

        methods: {
            formatTime: function (time) {
                return moment(time).format("DD-MMM-YYYY");
            },
            listenAction: function (link) {
                let options = { content: this.content, link: link };
                this.$emit("click", options);
            },

            showPreview: function () {
                let options = {
                    content: this.content,
                    link: { action: "showPreview" },
                };
                this.$emit("click", options);
            },
        },
    };
</script>
<style lang="scss">
.thumbnail-card {
    display: flex;
    width: 290px;
    height: 200px;
}
.thumbnail-header-main {
    display: flex;
    flex: 0.1;
    padding-left: 8px;
}

.thumbnail-header-left {
    display: flex;
    flex: 0.9;
    flex-direction: row;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thumbnail-header-right {
    display: flex;
    flex: 0.1;
}

.thumbnail-header-left-timer {
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
}

.thumbnail-header-left-infobox {
    display: inline-block;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thumbnail-preview-container {
    margin: 8px;
    flex: 0.9;
}
</style>
