<template>
    <!-- The Modal -->
    <div ref="uploadSelectionModal" class="modal">
        <!-- Modal content -->
        <div class="aspect-ratio-box" :key="`${shouldReload}`">
            <div class="header-box">
                <div class="header-name-container">
                    <span class="header-title-label">
                        {{ $t("upload.upload_selection_window_title") }}
                    </span>
                </div>
                <b-button
                    class="close-button"
                    @click="hideModel"
                    variant="danger"
                >
                    <i class="zmdi zmdi-close zmdi-hc-lg"></i>
                </b-button>
            </div>
            <div class="content-box" ref="fileDragArea">
                <input
                    id="fileUpload"
                    type="file"
                    @change="handleFilesSelected($event)"
                    multiple="multiple"
                />
                <div
                    class="drop-box"
                    :class="`${isDropAreaActive ? 'active' : 'inactive'}`"
                    v-if="files.length == 0"
                >
                    <FileTypeEmpty />
                </div>
                <div class="files-selected-container" v-else>
                    <div class="files">
                        <file-item
                            :file="file"
                            :index="index"
                            v-for="(file, index) in this.files"
                            :key="`${index}${file.name}`"
                        />
                    </div>
                    <div class="bottom-tollbar">
                        <div class="toolbar-buttons">
                            <b-button
                                class="upload-more-button"
                                @click="browseFiles()"
                            >
                                {{ $t("upload.upload_more") }}
                            </b-button>
                            <b-button
                                variant="primary"
                                @click="uploadFiles()"
                                :disabled="shouldDisabledUploadButton()"
                            >
                                {{ $t("upload.upload") }}
                                <b-badge variant="light">{{
                                    this.files.length
                                }}</b-badge>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import EventBus from "../core/event-bus";
    import FileItem from "./uploadType/fileType/FileItem.vue";
    import FileTypeEmpty from "./uploadType/fileType/FileTypeEmpty.vue";
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                files: [],
                isDropAreaActive: false,
                shouldReload: false,
            };
        },

        components: {
            FileTypeEmpty,
            FileItem,
        },

        watch: {
            files: {
                // the callback will be called immediately after the start of the observation
                immediate: false,
                handler(val, oldVal) {
                    this.shouldReload = !this.shouldReload;
                },
            },
        },

        computed: {
            ...mapGetters("userStore", ["user"]),
        },

        mounted() {
            /*
          Listen to all of the drag events and bind an event listener to each
          for the fileDragArea.
        */
            [
                "drag",
                "dragstart",
                "dragend",
                "dragover",
                "dragenter",
                "dragleave",
                "drop",
            ].forEach(
                function (evt) {
                    /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
                    this.$refs.fileDragArea.addEventListener(
                        evt,
                        function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }.bind(this),
                        false
                    );
                }.bind(this)
            );

            /*
          Add an event listener for drop to the form
        */
            this.$refs.fileDragArea.addEventListener(
                "drop",
                function (e) {
                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        this.files.push(e.dataTransfer.files[i]);
                    }
                }.bind(this)
            );

            /*
          Add an event listener for dragenter to the form
        */
            this.$refs.fileDragArea.addEventListener(
                "dragenter",
                function (e) {
                    if (this.isDropAreaActive == false) {
                        this.isDropAreaActive = true;
                    }
                }.bind(this)
            );
        },
        created: function () {
            let _this = this;
            EventBus.$on("showUploadSelectionModel", function () {
                _this.showModel();
            });
            EventBus.$on("deleteFile", function (index) {
                _this.files.splice(index, 1);
            });
        },

        beforeDestroy() {
            EventBus.$off("showUploadSelectionModel");
            EventBus.$off("deleteFile");
        },

        methods: {
            showModel: function () {
                this.$refs.uploadSelectionModal.style.display = "block";
            },

            hideModel: function () {
                this.$refs.uploadSelectionModal.style.display = "none";
                this.shouldReload = !this.shouldReload;
                this.files = [];
                this.isDropAreaActive = false;
            },

            handleFilesSelected(event) {
                for (let i = 0; i < event.target.files.length; i++) {
                    this.files.push(event.target.files[i]);
                }
            },

            browseFiles: function () {
                document.getElementById("fileUpload").click();
            },

            shouldDisabledUploadButton: function () {
                for (let i = 0; i < this.files.length; i++) {
                    let file = this.files[i];
                    if (file.size > this.user.account.max_upload_limit) {
                        return true;
                    }
                }
                return false;
            },
            
            uploadFiles: function () {
                this.$refs.uploadSelectionModal.style.display = "none";
                this.isDropAreaActive = false;
                EventBus.$emit("startUploadingFiles", this.files);
                this.files = [];
            },
        },
    };
</script>
<style scoped>
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding: 0; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

.aspect-ratio-box {
    width: 90vw; /* 90% of viewport vidth */
    height: 50.625vw; /* ratio = 9/16 * 90 = 50.625 */
    max-height: 90vh;
    max-width: 160vh; /* 16/9 * 90 = 160 */
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.header-box {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 60px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #686666;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-box {
    position: absolute;
    top: 60px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #bebebe;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.drop-box {
    position: absolute;
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='black' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
}

.drop-box.active {
    background-color: rgba(7, 209, 240, 0.453);
}

.drop-box.inactive {
    background-color: rgba(7, 209, 240, 0.096);
}

.files-selected-container {
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.files {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex: 1;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
}
.bottom-tollbar {
    display: flex;
    height: 60px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    background-color: #f7f7f7;
    border-top: 1px solid #686666;
}
.toolbar-buttons {
    margin-right: 16px;
}
.upload-more-button {
    margin-right: 16px;
}
.header-name-container {
    margin-left: 16px;
}
.preview-text {
    color: black;
    font-weight: 500;
    font-size: 30px;
}
.header-title-label {
    font-size: 20px;
    font-weight: 500;
    color: rgb(19, 19, 19);
}

.close-button {
    margin-right: 16px;
}
</style>
