<template>

 <div class="search d-flex mr-3">
      <input class="form-control p-2" v-model="query"
           :placeholder="$t('common_words.search') + ' ' + media_type"
           v-debounce:300ms.lock="search"/>
  </div>

</template>
<script>

  export default {

    props:{
      media_type: {
        type: String,
        default: 'Content'
      },
      searchText: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        query: this.searchText
      }
    },

    mounted() {
    },
    watch: {

        searchText(val) {
            this.query = this.searchText
        },
    },
    methods: {
      search: function(){
        this.$emit('change', this.query);
      },

    }
  }
</script>
<style lang="scss" scoped>
.search-control{
   padding: 1px 0px;
   margin: 0px 30px;
   .search {
      padding: 4px 6px 1px 30px;
      height: 33px;
      width: 100%;
   }
   i.zmdi-pos{
     position: absolute;
     opacity: 0.3;
     font-size: 22px;
     left: 10px;
     top: 9px;
     z-index: 999;
   }
 }
</style>
