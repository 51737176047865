<template>
    <div class="new-channel-main-container">
        <div class="new-channel-header">
            <div class="new-channel-header-left-section">
                <span class="new-channel-text">New channel</span>
                <input
                    v-model="channel.name"
                    :class="{
                        'instance-invalid-name': this.isValideName == false,
                    }"
                    class="instance-name-textfield"
                    type="text"
                    tabindex="1"
                    autofocus
                    :placeholder="
                        isValideName
                            ? $t('channel.name_placeholder')
                            : $t('channel.invalid_name_placeholder')
                    "
                    @change="updateValidName"
                />
            </div>
            <div class="new-channel-header-right-section">
                <b-button
                    class="primary-action-b-button save"
                    variant="success"
                    @click="nextStep"
                >
                    {{ step == 1 ? $t("common_words.step") : $t("common_words.save") }}
                </b-button>

                <b-button
                    class="primary-action-b-button cancel"
                    @click="cancelButtonPressed"
                    variant="danger"
                >
                    {{ $t("common_words.cancel") }}
                </b-button>
            </div>
        </div>
        <div class="new-channel-content">
            <SelectZone
                :channel="channel"
                @selected="setLayout"
                v-if="step === 1"
            />
            <ChannelComposition
                :channel="channel"
                v-else-if="step === 2"
                @change="updateChannelMedia"
                @delete="deleteMedia"
            />
        </div>
    </div>
</template>
<script>
    import SelectZone from "./ScreenZone/SelectZone";
    import EventBus from "../core/event-bus";
    import ChannelComposition from "./ChannelComposition/ChannelComposition.vue";
    import ChannelService from "../services/ChannelService";
    export default {
        data() {
            return {
                step: 1,
                selected_zone: {
                    type: Object,
                    default: null,
                },
                channel: {
                    name: '',
                    media_list: []
                },
                channel_id: null,
                isValideName: true,
                deletedMedia: []
            };
        },
        created: function () {
            this.channel_id = this.$route.params.channel_id;
        },
   

        beforeDestroy() {
            EventBus.$off("zoneSelected");
        },

        mounted(){
            let _this = this
            if(this.channel_id != null){
                this.fetchChannel()
            }
             EventBus.$on("updateMedia", function (media_list) {
                _this.channel.media_list = media_list
            });
        },

        methods: {
            fetchChannel: function(){
                let _this = this;
                ChannelService.get(_this.channel_id)
                    .then((response) => {
                        _this.channel = response.data.channel;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            navigate_to_channel: function () {
                this.$router.push({ name: "channels" });
            },

            cancelButtonPressed: function () {
                this.closeThisScreen();
            },
            closeThisScreen: function () {
                this.$router.push({ name: "channels" });
            },

            nextStep: function () {
                if (this.step == 1 && this.channel.layout != null) {
                    this.step = this.step + 1;
                } else if (this.step == 2) {
                    this.updateValidName()
                    if(this.isValideName) {
                        if(this.channel.id == null){
                            this.createChannel()
                        }else{
                            this.updateChannel()
                        }
                    }
                }
            },

            createChannel: function () {
                let _this = this;
                let params = this.channel_params()
                ChannelService.create({ channel: params })
                    .then((response) => {
                        _this.navigate_to_channel();
                    })
                    .catch((error) => {
                        _this.channel.media_list = this.channel.media_list_attributes
                        _this.handleError(error);
                    });
            },

            updateChannel: function () {
                let _this = this;
                let params = this.channel_params()
                ChannelService.update(this.channel.id, { channel: params })
                    .then((response) => {
                        _this.navigate_to_channel();
                    })
                    .catch((error) => {
                        _this.channel.media_list = this.channel.media_list_attributes
                        _this.handleError(error);
                    });
            },

            channel_params: function(){
                let channel =  this.channel
                channel.layout_id = channel.layout.id
                
                channel.media_list_attributes = this.media_list_params()
                if(this.deletedMedia.length){
                    channel.media_list_attributes.push(this.deletedMedia)
                }
                delete channel.media_list
                return channel
            },

            media_list_params: function(){
                let media_list_params = this.channel.media_list
                media_list_params.forEach(media => {
                    media.schedules_attributes = media.schedules
                    delete media.schedules
                });
                return media_list_params
            },

            updateChannelMedia: function (media_list) {
                this.channel.media_list = media_list;
            },

            setLayout: function (layout) {
                this.$set(this.channel, "layout", layout);
            },

            updateValidName: function() {
                if(this.channel.name == ''){
                    this.isValideName = false
                }else {
                  this.isValideName = true
                }
            },

            deleteMedia: function(media){
                this.deletedMedia.push({id: media.id, _delete: true})
                this.channel.media_list = this.channel.media_list.filter((item) => item.position != media.position)
            }
        },
        components: { SelectZone, ChannelComposition },

    };
</script>

<style scoped>
.new-channel-main-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    background-color: rgb(210, 13, 13);
}

.new-channel-header {
    display: flex;
    flex: 0;
    background-color: #ecf0f1;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(194, 194, 194);
    align-items: center;
}

.new-channel-header-left-section {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: row;
    align-items: center;
}
.new-channel-header-right-section {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.primary-action-b-button {
    height: 100%;
    border-radius: 0px;
    width: 100px;
}

.primary-action-b-button.save {
    font-weight: 500;
    font-size: 18px;
}

.primary-action-b-button.cancel {
    font-weight: 400;
    font-size: 18px;
}

.new-channel-text {
    font-weight: 500;
    font-size: 24px;
    background-color: #283149;
    color: #dbedf3;
    padding-left: 16px;
    padding-right: 16px;
    white-space: nowrap;
    height: 100%;
    display: flex;
    flex: 0.3;
    align-items: center;
    justify-content: flex-start;
}

.new-channel-content {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    overflow: scroll;
    background-color: white;
}
</style>
