import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const GroupedScheduleService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('user/grouped_schedules.json?' +  query_string);
	},

	get(id) {
		return Http.get('user/grouped_schedules/' + id + '.json');
	},

	create(params) {
		return Http.post('user/grouped_schedules.json', params);
	},

	update(id, params) {
		return Http.put('user/grouped_schedules/' + id + '.json', params);
	},

	destroy(id) {
		return Http.delete('user/grouped_schedules/' + id + '.json');
	},
	
	search(query) {
		return Http.get('user/grouped_schedules/search.json',{ params: {query: query} });
	},
};

export default GroupedScheduleService;
