<template>
  <div class="d-flex mt-5 pt-4">
    <div class="m-auto">
      <div class="text-center">
          <div class="msg"
               v-html="this.message">
          </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  props: {
    message: ''
  },

  mounted() {
  },

  methods: {

  }
}
</script>

<style>
 
</style>
