<template>
  <div class="form-element-container">
    <label class="form-element-label">
      {{ $t(element.field_label_text) }}
    </label>
    <div class="form-element-control-container">
      <div v-if="isAuthenticated" class="form-element-required-container">
        <logged-in :calendarName="calendarName" :email="email" :provider="providerName"/>
         <i class="close-button-icon zmdi zmdi-delete zmdi-hc-lg" @click="removeCalendar()"></i>
      </div>
      <div v-else class="auth-login-buttons-container">
        <google-login :element="element" />
        <microsoft-login :element="element" />
         <span v-if="isRequiredElement" class="form-element-required-text">*</span>
      </div>
      <span v-if="isValid == false" class="text-danger">
        {{ $t(element.validation_error_message) }}
      </span>
    </div>
  </div>
</template>

<script>
import Element       from '../form_elements/helper/Element'
import EventBus from "../../../core/event-bus";
import GoogleLogin from './AuthLoginComponents/GoogleLogin.vue';
import MicrosoftLogin from './AuthLoginComponents/MicrosoftLogin.vue';
import { isElmentInputValid } from "./helper/validations";
import LoggedIn from './AuthLoginComponents/LoggedIn.vue';

export default {
  mixins: [Element],
  components: {
    GoogleLogin,
    MicrosoftLogin,
    LoggedIn,
  },
  data() {
    return {
      isValid: true,
      isAuthenticated:true,
      providerName: null,
      email: null,
      calendarName: null,
    };
  },
  watch: {
    isAuthenticated(newState, oldState) {
      this.onChange()
    }
  },
  computed: {
    isRequiredElement: function() {
      return this.element.validation === "required"
    },
  },
  created: function() {
    let _this = this;
    this.checkAuthenticated()
    EventBus.$on("validate_widget_form", function() {
      _this.checkValidation();
    });
    EventBus.$on("login_authenticated", function() {
      _this.authenticatedStateChange();
    });
  },
  beforeDestroy(){
    EventBus.$off('validate_widget_form')
    EventBus.$off('login_authenticated')
  },
  methods: {
    checkAuthenticated: function() {
      if(this.element.model != null && Object.keys(this.element.model).length > 0) {
        this.isAuthenticated = true
        this.providerName = this.element.model['provider']
        this.email = this.element.model['email']
        this.calendarName = this.element.model['calendar_name']
      }else {
        this.isAuthenticated = false
      }
    },
    authenticatedStateChange: function() {
      this.checkAuthenticated()
      this.checkValidation()
    },
    checkValidation: function() {
      this.isValid = isElmentInputValid(this.element);
    },
    removeCalendar: function() {
      this.element.model = {}
      this.isAuthenticated = false
    }
  },
};
</script>

<style>
.auth-login-buttons-container {
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.close-button-icon{
  color: rgb(199, 64, 64);
  margin-left: 4px;
}
.close-button-icon:hover {
  color: rgb(143, 8, 8);
}
</style>
