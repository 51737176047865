<template>
<b-list-group-item class="p-0">
    <b-button variant="outline" @click="drawline" :class="{ btn_selected: this.isDraw['line'] }">
        <i class="zmdi zmdi-minus"></i>
        <div>Line</div>
    </b-button>
</b-list-group-item>
</template>

<script>
import Drawing from '../Drawing.js'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} },
        selectedTool: { type: String, default: null }
    },

    data() {
        return {
            isDraw: {
                line: false,
            }
        }
    },

    mixins: [Drawing],

    methods: {
        drawline: function () {
            this.canvas.discardActiveObject();
            this.canvas.renderAll();
            this.drawObj('line');
            this.canvas.defaultCursor = 'crosshair';
        },
    }
}
</script>
