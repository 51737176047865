<template>
    <div class="col-12 p-4" v-observe-visibility="resetStrokeWidth">
        <label>Stroke Size:</label>
        <b-input v-model="stroke_width" type="number" @change="changeSize"/>
    </div>
</template>

<script>
    export default {
        props: {
            canvas: { type: Object, default: {} },
            properties: { type: Object, default: {} }
        },

        data() {
            return {
                stroke_width: this.properties.stroke_width,
            }
        },

        mounted() {
            let _this = this
            this.canvas.on('selection:updated', function () {
                _this.resetStrokeWidth()
            })
        },

        methods: {
            resetStrokeWidth: function () {
                let _this = this
                let object = this.canvas.getActiveObject()
                if (object != null) {
                    this.stroke_width = object.strokeWidth
                }
            },

            changeSize: function (width) {
                var object = this.canvas.getActiveObject()
                object.set('strokeWidth', this.stroke_width)
                this.canvas.renderAll()
                this.canvas.fire('object:statechange')
            }
        }
    }
</script>
