<template>
    <modal-dialog>
        <template #header>
            <div class="header-name-container">
                <h3 v-if="newSchedule.id != null || schedule.schedule_type != null">
                    {{ displayEditTitle() }}
                </h3>
                <h3 v-else>{{ displayNewTitle() }}</h3>
            </div>
            <div class="header-close-container">
                <button
                    class="btn btn-sm btn-danger close-button"
                    @click="cancel"
                >
                    <i class="zmdi zmdi-close"></i>
                </button>
            </div>
        </template>
        <template v-slot:body >
           <SelectSchedueType
                :schedule="newSchedule"
                @selected="setSechdeuleType"
                v-if="step === 1"
                v-show="loaded"
            />
            <ScheduleForm
                :schedule="newSchedule"
                v-else-if="step === 2"
                v-show="loaded"
                @save="save"
                ref="form"
            />
        </template>
        <template v-slot:footer>
            <div slot="modal-footer" class="">
                <b-button
                    size="sm"
                    class="float-right ml-3"
                    variant="primary"
                    @click="save"
                >
                    {{ $t("common_words.save") }}
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="$emit('close')"
                >
                    {{ $t("common_words.cancel") }}
                </b-button>
            </div>
        </template>
    </modal-dialog>
</template>
<script>
    import ModalDialog from "../../components/ModalDialog";
    import ScheduleForm from "./ScheduleForm.vue";
    import SelectSchedueType from "./SelectSchedueType"

    export default {
        props: {
            schedule: {
                type: Object,
                default: {
                    weekdays: {},
                    dates: [],
                    times: [],
                },
            },
        },
        data() {
            return {
                loaded: false,
                newSchedule: {},
                error: null,
                open: false,
                schedule_types: ["daily", "weekly", "specific_date"],
                display_schedule_types: this.$t('channel.media.schedule.schedule_types'),
                step: 0,
            };
        },

        components: {
            ModalDialog,
            ScheduleForm,
            SelectSchedueType
        },

        beforeMount: function () {
            this.newSchedule = Object.assign({}, this.schedule)
            this.newSchedule.schedule_type = this.schedule.schedule_type
            if(this.newSchedule.id != null || this.newSchedule.schedule_type != null) { 
                if(this.newSchedule.schedule_type == null) {
                    this.newSchedule.schedule_type = this.getScheduleType()
                }
                this.step = 2
            }else{
                this.step = 1
            }
            this.loaded = true
        },

        computed: {},

        methods: {
            hideModal: function () {
                this.open = false;
                this.$emit("close");
            },

            changeScheduleType: function (type) {
                this.newSchedule.schedule_type = type;
            },

            save: function () {
                let _this = this;
                 _this.error = [];
                let current_form = null;
                if (this.newSchedule.schedule_type != "daily") {
                    current_form = this.$refs['form'].get_form();
                    current_form
                        .validate()
                        .then((schedule) => {
                            _this.$emit("submit", schedule);
                            _this.hideModal();
                        })
                        .catch((error) => {
                            _this.error = Object.values(error).join(",");
                        });
                } else {
                    this.newSchedule = {
                        week_days: {},
                        date: {},
                        times: [],
                    }
                    _this.$emit("submit", this.newSchedule);
                    _this.hideModal();
                }
            },

            setSechdeuleType: function(schedule_type){
                this.newSchedule.schedule_type = schedule_type
                this.step = 2
                if(schedule_type == this.schedule_types[0]){
                    this.$emit("submit", this.newSchedule);
                    this.hideModal();
                }
            },

            getScheduleType: function () {
                if (
                    Object.keys(this.newSchedule.week_days).length == 0 &&
                    this.newSchedule.dates.length == 0 &&
                    this.newSchedule.times.length == 0
                ) {
                    return "daily";
                } else if (Object.keys(this.schedule.week_days).length > 0) {
                    return "weekly";
                } else {
                    return "specific_date";
                }
            },

            displayEditTitle: function(){
                let str = this.$t("channel.media.schedule.schedule_types." + this.newSchedule.schedule_type)
                return this.$t("channel.media.schedule.title", { schedule_type: str })
            },

            displayNewTitle: function(){
                let str = ""
                if(this.newSchedule.schedule_type != null){
                  str = this.$t("channel.media.schedule.schedule_types." + this.newSchedule.schedule_type)
                }
                return this.$t("channel.media.schedule.new_title", { schedule_type: str })
            },

            cancel: function(){
                
                this.hideModal()
            }
        },
    };
</script>

<style lang="scss" scoped>
.header-name-container {
    flex: 0.8;
    align-items: center;
    display: flex;
}
.header-close-container {
    flex: 0.2;
    display: flex;
    justify-content: flex-end;
}
</style>
