<template>
    <div
        class="wizard-header"
        id="editor-header"
        v-observe-visibility="initStates"
    >
        <div class="wizard-header-left-section">
            <span class="app-name-text" v-if="!is_admin">{{
                $t(this.widget.title)
            }}</span>
            <input
                v-model="content.name"
                :class="{ 'instance-invalid-name': this.isValideName == false }"
                class="instance-name-textfield"
                type="text"
                tabindex="1"
                autofocus
                :placeholder="
                    isValideName
                        ? $t('widget.content_name_placeholder')
                        : $t('widget.content_invalid_name_placeholder')
                "
                v-if="!is_admin"
            />

            <input
                v-model="widget.name"
                :class="{ 'instance-invalid-name': this.isValideName == false }"
                class="instance-name-textfield"
                type="text"
                tabindex="1"
                autofocus
                :placeholder="
                    isValideName
                        ? $t('widget.content_name_placeholder')
                        : $t('widget.content_invalid_name_placeholder')
                "
                v-if="is_admin"
            />
        </div>
        <div class="wizard-header-right-section">
            <button
                type="button"
                class="btn btn-outline-secondary ml-3 btn-sm"
                @click="zoomIn"
            >
                <i class="zmdi zmdi-zoom-in"></i>
            </button>
            <button
                type="button"
                class="btn btn-outline-secondary ml-2 btn-sm"
                @click="zoomOut"
            >
                <i class="zmdi zmdi-zoom-out"></i>
            </button>

            <button
                type="button"
                class="btn btn-outline-secondary ml-3 btn-sm"
                @click="redo"
                title="Redo"
                :disabled="!canRedo"
            >
                <i class="zmdi zmdi-redo"></i>
            </button>

            <button
                type="button"
                class="btn btn-outline-secondary ml-3 btn-sm"
                @click="undo"
                title="Undo"
                :disabled="!canUndo"
            >
                <i class="zmdi zmdi-undo"></i>
            </button>

            <button
                type="button"
                class="btn btn-outline-secondary ml-3 btn-sm"
                :class="{ selected: this.startDrag }"
                @click="toggleDrag"
            >
                <span class="drag"></span>
            </button>
            <load-json-data :canvas="canvas" v-if="is_admin" />
        </div>

        <save-cancel-btn
            :canvas="canvas"
            :layout="layout"
            :content="content"
            :widget="widget"
            v-if="!is_admin"
            @change="isUpdated"
        />
        <template-save-cancel-btn
            :canvas="canvas"
            :layout="layout"
            :widget="widget"
            v-else
            @change="isUpdated"
        />
    </div>
</template>

<script>
    import SaveCancelBtn from './headerButtons/SaveCancelBtn.vue'
    import LoadJsonData from './LoadJsonData.vue'
    import State from './State.js'
    import TemplateSaveCancelBtn from '../../admin/components/TemplateSaveCancelBtn.vue'
    import { mapGetters } from 'vuex'

    export default {
        props: {
            canvas: { type: Object, default: {} },
            content: { type: Object, default: {} },
            widget: { type: Object, default: {} },
            properties: { type: Object, default: {} },
            layout: { type: String, defult: null }
        },

        data() {
            return {
                startDrag: false,
                isValideName: true,
                shouldCreateNewInstance: true
            }
        },

        mixins: [State],

        components: {
            LoadJsonData,
            SaveCancelBtn,
            TemplateSaveCancelBtn
        },

        computed: {
            ...mapGetters('userStore', ['is_admin']),

            canUndo: function () {
                return this.currentState > -1
            },

            canRedo: function () {
                return this.currentState < this.states.length
            },
            window: () => window
        },

        methods: {
            initStates: function () {
                let _this = this
                this.canvas.on('object:statechange', function () {
                    _this.save_state()
                })
                this.canvas.on('object:modified', function () {
                    _this.save_state()
                })
                this.canvas.on('object:removed', function () {
                    _this.save_state()
                })
            },

            zoomIn: function () {
                this.zoomCanvas(1)
            },

            zoomOut: function () {
                this.zoomCanvas(-1)
            },

            toggleDrag: function () {
                this.startDrag = !this.startDrag
                if (this.startDrag) {
                    this.startDraging()
                } else {
                    this.stopDraging()
                }
            },

            startDraging: function () {
                this.canvas.defaultCursor = 'grabbing'
                this.canvas.discardActiveObject()
                this.canvas.renderAll()
                this.canvas.forEachObject(function (o) {
                    o.selectable = false
                    o.evented = false
                })
                this.$emit('dragStart')
            },
            stopDraging: function () {
                this.canvas.defaultCursor = 'pointer'
                this.canvas.forEachObject(function (o) {
                    o.selectable = true
                    o.evented = true
                })
                this.$emit('dragStop')
            },

            zoomCanvas: function (delta) {
                let zoom_percent = 0.2 * delta
                this.properties.scale_value =
                    this.properties.scale_value + zoom_percent
                this.canvas.upperCanvasEl.parentNode.style.transform =
                    'scale(' + this.properties.scale_value + ')'
                if (this.properties.scale_value > 1) {
                    this.canvas.upperCanvasEl.parentNode.style.margin = '0'
                } else {
                    this.canvas.upperCanvasEl.parentNode.style.margin = 'auto'
                }
                this.canvas.renderAll()
            },

            isUpdated: function (isValideName) {
                this.isValideName = isValideName
            }
        }
    }
</script>
<style lang="scss">
.wizard-header {
    .btn:disabled {
        background-color: #dfdfdf;
        i {
            color: #dfdfdf;
        }
    }
}
</style>