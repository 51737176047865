<template>
<div class="col-md-6 col-lg-4 col-xl-3 text-center p-3 template">
    <div class="card col-12 file border rounded-lg h-100">
        <div class="dz-image shadow-sm" ref="container">
            <div class="thumbnail-bg" :class="widget.layout" :style="'background-image: url(' + widget.img_url + ')'" v-if="widget.img_url != '' && widget.img_url != null">
            </div>
        </div>
        <div class="card-body d-flex flex-column">
            <h4 class="card-title">{{$t(widget.title)}}</h4>
            <p class="card-text">{{$t(widget.description)}}</p>
            <b-button class="mt-auto" @click="showWizard" variant="primary">
                {{$t('widget.template.use_template')}}
            </b-button>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: {
        widget: {
            type: Object,
            default: {
                id: '',
                title: '',
                description: '',
                page_route: '',
                component: ''
            }
        }
    },

    data() {
        return {
            loaded: false,
        }
    },

    methods: {
        showWizard: function () {
            this.$router.push({
                name: 'addtemplate',
                params: {
                    widget_id: this.widget.id
                }
            })
        },
    }
}
</script>

<style lang="scss">
.template {
    .dz-image {
        width: 90%;
        max-height: 112px;
        margin: 10px;
        height: 112px;

        div.thumbnail-bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: flex;

            i.zmdi-eye {
                margin: auto;
                font-size: 32px;
                color: #FFFFFF;
                opacity: 0;
                margin: auto;
            }
        }
        .landscape{
          background-size: cover !important;
        }
        .portrait{
          background-size: contain !important;
        }
    }

    .blank-temp {
        width: 90%;
        max-height: 112px;
        height: 112px;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
    }

    .show_dialog {
        display: block;
        opacity: 1 !important;
    }

    .card-text {
        font-size: 16px;
    }
}
</style>
