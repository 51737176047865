<template>
    <div class="wizard-header-right-section">
        <b-button
            class="primary-action-button save"
            variant="success"
            @click="save"
            :tabindex="1"
        >
            {{
                this.widget.id == null
                    ? $t('common_words.save')
                    : $t('common_words.update')
            }}
        </b-button>

        <b-button
            :tabindex="2"
            class="primary-action-button cancel"
            @click="cancelButtonPressed"
            variant="danger"
        >
            {{ $t('common_words.cancel') }}
        </b-button>
    </div>
</template>

<script>
    import WidgetService from '../services/WidgetService'
    export default {
        props: {
            canvas: { type: Object, default: {} },
            widget: { type: Object, default: {} },
            layout: { type: String, defult: null }
        },

        data() {
            return {
                widget_data: {}
            }
        },

        computed: {
            btn_title: function () {
                return 'Save'
            }
        },

        methods: {
            save: function () {
                let _this = this
                this.widget_data.id = this.widget.id
                this.widget_data.name = this.widget.name
                this.widget_data.content_type = 'image/svg+xml'
                this.widget_data.metadata = this.getMetadata()
                this.widget_data.file = this.buildFile()
                this.show_loader = true
                 if(this.widget_data.name == null || this.widget_data.name == '') {
                    this.$emit("change", false)
                    return false
                }
                if (this.widget_data.id != null) {
                    this.update()
                } else {
                    this.create()
                }
            },

            create: function () {
                let _this = this
                WidgetService.create(this.widget_data)
                    .then((response) => {
                        this.$eventBus.$emit('alert', {
                            variant: 'success',
                            message: _this.$t('common_words.success_msg')
                        })
                        _this.show_loader = true
                        _this.cancelButtonPressed()
                    })
                    .catch((error) => {
                        _this.handleError(error)
                    })
            },

            update: function () {
                let _this = this
                WidgetService.update(this.widget_data.id, this.widget_data)
                    .then((response) => {
                        this.$eventBus.$emit('alert', {
                            variant: 'success',
                            message: _this.$t('common_words.success_msg')
                        })
                        _this.widget = response.data
                        _this.show_loader = true
                        _this.cancelButtonPressed   ()
                    })
                    .catch((error) => {
                        _this.handleError(error)
                    })
            },
            buildFile: function () {
                let blob = this.exportFile()
                blob.name = 'template.svg'
                return new File([blob], blob.name)
            },

            getMetadata: function () {
                let metadata = {}
                metadata['json'] = this.canvas.toJSON()
                metadata['layout'] = this.layout
                return metadata
            },

            exportFile: function () {
                var svg = this.canvas.toSVG()
                svg = svg.replace(
                    'xml:space="preserve"',
                    'preserveAspectRatio="none"'
                )
                return new Blob([svg], { type: 'image/svg+xml' })
            },

            cancelButtonPressed: function () {
                this.$router.push({ name: 'widgets' })
            },

            closeThisScreen: function () {
                this.cancelButtonPressed()
            }
        },

        watch: {
            contents: {
                handler: function (new_content, old_content) {
                    if (new_content.metadata['layout'] != null) {
                        this.layout = this.new_content.metadata['layout']
                    }
                },
                deep: true
            }
        }
    }
</script>
