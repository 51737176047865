<template>
    <div class="content-thumbnail-container">
        <div
            v-if="status == null || status === 'completed'"
            class="thumbnail success"
            :style="
                'background-image: url(' + this.get_img(this.content) + ');'
            "
        ></div>
        <div v-else-if="status === 'failed'" class="thumbnail failed">
            <span>Failed!!!</span>
        </div>
        <div v-else class="thumbnail processing">
            <span>Processing...</span>
        </div>
    </div>
</template>

<script>
    //[pending in_progress completed failed]
    import ContentThumbnailImg from "../mixins/content_thumbnail_img";

    export default {
        mixins: [ContentThumbnailImg],
        props: {
            content: {
                type: Object,
            },
        },
        watch: {
            content: function (newVal, oldVal) {
                // watch it
                this.status = newVal.status;
            },
        },

        mounted() {
            this.status = this.content.status;
        },

        data() {
            return {
                status: "completed",
            };
        },
    };
</script>
<style lang="scss" scoped>
  .content-thumbnail-container {
      display: flex;
      flex: 1;
      cursor: pointer;
      height: 100%;
  }
  .thumbnail {
      flex: 1;
  }
  .thumbnail.success {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
  }
  .thumbnail.processing {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
  }

  .thumbnail.failed {
      color: red;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
  }
</style>
