<template>
    <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
        <b-tabs
            card
            v-model="tabIndex"
            active-nav-item-class="font-weight-bold text-uppercase h-100"
            nav-class="col-lg-10"
            content-class="col-lg-12 p-0"
            @input="reset_filters()"
        >
            <b-tab class="p-0 h-100" active ref="screen-tab">
                <template #title>
                    {{ $t("screens.screen_group_tab_title") }}
                    ({{ group_count || "" }})
                </template>
                <div class="h-100 overflow-hidden">
                    <screen-group
                        :device_count="device_count"
                        :search_text="searchText"
                        :sort_order="sort_order"
                        @updated="updateScreen"
                        @added="get_devices"
                        @update="update_group_count"
                        ref="screen_group"
                    />
                </div>
            </b-tab>

            <b-tab class="p-0 h-100">
                <template #title>
                    {{ $t("side_bar_tabs.screens") }}
                    ({{ device_count }})
                </template>
                <no-screen
                    v-if="!loading && !devices.length && searchText == ''"
                    :message="$t('screens.no_data')"
                />
                <scroll-pagination
                    :scrollFunction="handleScroll"
                    :loading="loading"
                    :page="page"
                    :total_pages="device_total_pages"
                    v-if="tabIndex == 1 && devices.length"
                >
                    <no-search-result
                        :searchText="searchText"
                        v-if="
                            !loading && devices.length < 1 && searchText != ''
                        "
                    />
                    <device
                        :device="device"
                        v-for="device in devices"
                        :key="'device' + device.id + device.device_name"
                        @deleted="updateScreen"
                        @rename-device="rename_device"
                    />
                </scroll-pagination>
            </b-tab>
        </b-tabs>
        <b-button
            variant="primary"
            v-b-modal.create_group
            v-if="tabIndex == 0"
            class="tab-btn position-absolute right-10"
        >
            {{ $t("screen_group.new_group") }}
        </b-button>

        <div class="search_sort_cont align-items-center justify-content-end">
            <search
                :media_type="media_type"
                @change="getSearch"
                :searchText="searchText"
            />

            <sorting
                class="ml-2"
                @change="getSortedList"
                :sort_list="sort_options"
                :sort_order="sort_order"
            />
        </div>

        <rename-device
            :device="selected_device"
            @updated="updateScreen"
            v-if="selected_device != null"
        />
    </div>
</template>

<script>
    import DeviceService from "../services/DeviceService";
    import Screen from "./Screen";
    import Device from "./Device";
    import NoScreen from "./NoScreen";
    import ScreenGroup from "./ScreenGroup";
    import RenameDevice from "./RenameDevice";

    import Search from "../components/Search";
    import Sorting from "../components/Sorting";
    import NoSearchResult from "../components/NoSearchResult";
    import ScrollPagination from "../components/ScrollPagination.vue";
    import {
        getScreenGroupSortingField,
        setScreenGroupSortingField,
        getScreenGroupSortingOrderBy,
        setScreenGroupSortingOrderBy,
        getScreenSortingField,
        setScreenSortingField,
        getScreenSortingOrderBy,
        setScreenSortingOrderBy,
    } from "../core/UserPreferences";
    import { getSortingLabel } from "../core/HelperMethods";
    import ActionCableListener from "../mixins/ActionCableListener";

    export default {
        name: "Login",
        mixins: [ActionCableListener],

        data() {
            return {
                tabIndex: 0,
                groups: [],
                devices: [],
                loading: false,
                device_total_pages: 1,
                device_count: null,
                page: 1,
                selected_device: null,
                media_type: "Group",
                sort_options: [],
                sort_order: {
                    order: {
                        value: getScreenGroupSortingField(),
                        label: getSortingLabel(getScreenGroupSortingField()),
                    },
                    order_by: getScreenGroupSortingOrderBy(),
                },
                searchText: "",
                group_count: null,
                show_filter: true,
            };
        },

        components: {
            Screen,
            Device,
            ScreenGroup,
            NoScreen,
            RenameDevice,
            Search,
            Sorting,
            NoSearchResult,
            ScrollPagination,
        },

        mounted() {
            let _this = this;
            _this.reset_filters();
            this.startActionCableListener(function (data) {
                _this.get_devices();
            });
        },

        beforeDestroy() {
            this.stopActionCableListener();
        },

        methods: {
            get_devices: function () {
                let _this = this;
                this.loading = true;
                let query_params = { page: this.page, query: this.searchText };
                var order = {
                    order: getScreenSortingField(),
                    order_by: getScreenSortingOrderBy(),
                };
                DeviceService.list(Object.assign(query_params, order))
                    .then((response) => {
                        if (_this.page == 1) {
                            _this.devices = [];
                        }
                        _this.device_total_pages = response.data.total_pages;
                        _this.devices = _this.devices.concat(response.data.devices);
                        _this.device_count = response.data.total_count;
                        _this.loading = false;
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            },

            updateScreen: function () {
                this.page = 1;
                this.reload_data();
            },

            async rename_device(device) {
                await this.set_selected_device(device);
                this.$bvModal.show("rename_device");
            },

            set_selected_device: function (device) {
                this.selected_device = Object.assign({}, device);
            },

            getSearch(searchText) {
                this.searchText = searchText;
                this.$nextTick(() => {
                    this.reload_data();
                });
            },

            getSortedList(sort_order) {
                this.sort_order = sort_order;
                if (this.tabIndex == 0) {
                    setScreenGroupSortingField(sort_order.order.value);
                    setScreenGroupSortingOrderBy(sort_order.order_by);
                } else {
                    setScreenSortingField(sort_order.order.value);
                    setScreenSortingOrderBy(sort_order.order_by);
                }
                this.reload_data();
            },

            reset_filters: function () {
                this.searchText = "";
                if (this.tabIndex == 0) {
                    this.sort_order = {
                        order: {
                            value: getScreenGroupSortingField(),
                            label: getSortingLabel(getScreenGroupSortingField()),
                        },
                        order_by: getScreenGroupSortingOrderBy(),
                    };
                    this.sort_options = [
                        { value: "name", label: "Name" },
                        { value: "updated_at", label: "Last updated" },
                    ];
                    this.media_type = "Group";
                } else {
                    this.sort_order = {
                        order: {
                            value: getScreenSortingField(),
                            label: getSortingLabel(getScreenSortingField()),
                        },
                        order_by: getScreenSortingOrderBy(),
                    };
                    this.sort_options = [
                        { value: "device_name", label: "Name" },
                        { value: "last_seen", label: "Last seen" },
                    ];
                    this.media_type = "Screen";
                }
                this.$nextTick(() => {
                    this.reload_data();
                });
            },

            reload_data: function () {
                if (this.tabIndex == 0) {
                    this.$refs.screen_group.page = 1;
                    this.$refs.screen_group.get_groups();
                } else {
                    this.page = 1;
                    this.get_devices();
                }
            },

            update_group_count: function (group_count) {
                this.group_count = group_count;
                if (this.device_count == null) {
                    this.get_devices();
                }
            },

            handleScroll: function () {
                this.page++;
                this.get_devices();
            },

            show_hide_filters: function () {
                this.show_filter = !this.show_filter;
            },
        },
    };
</script>

<style lang="scss">
.right-10 {
    top: 10px;
    right: 10px;
}

.col-container {
    display: flex;
    width: 100%;
}

.smily {
    content: "";
}

.tab-btn {
    top: 6px;
}

#screens,
#screen_groups {
    overflow: auto;
    height: 99%;
}

.search_sort_cont {
    position: absolute;
    top: 7px;
    right: 160px;
    width: 400px;

    div.search {
        width: 55%;
        float: left;
    }

    div.sort {
        width: 35%;
        float: left;
    }
}
</style>
