export default {
  data() {
    return {
      states: [],
      restoring: false,
      currentState: -1
    }
  },

  methods: {
    save_state: function () {
      if (this.restoring) {
        return
      }
      let state = this.getState()
      if (state === this.states[this.currentState]) {
        // nothing has changed
        return
      }

      // Cap the number of states
      if (this.currentState > 10) {
        this.currentState--
        this.states.shift()
      }

      this.currentState++
      this.states.push(state)   
      // this.window.console.error(this.states[this.states.length - 1])
    },

    restore: function (state) {
      this.restoring = true
      this.setState()
      this.restoring = false
    },

    undo: function () {
      if (this.currentState > 0) {
        this.currentState--
        this.restore()
      }else{
        this.restoring = true
        this.canvas.clear()
        this.currentState = -1
        this.restoring = false
      }
    },

    redo: function () {
      if (this.currentState < (this.states.length - 1)) {
        this.currentState++
        this.restore()
      }
    },

    setState: function () {
      // set state
      this.canvas.clear()
      this.canvas.loadFromJSON(this.states[this.currentState])
      this.canvas.renderAll()
    },

    getState: function () {
      return JSON.stringify(this.canvas)
    }
  }
}
