<template>
  <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <div class="header shadow-sm col-lg-12 p-0 text-right p-2 border-bottom">
      <h4 class="float-left m-2 mb-0">{{ $t("faqs.title") }}</h4>
    </div>
    <div class="m-4 p-3">
      <b-link v-b-toggle.collapse-1 variant="primary">
        {{ $t("faqs.que1") }}
      </b-link>
      <b-collapse id="collapse-1" class="mt-2 mb-2">
        <b-card>
          <p class="card-text"><span v-html="$t('faqs.ans1')"></span></p>
        </b-card>
      </b-collapse>

      <div class="clearfix" />

      <b-link v-b-toggle.collapse-3 variant="primary">
        {{ $t("faqs.que3") }}
      </b-link>
      <b-collapse id="collapse-3" class="mt-2 mb-2">
        <b-card>
          <p class="card-text">{{ $t("faqs.ans3") }}</p>
        </b-card>
      </b-collapse>

      <div class="clearfix" />

      <b-link v-b-toggle.collapse-4 variant="primary">
        {{ $t("faqs.que4") }}
      </b-link>
      <b-collapse id="collapse-4" class="mt-2 mb-2">
        <b-card>
          <p class="card-text">{{ $t("faqs.ans4") }}</p>
        </b-card>
      </b-collapse>

      <div class="clearfix" />

      <b-link v-b-toggle.collapse-5 variant="primary">
        {{ $t("faqs.que5") }}
      </b-link>
      <b-collapse id="collapse-5" class="mt-2 mb-2">
        <b-card>
          <p class="card-text"><span v-html="$t('faqs.ans5')"></span></p>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    let help = document.getElementById("zsiq_float");
    if (help != null) {
      help.classList.remove("d-none");
    } else {
      let help = document.createElement("script");
      help.text =
        "var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq ||" +
        '{widgetcode:"45de7cf13427c470a79489e626719cb9748b81a9ecaf0213244ff3c1a8e3b6e00687c2a9a0e1dc46bcf0de046e88ccde",' +
        "values:{},ready:function(){}};" +
        'var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;' +
        's.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];' +
        "t.parentNode.insertBefore(s,t);";
      document.head.appendChild(help);
    }
  },

  destroyed() {
    let help = document.getElementById("zsiq_float");
    help.classList.add("d-none");
  },
};
</script>
