<template>
<div class="col-12 p-4" >
    <label>Corner Radius:</label>
    <b-input v-model="corner_radius" type="number" @change="changeSize"/>
</div>
</template>

<script>
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} }
    },

    data() {
        return {
            corner_radius: this.properties.corner_radius
        }
    },

    methods: {

        changeSize: function () {
            var object = this.canvas.getActiveObject();
            object.set({'rx': this.corner_radius, 'ry': this.corner_radius});
            this.canvas.renderAll()
            this.canvas.fire("object:statechange");
        },
    }
}
</script>
