<template>
<div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <div class="header shadow-sm col-lg-12  text-right border-bottom">
        <div class="row p-1">
            <div class="col-4 d-flex align-items-center">
                <h3 class="float-left m-0">{{ $t("schedules.title") }} ({{total_count}})</h3>
            </div>
            <div class="col-8 d-flex align-items-center justify-content-end">
                <search media_type="Schedules" @content_updated="update_content" @change="getSearch" />
                <sorting class="ml-2" @change="getSortedContents" :sort_list="this.sort_options" :sort_order="this.sort_order" />

                <b-button variant="primary" class="ml-2" @click="create_schedule">
                    {{$t('schedules.new_schedule')}}
                </b-button>
            </div>
        </div>
    </div>
    <scroll-pagination :scrollFunction="handleScroll" :loading="loading" :page="page" :total_pages="total_pages">

        <Schedule v-for="schedule in this.schedules" :schedule="schedule" :key="schedule.id" @updated="getSchedules()" @deleted="getSchedules()" />

    </scroll-pagination>

    <no-content :title="$t('schedules.no_data.no_content_title')" :imageName="'no-schedules.png'" :actionText="$t('schedules.no_data.action_title')" :nextSteptText="$t('schedules.no_data.no_schedule_next_step')" v-if="!loading && schedules.length == 0 && !this.isSearchActive" @clicked="create_schedule" />

    <no-search-result :searchText="this.searchText" v-if="!loading && !this.schedules.length && this.isSearchActive" />

    <create-schedule modal_id="create_schedule" @created="getSchedules" @updated="getSchedules" />
</div>
</template>

<script>
import Schedule from "./Schedule"
import GroupedScheduleService from "../services/GroupedScheduleService"
import CreateSchedule from "./CreateEditSchedule"
import NoContent from "../components/NoContent"
import NoSearchResult from "../components/NoSearchResult"
import Search from "../components/Search"
import Sorting from "../components/Sorting"
import {
  getSchedulesSortingField,
  setSchedulesSortingField,
  getSchedulesSortingOrderBy,
  setSchedulesSortingOrderBy,
} from "../core/UserPreferences";

import { getSortingLabel } from "../core/HelperMethods";
export default {
    data() {
        return {
            total_pages: 0,
            total_count: null,
            schedules: [],
            loading: false,
            isSearchActive: false,
            page: 1,
            searchText: "",
            sort_options: [{ 'value': 'name', 'label': 'Name' }, { 'value': 'updated_at', 'label': 'Last updated' }],
            sort_order: { 'order': { 'value': getSchedulesSortingField(), 'label': getSortingLabel(getSchedulesSortingField()) }, 'order_by': getSchedulesSortingOrderBy() }
        }
    },
    components: {
        Schedule,
        CreateSchedule,
        NoContent,
        Search,
        Sorting,
        NoSearchResult,
    },

    mounted() {
        this.getSchedules(null)
    },

    methods: {
        show_modal(modal_name) {
            this.$bvModal.show(modal_name)
        },

        getSchedules() {
            let _this = this
            _this.loading = true
            let query_params = { page: this.page, query: this.searchText }
            let order = { order: this.sort_order['order']['value'], order_by: this.sort_order['order_by'] }
            GroupedScheduleService.list(Object.assign(query_params, order))
                .then((response) => {
                    if (_this.page == 1) {
                        _this.schedules = []
                    }
                    _this.total_pages = response.data.total_pages
                    _this.total_count = response.data.total_count
                    _this.schedules = _this.schedules.concat(response.data.grouped_schedules)
                    _this.loading = false
                })
                .catch((error) => {
                    _this.handleError(error)
                })
        },

        didEnter(searchText) {
            this.searchText = searchText
            if (searchText.length >= 1) {
                this.isSearchActive = true
            } else {
                this.isSearchActive = false
            }
        },

        get_playlist_name(playlist) {
            return playlist.name
        },

        create_schedule() {
            let _this = this
            GroupedScheduleService.create({ schedule: { name: "New Schedule" } })
                .then((response) => {
                    this.$router.push({
                        name: "add_content",
                        params: { id: response.data.id },
                    })
                })
                .catch((error) => {
                    _this.handleError(error)
                })
        },

        update_content: function (contents) {
            // this.schedules = contents
            this.$refs.contents.show_serarchResult(contents)
        },
        getSearch(searchText) {
            this.searchText = searchText
            if (this.searchText.length >= 1) {
                this.isSearchActive = true
            } else {
                this.isSearchActive = false
            }
            this.page = 1
            this.getSchedules()
        },

        getSortedContents(sort_option) {
            this.sort_option = sort_option
            setSchedulesSortingField(sort_option.order.value);
            setSchedulesSortingOrderBy(sort_option.order_by);
            this.page = 1
            this.getSchedules()
        },

        handleScroll: function () {
            this.page++
            this.getSchedules()
        }
    },
}
</script>

<style lang="scss" scoped>
.schedule-name {
    width: 40%;
}

.actions {
    width: 10%;
}

.playlist-w {
    width: 200px;
}

div.playlist-w {
    width: 160px;
}

.schedule-cards-container {
    max-height: 87vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
