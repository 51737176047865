<template>
    <div class="col-12 p-4">
      <label class="float-left mr-2 mt-2"> Arrange: </label>
      <b-button variant="none" class="btn-link btn-sm float-left p-0"
              @click="bringForward"  tooltip="Bring Forward">
          <span class="send_back"/>
      </b-button>

      <b-button variant="none" class="btn-link btn-sm float-left p-0 m-0"
              @click="sendBackward" tooltip="Send Backward">
          <span class="send_front"/>
      </b-button>
    </div>
</template>
<script>

export default {
    props: {
      canvas: {type: Object, default: {}},
    },

    methods: {
      bringForward: function() {
        var activeObject = this.canvas.getActiveObject();
        if (activeObject) {
          this.canvas.bringForward(activeObject);
          // Push the this.canvas state to history
          this.canvas.fire("object:statechange");
        }
      },

      sendBackward: function() {
        var activeObject = this.canvas.getActiveObject();
        if (activeObject) {
          this.canvas.sendBackwards(activeObject);
          // Push the this.canvas state to history
          this.canvas.fire("object:statechange");
        }
      },
    }
}
</script>
