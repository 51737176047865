import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const DeviceService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('user/devices.json?' +  query_string);
	},

	update(id, params){
		return Http.put('user/devices/' + id + '.json', params);
	},

	get(id){
		return Http.get('user/devices/' + id + '.json');
	},

	sendNotification(id){
		return Http.get('user/devices/' + id + '/send_notification.json');
	},

	destroy(id) {
		return Http.delete('user/devices/' + id + '.json');
	},

	change_to_live_mode(id){
		return Http.put('user/devices/' + id + '/change_to_live_mode.json');
	},

};

export default DeviceService;
