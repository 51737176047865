<template>
  <b-modal :id="modal_id"
           :title="$t('playlist.create_playlist')"
           modal-class="modal-container"
           @shown="showModal()">
      <form ref="form"  method="post" @submit.prevent="save()">
          <div class="form-group mb-0" v-if="error">
              <p class="text-danger text-center">{{error}}</p>
          </div>
          <div class="form-group">

              <label>
                {{$t('playlist.playlist_name')}}
              </label>
              <div class="col-lg-13">
                <input type="name"
                       class="form-control"
                       :class="{'is-invalid': errors.has('name') }"
                       placeholder="name"
                       v-model="playlist.name"
                       v-validate="'required'"
                       data-vv-name="name"
                       data-vv-as="name">
              </div>
              <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
          </div>
      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" class="float-right ml-3" variant="primary" @click="save()">
          {{$t('common_words.save')}}
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import PlaylistService    from '../services/PlaylistService';
import GroupService       from '../services/GroupService';
import Multiselect        from 'vue-multiselect';
export default {

  props: {
    playlist_id:{
      type: Number,
      default: null
    },

    modal_id:{
      type: String,
      default: "create_playlist"
    }
  },

  data() {
    return {
      playlist: {
        id: null,
        name: ''
      },
      success_message: "",
      error: null
    }
  },

  components:{
    Multiselect
  },

  methods: {
    hideModal() {
      this.$refs.form.reset()
      this.$bvModal.hide(this.modal_id)
    },

    showModal(){
      this.resetForm();
      if(this.playlist_id != null){
        this.find()
      }
    },

    find(){
      let _this = this
      PlaylistService.get(this.playlist_id)
                     .then(response => {
                        _this.playlist = response.data
                     })
                     .catch(error => {
                       _this.handleError(error)
                     })
    },

    save(){
      let _this = this
      this.$validator
          .validateAll()
          .then((result) => {
              if(_this.playlist.id == null){
                _this.create_playlist()
              }else{
                _this.edit_playlist()
              }
          }).catch((error) => {
            _this.handleError(error)
          });

    },

    create_playlist(){
      let _this = this
      PlaylistService.create(this.playlist)
                     .then(response => {
                       _this.$emit("created")
                     })
                     .catch(error => {
                       _this.handleError(error)
                     })
    },

    edit_playlist(){
      let _this = this
      PlaylistService.update(this.playlist.id, this.playlist)
                     .then(response => {
                       _this.$emit("updated")
                     })
                     .catch(error => {
                       _this.handleError(error)
                     })
    },

    resetForm() {
      Object.keys(this.playlist).forEach(key => {
        this.playlist[key] = null
      })
      this.$validator.reset()
    }

  },
}
</script>
