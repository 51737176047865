import Vue from 'vue';

var UNITS = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
var STEP = 1024;

Vue.filter('formatFileSize', function(value) {
	value = parseFloat(value, 10);
	for (var i = 0; i < UNITS.length; i++) {
		if (value < Math.pow(STEP, i)) {
			if (UNITS[i - 1]) {
				return (value / Math.pow(STEP, i - 1)).toFixed(2) + UNITS[i - 1];;
			}
			return value + UNITS[i];
		}
	}
	return (value / Math.pow(STEP, i - 1)).toFixed(2) + UNITS[i - 1];
})
