<template>
  <div class="form-elements-container">
    <div
      v-for="(element, index) in form_elements"
      :key="index"
      :element="element"
      :index="index + 1"
      :is="element.element_type"
    ></div>
    <branding-selection
      :element="brandingModel"
      :index="form_elements.length + 1"
    />
  </div>
</template>

<script>
import EventBus from "../../core/event-bus";
import AuthLoginInput from "./form_elements/AuthLoginInput.vue";
import BrandingSelection from "./form_elements/BrandingSelection.vue";
import NumberInput from "./form_elements/NumberInput.vue";
import TextInput from "./form_elements/TextInput.vue";
import CheckboxInput from "./form_elements/CheckboxInput.vue";
import DateInput from "./form_elements/DateInput.vue";
import { isElmentInputValid } from "./form_elements/helper/validations";
export default {
  components: {
    AuthLoginInput,
    NumberInput,
    TextInput,
    DateInput,
    BrandingSelection,
    CheckboxInput
  },
  props: {
    form_elements: {
      type: Array,
    },
    brandingModel: {
      type: Object,
    },
    widgetName: {
      type: String,
    },
  },
  methods: {
    isValidFormInputs: function() {
      if (this.widgetName == "follow_us") {
        for (var i = 0, len = this.form_elements.length; i < len; i++) {
          const elementWithModel = this.form_elements[i];
          if(elementWithModel.field_name == "fb_url" && elementWithModel.model != null && elementWithModel.model.length > 0) {
            return true
          }
          if(elementWithModel.field_name == "instagram_url" && elementWithModel.model != null && elementWithModel.model.length > 0) {
            return true
          }
          if(elementWithModel.field_name == "twitter_url" && elementWithModel.model != null && elementWithModel.model.length > 0) {
            return true
          }
          if(elementWithModel.field_name == "youtube_url" && elementWithModel.model != null && elementWithModel.model.length > 0) {
            return true
          }
        }
        let _this = this
        this.$eventBus.$emit("alert", {
            variant: "success",
            message: this.$t("At least one social meida handle required"),
        });
        return false
      } else {
        for (var i = 0, len = this.form_elements.length; i < len; i++) {
          const elementWithModel = this.form_elements[i];
          if (isElmentInputValid(elementWithModel) == false) {
            return false;
          }
        }
        return true;
      }
    },
  },
};
</script>

<style>
.form-elements-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 32px 16px 32px 16px;
}

.form-element-container {
  display: flex;
  flex: 0;
  align-items: center;
}

.form-element-control-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: 6px 0px 6px 0px;
}

.form-element-required-container {
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-element-required-text {
  flex: 0.05;
  color: red;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.form-element-field {
  flex: 0.95;
  font-size: 18px;
  color: black;
}

.form-element-field-dropdown {
  flex: 0.95;
  font-size: 18px;
  color: black;
}

.form-element-label {
  display: flex;
  flex: 0.5;
  font-size: 20px;
  flex-direction: column;
  justify-items: center;
}
.form-element-sublable-hint-text {
  color: grey;
  font-size: 16px;
}
</style>
