<template>
    <div
        class="file-item-container"
        :class="`${
            isUploadLimitExceeded ? 'size-limit-error' : 'size-limit-success'
        }`"
    >
        <div class="left-section">
            <span class="file-name">{{ file.name }}</span>
            <span class="file-size">{{
                $t("upload.upload_size", { size: fileSize })
            }}</span>
            <span
                v-show="isUploadLimitExceeded"
                class="file-size-error-label"
                >{{ $t("upload.size_limit_exceed", { size: user.account.max_upload_limit /(1000 * 1000) }) }}</span
            >
        </div>
        <div class="right-section">
            <b-button @click="deleteFile()">
                <i class="zmdi zmdi-close-circle zmdi-hc-lg close-icon"></i>
            </b-button>
        </div>
    </div>
</template>
<script>
    import EventBus from "../../../core/event-bus";
    import { mapGetters } from "vuex";
    export default {
        props: {
            file: {
                type: File,
                default: null,
            },
            index: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                isUploadLimitExceeded: false,
            };
        },
        
        computed: {
           ...mapGetters("userStore", ["user"]),

            fileSize: function () {
                if (this.file.size == 0) {
                    return "0.00 B";
                }
                var e = Math.floor(Math.log(this.file.size) / Math.log(1024));
                return (
                    (this.file.size / Math.pow(1024, e)).toFixed(2) +
                    " " +
                    " KMGTP".charAt(e) +
                    "B"
                );
            },
        },
        mounted() {
            if (this.file.size > this.user.account.max_upload_limit) {
                this.isUploadLimitExceeded = true;
            } else {
                this.isUploadLimitExceeded = false;
            }
        },
        methods: {
            deleteFile: function () {
                EventBus.$emit("deleteFile", this.index);
            },
        },
    };
</script>
<style scoped>
.file-item-container {
    display: flex;
    flex: 0;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 4px;
    margin-bottom: 4px;

    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
}
.file-item-container.size-limit-error {
    background-color: rgba(192, 50, 3, 0.037);
    border: 1px solid rgba(247, 64, 3, 0.905);
}

.file-item-container.size-limit-success {
    background-color: rgba(7, 209, 240, 0.037);
    border: 1px solid rgba(7, 209, 240, 0.544);
}

.left-section {
    display: flex;
    flex: 0.95;
    flex-direction: column;
}

.right-section {
    display: flex;
    flex: 0.05;

    justify-content: space-around;
    align-items: center;
}

.file-name {
    font-size: 18px;
    font-weight: 500;
}

.file-size {
    font-size: 16px;
    color: #918e8e;
}
.file-size-error-label {
    font-size: 16px;
    color: red;
}

.close-icon {
    color: #4a5cb0;
}
</style>
