<template>
  <div>
    <div class="dz-image rounded-circle" @click="$emit('click')">
      <div data-dz-thumbnail-bg=""
           class="thumbnail-bg bg-contain"
           :style="'background-image: url(' + get_img() + ')'"
          >
      </div>
    </div>
    <div class="dz-details m-1 pt-1">
      <div class="dz-filename">
        <div v-b-tooltip.hover :title="content.name" class="col-10 text-truncate p-0">
          {{content.name}}
        </div>
      </div>
      <div class="dz-size">
        <span v-if="!content.widget.template">
          {{$t('content.widget')}}
        </span>
        <span v-if="content.widget.template">
          {{$t('content.widget_template')}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  props:{
    content: { }
  },

  data() {
    return {

    }
  },

  mounted() {
  },

  methods: {
    get_img: function(){
      let widget_img = ''
      if(this.content.widget.template){
        widget_img = this.content.img_url
      }else{
        widget_img = require('../images/widget_icons/' + this.content.widget.image +'.jpg')
      }
      return widget_img
    },
  }
}
</script>
<style lang="scss">
  .svg-thumbnail-container {
      width: auto !important;
      margin: -6px;
      .svg-container{
          position: relative;
          top: 4px;
          left: 1px;
      }
  }
</style>