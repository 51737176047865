<template>
  <div class="video-previewer">
    <video
      width="100%"
      height="100%"
      controls
      autoplay
      :key="videoUrl"
      ref="videoElement"
      v-if="content.content_type == 'video/mp4'"
    >
      <source :src="videoUrl" type="video/mp4" />
    </video>
    <div v-else>
      <h2>{{ $t("file_conversion.processing_video") }}</h2>
      <h3>{{ $t("file_conversion.processing_video_message") }}</h3>
    </div>
  </div>
</template>
<script>
import EventBus from "../../core/event-bus";
import ActionCableListener from "../../mixins/ActionCableListener";
export default {
  mixins: [ActionCableListener],

  props: {
    content: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      videoUrl: {
        type: String,
        default: null,
      },
    };
  },

  watch: {
    content: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        this.videoUrl = this.content.file_url;
      },
    },
  },

  mounted: function () {
    let _this = this;
    this.startActionCableListener(function (data) {
      let content = data["content"];
      if (content.id == _this.content.id) {
        _this.videoUrl = content.file_url;
      }
    });
  },

  created: function () {
    let _this = this;
    EventBus.$on("videoModelClosed", function () {
      _this.$refs.videoElement.pause();
    });
  },

  beforeDestroy() {
    EventBus.$off("videoModelClosed");
    this.stopActionCableListener();
  },
};
</script>
<style scoped>
.video-previewer {
  position: relative;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
