<template>
  <div class="col-lg-12">


    <form ref="form" @submit.prevent="submit">
        <h4 class="form-signin-heading">{{$t('login.sign_up.sign_up_title')}}</h4>
        <br/>
        <div class="form-inputs">
            <div class="form-group email required user_email">
                <input type="email"
                       class="form-control"
                       :placeholder="$t('login.sign_in.email')"
                       name="user[email]"
                       v-validate="'required'"
                       data-vv-name="email"
                       data-vv-as="Email">
                <span v-show="errors.has('email')"
                      class="invalid-feedback">
                      {{ errors.first('email') }}
                </span>
            </div>
            <div class="form-group">
                <input type="text"
                        autocomplete="organization"
                       class="form-control"
                       name="user[org_name]"
                       :placeholder="$t('login.sign_up.organisation_name')"
                       data-vv-name="org_name"
                       data-vv-as="org_name">
            </div>
            <div class="form-group">
                <input type="password"
                        autocomplete="new-password"
                       class="form-control"
                       id="password"
                       :placeholder="$t('login.sign_up.new_password')"
                       name="user[password]"
                       v-validate="'required|min:8'"
                       data-vv-name="password"
                       data-vv-as="password"
                       ref="password"
                       :class="{'is-invalid': errors.has('password') }">

                <small v-show="!errors.has('password')" class="text-muted">{{$t('login.sign_up.password_complexity_message')}}</small>
                <span v-show="errors.has('password')" class="invalid-feedback">{{ errors.first('password') }}</span>
            </div>

        </div>
        <div class="checkbox pb-2">
            <b-form-checkbox  v-model="status"
                              name="checkbox-1"
                              value="accepted"
                              unchecked-value="not_accepted">
                    {{$t('login.sign_up.agree_with_the')}} <a target="blank" href="http://cmssignage.com/terms">
                    {{$t('login.sign_up.terms_and_conditions')}}</a>
            </b-form-checkbox>
        </div>
        <br/>
        <div class="pb-2">
          <b-button variant="primary"
                    :disabled="!this.isFormDirty || status =='not_accepted'"
                    type="submit">
                    {{$t('login.sign_up.sign_up_title')}}
          </b-button>

          <div class="float-right mt-2 no-account">
              <span class="float-left">{{$t('login.sign_up.already_have_account')}}</span>
              <router-link :to="{ name: 'login' }"
                           class="text-capitalize font-weight-bold pl-2">{{$t('login.sign_in.sign_in_title')}}

              </router-link>
          </div>
        </div>
    </form>
    <b-alert :show="this.result.length" dismissible
             variant="danger">
             {{this.result}}
    </b-alert>
  </div>

</template>
<script>
    import Csrf from '../utils/Csrf';

    export default {
        name: 'Signup',

        data () {
            return {
                result: "",
                status: 'not_accepted'
            }
        },

        created () {
        },

        computed: {
          isFormDirty() {
            return Object.keys(this.dataFields).some(key => this.dataFields[key].dirty);
          }
        },

        methods: {

            onValidationSuccess () {
                let formData = new FormData(this.$refs.form);
                Csrf.append(formData);
                this.$http
                    .post('/users.json', formData)
                    .then(response => {
                        // redirect to home page
                        window.location.href = '/';
                    })
                    .catch(error => {
                        if (error.response) {
                            // HTTP error
                            let _this = this
                            let result = Object.keys(error.response.data.error).forEach(function(error){
                                                      _this.result.push(error)});
                        } else if (error.request) {
                            this.result = 'No Network available.';
                        }
                    })
            },
            /**
            * Performs validation before the form gets submitted.
            */
            submit() {
                this.$validator.validateAll().then((result) => {
                    if(!result){
                        return;
                    }
                    this.onValidationSuccess();
                }).catch(() => {
                });
            }
        }
    }
</script>
