export default {

  methods: {

    disableDraw: function() {
      this.canvas.off('mouse:down');
      this.canvas.off('mouse:move');
      this.canvas.off('mouse:up');

      this.canvas.selection = true;
      this.canvas.forEachObject(function(o) {
        o.selectable = true;
        o.evented = true;
      });
    },

    enableDraw: function() {
      this.canvas.selection = false;
      this.start_drag = false;
      this.canvas.forEachObject(function(o) {
        o.selectable = false;
        o.evented = false;
      });
    },
    
    insertText: function() {
      this.show_rhs_bar = true;
      this.canvas.defaultCursor = 'crosshair';
      this.start_drag = false;
      this.isTextInsert = true
      // Esc key handler
      $(document).on("keyup", this.textEscHandler);

      this.enableDraw()

      let _this = this
      this.canvas.on('mouse:down', function(o){
        // Unregister escape key handler
        $(document).off("keyup", this.textEscHandler);

        _this.disableDraw();

        let pointer = _this.canvas.getPointer(o.e);
        let text = new fabric.IText('Sample Text', {
          fontFamily: _this.properties.font_family,
          left: pointer.x,
          top: pointer.y,
          fill: _this.properties.text_color.hex8,
          underline: _this.properties.underline,
          italics: _this.properties.italics,
          fontWeight: (_this.properties.bold ? 'bold': 'normal')
        });

        text.on('editing:exited', function(o){
          if ($(this)[0].text.length === 0) {
            _this.canvas.remove(text);
          } else {
            // Delete the event listener
            //text.__eventListeners["editing:exited"] = [];

            // Push the this.canvas state to history
            _this.canvas.fire("object:statechange");
          }
        });

        text.setSelectionStart(text.text.length);
        text.setSelectionEnd(text.text.length);

        _this.canvas.add(text);
        _this.canvas.setActiveObject(text);
        _this.canvas.defaultCursor = 'auto';
        text.enterEditing();
        text.hiddenTextarea.focus();
        _this.isTextInsert = false
      });
    },

    cancelTextInsert: function() {
      this.canvas.defaultCursor = 'auto';
      this.disableDraw();
      $(document).off("mouse:down");
    },

    // Cancel text insertion
    textEscHandler: function (e) {
      if (e.keyCode == 27) {
        this.cancelTextInsert();

        // Unregister escape key handler
        $(document).off("keyup", this.textEscHandler);
      }
    },

    // Return focus to itext if user was editing text
    returnFocus: function() {
      var o = this.canvas.getActiveObject();
      if (o === undefined || o === null || o.type !== "i-text") {
        return;
      }

      if (o.hiddenTextarea) {
        o.hiddenTextarea.focus();
      }
    },

    // Set object style
    setStyle: function(object, styleName, value) {
      object = object || this.canvas.getActiveObject();
      object[styleName] = value;
      object.set({dirty: true});
    },

    // Get object style
    getStyle: function(object, styleName) {
      object = object || this.canvas.getActiveObject();
      return object[styleName];
    },

    toggleItalics: function(isItalics) {
      var button = $("#toolbar-italics");
      var obj = this.canvas.getActiveObject();
      this.setStyle(obj, 'fontStyle', isItalics ? 'italic' : 'normal');
      this.canvas.renderAll();
      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },

    toggleBold: function(isBlod) {
      let button = $("#toolbar-bold");
      let obj = this.canvas.getActiveObject();
      this.setStyle(obj, 'fontWeight', isBlod ? 'bold' : '');
      this.canvas.renderAll();
      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },

    toggleUnderline: function (isUnderline) {
      var button = $("#toolbar-underline");
      var obj = this.canvas.getActiveObject();
      this.setStyle(obj, 'underline', isUnderline);
      this.canvas.renderAll();
      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },
  }
}
