<template>
    <b-modal
        :id="this.getId()"
        :title="$t('content.expire_content')"
        modal-class="modal-container"
        @shown="showModal()"
        size="lg"
    >
        <form ref="form" method="post" @submit.prevent="validate">
            <div class="form-group mb-0" v-if="error">
                <p class="text-danger text-center">{{ error }}</p>
            </div>

            <div class="col-9 form-group email required user_email float-left">
                <label> {{ $t('content.set_expiration') }} </label>
                <datetime-input
                    class="form-control form-element-field"
                    v-model="content.expires_at"
                    :datetime="content.expires_at"
                    v-if="content.id != null"
                />
            </div>
            <b-button
                size="sm"
                class="float-right align-btn"
                variant="success"
                @click="remove_expiration()"
            >
                {{ $t('content.remove_expiration') }}
            </b-button>
        </form>
        <div slot="modal-footer" class="w-100 text-right">
            <b-button
                size="sm"
                class="float-right ml-3"
                variant="success"
                @click="update()"
            >
                {{ $t('common_words.save') }}
            </b-button>
            <b-button size="sm" variant="" @click="hideModal()">
                {{ $t('common_words.cancel') }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import PlaylistService from '../services/PlaylistService'
    import DatetimeInput from '../components/DatetimeInput'
    import ContentService from '../services/ContentService'
    export default {
        props: {
            content_id: {
                type: Number,
                default: null
            }
        },

        data() {
            return {
                content: {
                    id: null,
                    filename: '',
                    expires_at: null
                },
                success_message: '',
                error: null,
                options: ['cover', 'center', 'contain', 'stretch']
            }
        },

        components: {
            DatetimeInput
        },

        methods: {
            getId() {
                return 'expire_content' + this.content_id
            },

            showModal() {
                this.find()
            },

            hideModal() {
                this.resetForm()
                this.$bvModal.hide(this.getId())
            },

            durationReadOnly() {
                if (this.file != null && this.file.content_type != null) {
                    return (
                        this.file.content_type.startsWith('audio') ||
                        this.file.content_type.startsWith('video')
                    )
                } else {
                    return false
                }
            },

            find() {
                let _this = this
                ContentService.show(this.content_id)
                    .then((response) => {
                        _this.content = response.data
                    })
                    .catch((error) => {
                        _this.handleError(error)
                    })
            },

            update() {
                let _this = this
                ContentService.update(this.content.id, {
                    expires_at: this.content.expires_at
                })
                    .then((response) => {
                        _this.content = response.data
                        _this.hideModal()
                        _this.$emit('updated')
                    })
                    .catch((error) => {
                        _this.handleError(error)
                    })
            },

            remove_expiration: function () {
                this.content.expires_at = null
                this.update()
            },

            resetForm() {
                Object.keys(this.content).forEach((key) => {
                    this.content[key] = null
                })
                this.$validator.reset()
            }
        }
    }
</script>
<style scoped>
.align-btn {
    margin: 32px 5px 0px 10px;
    padding: 8px;
}
</style>