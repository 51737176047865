<template>
  <div
    class="zone-division-box"
    :style="
      'left:' +
      zoneDivisionBox.dimensions.x_offset_percentage +
      '%; top:' +
      zoneDivisionBox.dimensions.y_offset_percentage +
      '%; width:' +
      zoneDivisionBox.dimensions.width_percentage +
      '%; height:' +
      zoneDivisionBox.dimensions.height_percentage +
      '%;  '
    "
  >
  </div>
</template>
<script>
export default {
  props: {
    zoneDivisionBox: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style scoped>
.zone-division-box {
  background-color: rgb(64, 63, 63);
  border: 1px solid #ecf0f1;
}
</style>
