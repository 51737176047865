import { render, staticRenderFns } from "./StrokeColor.vue?vue&type=template&id=0c40d6e5&"
import script from "./StrokeColor.vue?vue&type=script&lang=js&"
export * from "./StrokeColor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports