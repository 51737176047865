export const ElementDefaultValue = {
  textinput(value) {
    return value;
  },
  numberinput(value) {
    return value;
  },
  dateinput(value) {
    if (value != null) {
      const intValue = parseInt(value);
      return new Date(
        new Date().setDate(new Date().getDate() + intValue)
      ).toUTCString();
    } else {
      return new Date().toUTCString();
    }
  },
  authlogininput(value) {
    return value;
  },

  checkboxinput(value) {
    return value=='true' ? true : false;
  },
};
