import Vue from 'vue';
import Http from '../core/http';

const ScheduleService = {
	list() {
		return Http.get('user/schedules.json');
	},

	get(id) {
		return Http.get('user/schedules/' + id + '.json');
	},

	create(params) {
		return Http.post('user/schedules.json', params);
	},

	update(id, params) {
		return Http.put('user/schedules/' + id + '.json', params);
	},

	destroy(id) {
		return Http.delete('user/schedules/' + id + '.json');
	}

};

export default ScheduleService;
