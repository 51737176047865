<template>
  <div class="preview-container portrait">
    <div class="wizard-preview-aspect-ratio-box portrait">
      <div class="content-box">
        <iframe
          v-if="clientPreviewURL != 'about:blank'"
          :src="clientPreviewURL"
          width="100%"
          height="100%"
          frameBorder="0"
        >
        </iframe>
        <h1 v-else class="preview-text">
          Preview...
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clientPreviewURL: {
      type: String,
      default: "about:blank",
    },
  },
};
</script>
<style scoped>
.preview-container {
  padding: 32px 16px 0px 16px;
  box-sizing: border-box; 
}

.preview-container.portrait {
  resize: horizontal;
  max-width: 100%;
  height: calc(100% - 32);
}

.wizard-preview-aspect-ratio-box {
  border: 5px outset #283149;
  box-shadow: 4px 4px 2px 4px #e7e9eb;
  border-radius: 4px;
  position: relative;
}

.wizard-preview-aspect-ratio-box.portrait {
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 ratio 56.25%, 9:16 ratio  0.5625% */
}
.content-box {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: gold;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.preview-text {
  color: black;
  font-weight: 500;
  font-size: 30px;
}
</style>