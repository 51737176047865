import Http from '../../core/http';

export default {
  methods: {
    selectAll: function() {
      this.canvas.discardActiveObject();
      let sel = new fabric.ActiveSelection(this.canvas.getObjects(), {
        canvas: this.canvas,
      });
      this.canvas.setActiveObject(sel);
      this.canvas.requestRenderAll();
    },

    clone: function() {
      // clone what are you copying since you
      // may want copy and paste on different moment.
      // and you do not want the changes happened
      // later to reflect on the copy.
      this.canvas.getActiveObject().clone(function(cloned) {
        this.canvas.discardActiveObject();
        cloned.set({
          left: cloned.left + 10,
          top: cloned.top + 10,
          evented: true,
        });
        if (cloned.type === 'activeSelection') {
          // active selection needs a reference to the this.canvas.
          cloned.this.canvas = this.canvas;
          cloned.forEachObject(function(obj) {
            this.canvas.add(obj);
            obj.perPixelTargetFind = true;
            obj.targetFindTolerance = 4;
          });
          // this should solve the unselectability
          cloned.setCoords();
        } else {
          this.canvas.add(cloned);
          cloned.perPixelTargetFind = true;
          cloned.targetFindTolerance = 4;
        }

        this.canvas.setActiveObject(cloned);
        this.canvas.requestRenderAll();
      });

      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },

    // inludes shadows
    getImageBounds: function (fitToCanvas) {
      var objs = this.canvas.getObjects();

      if (objs.length === 0) {
        return {
          top: 0, left: 0, height: 0, width: 0
        };
      }

      // Fabric.js bug getting an objects bounds when all objects are selected
      this.canvas.discardActiveObject() ;
      var bounds = objs[0].getBoundingRect();

      // Find maximum bounds
      for (var i = 0; i < objs.length; i++) {
        var obj = objs[i];
        var rect = this.getObjBounds(obj);

        if (rect.left < bounds.left) {
          bounds.width += bounds.left - rect.left;
          bounds.left = rect.left;
        }

        if (rect.top < bounds.top) {
          bounds.height += bounds.top - rect.top;
          bounds.top = rect.top;
        }

        var right = rect.left + rect.width;
        var bottom = rect.top + rect.height;

        if (right > bounds.left + bounds.width) {
          bounds.width = right - bounds.left;
        }

        if (bottom > bounds.top + bounds.height) {
          bounds.height = bottom - bounds.top;
        }
      }

      if (fitToCanvas) {
        // Fit to this.canvas
        if (bounds.left < 0) {
          bounds.width -= Math.abs(bounds.left);
          bounds.left = 0;
        }

        if (bounds.top < 0) {
          bounds.height -= Math.abs(bounds.top);
          bounds.top = 0;
        }

        if (bounds.left + bounds.width > this.canvas.width) {
          bounds.width = this.canvas.width - bounds.left;
        }

        if (bounds.top + bounds.height > this.canvas.height) {
          bounds.height = this.canvas.height - bounds.top;
        }
      }

      // Don't show selection tools
      this.selectAll();
      this.canvas.discardActiveObject() ;
      this.canvas.renderAll();

      return bounds;
    },

    // includes shadows
    getObjBounds: function(obj) {
      var bounds = obj.getBoundingRect();
      var shadow = obj.Shadow;

      if (shadow != null) {
        var blur = shadow.blur;
        var mBlur = blur * Math.abs(obj.scaleX + obj.scaleY) / 4;
        var signX = shadow.offsetX >= 0.0 ? 1.0 : -1.0;
        var signY = shadow.offsetY >= 0.0 ? 1.0 : -1.0;
        var mOffsetX = shadow.offsetX * Math.abs(obj.scaleX);
        var mOffsetY = shadow.offsetY * Math.abs(obj.scaleY);
        var offsetX = mOffsetX + (signX * mBlur);
        var offsetY = mOffsetY + (signY * mBlur);

        if (mOffsetX > mBlur) {
          bounds.width += offsetX;
        } else if (mOffsetX  < -mBlur) {
          bounds.width -= offsetX;
          bounds.left += offsetX;
        } else {
          bounds.width += mBlur * 4;
          bounds.left -= mBlur - mOffsetX;
        }

        if (mOffsetY > mBlur) {
          bounds.height += offsetY;
        } else if (mOffsetY < -mBlur) {
          bounds.height -= offsetY;
          bounds.top += offsetY;
        } else {
          bounds.height += mBlur * 2;
          bounds.top -= mBlur - mOffsetY;
        }
      }

      return bounds;
    },

    deleteSelected: function() {
      // Delete the current object(s)
      var activeObjects = this.canvas.getActiveObjects();
      this.canvas.discardActiveObject() ;
      if (activeObjects.length) {
        this.canvas.remove.apply(this.canvas, activeObjects);
      }
    },

    

    getActiveStyle: function(styleName, object) {
      object = object || this.canvas.getActiveObject();

      if (typeof object !== 'object' || object === null) {
        return '';
      }

      return (object[styleName] || '');
    },

    getFillColor: function () {
      var object = this.canvas.getActiveObject();
      if (object.customFillColor !== undefined) {
        return object.customFillColor;
      } else if (object.type === 'line') {
        return getActiveStyle("stroke");
      } else {
        return getActiveStyle("fill");
      }
    },

    setActiveStyle: function(styleName, value, object) {
      object = object || this.canvas.getActiveObject();
      if (!object) return;

      if (object.setSelectionStyles && object.isEditing) {
        var style = { };
        style[styleName] = value;
        object.setSelectionStyles(style);
        object.setCoords();
      }
      else {
        object.set(styleName, value);
      }

      object.setCoords();
      this.canvas.renderAll();
    },

    getOutlineColor: function() {
      var object = this.canvas.getActiveObject();
      if (object.customOutlineColor !== undefined) {
        return object.customOutlineColor;
      }

      return getActiveStyle("stroke");
    },

    setOutlineColor: function(hex) {
      var object = this.canvas.getActiveObject();
      if (object) {
        this.setActiveStyle('stroke',hex);
      }
    },

    getFontSize: function() {
      return getActiveStyle('fontSize');
    },

    setFontSize: function(value) {
      this.setActiveStyle('fontSize', parseInt(value, 10));
      this.canvas.renderAll();
    },

    getFont: function() {
      var fontFamily = this.canvas.getActiveObject().fontFamily;
      return fontFamily ? fontFamily.toLowerCase() : '';
    },


    /* ----- shadow and glow ----- */

    setShadow: function(_color, _blur, _offsetX, _offsetY, object) {
      object = object || this.canvas.getActiveObject();
      object.setShadow({
          color: _color,
          blur: _blur,
          offsetX: _offsetX,
          offsetY: _offsetY
      });
      this.canvas.renderAll();

      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },

    changeShadowColor: function(color, object) {
      object = object || this.canvas.getActiveObject();
      var shadow = object.shadow;
      if (shadow === null) {
        return null;
      }
      setShadow(color, shadow.blur, shadow.offsetX, shadow.offsetY, object);
    },

    clearShadow: function(object) {
      object = object || this.canvas.getActiveObject();
      object.setShadow(null);
      this.canvas.renderAll();

      // Push the this.canvas state to history
      this.canvas.fire("object:statechange");
    },

    isShadow: function(object) {
      object = object || this.canvas.getActiveObject();
      var shadow = object.shadow ;
      return (shadow !== null && (shadow.offsetX !== 0 || shadow.offsetY !== 0));
    },

    // Glow is just a shadow with an offset of zero
    isGlow: function(object) {
      object = object || this.canvas.getActiveObject();
      var shadow = object.shadow;
      return (shadow !== null && shadow.offsetX === 0 && shadow.offsetY === 0);
    },

    getShadowBlur: function(object) {
      object = object || this.canvas.getActiveObject();
      var shadow = object.shadow;
      if (shadow === null) {
        return null;
      }

      return parseInt(shadow.blur);
    },

    getShadowColor: function(object) {
      object = object || this.canvas.getActiveObject();
      var shadow = object.shadow;
      if (shadow === null) {
        return null;
      }

      return shadow.color;
    },

    getShadowOffset: function(object) {
      object = object || this.canvas.getActiveObject();
      var shadow = object.shadow;
      if (shadow == null) {
        return null;
      }
      var x = parseInt(shadow.offsetX);
      var y = parseInt(shadow.offsetY);
      return {"x": x, "y": y};
    },

    changeImageOffset: function(left, top) {
      var objs = this.canvas.getObjects();
      for (var i = 0; i < objs.length; i++) {
        objs[i].left += left;
        objs[i].top += top;
      }
    },

    centerContent: function() {
      let bounds = getImageBounds(true);

      let canvasMidpointLeft = Math.round(this.canvas.width / 2);
      let canvasMidpointTop = Math.round(this.canvas.height / 2);
      let imageMidpointLeft = Math.round((bounds.width / 2) + bounds.left);
      let imageMidpointTop = Math.round((bounds.height / 2) + bounds.top);
      let diffLeft = canvasMidpointLeft - imageMidpointLeft;
      let diffTop = canvasMidpointTop - imageMidpointTop;

      changeImageOffset(diffLeft, diffTop);
      this.canvas.renderAll();
    },

    zoomCanvas: function(delta){
      let zoom_percent = 0.2 * delta
      this.properties.scale_value = this.properties.scale_value + zoom_percent;
      this.canvas.upperCanvasEl.parentNode.style.transform = 'scale(' + this.properties.scale_value + ')'
      if(this.properties.scale_value > 1){
        this.canvas.upperCanvasEl.parentNode.style.margin = "0"
      }else{
        this.canvas.upperCanvasEl.parentNode.style.margin = "auto"
      }
    },
  }
}
