<template>
  <div class="audio-previewer">
    <video
      width="50%"
      height="100px"
      controls
      autoplay
      :key="audioUrl"
      ref="audioElement"
    >
      <source :src="audioUrl" type="video/mp4" />
    </video>
  </div>
</template>
<script>
import EventBus from "../../core/event-bus";
export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      audioUrl: {
        type: String,
        default: null,
      },
    };
  },
  watch: {
    content: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        this.audioUrl = this.content.file_url;
      },
    },
  },
  created: function () {
    let _this = this;
    EventBus.$on("audioModelClosed", function () {
      _this.$refs.audioElement.pause();
    });
  },
  beforeDestroy() {
    EventBus.$off("audioModelClosed");
  },
};
</script>
<style scoped>
.audio-previewer {
  position: relative;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
