import Vue from 'vue';
import Http from '../../core/http';

const WidgetService = {
	list(page) {
		if(page != null){
			return Http.get('/admin/widgets.json?page=' + page);
		}else{
			return Http.get('/admin/widgets.json?');
		}
	},

	show(id) {
		return Http.get('/admin/widgets/' + id + '.json');
	},


	create(params) {
		params['metadata'] = JSON.stringify(params['metadata'])
		let formData = new FormData();
		if(params['file'] != null){
			formData.append("widget[file]", params['file']);
			params['file'].delete
		}
		for ( let key in params ) {
			formData.append("widget[" + key + "]", params[key]);
		}
		return Http.post('widgets.json', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},

	update(id, params) {
		params['metadata'] = JSON.stringify(params['metadata'])
		let formData = new FormData();
		if(params['file'] != null){
			formData.append("widget[file]", params['file']);
			params['file'].delete
		}
		for ( let key in params ) {
			formData.append("widget[" + key + "]", params[key]);
		}
		return Http.put('widgets/' + id + '.json', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},

	destroy(id) {
		return Http.delete('/admin/widgets/' + id + '.json');
	},

};

export default WidgetService;
