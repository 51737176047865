import Vue from 'vue';
import Http from '../core/http';

const ProfileService = {
	get() {
		return Http.get('users/me.json');
	},

	update(params) {
		return Http.put('users/me.json', params);
	},

	change_password(params){
		return Http.put('users/change_password.json', params);
	}

};

export default ProfileService;
