<template>
<div class="col-12 p-4" v-observe-visibility="visibilityChanged">
    <label>Stroke Size:</label>
    <b-form-select v-model="stroke_width" @change="changeSize">
        <option v-for="size in [1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100]" :key="size" :value="size">
            {{size}}
        </option>
    </b-form-select>
</div>
</template>

<script>
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} }
    },

    data() {
        return {
            stroke_width: this.properties.stroke_width
        }
    },

    methods: {
        visibilityChanged: function (isVisible) {
            if (isVisible && this.canvas.freeDrawingBrush != null) {
                this.stroke_width = this.properties.stroke_width
            }
        },

        changeSize: function (width) {
            this.canvas.freeDrawingBrush.width = width
            this.canvas.fire("object:statechange");
        },
    }
}
</script>
