<template>
  <div class="file-upload-empty-container">
    <img :src="uploadImage" style="align-self: center" />
    <br />
    <h1>{{ $t("upload.file_type_empty_title") }}</h1>
    <br />
    <h4>{{ $t("upload.file_type_empty_description") }}</h4>
    <br />

    <b-button variant="primary" @click="browseFiles()">
      {{ $t("upload.file_type_empty_button_text") }}
    </b-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      uploadImage: require("../../../images/upload/add_file.png"),
    };
  },
  methods: {
    browseFiles: function () {
      document.getElementById("fileUpload").click();
    },
  },
};
</script>
<style scoped>
.file-upload-empty-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
