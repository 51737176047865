<template>
  <div class="side-bar-container">
    <div class="header-menu-item">
      <img :src="get_logo()" height="20px" width="20px" />
      <a class="header-title-text" href="/">{{ get_site_title() }} </a>
    </div>
    <div class="menu-items">
      <router-link class="menu-item" :to="{ name: 'home' }">
        <i class="zmdi zmdi-desktop-mac zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.screens") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'library' }">
        <i class="zmdi zmdi-tv-list zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.library") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'widgets' }">
        <i class="zmdi zmdi-apps zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.widgets") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'templates' }">
        <i class="zmdi zmdi-widgets zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.templates") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'playlists' }">
        <i class="zmdi zmdi-playlist-audio zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.playlists") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'channels' }">
        <i class="zmdi zmdi-remote-control zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.channels") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'schedules' }">
        <i class="zmdi zmdi-assignment zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.schedules") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'brandings' }">
        <i class="zmdi zmdi-palette zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.branding") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'reports' }">
        <i class="zmdi zmdi-graphic-eq zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.reports") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'alerts' }">
        <i class="zmdi zmdi-notifications zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.alerts") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'user' }">
        <i class="zmdi zmdi-account-add zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.users") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'billing' }" v-if="user.account.subscription == null">
        <i class="zmdi zmdi-receipt zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.billing") }}</span>
      </router-link>

      <router-link class="menu-item" :to="{ name: 'subscription' }" v-else>
        <i class="zmdi zmdi-receipt zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.subscription") }}</span>
      </router-link>
    </div>

    <div class="menu-items-bottom">
      <router-link class="menu-item" :to="{ name: 'support' }">
        <i class="zmdi zmdi-help zmdi-hc-lg"></i>
        <span class="menu-name-text">{{ $t("side_bar_tabs.support") }}</span>
      </router-link>
      <b-button variant="link" class="menu-item" href="/users/sign_out">
        <i class="zmdi zmdi-power zmdi-hc-lg"> </i>
        <span class="menu-name-text">{{ $t("nav_bar.logout") }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: {
        account: {in_trial: false}
      }
    },
  },

  data() {
    return {
      logo: require("../images/logo.jpg"),
    };
  },

  methods: {
    logout: function () {
      this.$http
        .get("/users/sign_out.json", {
          params: {
            id: this.user.id,
          },
        })
        .then((response) => {
          // redirect to home page
          window.location.href = "/";
        })
        .catch((error) => {
          if (error.response) {
            // HTTP error
            let data = error.response.data;
            if (data.error) {
              this.result = data.error;
            }
          } else if (error.request) {
            this.result = "No Network available.";
          } else {
            this.result = error.message;
          }
        });
    },

    get_logo: function () {
      return LOGO_URL == "" ? this.logo : LOGO_URL;
    },

    get_site_title: function () {
      return SITE_TITLE == "" ? "CMS Signage" : SITE_TITLE;
    },
  },
};
</script>
<style scoped>
.side-bar-container {
  display: flex;
  
  flex-direction: column;

  background-color: white;
  border-right: 1px solid rgb(230, 230, 230);
}

.header-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.menu-items {
  overflow-y: scroll;
  flex: 1;
}
.menu-items-bottom {
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px -4px 3px rgba(89, 109, 112, 0.177);
}
.menu-item {
  display: flex;
  flex: 0;
  text-decoration: none;
  align-items: center;
  border-top: 1px solid rgb(230, 230, 230);
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.menu-item:hover {
  background-color: rgba(7, 209, 240, 0.081);
}

.menu-item.router-link-exact-active {
  background-color: rgba(7, 209, 240, 0.31);
}

.header-title-text {
  padding-left: 8px;
  font-weight:bold;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap
}

.menu-name-text {
  padding-left: 8px;
}


</style>
