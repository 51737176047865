<template>
    <div class="channel-media-list-container" v-show="media_list.length">
        <div
            class="media-container"
            v-for="media in media_list"
            :key="'read-only' + media.id"
        >
            <div class="media">
                <div class="left-section">
                    <ContentThumbnail :content="media"/>
                    <div
                        class="expand-collapse"
                        v-if="media.type == 'Account::Channel'"
                    >
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            class=""
                            @click="show_media_list()"
                            v-show="!expand"
                        >
                            Show media list
                            <i
                                class="zmdi zmdi-chevron-right"
                                v-if="!expand"
                            ></i>
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            class=""
                            @click="show_media_list()"
                            v-show="expand"
                        >
                            Hide media list
                            <i class="zmdi zmdi-chevron-down" v-if="expand"></i>
                        </b-button>
                    </div>
                </div>
                <div class="middle-section dz-details">
                    <div class="schedule-container">
                        <schedule
                            v-for="(schedule, index) in media.schedules"
                            :key="'schedule' + media.id + index"
                            :schedule="schedule"
                            :readOnly="readOnly"
                            :canDelete="media.schedules.length > 1"
                            @change="editSchedule(schedule, index)"
                        />
                    </div>
                </div>
                <div v-if="expand">
                    <ChannelMediaListReadOnly :channel="media" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ChannelService from "../../services/ChannelService";
    import ChannelMedia from "./ChannelMedia.vue";
    import Schedule from "../ScheduleComposition/Schedule.vue";
    import ContentThumbnail from '../../components/ContentThumbnail.vue';
    export default {
        props: {
            channel: {
                type: Object,
                default: null,
            },
            readOnly: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                loaded: true,
                media_list: [],
                expand: false,
            };
        },

        components: {
            ContentThumbnail,
            ChannelMedia,
            Schedule,
        },

        mounted() {
            this.fetchChannel();
        },

        methods: {
            fetchChannel: function () {
                let _this = this;
                ChannelService.get(_this.channel.item_id)
                    .then((response) => {
                        _this.media_list = response.data.channel.media_list;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            show_media_list: function () {
                this.expand = !this.expand;
            },
        },
    };
</script>
<style lang="scss" scoped>
.channel-media-list-container {
    flex-basis: 100%;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #DFDFDF;
    margin-bottom: 5px;
    .media-container {
        flex-basis: 100%;
        display: flex;
        .media {
            display: flex;
            flex: 0.7;
            .dz-image {
                height: 50px;
                width: 100%;
            }

            .left-section {
                flex: 0.3;
                min-width: 120px;
                .expand-collapse {
                    margin: 10px 0px;
                }
            }
            .middle-section {
                flex: 0.7;
            }
        }
    }
}
</style>