<template>
    <div class="cmodal" tabindex="0" @mousedown.stop="modalClick">
        <div :class="content_class" class="modal_content">
            <div class="dialog-header">
                <div>
                    <slot name="header" />
                </div>
            </div>
            <div class="modal-body" :class="modal_body">
                <slot name="body" />
            </div>
            <div class="footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content_class: {
                type: String,
                default: "modal_content",
            },
            modal_body: {
                type: String,
                default: "",
            },
        },

        methods: {
            modalClick(event) {
                event.preventDefault()
                return false
            },
        },
    };
</script>
<style lang="scss">
.cmodal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    background-color: #0000008c;
    .modal_content {
        width: 90vw;
        max-width: 600px;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        margin: 40px auto;
        background-color: #ffffff;
        border: 1px solid #dfdfdf;
        .dialog-header {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 5px 15px;
            border-bottom: 1px solid #dfdfdf;
            > div {
                flex: 1;
                display: flex;
                h3 {
                    margin: 0;
                    text-align: left;
                    font-size: 20px;
                }
            }
        }
        .modal-body {
            flex: 1;
            text-align: left;
            overflow: auto;
            padding-left: 10px 15px;
            height: calc(100% - 100px);
        }
        .footer {
            height: auto;
            text-align: right;
            border-top: solid 1px #cccccc;
            padding: 10px 20px;
            background-color: #ffffff;
            button,
            input {
                margin: 0;
            }
        }
    }
}
</style>
