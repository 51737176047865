import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Users from './Users';

let routes = [{
	path: '/user/users',
	name: 'user',
	component: Users
}];

export default routes;
