import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const WidgetService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('widgets.json?' + query_string);
	},

	get(name){
		return Http.get('widgets/get/' + name + '.json');
	},

	show(id) {
		return Http.get('widgets/' + id + '.json');
	},

	get_content(params) {
		params['metadata'] = JSON.stringify(params['metadata_hash'])
		return Http.post('widgets/get_content.html', {
			content: params
		});
	},

};

export default WidgetService;
