<template>
  <div class="reports-form-main-container">
    <div class="input-controls">
      <div class="input-control">
        <span class="field-label-text">{{ $t('reports.content_label') }}</span>
        <tags-input
          element-id="tags-1"
          v-model="selected_content"
          placeholder="Filter contents (Default all)"
          :typeahead-url="content_search_url"
          id-field="id"
          text-field="name"
          typeahead-style="dropdown"
          :typeahead="true"
        >
          <template v-slot:selected-tag="{ tag, index, removeTag }">
            <span>{{ tag["name"] }}</span>

            <a
              class="tags-input-remove"
              @click="removeSelectedTag(index, removeTag)"
            >
            </a>
          </template>
        </tags-input>
      </div>
      <div class="input-control">
        <span class="field-label-text">{{ $t('reports.device_label') }}</span>
        <tags-input
          element-id="tags-2"
          v-model="selected_device"
          placeholder="Filter screens (Default all)"
          :typeahead-url="device_search_url"
          id-field="id"
          text-field="device_name"
          typeahead-style="dropdown"
          :typeahead="true"
        >
          <template v-slot:selected-tag="{ tag, index, removeTag }">
            <span>{{ tag["device_name"] }}</span>

            <a
              class="tags-input-remove"
              @click="removeSelectedTag(index, removeTag)"
            >
            </a>
          </template>
        </tags-input>
      </div>
      <div class="input-control">
        <span class="field-label-text">{{ $t("reports.date_range_label") }}</span>
        <date-range-picker
          ref="picker"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
          :minDate="minDate"
          :maxDate="maxDate"
          :singleDatePicker="singleDatePicker"
          :ranges="range"
          v-model="dateRange"
          :dateFormat="dateFormat"
        >
        </date-range-picker>
      </div>
    </div>
    <div class="button-container">
      <button
        class="btn btn-primary btn-lg generate-report-button"
        @click="create_report"
      >
      {{ $t('reports.button_text_generate_report') }}
      </button>
    </div>
    <hr />
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import TagsInput from "@voerro/vue-tagsinput";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "../../../../node_modules/@voerro/vue-tagsinput/dist/style.css";

import moment from "moment";
import ReportService from "../../services/ReportService";
import ContentService from "../../services/ContentService";
import DeviceService from "../../services/DeviceService";

export default {
  props: {
    device_id: {
      type: Number,
      default: null,
    },
    content_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      filters: {
        content_ids: [],
        device_ids: [],
        date_range: {
          from: moment().startOf("week").toDate(),
          to: moment().endOf("week").toDate(),
        },
      },
      content_search_url:
        "/user/contents/search.json?per_page=10&query=:search",
      device_search_url: "/user/devices/search?per_page=10&query=:search",
      selected_content: null,
      selected_device: null,
      dateRange: {
        startDate: moment().subtract(7, "days").toDate(),
        endDate: new Date(),
      },
    };
  },

  components: {
    TagsInput,
    DateRangePicker,
  },

  created() {
    if (this.content_id != null) {
      this.filters["content_ids"].push(this.content_id);
    }
    if (this.device_id != null) {
      this.filters["device_ids"].push(this.device_id);
    }
  },

  mounted() {
    let _this = this;
    if (this.filters["device_ids"].length != 0) {
      DeviceService.get(this.filters["device_ids"][0])
        .then((response) => {
          _this.selected_device = [response.data];
        })
        .catch((error) => {
          _this.handleError(error);
        });
    }
    if (this.filters["content_ids"].length != 0) {
      ContentService.get(this.filters["content_ids"][0])
        .then((response) => {
          _this.selected_content = [response.data];
        })
        .catch((error) => {
          _this.handleError(error);
        });
    }
  },
  computed: {
    singleDatePicker: function () {
      return false;
    },
    range: function () {
      return false;
    },
    minDate: function () {
      return moment().subtract(30, "days").toDate();
    },
    maxDate: function () {
      var myCurrentDate = new Date();
      return myCurrentDate;
    },
  },
  methods: {
    dateFormat: function (classes, date) {
      return classes;
    },

    create_report: function () {
      let _this = this;
      _this.loaded = false;
      this.setFilter();
      let params = { report: { filters: this.filters } };
      ReportService.create(params)
        .then((response) => {
          _this.$emit("created");
          _this.$notify({
            group: "alert",
            text: "Shortly you will receive a report on your email as well",
            type: "alert-success",
            duration: 5000,
          });
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },

    validate_params(filters) {
      if (
        filters["content_ids"].length == 0 &&
        filters["device_ids"].length == 0
      ) {
        return false;
      }
      return true;
    },

    get_device_name: function (device_names) {
      if (device_names != null) {
        let names = device_names.split(",");
        let display_names_text = names.slice(0, 1).join(",");
        if (names.length > 2) {
          display_names_text += " + " + (names.length - 2) + "more";
        }
        return display_names_text;
      } else {
        return "N/A";
      }
    },

    setFilter: function () {
      if (this.selected_content != null) {
        this.filters["content_ids"] = this.selected_content.map(function (
          content
        ) {
          return content.id;
        });
      }
      if (this.selected_device != null) {
        this.filters["device_ids"] = this.selected_device.map(function (
          device
        ) {
          return device.id;
        });
      }
      this.filters["date_range"]["from"] = this.dateRange.startDate;
      this.filters["date_range"]["to"] = this.dateRange.endDate;
    },

    removeSelectedTag: function (index, removeTag) {
      setTimeout(function () {
        removeTag(index);
      }, 100);
    },
  },
};
</script>

<style scoped>
.reports-form-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}
.input-controls {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.input-control {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.generate-report-button {
  width: 200px;
}
.field-label-text {
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
}
</style>
