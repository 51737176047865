import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import UserNotificationsHome from './UserNotificationsHome';

let routes = [{
	path: '/user/notifications',
	name: 'alerts',
	component: UserNotificationsHome,
	props: route => Object.assign({},castRouteParams(route), route.params)
},];

function castRouteParams(route) {
	return {
	  device_id: Number(route.query.device_id) || null,
	  content_id: Number(route.query.content_id) || null,
	};
  }

export default routes;
