import { mapGetters } from "vuex";

export default {
  data() {
    return {
      socket: {
        type: Object,
        default: null,
      },
    };
  },

  computed: {
    ...mapGetters("userStore", ["user"]),
  },
  methods: {
    startActionCableListener: function (dataReceivedCallback) {
      let _this = this
      let socket_url = this.getWebSocketURL()
      if (socket_url != null) {
        this.socket = new WebSocket(socket_url);
        this.socket.onopen = function(event) {
            const subscribe_msg = {
                command: 'subscribe',
                identifier: JSON.stringify({channel: 'UserChannel'}),
            };
            _this.socket.send(JSON.stringify(subscribe_msg));
        };

        this.socket.onmessage = function(event) {
          const data = JSON.parse(event.data);
          if(typeof data["type"] == "undefined"){
            dataReceivedCallback(data.message);
          }
        }
      }
    },

    stopActionCableListener: function () {
      this.socket.close();
    },

    getWebSocketURL: function () {
      const element = document.head.querySelector(`meta[name='action-cable-url']`)
      let url = element.getAttribute("content")
      if (typeof url === "function") {
        url = url()
      }
    
      if (url && !/^wss?:/i.test(url)) {
        const a = document.createElement("a")
        a.href = url
        // Fix populating Location properties in IE. Otherwise, protocol will be blank.
        a.href = a.href
        a.protocol = a.protocol.replace("http", "ws")
        return a.href
      } else {
        return url
      }
    }
  },
};
