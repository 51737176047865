import axios from "axios";
import FileChecksum from "../../core/FileChecksum.js"
import DirectUploadService from "../services/DirectUploadService"

export default {
    methods: {
        async uploadFile(file, index, save_data) {
            let _this = this
            let checksum = await this.fileChecksum(file)
            
            let resp = await DirectUploadService.post({ file: {filename: file.name, byte_size: file.size, 
                                                        content_type: file.type, checksum: checksum} })
            if(resp.status == 200) {
                let formData = new FormData();
                    
                Object.entries(resp.data.direct_upload.fields).forEach(([k, v]) => {
                    formData.append(k, v);
                });
                formData.append('file', file);
                // send request to S3 for the file upload.
                axios.post(
                    resp.data.direct_upload.url,
                    formData,
                    {
                        onUploadProgress: function (progressEvent) {
                            let progressPercent = parseInt(
                                Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            );
                            _this.uploadPercentage = progressPercent;
                        }.bind(this),
                    }).then((awsResponse) => {
                        save_data(resp.data.blob_signed_id)
                    }
                )
                
            }else{
                EventBus.$emit("uploadFailed", resp);
            }
        },

        async fileChecksum(file){
            return new Promise((resolve, reject) => {
                FileChecksum.create(file, ((error, checksum) => {
                    if (error) {
                        callback(error);
                        return;
                    }
                    resolve(checksum)
                }))
            })
        }
    }
}