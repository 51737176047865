<template>
    <div class="card shadow-sm overflow-hidden channel-section">
        <div class="header shadow-sm text-right border-bottom">
            <div class="">
                <h3 class="">{{ $t("channels.title") }} ({{ total_count }})</h3>
            </div>
            <div class="">
                <search media_type="Channel" @change="getSearch" />
                <sorting
                    :sort_list="this.sort_options"
                    :sort_order="this.sort_order"
                    @change="getSortedChannels"
                />
                <b-button variant="primary" @click="createNewChannel">
                    {{ $t("channels.new_channel") }}
                </b-button>
            </div>
        </div>
        <scroll-pagination
            :scrollFunction="handleScroll"
            :loading="loading"
            :page="page"
            :total_pages="total_pages"
        >
            <Channel
                v-for="channel in this.channels"
                :channel="channel"
                :key="channel.id"
                @updated="reloadChannels()"
                @deleted="reloadChannels()"
                @click="listenAction"
            />
        </scroll-pagination>
        <no-content
            :title="$t('channel.no_data.title')"
            :imageName="'no-playlist.png'"
            :actionText="$t('channel.no_data.action')"
            v-if="!loading && !this.channels.length && !this.isSearchActive"
            @clicked="createNewChannel"
        />

        <no-search-result
            :searchText="this.searchText"
            v-if="!loading && !this.channels.length && this.isSearchActive"
        />

        <Previewer
            :channel="selectedChannel"
            v-if="selectedChannel != null && this.preview"
            @close="selectedChannel = null"
        />
    </div>
</template>
<script>
    import Search from "../components/Search";
    import Sorting from "../components/Sorting";
    import ChannelService from "../services/ChannelService";
    import NoContent from "../components/NoContent";
    import NoSearchResult from "../components/NoSearchResult";
    import Channel from "./Channel";
    import Previewer from "./Previewer.vue";
    import { showDeleteConfirmation } from "../utils/DeleteConfirmation";
    import {
        getChannelsSortingField,
        setChannelsSortingField,
        getChannelsSortingOrderBy,
        setChannelsSortingOrderBy,
    } from "../core/UserPreferences";

    import { getSortingLabel } from "../core/HelperMethods";

    export default {
        components: {
            Channel,
            NoContent,
            NoSearchResult,
            Search,
            Sorting,
            Previewer,
        },

        data() {
            return {
                loading: false,
                sort_options: [
                    { value: "name", label: "Name" },
                    { value: "updated_at", label: "Last updated" },
                ],
                isSearchActive: false,
                searchText: null,
                in_progress: true,
                total_pages: 0,
                page: 1,
                sort_order: {
                    order: {
                        value: getChannelsSortingField(),
                        label: getSortingLabel(getChannelsSortingField()),
                    },
                    order_by: getChannelsSortingOrderBy(),
                },
                total_count: null,
                channels: [],
                selectedChannel: null,
                preview: false,
            };
        },

        mounted() {
            this.getChannels();
        },

        methods: {
            createNewChannel: function () {
                this.$router.push({
                    name: "newchannel",
                });
            },

            async listenAction(options) {
                let _this = this;
                await this.setSelectedChannel(options.channel);
                this.$nextTick(() => {
                    _this[options.link["action"]]();
                });
            },

            edit: function () {
                this.$router.push({
                    name: "editchannel",
                    params: {
                        channel_id: this.selectedChannel.id,
                    },
                });
            },

            getSearch(searchText) {
                this.searchText = searchText;
                if (searchText.length >= 1) {
                    this.isSearchActive = true;
                } else {
                    this.isSearchActive = false;
                }
                this.page = 1;
                this.getChannels();
            },

            getSortedChannels(sort_option) {
                this.sort_option = sort_option;
                setChannelsSortingField(sort_option.order.value);
                setChannelsSortingOrderBy(sort_option.order_by);
                this.page = 1;
                this.getChannels();
            },
            getChannels: function () {
                let _this = this;
                _this.loading = true;
                let query_params = { page: this.page, query: this.searchText };
                let order = {
                    order: this.sort_order["order"]["value"],
                    order_by: this.sort_order["order_by"],
                };
                ChannelService.list(Object.assign(query_params, order))
                    .then((response) => {
                        if (response.data != null) {
                            _this.channels = response.data.channels;
                            _this.total_pages = response.data.total_pages;
                            _this.total_count = response.data.total_count;
                        }
                        _this.loading = false
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            reloadChannels: function () {
                this.getChannels();
            },

            handleScroll: function () {
                this.page++;
                this.getChannels();
            },

            setSelectedChannel: function (channel) {
                this.selectedChannel = channel;
            },

            delete: function (channel) {
                let _this = this;
                showDeleteConfirmation(
                    _this,
                    _this.$t("confirmation_alert.channel_delete_message")
                ).then((result) => {
                    if (result.value) {
                        ChannelService.destroy(this.selectedChannel.id)
                            .then((response) => {
                                if (response.data != null) {
                                    _this.reloadChannels();
                                }
                            })
                            .catch((error) => {
                                _this.handleError(error);
                            });
                    }
                });
            },

            showPreview: function () {
                this.preview = true;
            },

            duplicate(channel) {
                //this.$router.push({name:'contents', params: {id: this.playlist.id}})
                let _this = this;
                ChannelService.duplicate({
                    name: "Duplicate of " + this.selectedChannel.name,
                    channel_id: this.selectedChannel.id,
                })
                    .then((response) => {
                        this.$router.push({
                            name: "editchannel",
                            params: {
                                channel_id: response.data.channel.id,
                            },
                        });
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
.header {
    display: flex;
    flex: 0;
    .row {
        flex: 1;
    }
    .zmdi-arrow-left {
        font-size: 24px;
    }
    > div {
        display: flex;
        flex: 1;
        padding: 5px 15px;
    }
    > div:nth-child(2) {
        justify-content: flex-end;
    }
}
</style>