<template>
  <b-list-group-item class="p-0 text-center">
    <b-button variant="outline btn-bg" >
      <i class="zmdi zmdi-format-color-fill"></i>
      <div>Bg Color</div>
      <color-picker v-model="bg_color"
                    @show="getBgColor"
                    @input="setBgColor"/>
    </b-button>
  </b-list-group-item>
</template>
<script>
import ColorPicker         from '../../components/ColorPicker'
export default {
    props: {
      canvas: {type: Object, default: {}},
    },

    data() {
      return {
        bg_color: { hex8: 'FFFFFFFF'}
      }
    },

    components: {
      ColorPicker
    },

    methods: {
      setBgColor: function(color){
        this.canvas.backgroundColor = color.hex8;
        this.canvas.renderAll();
        this.canvas.fire("object:statechange");
        this.bg_color = color
      },

      getBgColor: function(){
        this.bg_color.hex8 = this.canvas.backgroundColor
      }

    }
}
</script>
