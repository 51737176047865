export function isElmentInputValid(element) {
  if (element.validation === "required") {
    if(element.is_composite_element  && element.model != null && Object.keys(element.model).length > 0) {
      return true
    }else {
      if (element.model != null) {
        return true;
      }
    }
    return false 
  }
}
