<template>
<div class="col-12 p-4" v-observe-visibility="visibilityChanged">
    <label class="float-left"> Font Color: </label>
    <div class="d-flex">
        <color-picker v-model="text_color" @input="setFillColor" />
    </div>
</div>
</template>

<script>
import ColorPicker from '../../../components/ColorPicker'
import Text from './Text.js'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} }
    },

    components: {
        ColorPicker
    },

    data() {
        return {
            text_color: this.properties.text_color
        }
    },

    mixins: [Text],

    methods: {
        visibilityChanged: function (isVisible) {
            if (isVisible) {
                let object = this.canvas.getActiveObject()
                this.text_color = { hex8: object['fill'] }
            }
        },

        setFillColor: function (color) {
            var object = this.canvas.getActiveObject();
            if (object) {
                object.set('fill', color.hex);
                object.setCoords();
                this.canvas.renderAll();
            }
            this.canvas.fire("object:statechange");
        },
    }
}
</script>
