<template>
    <b-modal
        :id="get_modal_id()"
        :title="$t('subscription.title')"
        class="modal-container"
        @shown="showModal()"
    >
        <form ref="form" method="post" @submit.prevent="save()">
            <div class="row p-1">
                <div class="col-12">
                    <div class="card p-2 m-3">
                        <h2
                            class="card-title text-center font-weight-bold pt-1"
                        >
                            {{ subscription.plan.name }}
                        </h2>
                        <div class="mt-2 mb-2 pt-2 pb-1">
                            <h2 class="text-center mt-2 font-weight-bold">
                                <money-format
                                    :value="subscription.plan.amount['cents']"
                                    locale="en"
                                    :currency-code="
                                        subscription.plan.amount['currency_iso']
                                    "
                                    :subunits-value="true"
                                >
                                </money-format>
                            </h2>
                            <p class="text-center mb-2">
                                {{ $t("plan.per_screen") }}
                            </p>
                        </div>
                    </div>
                    <div class="form-group" v-if="error">
                        <p class="text-danger text-center">{{ error }}</p>
                    </div>
                    <div
                        class="form-group"
                        :class="{ 'has-danger': errors.has('name') }"
                        v-if="subscription.quantity > 0"
                    >
                        <label> {{ $t("subscription.quantity") }}: </label>
                        <span>
                            {{ subscription.quantity }} 
                        </span>
                        <br/>
                        <span class="body-font-help">
                            ({{
                                $t("subscription.quantity_message")
                            }})
                        </span>
                    </div>
                    <div v-else class="form-group">
                        <span class="title-font-danger"> {{ $t("subscription.no_screen_linked_title") }}</span>
                        <br/>
                        <span class="body-font-help">
                            ({{
                                $t("subscription.no_screen_linked_description")
                            }})
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div slot="modal-footer" class="w-100 text-right">
            <b-button size="sm" variant="outline-default" @click="hideModal()">
                {{ $t("common_words.cancel") }}
            </b-button>
            <b-button
                :disabled="subscription.quantity == 0"
                size="sm"
                variant="primary"
                @click="save()"
                v-if="subscription.id == null"
            >
                {{ $t("subscription.pay") }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import SubscriptionService from "../../services/SubscriptionService";
    import DeviceService from "../../services/DeviceService";
    import MoneyFormat from "vue-money-format";

    export default {
        props: {
            subscription: {
                id: null,
                processor_plan_id: null,
                quantity: null,
            },
        },

        data() {
            return {
                device_otp: "",
                device_name: "",
                success_message: "",
                error: null,
            };
        },

        components: {
            MoneyFormat,
        },

        methods: {
            showModal: function () {
                this.get_devices();
            },

            save() {
                let _this = this;
                this.$validator
                    .validateAll()
                    .then((result) => {
                        if (_this.subscription.id == null) {
                            _this.create();
                        } else {
                            _this.update();
                        }
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            create: function () {
                SubscriptionService.create(this.subscription)
                    .then((response) => {
                        var stripe = Stripe(STRIPE_PUBLIC_KEY);
                        stripe.redirectToCheckout({
                            sessionId: response.data.session_id,
                        });
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            update: function () {
                let _this = this;
                SubscriptionService.update(this.subscription.id, this.subscription)
                    .then((response) => {
                        _this.$emit("updated");
                        _this.hideModal();
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            hideModal() {
                this.resetForm();
                this.$bvModal.hide(this.get_modal_id());
            },

            resetForm() {
                this.device_otp = "";
                this.success_message = "";
                this.error = null;
            },

            checkout: function () {
                let _this = this;
                Subscriptions.new({ name: "day" })
                    .then((response) => {
                        var stripe = Stripe(STRIPE_PUBLIC_KEY);
                        stripe.redirectToCheckout({
                            sessionId: response.data.session_id,
                        });
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            get_modal_id: function () {
                if (this.subscription.id == null) {
                    return "subscription_form_plan" + this.subscription.plan.id;
                } else {
                    return "subscription_form" + this.subscription.id;
                }
            },

            get_devices: function () {
				let _this = this
                DeviceService.list()
                    .then((response) => {
                        _this.subscription.quantity = response.data.total_count;
                        _this.loading = false;
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            },
        },
    };
</script>
