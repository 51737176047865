export default {
	methods: {
        getTime(time) {
            let dt = new Date()
            let hrs = parseInt(time/(60 * 60))
            let mins = parseInt((time % (60 * 60))/ 60)

            dt.setUTCHours(hrs,mins,0, 0)
            return dt
        },

        formattedTime(time) {
            let timeStr = this.getTime(time)
                .toLocaleTimeString("en-US", {
                    timeZone: 'UTC',
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                })
            return (timeStr || "").trim();
        },
	}
}