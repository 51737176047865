<template>

      <input type="number"
             v-model="hours"
             @input="this.setDuration"
             :blur="this.setValue"
             :readonly="readOnly"
             min="1"/>


</template>

<script>
    export default {
        props: {
            duration: {
              type: Number,
              default: 0
            },

            readOnly: {
              type: Boolean,
              default: false
            }

        },

        data: function() {
          return {
            hours: 0,
            minutes: 0,
            seconds: 0
          }
        },

        mounted: function(){
            this.hours = this.setValue(Math.floor(this.duration))
           // this.minutes = this.setValue(Math.floor((this.duration - (this.hours * 3600)) / 60))
            //this.seconds = this.setValue(this.duration - (this.hours * 3600) - (this.minutes * 60))
        },

        methods: {
          setDuration: function(){
            let duration = parseInt(this.hours)
            this.$emit("input", duration)
          },

          setElementValue: function(el){
            el.value = this.setValue(el.value)
          },

          setValue: function(val){
            return val
          },

        },
    }
</script>
<style>
 .form-control {
   padding-right:4px !important;
   padding-left:8px !important;
   }
   input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
  }
</style>
