<template>
    <b-modal
        :id="'change_media_' + group.id"
        :title="$t('screen_group.set_media_title')"
        class="modal-container"
        @shown="showModal()"
    >
        <form ref="form" method="post" @submit.prevent="validate">
            <div class="form-group" v-if="error">
                <p class="text-danger text-center">{{ error }}</p>
            </div>

            <div class="form-group">
                <label>
                    {{ $t("change_media.media_type") }}
                </label>
                <b-form-select v-model="media_type" :options="options" @change="set_media_list"/>
            </div>

            <div class="form-group">
                <label>
                    {{ $t("change_media.media") }}
                </label>
                <multiselect
                    v-model="media"
                    :loading="isLoading"
                    :searchable="true"
                    :internal-search="false"
                    :close-on-select="true"
                    placeholder="Search and Select"
                    label="name"
                    track-by="id"
                    :options="media_options"
                    @search-change="find_media_options"
                >
                </multiselect>
            </div>
        </form>
        <div slot="modal-footer" class="w-100 text-right">
            <b-button
                size="sm"
                variant="primary"
                @click="save()"
                :disabled="media == null"
            >
                {{ $t("common_words.save") }}
            </b-button>
            <b-button size="sm" variant="outline-default" @click="hideModal()">
                {{ $t("common_words.cancel") }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import GroupService from "../services/GroupService";
    import ContentService from "../services/ContentService";
    import GroupedScheduleService from "../services/GroupedScheduleService";
    import PlaylistService from "../services/PlaylistService";
    import ChannelService from "../services/ChannelService";
    import Multiselect from "vue-multiselect";
    export default {
        props: {
            group: null,
        },

        data() {
            return {
                success_message: "",
                error: null,
                media_type:
                    this.group.media == null ? "content" : this.group.media.type,
                media: "",
                media_options: [],
                options: [
                    {
                        value: "content",
                        text: "Content",
                        action: "getContents",
                    },
                    {
                        value: "playlist",
                        text: "Playlist",
                        action: "getPlaylists",
                    },
                    {
                        value: "groupedschedule",
                        text: "Schedule",
                        action: "getSchedules",
                    },
                    {
                        value: "channel",
                        text: "Channel",
                        action: "getChannels",
                    },
                ],
                schedules: [],
                playlists: [],
                contents: [],
                isLoading: false,
            };
        },

        components: {
            Multiselect,
        },

        methods: {
            save() {
                let _this = this;
                GroupService.set_media(this.group.id, {
                    media: {
                        media_type: this.media_type,
                        media_id: this.media.id,
                    },
                })
                    .then((response) => {
                        _this.$emit("updated");
                        _this.hideModal();
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            getContents: function (query) {
                let _this = this;
                ContentService.list({ query: query })
                    .then((response) => {
                        if (query == "" && _this.media != null) {
                            _this.media_options = _this.remove_existing(
                                response.data.contents,
                                _this.media
                            );
                            if(_this.media != null) {
                                _this.media_options.unshift(_this.media);
                            }
                        } else {
                            _this.media_options = response.data.contents;
                        }
                        _this.isLoading = false;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            getSchedules: function (query) {
                let _this = this;
                GroupedScheduleService.list({ query: query })
                    .then((response) => {
                        if (query == "" && _this.media != null) {
                            _this.media_options = _this.remove_existing(
                                response.data.grouped_schedules,
                                _this.media
                            );
                            if(_this.media != null) {
                                _this.media_options.unshift(_this.media);
                            }
                        } else {
                            _this.media_options = response.data.grouped_schedules;
                        }
                        _this.isLoading = false;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            getPlaylists: function (query) {
                let _this = this;
                PlaylistService.list({ query: query, per_page: 1000 })
                    .then((response) => {
                        if (query == "" && _this.media != null) {
                            _this.media_options = _this.remove_existing(
                                response.data.playlists,
                                _this.media
                            );
                            if(_this.media != null) {
                                _this.media_options.unshift(_this.media);
                            }
                        } else {
                            _this.media_options = response.data.playlists;
                        }
                        _this.isLoading = false;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            getChannels: function (query) {
                let _this = this;
                _this.loading = true;
                ChannelService.list({ query: query })
                    .then((response) => {
                        if (response.data != null) {
                            _this.media_options = _this.remove_existing(
                                response.data.channels,
                                _this.media
                            );
                            if(_this.media != null) {
                                _this.media_options.unshift(_this.media);
                            }
                        }
                        _this.isLoading = false;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            remove_existing: function (list, media) {
                if(media != null){
                    let index = list.findIndex((item) => item.id === media.id);
                    list.splice(index, 1);
                }
                return list;
            },

            set_media_list: function () {
                this.media_options = []
                this.media = null
                let _this = this
				this.list_media('')
            },

            showModal: function () {
                let _this = this
                this.media = this.group.media
                setTimeout(function () {
                    _this.find_media_options("")
                }, 50)
            },

            hideModal() {
                this.resetForm()
                this.$bvModal.hide("change_media_" + this.group.id)
            },

            resetForm() {
                this.media_id = ""
                this.success_message = ""
                this.error = null
            },

            find_media_options: function (query) {
                this.isLoading = true
				this.list_media(query)
            },

            list_media: function(query){
                let _this = this
                let selected_option = this.options.filter(function(option){ return option['value'] == _this.media_type })[0]
                this[selected_option['action']](query)
            }
        },
    }
</script>
<style lang="scss">
.multiselect__option--highlight,
.multiselect__option--highlight:after {
    background-color: #5e6ebe !important;
}
</style>
