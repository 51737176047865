<template>
  <b-modal id="add_edit_branding"
           class="modal-container"
           size="xl"
           no-close-on-backdrop
           hide-footer>
       <template v-slot:modal-header="{ close }">
          <h3 class="w-50 float-left m-0"> {{$t('branding.title')}}</h3>
          <div class="col-3">
             <b-button class="float-right ml-2"
                       variant="secondary"
                       size='sm'
                       @click="hideModal()">
                  {{$t('common_words.cancel')}}
             </b-button>
             <b-button class="float-right" variant="primary" size='sm' @click="save()">
               {{$t('common_words.save')}}
             </b-button>
          </div>
       </template>
      </div>
      <div class="branding-container ">
          <div class="col-md-6 col-lg-4 form-sidebar">
              <form ref="brandingform"  method="post" @submit.prevent="validate">
              <div class="form-group" v-if="error">
                  <p class="text-danger text-center">{{error}}</p>
              </div>
              <div class="form-group">
                <label for="primary_bg_color float-left">{{$t('branding.name')}} </label>
                <span>
                  <input type="text"
                         class="form-control"
                         placeholder="Email"
                         v-model="branding.name"
                         name="branding[name]"
                         v-validate="'required'"
                         data-vv-name="name"
                         data-vv-as="name">
                  <span v-show="errors.has('name')"
                        class="text-danger">
                        {{ errors.first('name') }}
                  </span>
                </span>
              </div>
              <div class="form-group">
                  <label for="primary_bg_color float-left">{{$t('branding.primary_bg_color')}} </label>
                  <span>
                    <color-picker v-model="branding.primary_bg_color"/>
                  </span>
              </div>

              <div class="form-group">
                  <label for="secondary_bg_color float-left">{{$t('branding.secondary_bg_color')}} </label>
                  <span>
                    <color-picker v-model="branding.secondary_bg_color"/>
                 </span>
              </div>

              <div class="form-group">
                  <label for="title_text_color">{{$t('branding.title_text_color')}} </label>
                  <span>
                    <color-picker v-model="branding.title_text_color"/>
                  </span>
              </div>

              <div class="form-group">
                  <label for="body_text_color">{{$t('branding.body_text_color')}} </label>
                  <span>
                    <color-picker v-model="branding.body_text_color"/>
                  </span>
              </div>
              <div class="form-group">
                  <label for="body_text_color">{{$t('branding.title_font_family')}} </label>
                  <b-form-select v-model="branding.title_font_family">
                    <option v-for="font in font_family_list"
                            :key="font" :value="font.toLowerCase()">
                         {{font}}
                     </option>
                  </b-form-select>
              </div>

              <div class="form-group">
                  <label for="body_text_color">{{$t('branding.body_font_family')}} </label>
                  <b-form-select v-model="branding.body_font_family">
                    <option v-for="font in font_family_list"
                            :key="font" :value="font.toLowerCase()">
                         {{font}}
                     </option>
                  </b-form-select>
              </div>

              <div class="form-group">
                  <file-input v-model="branding.logo_url"
                              :label="$t('branding.logo')"
                              v-if="branding.logo_url == ''"/>
                  <b-button class="position-absolute float-left btn_upload"
                            variant="primary"
                            v-if="branding.logo_url != ''"
                            @click="branding.logo_url = '' ">
                            {{ $t('branding.remove_logo')}}
                  </b-button>
              </div>
          </form>
          </div>
          <preview :branding="branding"/>
      </div>
  </b-modal>
</template>

<script>
import BrandingService          from '../services/BrandingService';
import FileInput                from '../components/FileInput';
import ColorPicker              from '../components/InputColorPicker'
import Preview                  from './Preview'
import Csrf                     from '../utils/Csrf';

export default {
  props: {
    branding: {
      type: Object
    }
  },

  components: {
    ColorPicker,
    FileInput,
    Preview
  },


  data() {
    return {
      success_message: "",
      error: null,
      font_family_list: ['Sans-Serif', 'Arial', 'Helvetica', 'Gill Sans', 'Lucida', 'Helvetica Narrow',
                      'sans-serif', 'Serif', 'Times', 'Times New Roman', 'Palatino', 'Bookman',
                      'New Century Schoolbook', 'serif', 'Monospace','Andale Mono', 'Courier New',
                      'Courier', 'Lucidatypewriter', 'Fixed', 'monospace'],
    }
  },

  methods: {

    save(){
      let _this = this
      if(_this.branding.id != null){
        _this.update()
      }else{
        _this.create()
      }
    },

    create(){
      let _this = this
      if(typeof(this.branding.logo_url) == "object"){
        this.branding.logo = this.branding.logo_url
      }
      BrandingService.create(this.branding)
                     .then(response => {
                        _this.$emit("created")
                        _this.hideModal()
                     })
                     .catch(error => {
                         _this.handleError(error)
                     })
    },


    update(){
      let _this = this
      if(typeof(this.branding.logo_url) == "object"){
        this.branding.logo = this.branding.logo_url
      }
      BrandingService.update(this.branding.id, this.branding)
                     .then(response => {
                         _this.$emit("updated")
                         _this.hideModal()
                     })
                     .catch(error => {
                         _this.handleError(error)
                     })
    },

    hideModal() {
      this.resetForm()
      this.$emit("done")
      this.$bvModal.hide('add_edit_branding')
    },

    getTitle(){
      if(this.branding != null && this.branding.id != null){
        return 'Edit'
      }
      return 'Create'
    },

    getImageUrl: function(imageId) {
      const { width, height } = this.mainProps
      return `https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png`
    },

    resetForm() {
      this.branding = {}
    },

  },


}
</script>

<style lang="scss">
  #add_edit_branding{
    .modal-body{
      padding-bottom: 0px;
      .branding-container{
        height: 70vh;

        .form-sidebar{
          float: left;
          border-right: 1px solid #DFDFDF;
          overflow-y: auto;
        }
        .form-preview{
          float: left;
          display: flex;
          top: 0;
          height: 100%;
        }
      }
    }
    input[type=file] {
        opacity: 0;
        width: 100px;
        position: relative;
        top: 0px;
        z-index: 1020;
        left: 5px;
        height: 35px;
        cursor: pointer;
    }
    .btn_upload {
      margin: -5px 10px;
    }
  }
</style>
