<template>
<b-modal id="account_inactive" class="modal-container" size="lg" hide-header hide-footer centered no-close-on-backdrop>
    <div>
        <div class="d-block text-center stop_icon ">
            <i class="zmdi zmdi-close-circle-o"></i>
        </div>
        <div class="text-center" v-if="!user.account.in_trial && !user.account.subscribed" >
            <div><span class="account_block_msg_title">{{$t('account_inactive.msg1')}}</span></div>
            <br />
            <div><span class="account_block_msg_description">{{$t('account_inactive.msg2')}}</span></div>
        </div>
        <div class="text-center" v-else>
            <div><span class="account_block_msg_title">{{$t('account_inactive.msg3')}}</span></div>
            <br />
            <div><span class="account_block_msg_description">{{$t('account_inactive.msg2')}}</span></div>
        </div>
        <br />
        <br />
    </div>
    <div class="w-100 pb-4 mb-5">
        <div class="col-6 float-left  text-center">
            <b-button size="lg" class="pl-5 pr-5" variant="outline-primary" href="/users/sign_out">
                <div class="float-left mt-0">{{$t('nav_bar.logout')}}</div>
            </b-button>
        </div>
        <div class="col-6 float-left  text-center">
            <router-link tag="button" class="btn btn-outline-primary btn-lg pl-5 pr-5" :to="{ name: 'billing' }" @click.native="hideModal()">
                <div>{{$t('account_inactive.view_billing')}}</div>
            </router-link>
        </div>
    </div>
</b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

    props: {},

    computed: {
        ...mapGetters('userStore', ['user']),

    },
    methods: {

        hideModal() {
            this.$bvModal.hide('account_inactive')
        },
    }

}
</script>

<style scoped>
.stop_icon {
    font-size: 50px;
    color: #f97373;
}

.account_block_msg_title {
    font-size: 21px;
    padding: 50px;
    color: #f97373;
    font-weight: 600;
}

.account_block_msg_description {
    font-size: 18px;
    padding: 50px;
}
</style>
