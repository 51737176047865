<template>
  <div class="set-alert-main-container">
    <div class="set-alert-form-container">
      <SetAlertForm @created="getActiveAlerts" />
    </div>
    <div class="set-alert-listings-container">
      <ActiveAlerts ref="active_alerts" />
    </div>
  </div>
</template>

<script>
import SetAlertForm from "./SetAlertForm.vue";
import ActiveAlerts from "./ActiveAlerts";

export default {
  components: {
    SetAlertForm,
    ActiveAlerts,
  },

  created() {},

  mounted() {
  },

  computed: {},
  
  methods: {
    getActiveAlerts: function () {
      this.$refs.active_alerts.getActiveAlerts();
    },
  },
};
</script>

<style scoped>
.set-alert-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.set-alert-form-container {
  display: flex;
  flex: 0;
}
.set-alert-listings-container {
  display: flex;
  flex: 1;
}
</style>
