<template>
  <div class="default-layout-container">
    <sidebar :user="user" v-show="user.id"></sidebar>
    <div class="main-container">
      <top-banner
        v-if="shouldShowBanner"
        :title="bannerTitle"
        :description="bannerDescription"
        :variant="bannerVariant"
        @closeButtonClicked="closeBanner"
      />
      <router-view class="right-side-container"></router-view>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Sidebar from "../components/Sidebar.vue";
import TopBanner from "../components/TopBanner.vue";

export default {
  data() {
    return {
      shouldShowBanner: true,
      bannerTitle: null,
      bannerDescription: null,
      bannerVariant: "info",
    };
  },
  components: {
    Sidebar,
    TopBanner,
  },

  computed: {
    ...mapGetters("userStore", ["user"]),
  },

  mounted() {
    this.showTrialBannerIfNeeded();
  },
  methods: {
    showTrialBannerIfNeeded: function () {
      let trialDaysLeft = this.user.account.trial_left_days
      if (
        this.user.account.hasOwnProperty('subscription') == false &&
        this.user.account.in_trial == true
      ) {
        if (trialDaysLeft > 14) {
          //info view
          this.bannerVariant = "info";
          this.bannerTitle = this.$t("subscription.trial_extended_banner_title",{day: trialDaysLeft});
          this.bannerDescription = this.$t("subscription.trial_extended_banner_description");
        }else if (trialDaysLeft > 8) {
          //info view
          this.bannerVariant = "info";
          this.bannerTitle = this.$t("subscription.trial_started_banner_title",{day: trialDaysLeft});
          this.bannerDescription = this.$t("subscription.trial_started_banner_description");
        } else if (trialDaysLeft > 5) {
          //warning view
          this.bannerVariant = "warning";
          this.bannerTitle = this.$t("subscription.trial_in_middle_banner_title");
          this.bannerDescription = this.$t("subscription.trial_in_middle_banner_description",{day: trialDaysLeft});
        } else {
          //error view
          this.bannerVariant = "error";
          this.bannerTitle = this.$t("subscription.trial_nearing_end_banner_title",{day: trialDaysLeft});
          this.bannerDescription = this.$t("subscription.trial_nearing_end_banner_description");
        }
        this.shouldShowBanner = true;
      } else {
        this.shouldShowBanner = false;
      }
    },
    closeBanner: function () {
      this.shouldShowBanner = false;
    },
  },
};
</script>
<style scoped>
.default-layout-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
}

.main-container {
  display: flex;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  flex-direction: column;
}
.right-side-container {
  display: flex;
  flex: 1;
  margin-top: 16px;
  padding: 0px;
}
</style>
