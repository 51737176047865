<template>
<div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <div class="header shadow-sm col-lg-12  text-right border-bottom">
        <div class="row p-1">
            <div class="col-4 d-flex align-items-center">
                <h3 class="float-left m-0">{{ $t("branding.title") }}</h3>
            </div>
            <div class="col-8 d-flex align-items-center justify-content-end">
                <b-button variant="primary" class="ml-2" @click="showModal">
                    {{ $t("branding.create") }}
                </b-button>
            </div>
        </div>
    </div>

    <scroll-pagination :scrollFunction="handleScroll" :loading="loading" :page="page" :total_pages="total_pages">
        <branding :branding="branding" @edit="editBranding" @deleted="reload_brandings()" v-for="branding in this.brandings" :key="'branding' + branding.id" />
    </scroll-pagination>

    <add-edit-branding :branding="selected_branding" @updated="reload_brandings()" @done="reset_selected_branding()" @created="reload_brandings()" />
</div>
</template>

<script>
import Branding from "./Branding";
import AddEditBranding from "./AddEditBranding";
import BrandingService from "../services/BrandingService";
export default {
    data() {
        return {
            brandings: [],
            loading: false,
            default_branding: {
                primary_bg_color: "#FFFFFF",
                secondary_bg_color: "#DFDFDF",
                title_text_color: "#000000",
                body_text_color: "#02028c",
                name: "New Branding",
                title_font_family: "arial",
                body_font_family: "arial",
                logo_url: "",
                page: 1,
                total_pages: 0
            },
            page: 1,
            total_pages: 0,
            selected_branding: {},
        };
    },

    components: {
        Branding,
        AddEditBranding,
    },

    mounted() {
        this.getBrandings();
        this.selected_branding = this.default_branding;
    },

    methods: {
        getBrandings: function () {
            let _this = this;
            _this.loading = true
            BrandingService.list()
                .then((response) => {
                    if (_this.page == 1) {
                        _this.brandings = [];
                    }
                    _this.brandings = response.data;
                    _this.total_pages = response.data.total_pages
                    _this.loading = false
                })
                .catch((error) => {
                    _this.handleError(error);
                });
        },

        showModal: function () {
            this.$bvModal.show("add_edit_branding");
        },

        async editBranding(branding) {
            await this.setSelectedBranding(branding);
            this.showModal();
        },

        setSelectedBranding: function (branding) {
            this.selected_branding = branding;
        },
        reload_brandings() {
            this.getBrandings();
        },

        reset_selected_branding: function () {
            this.selected_branding = this.default_branding;
        },

        handleScroll: function () {
            this.page++
            this.getBrandings()
        }
    },
};
</script>
