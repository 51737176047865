<template>
    <div class="side_toolbar p-2">
        <fill-color
            :canvas="canvas"
            :properties="properties"
            v-if="checkActiveObjectShape"
        />

        <div class="border-top my-3" v-if="checkActiveObjectShape"></div>

        <stroke-size
            :canvas="canvas"
            :properties="properties"
            v-if="checkActiveObjectShape"
        />

        <corner-radius
            :canvas="canvas"
            :properties="properties"
            v-if="checkActiveObjectRect"
        />

        <stroke-color
            :canvas="canvas"
            :properties="properties"
            v-if="checkActiveObjectShape"
        />

        <div class="border-top my-3" v-if="checkActiveObjectShape"></div>

        <back-front
            :canvas="canvas"
            :properties="properties"
            v-if="checkActiveObjectShape"
        />

        <brush-size
            :canvas="canvas"
            :properties="properties"
            v-if="checkIsDrawingEnable"
        />

        <brush-color
            :canvas="canvas"
            :properties="properties"
            v-if="checkIsDrawingEnable"
        />
    </div>
</template>

<script>
    import ColorPicker from '../../components/ColorPicker'
    import BackFront from './properties/BackFront.vue'
    import StrokeSize from './properties/StrokeSize.vue'
    import StrokeColor from './properties/StrokeColor.vue'
    import FillColor from './properties/FillColor.vue'
    import CornerRadius from './properties/CornerRadius'
    import BrushSize from './brush/BrushSize.vue'
    import BrushColor from './brush/BrushColor.vue'
    export default {
        props: {
            canvas: {
                type: Object,
                default: null
            },
            properties: { type: Object, default: {} },
            selectedTool: { type: String, default: '' }
        },

        data() {
            return {
                activeObject: {},
                cropEnable: false
            }
        },

        components: {
            ColorPicker,
            BackFront,
            FillColor,
            StrokeSize,
            StrokeColor,
            CornerRadius,
            BrushSize,
            BrushColor
        },

        computed: {
            checkActiveObjectShape: function () {
                let types = ['circle', 'rect', 'polyline', 'line', 'arrow']
                if (this.selectedTool != '' || this.activeObject != {}) {
                    return (
                        types.includes(this.selectedTool) ||
                        (types.includes(this.activeObject.type) &&
                            !this.cropEnable &&
                            !this.checkIsDrawingEnable)
                    )
                } else {
                    return false
                }
            },

            checkActiveObjectRect: function () {
                if (this.selectedTool != '' || this.activeObject != {}) {
                    return (
                        this.selectedTool == 'rect' ||
                        (this.activeObject.type == 'rect' &&
                            !this.cropEnable &&
                            !this.checkIsDrawingEnable)
                    )
                } else {
                    return false
                }
            },

            checkIsDrawingEnable: function () {
                if (this.selectedTool != '' || this.activeObject != {}) {
                    return (
                        this.activeObject.type == 'path' ||
                        this.canvas.isDrawingMode
                    )
                } else {
                    return false
                }
            }
        },

        mounted() {
            let _this = this
            this.canvas.on('selection:created', function () {
                _this.activeObject = {}
                _this.activeObject = _this.canvas.getActiveObject()
            })
            
            this.canvas.on('selection:updated', function () {
                _this.activeObject = {}
                _this.activeObject = _this.canvas.getActiveObject()
            })

            this.canvas.on('selection:cleared', function (event) {
                _this.activeObject = {}
            })
        },

        watch: {
            selectedTool: {
                handler: function (new_tool_type) {
                    this.selectedTool = new_tool_type
                },
                deep: true
            },

            activeObject: {
                handler: function (new_activeObject) {
                    this.activeObject = new_activeObject
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss">
.text_toolbar {
    .picker {
        opacity: 1;
        margin: 5px 0px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        transform: scale(1.5);
    }

    label {
        margin-right: 2px;
    }
}
</style>
