<template>
  <div
    class="row border rounded shadow-sm extra-bottom-padding p-2 m-0"
    style="min-width:100%;"
  >
    <div class="row" style="min-width:100%;">
      <div class="col-md-12 ">
        <div class="text-truncate pb-2">
          <b-badge pill variant="success">{{ this.index }}</b-badge>
          <span class="content-title-text">{{
            playlist_content.media.name
          }}</span>
        </div>
      </div>
    </div>

    <!-- first section/column for image and name mode -->
    <div class="col-md-4 p-0">
      <div
        class="show_bg"
        :style="'background-image:url(' + this.img + ')'"
      ></div>
    </div>

    <!-- middle section/column for fields duration & resize mode -->
    <div
      class="col-md-7 d-flex align-items-center justify-content-md-between"
      v-if="!hide_action"
    >
      <label class="label">
        <i
          v-b-tooltip.hover
          :title="$t('playlist.duration_help_text_playlist')"
          class="zmdi zmdi-help"
        >
        </i>
        {{ $t("playlist.duration") }}: {{ playlist_content.media.duration }}
      </label>
    </div>

    <!-- last section/column for delete button -->
    <div class="col-md-1 actions-container" v-if="!hide_action">
      <i class="zmdi zmdi-format-line-spacing reorder-handler-icon mb-4"></i>

      <playlist-content-action
        :playlist_content="playlist_content"
        @deleted="remove"
      />
    </div>
    <div class="clearfix" />
    <div class="col-12 pl-0 pt-2">
      <b-button
        variant="outline-primary"
        size="sm"
        class=""
        @click="show_media_list()"
        v-show="!expand"
      >
        Show media list <i class="zmdi zmdi-chevron-right" v-if="!expand"></i>
      </b-button>
      <b-button
        variant="outline-primary"
        size="sm"
        class=""
        @click="show_media_list()"
        v-show="expand"
      >
        Hide media list
        <i class="zmdi zmdi-chevron-down" v-if="expand"></i>
      </b-button>
      <br />
      <playlist-media
        v-for="contents_playlist in contents_playlists"
        v-show="expand"
        :hide_action="true"
        :key="'contents-playlist' + contents_playlist.id"
        :contents_playlist="contents_playlist"
        :draggable="false"
      />
    </div>
  </div>
</template>
<script>
import PlaylistContentAction from "./PlaylistContentAction"
import PlaylistService from "../services/PlaylistService"
export default {
  props: {
    name: "MediaPlaylist",
    index: {
      type: Number,
    },
    playlist_content: {
      type: Object,
      default: {
        id: null,
        playlist_id: null,
        media_id: null,
        media: {},
      },
    },
    hide_action: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      expand: false,
      contents_playlists: [],
      options: ["cover", "center", "contain", "stretch"],
      img: require("../images/no_content_images/no-playlist"),
    }
  },

  components: {
    PlaylistContentAction,
    PlaylistMedia: () => import("./PlaylistMedia"),
  },

  mounted() {},

  methods: {
    remove(position) {
      this.$emit("deleted", position)
    },

    show_media_list: function() {
      let _this = this
      if (!this.expand && !this.contents_playlists.length) {
        PlaylistService.get(this.playlist_content.media_id)
          .then((response) => {
            _this.expand = true
            _this.contents_playlists = _this.contents_playlists.concat(
              response.data.contents_playlists
            )
            _this.expand = true
            _this.loaded = true
          })
          .catch((error) => {
            _this.handleError(error)
          })
      } else {
        _this.expand = !this.expand
      }
    },
  },
}
</script>
<style lang="scss">
.pl-details {
  span {
    margin: -5px 15px;
  }
}
.pl-expand {
  position: absolute;
  right: 10;
}
</style>
