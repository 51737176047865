<template>
  <div>
    <div class="dz-image rounded-circle ">
      <div data-dz-thumbnail-bg=""
           class="thumbnail-bg"
           :style="'background-image: url(' + this.img + ')'">
      </div>
    </div>
    <div class="dz-details m-1 pt-1">
      <div class="dz-filename">
        <div v-b-tooltip.hover :title="playlist.name" class="col-11 text-truncate p-0">
          {{playlist.name}}
        </div>
      </div>
      <div class="dz-size">
        <label> {{$t('playlist.duration')}}:</label>
        <span>
          {{playlist.duration | formatDuration}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    props:{
      playlist: {
        type: Object,
        default:{
          id: null,
          playlist_id: null,
          media_id: null,
          media: {},
        }
      }
    },

    data() {
      return {
        options: ['cover', 'center', 'contain', 'stretch' ],
        img: require('../images/no_content_images/no-playlist'),
      }
    },

    components:{
    },

    mounted() {

    },

    methods: {

      remove(position){
        this.$emit('deleted', position)
      }
    }
  }
</script>
