import Vue from 'vue';

Vue.filter('formatDuration', function(value) {
	var sec_num = parseInt(value, 10); // don't forget the second param
	var hours = sec_num > 3600 ? Math.floor(sec_num / 3600) : 0;
	var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	var seconds = sec_num - (hours * 3600) - (minutes * 60);
	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	return hours + ':' + minutes + ':' + seconds;
})
