import Swal from "sweetalert2"

export function showDeleteConfirmation(parent, message) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: parent.$t("confirmation_alert.title"),
            text: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: parent.$t("confirmation_alert.yes_delete"),
            cancelButtonText: parent.$t("confirmation_alert.no_keep_it"),
            confirmButtonColor: '#FF0000',
        }).then((result) => {
            resolve(result)
        })
    });
}

export function showYesNoConfirmation(parent, message) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: parent.$t("confirmation_alert.title"),
            text: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: parent.$t("confirmation_alert.yes"),
            cancelButtonText: parent.$t("confirmation_alert.no"),
            confirmButtonColor: '#FF0000',
        }).then((result) => {
            resolve(result)
        })
    });
}

export function showYesNoConfirmationInfo(parent, message) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: parent.$t("confirmation_alert.title"),
            text: message,
            type: "info",
            showCancelButton: true,
            confirmButtonText: parent.$t("confirmation_alert.yes"),
            cancelButtonText: parent.$t("confirmation_alert.no"),
            confirmButtonColor: '#3085d6',

        }).then((result) => {
            resolve(result)
        })
    });
}

export function showInformation(parent, title, message) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: title,
            text: message,
            showCancelButton: false,
        }).then((result) => {
            resolve(result)
        })
    });
}

export function showDeleteConfirmationWithInput(parent, message, input_placeholder) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: parent.$t("confirmation_alert.title"),
            text: message,
            type: "warning",
            input: 'text',
            inputPlaceholder: input_placeholder,
            showConfirmButton: true,
            showCancelButton: true,
            inputValidator: (value) => {
                if (value != 'delete') {
                    return 'Please type delete to confirm'
                }
            },
            cancelButtonText: parent.$t("confirmation_alert.no_keep_it"),
            confirmButtonColor: '#FF0000',
        }).then((result) => {
            resolve(result)
        })
    });
}