<template>
  <div
    class="image-previewer"
    :style="'background-image: url(' + getImage() + ');'"
  ></div>
</template>
<script>
export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  mounted() {},
  methods: {
    getImage: function () {
      return this.content.file_url;
    },
  },
};
</script>
<style scoped>
.image-previewer {
  position: relative;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>
