<template>
    <div class="weekly" v-if="weeklySchedule()" @click.stop>
        <div class="week-days">
            <div
                v-for="key in Object.keys(schedule.week_days)"
                :class="schedule.week_days[key] ? 'active' : ''"
                :key="'key' + key"
            >
                {{ key }}
            </div>
        </div>
        <div class="time-container">
            <div v-for="(time, index) in schedule.times" :key="'kd-' + index">
                <i class="zmdi zmdi-time zmdi-hc-lg" v-if="index == 0"></i>
                <span> {{ formattedTime(time["start"]) }}</span
                >&nbsp;-&nbsp;<span>{{ formattedTime(time["end"]) }}</span>

                <div
                    class="action"
                    v-if="!readOnly && index == schedule.times.length - 1"
                >
                    <button @click.stop="editSchedule">
                        <i class="zmdi zmdi-edit"></i>
                    </button>
                    <button @click.stop="deleteSchedule" v-if="canDelete">
                        <i class="zmdi zmdi-delete"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateTimeFormat from "../../mixins/date_time_format";
    export default {
        props: {
            schedule: {
                type: Object,
                default: {},
            },

            canDelete: {
                type: Boolean,
                default: false,
            },

            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {};
        },

        mounted: function () {},

        mixins: [DateTimeFormat],

        methods: {
            weeklySchedule: function () {
                if (
                    this.schedule.schedule_type == "weekly" ||
                    (this.schedule.week_days != null &&
                        Object.keys(this.schedule.week_days).length > 0)
                ) {
                    return true;
                }
                return false;
            },

            editSchedule: function () {
                this.$emit("change", this.schedule);
            },

            deleteSchedule: function () {
                if (this.canDelete) {
                    this.$set(this.schedule, "_destroy", true);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.weekly {
    flex: 1;
    border: 1px solid #dfdfdf;
    padding: 2px 8px;
    margin: 2px 5px;
    border-radius: 5px;
    div.week-days {
        flex: 1;
        flex-basis: 100%;
        display: flex;
        cursor: pointer;
        div {
            flex: 0.14;
            padding: 2px;
            text-align: center;
            background-color: #dfdfdf;
            border-radius: 5px;
            color: #ffffff;
            margin: 2px 5px;
            text-transform: capitalize;
        }
        div.active {
            background-color: #049d04;
        }
    }
    div.time {
        margin-top: 4px;
    }
    div.time-container {
        display: flex;
        flex-wrap: wrap;
        .action {
            margin-left: auto;
            button {
                border: 0px none;
                border-radius: 3px;
                padding: 0px 4px;
                background: none;
            }
        }
        > div:first-child {
            margin: 0px 5px;
            flex-basis: 100%;
            .zmdi-time {
                line-height: 19px;
                margin-right: 5px;
            }
        }
        > div {
            margin: 0px 0px 0px 28px;
            flex-basis: 100%;
        }
        > div:last-child {
            display: flex;
            justify-content: right;
        }
    }
}
</style>
