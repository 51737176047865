<template>
  <div class="d-flex flex-column justify-content-between p-2" style="height:100%; width:100%;">
    
          <span class="no-content-headline" v-html="title" style="align-self: center;"></span>
          <div class="d-flex flex-column">
            <img :src="get_img()"  style="align-self: center;"/>
            <b-link variant="primary"
                    class="no-content-start-with-one"
                    @click="$emit('clicked')"
                    v-html="actionText" style="align-self: center;">
            </b-link>
          </div>
          
          <span class="no-content-next-step" v-html="nextSteptText" style="align-self: center;"></span>
  </div>
</template>
<script>

export default {

  props:{
    title: '',
    imageName: '',
    actionText: '',
    nextSteptText: ''

  },

  data() {
      return {
          img: require('../images/no_content_images/no_content.png'),
      }
  },

  mounted() {
    
  },

  methods: {
    get_img: function(){
      let widget_img = require('../images/no_content_images/' + this.imageName)
        return widget_img
    },
  }
}
</script>

<style>
 
 
</style>
