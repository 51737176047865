<template>
    <modal-dialog content_class="preview_modal_content">
        <template #header>
            <TitleBar :channel="channel" @close="hideModal" />
        </template>

        <template v-slot:body>
            <div class="preview-container portrait">
                <div class="wizard-preview-aspect-ratio-box portrait">
                    <iframe
                        :src="channel.preview_url"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                    >
                    </iframe>
                </div>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
    import ModalDialog from "../components/ModalDialog";
    import TitleBar from "./PreviewerComposition/TitleBar.vue";

    export default {
        props: {
            channel: {
                type: Object,
            },
        },

        data() {
            return {};
        },

        components: {
            ModalDialog,
            TitleBar,
        },

        methods: {
            hideModal: function () {
                let _this = this;
                let zones = this.refs;
                Object.keys(this.$refs).forEach(function (key) {
                    clearTimeout(_this.$refs[key][0].mediaTimeout);
                });
                this.open = false;
                this.$emit("close");
            },
        },
    };
</script>

<style lang="scss">
.preview_modal_content {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto !important;
}
.preview-container {
    width: 100%;
    height: 100%;
}

.wizard-preview-aspect-ratio-box {
    border: 5px outset #283149;
    box-shadow: 4px 4px 2px 4px #e7e9eb;
    border-radius: 4px;
    position: relative;
    aspect-ratio: 1900/1024;
}

.wizard-preview-aspect-ratio-box.portrait {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
}
.content-box {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: gold;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>

