<template>
  <div class="col-xl-4 col-lg-6 col-md-9 p-3">
    <b-card no-body class="">
      <template #header>
        <h4>
          {{ subscription.plan.name }}
          {{ $t("subscription.subscription_title") }}
        </h4>
        <div class="d-flex">
          <money-format
            :value="subscription.plan.amount['cents']"
            locale="en"
            :currency-code="subscription.plan.amount['currency_iso']"
            :subunits-value="true"
          >
          </money-format>
          <span class="pl-1">{{ $t("plan.per_screen") }}</span>
        </div>
        <SubscriptionContextMenu :subscription="subscription"
                                   @updated="reload_subscription()"
                                   @deleted="reload_subscription()"/>
      </template>
      <b-list-group flush>


        <b-list-group-item class="d-flex">
            <div class="col-6 p-0 font-weight-bold">{{$t('subscription.total_amount')}}</div>
            <div class="col-6 p-0">
                <money-format :value="this.subscription.amount['cents']"
                  locale='en'
                  :currency-code="this.subscription.amount['currency_iso']"
                  :subunits-value='true'>
                </money-format>
            </div>
        </b-list-group-item>

        <b-list-group-item class="d-flex">
            <div class="col-6 p-0 font-weight-bold" v-if="this.subscription.last_paid_amount['cents'] > 0">
              {{$t('subscription.last_paid_amount')}}
            </div>
            <div class="col-6 p-0 font-weight-bold"v-if="this.subscription.last_paid_amount['cents'] < 0">
              {{$t('subscription.credit_balance')}}
            </div>
            <div class="col-6 p-0">
                <money-format :value="this.getAmount"
                  locale='en'
                  :currency-code="this.subscription.last_paid_amount['currency_iso']"
                  :subunits-value='true'>
                </money-format>
            </div>
        </b-list-group-item>

        <b-list-group-item class="d-flex">
            <div class="col-6 p-0 font-weight-bold">{{$t('subscription.total_quantity')}}</div>
            <div class="col-6 p-0">{{subscription.quantity}}</div>
        </b-list-group-item>

        <b-list-group-item class="d-flex">
            <div class="col-6 p-0 font-weight-bold">{{$t('subscription.subscription_status')}}</div>
            <div class="col-6 p-0">
                <span :class="get_class()"> {{subscription.status.toUpperCase() }}</span>
                <i
                  v-b-tooltip.hover
                  :title="$t('subscription.subscription_cancel_tooltip', {date: get_subscription_date()})"
                  class="zmdi zmdi-help"
                  v-if="subscription.metadata['cancelled'] == true"
                ></i>
                <i
                  v-b-tooltip.hover
                  :title="$t('subscription.subscription_updated_tooltip')"
                  class="zmdi zmdi-help"
                  v-if="subscription.metadata['updated'] == true"
                >
                </i>
            </div>
        </b-list-group-item>

        <b-list-group-item class="d-flex" v-if="subscription.metadata['downgrade_pending'] == true">
            <div class="col-6 p-0 font-weight-bold">{{$t('subscription.downgrade_label')}}</div>
            <div class="col-6 p-0">{{$t('subscription.pending_state')}}</div>
        </b-list-group-item>

        <b-list-group-item class="d-flex" v-if="subscription.metadata['upgrade_pending'] == true">
            <div class="col-6 p-0 font-weight-bold">{{$t('subscription.upgrade_label')}}</div>
            <div class="col-6 p-0">{{$t('subscription.pending_state')}}</div>
        </b-list-group-item>

        <b-list-group-item class="d-flex" >
            <div class="col-6 p-0 font-weight-bold" v-if="subscription.metadata['pause_collection'] == null">
              <span v-if="subscription.status != 'cancelled'">{{$t('subscription.next_billing')}}</span>
              <span v-else>{{$t('subscription.cancelled_on')}}</span>
            </div>
            <div class="col-6 p-0 font-weight-bold" v-if="subscription.metadata['pause_collection'] == true">
              <span v-if="subscription.status != 'cancelled'">{{$t('subscription.cancelling_on')}}</span>
            </div>
            <div class="col-6 p-0">
                {{subscription.expires_at | nextBillingDateFormat}}
            </div>
        </b-list-group-item>

      </b-list-group>
    </b-card>

  </div>
</template>
<script>
import SubscriptionContextMenu from "./SubscriptionContextMenu"
import moment from 'moment'
import MoneyFormat from "vue-money-format"

export default {
  props: {
    subscription: {
      id: null,
      plan_name: "monthly",
      quantity: 1,
      amount: { cents: null, country_iso: null },
      customer_portal_url: "",
    },
  },

  data() {
    return {}
  },

  components: {
    SubscriptionContextMenu,
    MoneyFormat,
  },


  computed: {
    getAmount: function(){
      return Math.abs(this.subscription.last_paid_amount['cents'])
    }
  },

  methods: {
    show_dialog() {
      this.$bvModal.show("subscription_form")
    },

    add_screens: function() {
      this.$router.push({
        name: "add_screens",
        params: { subscription_id: this.subscription.id },
      })
    },

    reload_subscription: function() {
      this.$emit("updated")
    },

    get_class: function() {
      if (this.subscription.status == "cancelled") {
        return "text-danger"
      } else if (this.subscription.status == "active") {
        return "text-success"
      }
    },
    get_subscription_date: function(){
      return moment(this.subscription.expires_at).format('DD MMMM')
    },

    expired: function(){
      if(Date.parse(this.subscription.expires_at) > Date.now()){
        return true
      }else{
        return false
      }
    },

    unused_screen_count: function(){
      let unused_screen_count = this.subscription.quantity - this.subscription.selected_screen_count
      if( unused_screen_count < 0){
        return 0
      }else{
        return (this.subscription.quantity - this.subscription.selected_screen_count)
      }
    }

  },

}
</script>
