<template>
    <div class="specific-date" v-if="specificDateSchedule()">
        <div class="date-container">
            <div v-for="(date, index) in schedule.dates" :key="'d' + index">
                <i class="zmdi zmdi-calendar zmdi-hc-lg" v-if="index == 0"></i>
                <span>{{ formattedDate(date["start"]) }}</span
                >&nbsp;-&nbsp;<span> {{ formattedDate(date["end"]) }}</span>
            </div>
        </div>
        <div class="time-container">
            <div v-for="(time, index) in schedule.times" :key="'kd-' + index">
                <i class="zmdi zmdi-time zmdi-hc-lg" v-if="index == 0"></i>
                <span> {{ formattedTime(time["start"]) }}</span
                >&nbsp;-&nbsp;<span>{{ formattedTime(time["end"]) }}</span>

                <div
                    class="action"
                    v-if="!readOnly && index == schedule.times.length - 1"
                >
                    <button @click.stop="editSchedule">
                        <i class="zmdi zmdi-edit"></i>
                    </button>
                    <button @click.stop="deleteSchedule" v-if="canDelete">
                        <i class="zmdi zmdi-delete"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateTimeFormat from "../../mixins/date_time_format";

    export default {
        props: {
            schedule: {
                type: Object,
                default: {},
            },
            canDelete: {
                type: Boolean,
                default: false,
            },

            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {};
        },

        mixins: [DateTimeFormat],

        mounted: function () {},

        methods: {
            specificDateSchedule: function () {
                if ((this.schedule.schedule_type == 'specific_date') ||(
                    this.schedule.dates.length > 0 &&
                    this.schedule.times.length > 0 &&
                    Object.keys(this.schedule.week_days).length < 1
                )) {
                    return true;
                }
                return false;
            },

            editSchedule: function () {
                this.$emit("change", this.schedule);
            },

            deleteSchedule: function (event) {
                event.stopPropagation();
                if (this.canDelete) {
                    this.$set(this.schedule, "_destroy", true);
                }
            },

            formattedDate(date) {
                return new Date(date).toLocaleDateString();
            },
        },
    };
</script>

<style lang="scss" scoped>
.schedule-composition-container {
    flex: 1;
    div.specific-date {
        flex: 1;
        border: 1px solid #dfdfdf;
        padding: 2px 8px;
        margin: 2px 5px;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        > div:first-child {
            margin-left: 6px;
        }
        div.date-container {
            flex: 0.9;
            flex-basis: 95%;
            margin-left: 5px;
            > div:first-child {
                margin: 0px 5px;
                flex-basis: 100%;
                .zmdi-calendar {
                    line-height: 19px;
                    margin: 0px 5px 0px -3px;
                }
            }
            > div {
                margin: 0px 0px 0px 28px;
                flex-basis: 100%;
            }
            > div:last-child {
                display: flex;
                justify-content: left;
            }
        }
        div.time-container {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 100%;
            .action {
                margin-left: auto;
                button {
                    border: 0px none;
                    border-radius: 3px;
                    padding: 0px 4px;
                    background: none;
                }
            }
            > div:first-child {
                margin: 0px 5px;
                flex-basis: 100%;
                .zmdi-time {
                    line-height: 19px;
                    margin-right: 5px;
                }
            }
            > div {
                margin: 0px 0px 0px 28px;
                flex-basis: 100%;
            }
            > div:last-child {
                display: flex;
                justify-content: right;
            }
        }
    }
}
</style>
