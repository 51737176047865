<template>
  <div class="actve-alert-container">
    <div class="actve-alert-header">
      <ActiveAlertHeader :alertCount="custom_alerts.length" />
    </div>
    <div class="actve-alert-items" v-if="custom_alerts.length > 0">
      <ActiveAlertItem
        v-for="custom_alert in custom_alerts"
        :custom_alert="custom_alert"
        :key="custom_alert.id"
        @deleted="getActiveAlerts"
      />
    </div>
    <div class="actve-alert-items" v-else>
      <span>{{ $t("custom_alert.no_data") }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CustomAlertService from "../../services/CustomAlertService";
import ActiveAlertItem from "./ActiveAlertItem";
import ActiveAlertHeader from "./ActiveAlertHeader";

export default {

  data() {
    return {
      custom_alerts: [],
    };
  },

  mounted() {
    this.getActiveAlerts();
  },
  
  methods: {
    getActiveAlerts: function () {
      CustomAlertService.list()
        .then((response) => {
          this.custom_alerts = response.data;
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
    formatTime: function (time) {
      return moment(time).format("DD-MMM-YYYY h:mm A zz");
    },
  
    download_file: function (download_url) {
      let iframe = document.createElement("iframe");
      iframe.id = "hiddenDownloader";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.src = download_url;
    },
  },
  components: { ActiveAlertItem, ActiveAlertHeader },
};
</script>
<style scoped>
.actve-alert-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.actve-alert-header {
  display: flex;
  flex: 0;
}
.actve-alert-items {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  overflow: scroll;
  flex-direction: column;
}
</style>
