<template>
    <div class="thumbnail-card card rounded shadow-sm m-2">
        <div class="card-header thumbnail-header-main">
            <div class="thumbnail-header-left">
                <div class="thumbnail-header-left-infobox">
                    <span
                        class="title-font"
                        v-b-tooltip.hover
                        :title="channel.name"
                    >
                        {{ channel.name }}
                    </span>
                    <br />
                    <span class="body-font-dimmed">
                        {{ $t("channel.created_at") }}
                        {{ formatTime(this.channel.created_at) }}
                    </span>
                </div>

                <div class="thumbnail-header-right">
                    <dropdown-links
                        :link_id="link_id"
                        :dropdownLinks="dropdownLinkOptions"
                        @click="listenAction"
                    />
                </div>
            </div>
        </div>
        <div class="thumbnail-preview-container">
            <channel-thumbnail :channel="channel" />
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import DropdownLinks from "../components/DropdownLinks";
    import ChannelThumbnail from "./ChanneThumbnail.vue";
    export default {
        props: {
            channel: {
                type: Object,
            },
        },

        components: {
            DropdownLinks,
            ChannelThumbnail,
        },

        computed: {
            link_id: function () {
                return `${this.channel.id}`;
            },
            dropdownLinkOptions: function () {
                return [
                        {
                            name: "Edit",
                            icon_class: "zmdi-edit zmdi",
                            link_class: "",
                            action: "edit",
                        },
                        {
                            name: "Clone",
                            icon_class: "zmdi-copy zmdi",
                            link_class: "",
                            action: "duplicate",
                        },
                        {
                            name: "Delete",
                            icon_class: "zmdi-delete zmdi danger",
                            link_class: "text-danger",
                            action: "delete",
                        },
                    ];
            },
        },

        mounted() {},

        methods: {
            formatTime: function (time) {
                return moment(time).format("DD-MMM-YYYY");
            },
            listenAction: function (link) {
                let options = { channel: this.channel, link: link };
                this.$emit("click", options);
            },
        },
    };
</script>
