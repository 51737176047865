<template>

    <div>
        <form ref="form" method="post" @submit.prevent="validate">
            <h4 class="form-signin-heading">{{$t('login.reset_password.set_new_password_alert_title')}}</h4>

            <div class="form-group">
                <input type="password"
                       class="form-control"
                       id="password"
                       :placeholder="$t('login.reset_password.new_password_placeholder')"
                       name="user[password]"
                       v-validate="'required|min:8'"
                       data-vv-name="password"
                       data-vv-as="password"
                       ref="password"
                       :class="{'is-invalid': errors.has('password') }">

                <small v-show="!errors.has('password')" class="text-muted">{{$t('login.sign_up.password_complexity_message')}}</small>
                <span v-show="errors.has('password')" class="invalid-feedback">{{ errors.first('password') }}</span>
            </div>
            <div class="form-group">
                <input type="password"
                       class="form-control"
                       id="confirm_password"
                       :placeholder="$t('login.reset_password.confirm_new_password_placeholder')"
                       name="user[password_confirmation]"
                       v-validate="'required|min:8|confirmed:password'"
                       data-vv-name="conf_pwd"
                       data-vv-as="password"
                       :class="{'is-invalid': errors.has('conf_pwd')}">
                <span class="invalid-feedback" v-show="errors.has('conf_pwd')">{{ errors.first('conf_pwd') }}</span>
            </div>
            <button class="btn btn-lg btn-primary btn-block" type="submit">{{$t('login.reset_password.change_password_button')}}</button>
        </form>
    </div>

</template>

<script>
    import Csrf from '../utils/Csrf';

    export default {
        props: {
            password_token: {
                type: String,
                required: true
            }
        },

        data () {
            return {
                result: null,
            }
        },

        mounted () {

        },

        methods: {
            onValidationSuccess () {
                let formData = new FormData(this.$refs.form);
                formData.append('user[reset_password_token]', this.password_token)
                Csrf.append(formData);

                this.$http
                    .patch('/users/password/', formData)
                    .then(response => {
                      // redirect to home page
                      window.location.href = '/';
                    })
                    .catch(error => {
                        if (error.response) {
                            // HTTP error
                            let data = error.response.data;
                            if (data.error) {
                                this.result = data.error;
                            }
                        } else if (error.request) {
                            this.result = 'No Network available.';
                        } else {
                            this.result = error.message;
                        }
                    })
            },

            /**
             * Performs validation before the form gets submitted. For it to work, it needs to be
             * set as the event handler like this `@submit.prevent="validate"`
             */
            validate () {
                this.$validator.validateAll().then((result) => {
                    return result ? this.onValidationSuccess() : this.onValidationFailure();
                });
            },

        }
    }
</script>
<style>

</style>
