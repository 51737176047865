import EventBus from "../../../../core/event-bus";

export default {
  props: {
    element: {
      type: Object,
    },
    index: 0,
  },
  methods: {
    onChange: function() {
      EventBus.$emit("form_element_input_change");
    },
  },
};
