import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import BillingHome from './BillingHome';
import SubscriptionHome from './subscription/SubscriptionHome';

let routes = [{
	path: '/user/billings/:tab?',
	name: 'billing',
	component: BillingHome,
	props: castStringRouteParams
},
{
	path: '/user/subscription/',
	name: 'subscription',
	component: SubscriptionHome
}];

function castRouteParams(route) {
  return {
    subscription_id: Number(route.params.subscription_id),
  };
}

function castStringRouteParams(route) {
  return {
    tab: route.params.tab,
  };
}
export default routes;
