<template>
    <div class="wizard-header-right-section">
        <b-button
            class="primary-action-button save"
            variant="success"
            @click="save"
            :tabindex="this.widget.widget_form_elements.length + 3"
        >
            {{
                this.new_content.id == null
                    ? $t('common_words.save')
                    : $t('common_words.update')
            }}
        </b-button>

        <b-button
            :tabindex="this.widget.widget_form_elements.length + 4"
            class="primary-action-button cancel"
            @click="cancelButtonPressed"
            variant="danger"
        >
            {{ $t('common_words.cancel') }}
        </b-button>
    </div>
</template>

<script>
    import ContentService from '../../../services/ContentService'
    import WidgetService from '../../../services/WidgetService'
    import EventBus from "../../../core/event-bus";
    import Csrf from "../../../utils/Csrf";
    import axios from "axios";
    import DirectUploadWithPresign from "../../../upload/mixins/DirectUploadWithPresign"

    export default {
        props: {
            canvas: { type: Object, default: {} },
            content: { type: Object, default: {} },
            widget: { type: Object, default: {} },
            layout: { type: String, defult: null }
        },

        data() {
            return {
                new_content: this.content,
                file: null
            }
        },

        computed: {
            btn_title: function () {
                let title = 'Save'
                if (this.content.id == null) {
                    title = 'Create'
                }
                return title
            }
        },

        mixins: [DirectUploadWithPresign],

        methods: {
            async save() {
                let _this = this
                this.new_content.widget_id = this.widget.id
                this.new_content.content_type = 'image/svg+xml'
                this.new_content.metadata = this.getMetadata()
                this.show_loader = true
                if(this.new_content.name == null || this.new_content.name == '') {
                    this.$emit("change", false)
                    return false
                }
                let resp = null
                this.file = this.buildFile()
                if (this.new_content.id != null) {
                    resp = await this.uploadFile(this.file, 0, this.update)
                } else {
                    resp = await this.uploadFile(this.file, 0, this.create)
                }
            },

            create: function (blob_signed_id) {
                let _this = this
                this.new_content['file'] = blob_signed_id
                this.new_content['content_type'] = this.file.type
                ContentService.create(this.new_content)
                    .then((response) => {
                        this.$eventBus.$emit('alert', {
                            variant: 'success',
                            message: _this.$t('common_words.success_msg')
                        })
                        _this.show_loader = true
                        _this.closeThisScreen()
                    })
                    .catch((error) => {
                        _this.handleError(error)
                    })
            },

            update: function (blob_signed_id) {
                let _this = this
                this.new_content['file'] = blob_signed_id
                this.new_content['content_type'] = blob_signed_id
                ContentService.update(this.new_content.id, this.new_content)
                    .then((response) => {
                        this.$eventBus.$emit('alert', {
                            variant: 'success',
                            message: _this.$t('common_words.success_msg')
                        })
                        _this.new_content = response.data
                        _this.show_loader = true
                        _this.closeThisScreen()
                    })
                    .catch((error) => {
                        _this.handleError(error)
                    })
            },
            buildFile: function () {
                let blob = this.exportFile()
                blob.name = 'content.svg'
                return new File([blob], 'content.svg')
            },

            getMetadata: function () {
                let metadata = {}
                metadata['json'] = this.canvas.toJSON()
                metadata['layout'] = this.layout
                return metadata
            },

            exportFile: function () {
                var svg = this.canvas.toSVG()
                svg = svg.replace(
                    'xml:space="preserve"',
                    'preserveAspectRatio="none"'
                )
                return new Blob([svg], { type: 'image/svg+xml' })
            },

            cancelButtonPressed: function () {
                this.$router.back()
            },

            closeThisScreen: function () {
                if (this.content.id == null) {
                    // this.$router.back(); //this won't work due to iFrame(preview) history gets created on src change
                    this.$router.push({ name: 'templates' })
                } else {
                    this.$router.push({ name: 'library' })
                }
            }
        },

        watch: {
            contents: {
                handler: function (new_content, old_content) {
                    if (new_content.metadata['layout'] != null) {
                        this.layout = this.new_content.metadata['layout']
                    }
                },
                deep: true
            }
        }
    }
</script>
