<template>
    <div class="form-element-container">
        <label class="form-element-label">
            {{ $t(element.field_label_text) }}
        </label>
        <div class="form-element-control-container">
            <div class="form-element-required-container">
                <span class="form-checkbox">
                    <input
                        type="checkbox"
                        class="form-control form-checkbox"
                        :tabindex="this.index"
                        v-model="element.model"
                        @change="set_value"
                    />
                </span>
                <span
                    v-if="isRequiredElement"
                    class="form-element-required-text"
                    >*</span>
            </div>
            <span v-if="isValid == false" class="text-danger">
                {{ $t(element.validation_error_message) }}
            </span>
        </div>
    </div>
</template>

<script>
    import Element from "./helper/Element";
    import EventBus from "../../../core/event-bus";
    import { isElmentInputValid } from "./helper/validations";

    export default {
        mixins: [Element],

        data() {
            return {
                isValid: true,
            };
        },
        computed: {
            isRequiredElement: function () {
                return this.element.validation === "required";
            },
        },
        created: function () {
            let _this = this;
            EventBus.$on("validate_widget_form", function () {
                _this.checkValidation();
            });
        },

        methods: {
            checkValidation: function () {
                this.isValid = isElmentInputValid(this.element);
            },
            set_value: function(){
                if(this.element.model == null){
                    this.element.model = false
                }
            }
        },

    };
</script>

<style lang="scss" scoped>
.form-checkbox {
    font-family: system-ui, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    border: 1px solid #ced4da;
    border-radius: 0.4rem;
    overflow: hidden;
    
    input[type="checkbox"] {
        width: 28px;
        height: 28px;
        margin: -2px;
    }
}
</style>
