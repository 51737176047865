<template>
    <div class="media-action">
        <div class="media-action-1">
            <i class="zmdi zmdi-format-line-spacing reorder-handler-icon"></i>
        </div>
        <div class="media-action-2">
            <button class="btn btn-sm" @click="deleteMedia">
                <i class="zmdi zmdi-delete"></i>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            media: {
                type: Object,
                default: {
                    id: null,
                    content_id: null,
                    resize_mode: "cover",
                    duration: 0,
                    position: 0,
                    object: {},
                },
            },
        },

        data() {
            return {
                success_message: "",
                error: null,
            };
        },

        methods: {
            deleteMedia: function () {
                this.$set(this.media, "_destroy", true);
                this.$emit("delete");
            },
        },
    };
</script>
<style lang="scss">
.a-width {
    width: 50px !important;
}
.media-action {
    align-items: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    .media-action-1 {
        flex: 1 0.5;
        justify-content: center;
        display: flex;
    }
    .media-action-2 {
        flex: 1 0.5;
        justify-content: right;
        display: flex;
    }
}
</style>
