export const getSortingLabel = (sort_value) => {
    if (sort_value == "name") {
        return "Name"
      }else if (sort_value == "updated_at") {
        return "Last updated"
      }else if (sort_value == "device_name") {
        return "Name"
      }else if (sort_value == "last_seen") {
        return "Last seen"
      }else {
        return "Unknown"
      }
}