<template>
  <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <b-tabs
      card
      v-model="tabIndex"
      active-nav-item-class="font-weight-bold text-uppercase h-100"
      nav-class="col-lg-10"
      content-class="col-lg-12 p-0"
    >
      <b-tab class="p-0 h-100">
        <template #title>
          {{ $t("content.title") }} ({{ contents_count }})
        </template>
        <library-contents
          ref="contents"
          @click="showPreview"
          :isDeletedContentsTab="false"
          :queryParam="contentsQueryParam"
          :key="contentsTabKey"
        />
      </b-tab>

      <b-tab class="p-0 h-100">
        <template #title>
          <i class="zmdi-delete zmdi danger">
            {{ $t("content.deleted_content_title") }} ({{
              deleted_contents_count
            }})
          </i>
        </template>

        <library-contents
          ref="contents_deleted"
          @click="showPreview"
          :isDeletedContentsTab="true"
          :queryParam="deletedContentQueryParam"
          :key="deletedContentsTabKey"
        />
      </b-tab>
    </b-tabs>

    <div class="col-8 filters d-flex align-items-center justify-content-end">
      <search media_type="Content" @change="searchTextChanged" />
      <contents-sorting @change="sortingChanged" />
      <b-button
        variant="primary"
        class="float-right ml-2"
        @click="showUploadSelection()"
      >
        {{ $t("content.upload_btn") }}
      </b-button>
    </div>

    <upload-selection />
    <content-previewer />
  </div>
</template>

<script>
import EventBus from "../core/event-bus";
import ContentPreviewer from "../Previewer/ContentPreviewer";
import NoContent from "../components/NoContent";
import LibraryContents from "./LibraryContents";
import Search from "../components/Search";
import UploadSelection from '../upload/UploadSelection.vue';
import ContentsSorting from './ContentsSorting.vue';
import {
  getContentsSortingField,
  getContentsSortingOrderBy,
} from "../core/UserPreferences";

export default {
  props: {},

  data() {
    return {
      files: [],
      tabIndex: 0,
      searchText: "",
      contents_count: null,
      deleted_contents_count: null,
      contentsTabKey: true,
      deletedContentsTabKey: true,
      contentsQueryParam: {
        deleted: false,
        order: getContentsSortingField(),
        order_by: getContentsSortingOrderBy(),
        page: 1,
        query: "",
      },
      deletedContentQueryParam: {
        deleted: true,
        order: getContentsSortingField(),
        order_by: getContentsSortingOrderBy(),
        page: 1,
        query: "",
      },
    };
  },
  watch: {
    tabIndex: {
      immediate: false,
      handler(val, oldVal) {
        this.queryParamChanged();
      },
    },
    searchText: {
      immediate: false,
      handler(val, oldVal) {
        this.contentsQueryParam["query"] = this.searchText;
        this.deletedContentQueryParam["query"] = this.searchText;
        this.queryParamChanged();
      },
    },
  },
  components: {
    File,
    NoContent,
    LibraryContents,
    ContentPreviewer,
    Search,
    UploadSelection,
    ContentsSorting,
  },

  created: function () {
    let _this = this;
    EventBus.$on("contentsCountUpdated", function (contents_count) {
      _this.update_contents_count(contents_count);
    });
    EventBus.$on(
      "deletedContentsCountUpdated",
      function (deleted_contents_count) {
        _this.update_deleted_contents_count(deleted_contents_count);
      }
    );
  },

  beforeDestroy() {
    EventBus.$off("contentsCountUpdated");
    EventBus.$off("deletedContentsCountUpdated");
  },

  methods: {
    update_contents_count: function (contents_count) {
      this.contents_count = contents_count;
    },

    update_deleted_contents_count: function (deleted_contents_count) {
      this.deleted_contents_count = deleted_contents_count;
    },

    searchTextChanged(searchText) {
      this.searchText = searchText;
    },

    sortingChanged(sort_option) {
     
      this.contentsQueryParam["order"] = sort_option.order.value;
      this.contentsQueryParam["order_by"] = sort_option.order_by;

      this.deletedContentQueryParam["order"] = sort_option.order.value;
      this.deletedContentQueryParam["order_by"] = sort_option.order_by;

      this.queryParamChanged();
    },

    queryParamChanged() {
      if (this.tabIndex == 0) {
        this.contentsTabKey = !this.contentsTabKey;
      } else {
        this.deletedContentsTabKey = !this.deletedContentsTabKey;
      }
    },

    showPreview: function (content) {
      EventBus.$emit("showModel", content);
    },

    showUploadSelection: function () {
      EventBus.$emit("showUploadSelectionModel");
    },
  },
};
</script>
<style scoped>
.filters {
  position: absolute;
  top: 6px;
  right: 10px;
}
</style>
