<template>
<span class="m-2" @click="showModal()">
    <button class="btn btn-outline-secondary p-2">
        <span class="text-primary">Load JSON</span>
    </button>
    <b-modal id="canvasJsonForm" class="modal layout-modal" size="lg" title="Load JSON Data">
        <div class="layout-container col-12 p-4 ">
            <label> JSON Data</label>
            <b-form-textarea class="form-control input-sm" v-model="json_data" rows="7"></b-form-textarea>
        </div>
        <div slot="modal-footer" class="w-100 text-right">
            <b-button size="sm" variant="primary" @click="loadData()">
                {{$t('common_words.load')}}
            </b-button>
            <b-button size="sm" variant="outline-default" @click="hideModal()">
                {{$t('common_words.cancel')}}
            </b-button>
        </div>
        </div>
    </b-modal>

</span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        canvas: { type: Object, default: {} },

    },

    data() {
        return {
            json_data: ""
        }
    },

    computed: {
        ...mapGetters('userStore', ['is_admin']),
    },

    methods: {
        loadData: function () {
            if (this.json_data != '') {
                this.canvas.loadFromJSON(this.json_data)
            }else{
                this.canvas.clear()
            }
            this.hideModal()
        },

        showModal: function () {
            this.json_data = JSON.stringify(this.canvas.toJSON())
            this.$bvModal.show("canvasJsonForm")
        },

        hideModal: function () {
            this.$bvModal.hide("canvasJsonForm")
        }

    }
}
</script>

<style lang="scss" scoped>
#canvasJsonForm___BV_modal_outer_ {
    z-index: 1050 !important;
}
</style>
