import EventBus         from '../core/event-bus'
export default {
	methods: {
		handleError: function(error) {
			if (error.response && error.response.status != 402) {
				// HTTP error
				let data = error.response.data;
				if (data.error) {
					this.error = data.error;
				}
				if (data.errors) {
					this.error = Object.keys(data.errors).map(function(v) { return data.errors[v]; }).join(",");
				}
			} else if (error.request) {
				this.error = 'Internet connection not available.';
			}
			if( this.error){
				EventBus.$emit('alert', {variant: 'danger', message: this.error})
			}
		}
	}
}
