<template>
<div class="col-lg-12 d-flex p-0 bg-light h-100">
    <div class="col-lg-6 float-left m-auto">
        <div class="col-lg-12 text-center">
            <i class="question circle outline icon"></i>
            {{$t('screens.how_to_enroll_title')}}
        </div>
        <div class="col-lg-12 text-center">
            {{$t('screens.step_1')}}
            <h5 class="m-0 p-2">{{$t('common_words.or')}}</h5>
            {{$t('screens.step_2')}}
        </div>
    </div>
    <div class="col-lg-6 float-left d-flex border-left p-3">
        <div v-show="group != null" class="m-auto text-center">
            <qrcode :value="this.enrollment_token" :size='300' />
            <a id="copyToken" href="javascript:void(0)" class="btn btn-link mt-2" data-tooltip="Copy token to clipboard" @click="copyToClipboard">
                {{this.enrollment_token}}
                <i class="zmdi zmdi-copy"></i><br />
            </a>
        </div>
    </div>
    <b-alert variant="success" :show="dismissCountDown" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
        {{$t('common_success_messages.qr_code_copied')}}
    </b-alert>
</div>
</template>

<script>
import GroupService from '../services/GroupService';
import Qrcode from 'qrcode.vue';
export default {

    data() {
        return {
            tab_index: 1,
            group: null,
            copy_success: false,
            dismissSecs: 4,
            dismissCountDown: 0,
            showDismissibleAlert: false
        }
    },

    components: {
        Qrcode
    },

    computed: {
        enrollment_token() {
            return this.group == undefined ? '' : this.group.enrollment_token
        }
    },

    mounted() {
        let _this = this;
        this.getGroups()

    },

    methods: {
        copyToClipboard() {
            let temp = document.createElement("input");
            document.getElementsByTagName('body')[0].append(temp);
            temp.value = this.enrollment_token
            temp.select();
            document.execCommand("copy");
            temp.remove();
            this.showAlert()
        },

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        getGroups() {
            GroupService.default_group()
                .then(response => {
                    _this.group = response.data;
                })
                .catch(error => {
                    this.handleError(error)
                })
        }
    }
}
</script>
