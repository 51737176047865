import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Faqs from './Faqs';

let routes = [{
	path: '/support',
	name: 'support',
	component: Faqs
}];


export default routes;
