import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const BrandingService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('user/brandings.json' + query_string);
	},

	get(id) {
		return Http.get('user/brandings/' + id + '.json');
	},

	create(params) {
		let formData = new FormData();
		delete(params['logo_url'])
		if(params['file'] != null){
			formData.append("branding[logo]", params['logo']);
		}
		for ( let key in params ) {
			formData.append("branding[" + key + "]", params[key]);
		}
		return Http.post('user/brandings.json', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},

	update(id, params) {
		let formData = new FormData();
		if(params['logo_url'] == ''){
			formData.append("branding[delete_logo]", true);
		}
		delete(params['logo_url'])
		for ( let key in params ) {
			formData.append("branding[" + key + "]", params[key]);
		}
		return Http.put('user/brandings/' + id + '.json', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},

	destroy(id) {
		return Http.delete('user/brandings/' + id + '.json');
	}

};

export default BrandingService;
