<template>
  <div>
    <b-button
      class="btn float-left btn-secondary btn-sm mr-3"
      @click.prevent="show_modal($event)"
    >
      <i class="zmdi zmdi-edit"></i>
    </b-button>
    <b-button
      class="btn float-left btn-secondary btn-sm"
      @click.prevent="delete_branding($event)"
    >
      <i class="zmdi zmdi-delete"></i>
    </b-button>
  </div>
</template>

<script>
import BrandingService from "../services/BrandingService";
import { showDeleteConfirmation } from "../utils/DeleteConfirmation";

export default {
  props: {
    branding: {
      type: Object,
      default: {
        id: null,
        filename: "",
        size: "",
        img_url: "",
        content_type: "",
      },
    },
  },

  data() {
    return {
      success_message: "",
      error: null,
    };
  },

  methods: {
    show_modal(event) {
      event.stopPropagation();
      this.$emit("edit");
    },

    delete_branding(event) {
      event.stopPropagation();
      let _this = this;
      showDeleteConfirmation(
        _this,
        _this.$t("confirmation_alert.branding_delete_message")
      ).then((result) => {
        if (result.value) {
          _this.$emit("progress", true);
          BrandingService.destroy(this.branding.id)
            .then((response) => {
              _this.$emit("deleted");
            })
            .catch((error) => {
              this.handleError(error);
            });
        } else {
        }
      });
    },
  },
};
</script>
