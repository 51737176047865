<template>
  <div class="logged-in-view-container">
    <div class="provider-logo-section">
      <img :src="logo" width="24" height="24" />
    </div>

    <div class="loggedin-info-section">
      <span class="email-text">{{this.email}}</span>
      <span class="calendar-text">{{ this.calendarName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    calendarName: {
      type: String,
    },
    email: {
        type: String,
    },
    provider: {
      type: String,
    },
  },
  computed: {
    logo: function() {
        if(this.provider == "google_oauth2") {
            return require("../../../../images/third_party/google-small.png");
        }else {
            return require("../../../../images/third_party/microsoft-small.png");
        }
    },
  },
};
</script>

<style scoped>
.logged-in-view-container {
  display: flex;
  flex: 1;
  border: 1px solid rgb(196, 193, 193);
  border-radius: 4px;
}
.provider-logo-section {
  display: flex;
  flex: 0.15;
  align-items: center;
  justify-content: space-around;
  border-right: 1px solid rgb(196, 193, 193);

}
.loggedin-info-section {
  display: flex;
  flex: 0.85;
  flex-direction: column;
  padding-left: 8px;
}
.email-text {
    font-weight: 500;
    color: black;
}
.calendar-text {
    color: rgb(91, 91, 91);
    font-size: 16px;
}
</style>
