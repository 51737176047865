<template>
    <b-list-group-item class="p-0">
        <b-button
            variant="outline"
            @click="drawArrow"
            :class="{ btn_selected: this.isDraw['arrow'] }"
        >
            <i class="zmdi zmdi-arrow-right"></i>
            <div>Arrow</div>
        </b-button>
    </b-list-group-item>
</template>

<script>
    import Drawing from '../Drawing.js'

    export default {
        props: {
            canvas: { type: Object, default: {} },
            properties: { type: Object, default: {} },
            selectedTool: { type: String, default: null }
        },

        data() {
            return {
                isDraw: {
                    arrow: false
                },
                objType: 'arrow'
            }
        },

        mixins: [Drawing],

        methods: {
            drawArrow: function () {
                let fromx, fromy, tox, toy
                let _this = this
                this.cancelDraw()
                this.isDraw[this.objType] = true
                this.$emit('changed', this.objType)
                this.canvas.on('mouse:down', function (event) {
                    let pointer = _this.canvas.getPointer(event.e)
                    fromx = pointer.x
                    fromy = pointer.y
                })
                this.canvas.on('mouse:up', function (event) {
                    let pointer = _this.canvas.getPointer(event.e)
                    tox = pointer.x
                    toy = pointer.y
                    //this.drawArrow(startX, startY, endX, endY);

                    let angle = Math.atan2(toy - fromy, tox - fromx)

                    let headlen = 10 // arrow head size

                    // bring the line end back some to account for arrow head.
                    tox = tox - headlen * Math.cos(angle)
                    toy = toy - headlen * Math.sin(angle)

                    // calculate the points.
                    let points = [
                        {
                            x: fromx, // start point
                            y: fromy
                        },
                        {
                            x:
                                fromx -
                                (headlen / 4) * Math.cos(angle - Math.PI / 2),
                            y: fromy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
                        },
                        {
                            x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2),
                            y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2)
                        },
                        {
                            x: tox - headlen * Math.cos(angle - Math.PI / 2),
                            y: toy - headlen * Math.sin(angle - Math.PI / 2)
                        },
                        {
                            x: tox + headlen * Math.cos(angle), // tip
                            y: toy + headlen * Math.sin(angle)
                        },
                        {
                            x: tox - headlen * Math.cos(angle + Math.PI / 2),
                            y: toy - headlen * Math.sin(angle + Math.PI / 2)
                        },
                        {
                            x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
                            y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
                        },
                        {
                            x:
                                fromx -
                                (headlen / 4) * Math.cos(angle + Math.PI / 2),
                            y: fromy - (headlen / 4) * Math.sin(angle + Math.PI / 2)
                        },
                        {
                            x: fromx,
                            y: fromy
                        }
                    ]

                    let pline = new fabric.Polyline(points, {
                        fill: _this.properties.fill_color.hex8,
                        stroke: _this.properties.stroke_color.hex8,
                        opacity: 1,
                        strokeWidth: _this.properties.stroke_width,
                        originX: 'center',
                        originY: 'center',
                        selectable: true
                    })

                    _this.canvas.add(pline)
                    _this.canvas.setActiveObject(pline)
                    _this.isDown = false
                    _this.canvas.off('mouse:down').off('mouse:move').off('mouse:up')
                    _this.cancelDraw()
                    _this.canvas.renderAll()
                    // Push the this.canvas state to history
                    _this.canvas.fire('object:statechange')
                })
            }
        }
    }
</script>
