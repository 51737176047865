<template>
  <b-modal :id="this.getId()"
           title='Edit Content'
           @shown="showModal()">
      <form ref="form"  method="post" @submit.prevent="update()">
         <div class="form-group mb-0" v-if="error">
              <p class="text-danger text-center">{{error}}</p>
         </div>

         <div class="form-group email required user_email">
             <label> {{$t('playlist.duration')}} </label>
             <duration-input  class="form-control" v-model="duration"
                             :duration="playlist_content.duration"/>
         </div>

         <div class="form-group email required user_email">
              <label> {{$t('playlist.resize_mode')}} </label>
              <b-form-select v-model="resize_mode"
                          :options="options"/>
         </div>

      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" class="float-right ml-3" variant="success" @click="update()">
          {{$t('common_words.save')}}
        </b-button>
        <b-button size="sm" variant="" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import PlaylistService           from '../services/PlaylistService';
import DurationInput             from '../components/DurationInput';
import PlaylistContentService    from '../services/PlaylistContentService';
export default {

  props: {
    playlist_content: {
      type: Object,
      default:{
        id: null,
        playlist_id: null,
        resize_mode: 0,
        duration: 0,
        content: {}
      }
    }
  },



  data() {
    return {
      resize_mode: 0,
      duration: 0,
      success_message: "",
      error: null,
      options: ['cover', 'center', 'contain', 'stretch' ]
    }
  },

  components:{
    DurationInput
  },

  computed: {
    durationReadOnly(){
      if(this.playlist_content != null && this.playlist_content.content.content_type != null){
        return (this.playlist_content.content.content_type.startsWith("audio") ||
                this.playlist_content.content.content_type.startsWith("video"))
      }else {
        return false
      }
    },
  },

  methods: {
    getId() {
      return "edit_playlist_content" + this.playlist_content.id
    },

    showModal(){
      this.resize_mode = this.playlist_content.resize_mode
      this.duration = this.playlist_content.duration
    },

    hideModal() {
      this.$bvModal.hide(this.getId())
    },



    update(){
      let _this = this
      this.playlist_content.resize_mode = this.resize_mode
      this.playlist_content.duration = this.duration
      this.hideModal()
    },

  },
}
</script>
