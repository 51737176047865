<template>
  <b-modal id="edit_profile"
           :title="$t('user.update_profile_alert_title')"
           class="modal-container"
           @shown="showModal()">
      <form ref="profileform"  method="post" @submit.prevent="validate">
          <div class="form-group" v-if="error">
              <p class="text-danger text-center">{{error}}</p>
          </div>

          <div class="form-group email required user_email">
              <label class="form-control-label email required">
                     {{$t('common_words.email')}} <abbr title="required">*</abbr>
              </label>
              <input type="email"
                     class="form-control"
                     placeholder="Email"
                     v-model="user.email"
                     disabled>
           </div>

          <div class="form-group">
              <label for="firstName">{{$t('user.organization_name')}} </label>
              <input type="text"
                     v-model="user.org_name"
                     name="user[org_name]"
                     class="form-control"/>
          </div>

          <div class="form-group">
            <div class="form-group">
                 <label> {{$t('user.timezone')}} </label>
                 <b-form-select v-model="selected_timezone"
                             :options="this.options"/>
            </div>
          </div>

      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" variant="primary" @click="save()">
          {{$t('common_words.save')}}
        </b-button>
        <b-button size="sm" variant="outline-default" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import ProfileService            from '../services/ProfileService';
import LabelInput                from  '../components/LabelInput';
import Csrf                      from '../utils/Csrf';

export default {
  props: {
    user: {
      type: Object,
      default: {
        email: null,
        org_name: null,
        timezone: null
      }
    }
  },

  components: {
  },


  data() {
    return {
      success_message: "",
      error: null,
      selected_timezone: '',
      options: this.$moment.tz.names()
    }
  },

  mounted() {
    if(this.user.timezone == null){
      this.selected_timezone = this.$moment.tz.guess()
    }else{
      this.selected_timezone = this.user.timezone
    }
  },

  methods: {
    showModal: function(){

    },

    save(){
      let _this = this
      this.user.timezone = this.selected_timezone;
      this.$validator.validateAll().then((result) => {
          if(!result){
              return;
          }
          _this.update_profile()
      }).catch((error) => {
        _this.handleError(error)
      });

    },

    update_profile(){
      let _this = this
      ProfileService.update({user: this.user})
                  .then(response => {
                      _this.$store.dispatch('userStore/setUser', response.data)
                      _this.$moment.tz.setDefault(response.data.timezone)
                      window.location.reload()
                  })
                  .catch(error => {
                      _this.handleError(error)
                  })
    },


    hideModal() {
      this.$bvModal.hide('edit_profile')
    },
  },


}
</script>
