import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Home from './Home';

// export default {
// 	/**
// 	 * Installs all the routes with the Vue
// 	 *
// 	 * @param base - Base URL, by default it is /app/
// 	 * @returns {VueRouter} instance
// 	 */
// 	create(base = '/') {
// 		Vue.use(VueRouter);

let routes = [{
	path: '/',
	name: 'home',
	component: Home
}];

// 		return new VueRouter({
// 			mode: 'history',
// 			base: base,
// 			routes: routes,
// 			linkActiveClass: 'active'
// 		});
// 	}
// }

export default routes;
