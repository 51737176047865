<template>
  <b-modal :id="'add_device_' + group.id"
           :title="$t('add_device_dialog.add_group_title')"
           class="modal-container">
      <form ref="form"  method="post" @submit.prevent="save()">
          <div class="form-group" v-if="error">
              <p class="text-danger text-center">{{error}}</p>
          </div>
          <div class="form-group"
              :class="{'has-danger': errors.has('name') }">
              <label>
                {{$t('screens.name')}}
              </label>
              <input type="name"
                     class="form-control"
                     :placeholder="$t('screens.name_placeholder')"
                     v-model="device_name"
                     v-validate="'required'"
                     data-vv-name="device_name"
                     data-vv-as="device_name">

              <span v-show="errors.has('device_name')" class="text-danger">{{ errors.first('device_name') }}</span>
          </div>
          <div class="form-group"
              :class="{'has-danger': errors.has('device_otp') }">
              <label>
                {{$t('add_device_dialog.otp')}}
              </label>
              <input type="name"
                     class="form-control"
                     :placeholder="$t('screens.otp_placeholder')"
                     v-model="device_otp"
                     v-validate="'required'"
                     data-vv-name="device_otp"
                     data-vv-as="device_otp">

              <span v-show="errors.has('device_otp')" class="text-danger">{{ errors.first('device_otp') }}</span>
          </div>
      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" variant="primary" @click="save()">
          {{$t('common_words.add')}}
        </b-button>
        <b-button size="sm" variant="outline-default" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import GroupService    from '../services/GroupService';
export default {

  props:{
    group: null
  },

  data() {
    return {
      device_otp: '',
      device_name: '',
      preview_mode: false,
      success_message: "",
      error: null
    }
  },

  methods: {
    save(){
      let _this = this
      this.$validator
          .validateAll()
          .then((result) => {
              if(!result){
                _this.error = "The OTP seems to be invalid"
                  return;
              }
              _this.add_device()
          }).catch((error) => {
            _this.handleError(error)
          });

    },

    add_device(){
      let _this = this
      GroupService.add_device(this.group.id,{name: this.device_name, device_otp: this.device_otp, preview_mode: this.preview_mode})
                  .then(response => {
                      _this.$eventBus.$emit('alert', {variant: 'success', message: _this.$t('add_device_dialog.device_added_succes') } )
                      _this.$emit("created")
                      _this.hideModal()
                  })
                  .catch(error => {
                      _this.handleError(error)
                  })
    },

    hideModal() {
      this.resetForm()
      this.$bvModal.hide("add_device_" + this.group.id)
    },

    resetForm() {
      this.device_otp = ''
      this.success_message = ''
      this.error = null
    },

  },
}
</script>
