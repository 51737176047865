<template>
  <div v-if="isLoadingComleted" class="wizard-main-container">
    <div class="wizard-header">
      <div class="wizard-header-left-section">
        <span class="app-name-text">{{ $t(this.widget.title) }}</span>
        <input
          v-model="instanceName"
          :class="{ 'instance-invalid-name': this.isValideName == false }"
          class="instance-name-textfield"
          type="text"
          tabindex="1"
          autofocus
          :placeholder="
            isValideName
              ? $t('widget.content_name_placeholder')
              : $t('widget.content_invalid_name_placeholder')
          "
        />
      </div>
      <div class="wizard-header-right-section">
          <b-button
            class="primary-action-button save"
            variant="success"
            @click="saveInstance"
            :tabindex="this.widget.widget_form_elements.length + 3"
          >
            {{ this.shouldCreateNewInstance == true ? $t("common_words.save") : $t("common_words.update") }}
          </b-button>

          <b-button
          :tabindex="this.widget.widget_form_elements.length + 4"
          class="primary-action-button cancel"
          @click="cancelButtonPressed"
          variant="danger"
        >
          {{$t('common_words.cancel')}}
        </b-button>
        
      </div>
    </div>
    <div class="wizard-content">
      <div class="form-section">
        <common-form-widget
          :form_elements="this.elements"
          :brandingModel="this.brandingModel"
          :widgetName="this.widget.name"
          ref="commonFormWidget"
        />
      </div>
      <div class="preview-section">
        <wizard-previewer :clientPreviewURL="this.clientPreviewURL" />
      </div>
    </div>
  </div>
</template>

<script>
import CommonFormWidget from "./components/CommonFormWidget.vue";
import WizardPreviewer from "./components/WizardPreviewer.vue";
import EventBus from "../core/event-bus";
import BrandingService from "../services/BrandingService";
import ContentService from "../services/ContentService";
import WidgetService from "../services/WidgetService";
import {ElementDefaultValue} from "./components/form_elements/helper/ElementDefaultValue"
import Swal from "sweetalert2";

export default {
  components: { CommonFormWidget, WizardPreviewer },
  data() {
    return {
      isLoadingComleted: false,
      clientPreviewURL: "about:blank",
      instanceName: "",
      isValideName: true,
      instanceId: "",
      brandingModel: {
        type: Object,
      },
      widget: {
        type: Object,
      },
      content: {
        type: Object,
      },
      shouldCreateNewInstance: true,
      elements: {
        type: Array,
      },
    };
  },
  created() {
    let _this = this;
    const contentId = this.$route.params.content_id;
    if (contentId == null) {
      const widgetId = this.$route.params.widget_id;
      WidgetService.show(widgetId)
        .then((response) => {
          _this.widget = response.data;
          _this.shouldCreateNewInstance = true;
          _this.createFormElements();
        })
        .catch((error) => {
          _this.handleError(error);
        });
    } else {
      ContentService.show(contentId)
        .then((response) => {
          _this.content = response.data;
          _this.widget = response.data.widget;
          _this.instanceName = _this.content.name;
          _this.shouldCreateNewInstance = false;
          _this.createFormElements();
        })
        .catch((error) => {
          _this.handleError(error);
        });
    }
  },
  mounted() {
    let _this = this;
    EventBus.$on("branding_element_mounted", function() {
      _this.getClientPreviewURL();
    });
     EventBus.$on("form_element_input_change", function() {
      _this.getClientPreviewURL();
    });
  },
  beforeDestroy() {
    EventBus.$off("branding_element_mounted");
    EventBus.$off("form_element_input_change");
  },
  computed: {
    isValidInstanceName: function() {
      if (this.instanceName != null && this.instanceName.length > 0) {
        this.isValideName = true;
        return true;
      } else {
        this.isValideName = false;
        return false;
      }
    },
  },
  methods: {
    createFormElements: function() {    
      var arrElementsWithModel = [];
      for (
        var i = 0, len = this.widget.widget_form_elements.length;
        i < len;
        i++
      ) {
        var elementWithModel = this.widget.widget_form_elements[i];
        if (this.shouldCreateNewInstance) {
          const field_name = elementWithModel.element_type.replaceAll('-', '');
          const defaultValue = ElementDefaultValue[field_name](elementWithModel.default_value);
          elementWithModel["model"] = defaultValue;
        } else {
          if (elementWithModel.is_composite_element) {
            elementWithModel["model"] = this.content.metadata;
          } else {
            elementWithModel["model"] = this.content.metadata[
              elementWithModel.field_name
            ];
          }
        }
        arrElementsWithModel.push(elementWithModel);
      }
      arrElementsWithModel.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
      this.elements = arrElementsWithModel
      this.getBrandings();
    },
    cancelButtonPressed: function() {
      this.closeThisScreen();
    },
    getClientPreviewURL: function() {
      let _this = this;
      if (
        this.$refs.commonFormWidget != null &&
        this.$refs.commonFormWidget.isValidFormInputs()
      ) {
        let url = null;
        let apiurl = new URL(this.widget.data_api_url);
        for (var i = 0, len = this.elements.length; i < len; i++) {
          const element = this.elements[i];
          if (element.is_composite_element) {
            Object.keys(element.model).forEach(function(key) {
              apiurl.searchParams.append(
                "metadata_hash[" + key + "]",
                encodeURIComponent(element.model[key])
              );
            });
          } else {
            if (element.model != null) {
              apiurl.searchParams.append(
                "metadata_hash[" + element.field_name + "]",
                encodeURIComponent(element.model)
              );
            }
          }
        }
        apiurl.searchParams.append("branding_id", this.brandingModel.model);
        apiurl.searchParams.append("widget_id", this.widget.id);
        url = this.widget.app_url + "&apiurl=" + encodeURIComponent(apiurl);
        this.clientPreviewURL = url;
      }
    },

    saveInstance: function() {
      EventBus.$emit("validate_widget_form");
      if (
        this.$refs.commonFormWidget.isValidFormInputs() &&
        this.isValidInstanceName
      ) {
        var content_data = {};
        var meta_data = {};
        for (var i = 0, len = this.elements.length; i < len; i++) {
          const element = this.elements[i];
          if (element.is_composite_element) {
            Object.assign(meta_data, element.model);
          } else {
            meta_data[element.field_name] = element.model;
          }
        }
        content_data.widget_id = this.widget.id;
        content_data.name = this.instanceName;
        content_data.branding_id = this.brandingModel.model;
        content_data.metadata = meta_data;
        if (this.shouldCreateNewInstance) {
          this.createNewInstance(content_data);
        } else {
          this.updateInstance(this.content.id, content_data);
        }
      } else {
        console.log("form validation failed!!!!");
      }
    },
    createNewInstance: function(content_data) {
      let _this = this;
      ContentService.create(content_data)
        .then((response) => {
          this.$eventBus.$emit("alert", {
            variant: "success",
            message: _this.$t("widget.instance_created"),
          });
          _this.closeThisScreen();
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
    updateInstance: function(content_id, content_data) {
      let _this = this;
      ContentService.update(content_id, content_data)
        .then((response) => {
          this.$eventBus.$emit("alert", {
            variant: "success",
            message: _this.$t("widget.instance_updated"),
          });
          _this.closeThisScreen();
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
    getBrandings: function() {
      let _this = this;
      BrandingService.list()
        .then((response) => {
          var brandings = [];
          brandings = response.data.map(function(branding) {
            return { text: branding.name, value: branding.id };
          });
          _this.brandingModel.brandings = brandings;
          _this.brandingModel.field_label_text = "branding.title";
          _this.brandingModel.validation_error_message =
            "Branding selection is required";
          if (_this.shouldCreateNewInstance) {
            _this.brandingModel.model = brandings[0].value;
          } else {
            _this.brandingModel.model = _this.content.branding_id;
          }
          _this.isLoadingComleted = true;
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
    handleError: function(error) {
      Swal.fire({
        title: "error",
        text: "Opps! something went wrong",
        showCancelButton: false,
      }).then((result) => {});
    },
    closeThisScreen: function() {
      if (this.shouldCreateNewInstance) {
        // this.$router.back(); //this won't work due to iFrame(preview) history gets created on src change
        this.$router.push({ name: "widgets" });
      } else {
        this.$router.push({ name: "library" });
      }
    },
  },
};
</script>