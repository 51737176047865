<template>
  <b-modal id="create_group"
           :title="modal_title()"
           class="modal-container">
      <form ref="form"  method="post" @submit.prevent="save()">
          <div class="form-group" v-if="error">
              <p class="text-danger text-center">{{error}}</p>
          </div>
          <div class="form-group"
              :class="{'has-danger': errors.has('name') }">
              <label>
                {{$t('create_group_dialog.group_name')}}
              </label>
              <input type="name"
                     class="form-control"
                     placeholder="E.g. first-floor screens"
                     v-model="group.name"
                     v-validate="'required'"
                     data-vv-name="name"
                     data-vv-as="name">

              <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
          </div>
      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" variant="primary" @click="save()">
          {{$t('common_words.save')}}
        </b-button>
        <b-button size="sm" variant="outline-default" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import GroupService    from '../services/GroupService';
export default {

  props: {
    group: {
      name: ''
    }
  },

  data() {
    return {

      success_message: "",
      error: null
    }
  },

  methods: {
    modal_title: function(){
      if(this.group != null && this.group.id != null) {
        return this.$t('screen_group.rename')
      }else{
          return this.$t('create_group_dialog.create_group_title')
      }
    },

    save: function(){
      let _this = this
      this.$validator
          .validateAll()
          .then((result) => {
              if(!result){
                _this.error = "Error in creating group"
                  return;
              }
              if(this.group.id != null){
                _this.update_group();
              }else{
                _this.create_group()
             }
          }).catch((error) => {
            _this.handleError(error)
          });

    },

    create_group(){
      let _this = this
      GroupService.create(this.group)
                  .then(response => {
                      this.$eventBus.$emit('alert', {variant: 'success', message: _this.$t('screen_group.created_succes')})
                      _this.$emit("created")
                      this.hideModal();
                  })
                  .catch(error => {
                      _this.handleError(error)
                  })
    },

    update_group(){
      let _this = this
      GroupService.update(this.group.id, this.group)
                  .then(response => {
                      this.$eventBus.$emit('alert', {variant: 'success', message: _this.$t('screen_group.renamed_succes')})
                      _this.$emit("updated")
                      this.hideModal();
                  })
                  .catch(error => {
                      _this.handleError(error)
                  })
    },

    hideModal() {
      this.$bvModal.hide('create_group')
    },

  },
}
</script>
