import Channels from './Channels'
import NewChannel from './NewChannel'

let routes = [{
	path: '/user/channels',
	name: 'channels',
	component: Channels
},
{
	path: '/user/newchannel',
	name: 'newchannel',
	component: NewChannel,
	meta: {
		layout: 'NoSidebarLayout'
	  }
},
{
	path: '/user/editchannel/:channel_id',
	name: 'editchannel',
	component: NewChannel,
	meta: {
		layout: 'NoSidebarLayout'
	  }
}
];

export default routes;
