<template>
  <div class="float-right mt-2 mr-2">
    <b-button 
      class="btn float-left btn-secondary btn-sm mr-3" @click="edit">
      <i class="zmdi zmdi-edit"></i>
    </b-button>
    <b-button
      class="btn float-left btn-secondary btn-sm"
      @click.prevent="delete_schedule($event)"
    >
      <i class="zmdi zmdi-delete"></i>
    </b-button>
  </div>
</template>

<script>
import GroupedScheduleService from "../services/GroupedScheduleService"
import {showDeleteConfirmation} from "../utils/DeleteConfirmation"

export default {
  props: {
    schedule: {
      type: Object,
    },
  },

  data() {
    return {
      success_message: "",
      error: null,
    }
  },

  methods: {
    edit() {
      this.$router.push({
        name: "add_content",
        params: { id: this.schedule.id },
      })
    },

    delete_schedule() {
      let _this = this
      showDeleteConfirmation(_this, _this.$t("confirmation_alert.schedule_delete_message")).then((result) => {
        if (result.value) {
          GroupedScheduleService.destroy(this.schedule.id)
            .then((response) => {
              _this.$emit("deleted")
            })
            .catch((error) => {
              this.handleError(error)
            })
        }
      })
    },
  },
}
</script>
