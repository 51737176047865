<template>
  <div class="list-view-item">
    <div class="notification-contents">
      <div class="notification-info-box">
        <i class="zmdi zmdi-wifi-off zmdi-hc-lg offline-icon"></i>
        <div>
          <span class="badge badge-warning">{{ $t("custom_alert.label.notification_type_offline_screens") }}</span>
          <br/>
          <span class="notification-title-text">
            {{
              $t("custom_alert.label.notification_message", {
                day: notification.custom_alert.metadata["last_seen"],
              })
            }}
          </span>
          <br />
          <span>
            {{ $t("custom_alert.label.name_alert_item") }}
            {{ notification.custom_alert.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="created-at">
      <span>{{ formatTime(notification.created_at) }}</span>
    </div>
    <div class="notification-actions">
      <b-button
        variant="primary"
        size="sm"
        class="ml-4 mr-4"
        @click="download_file(notification.download_url)"
      >
        <i class="zmdi zmdi-cloud-download download-icon"></i>
      </b-button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    notification: {
      type: Object,
      default: {},
    },
  },

  computed: {
    device_ids: function () {
      return this.notification.custom_alert.metadata["device_ids"] || [];
    },
  },

  methods: {
    formatTime: function (time) {
      return moment(time).format("DD-MMM-YYYY h:mm A zz");
    },

    download_file: function (download_url) {
      let iframe = document.createElement("iframe");
      iframe.id = "hiddenDownloader";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.src = download_url;
    },
  },
};
</script>
<style>
.notification-title-text {
  font-weight: 600;
}
.download-icon {
  color: white;
}
.offline-icon {
  color: rgb(240, 10, 10);
  padding-right: 16px;
}
.notification-info-box {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>
