<template>
<div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 text-center p-3 " :key="'screen-g' + device.id">
    <div class="card shadow-sm p-0" :class="{'watermarked' : this.device.preview_mode || this.device.free}" :data-watermark="getWaterMarkText">
        <div class="col-lg-12 card-header border-0 pb-0 pl-2 d-table">
            <h4 class="ml-2 pl-2 text-left mb-0">
                {{ device.device_name || "-" }}
            </h4>
            <div class="col-lg-12 text-left body-font-dimmed">ID: {{ device.udid }}</div>
        </div>

        <device-dropdown :device="device"
                         cssClass="dd-actions"
                         @save="save_rotation"
                         @delete="delete_device"
                         @rename-device="rename_device"
                         @refresh-device="refresh"
                         @live-mode="live_mode" />

        <div class="col-lg-12 p-0 text-left">
            <div class="col-lg-12 p-0">
                <div class="col-lg-12 border-top d-flex">
                    <div class="col-lg-6 p-2 float-left">
                        {{ $t("screens.details.screen_group") }}
                    </div>
                    <div class="col-lg-6 p-2 float-left">
                        {{ device.group_name || "N/A" }}
                    </div>
                </div>

                <div class="col-lg-12 border-top d-flex" :style="'background-color:' + last_active_color">
                    <div class="col-lg-6 p-2 float-left">
                        {{ $t("screens.details.last_seen") }}
                    </div>
                    <div class="col-lg-6 p-2 float-left text-primary"  v-b-tooltip.hover :title="this.last_active_date_time">
                        <i class="zmdi zmdi-circle"  :class="online" v-if="device.online"> {{ $t("screens.details.online") }}</i>
                        <span v-if="!device.online"> {{ last_active }}</span>
                    </div>
                </div>

                <div class="col-lg-12 border-top d-flex">
                    <div class="col-lg-6 p-2 float-left">
                        {{ $t("screens.details.rotation") }}
                    </div>
                    <div class="col-lg-6 p-2 float-left">
                        {{ device.rotation_text }}<span>&#176;</span>
                    </div>
                </div>
                <div class="col-lg-12 border-top d-flex">
                    <div class="col-lg-6 p-2 float-left">
                        {{ $t("screens.details.os_type") }}
                    </div>
                    <div class="col-lg-6 p-2 float-left">
                        {{ device.os_version || "N/A" }}
                    </div>
                </div>
                <div class="col-lg-12 border-top d-flex">
                    <div class="col-lg-6 p-2 float-left">
                        {{ $t("screens.details.app_version") }}
                    </div>
                    <div class="col-lg-6 p-2 float-left">
                        {{ device.app_version || "N/A" }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
import DeviceService from "../services/DeviceService";
import DeviceDropdown from "./DeviceDropDown";
import { showDeleteConfirmation } from "../utils/DeleteConfirmation";

export default {
    props: {
        device: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {};
    },

    components: {
        DeviceDropdown,
    },

    computed: {
        device_attributes: function () {
            return Object.keys(this.device).filter(
                (key) => !["id", "device_name", "updated_at"].includes(key)
            );
        },

        last_active: function () {
            if (this.device.last_seen != "" && this.device.last_seen != null) {
                return moment(this.device.last_seen).fromNow();
            } else {
                return "N/A";
            }
        },

        last_active_date_time: function () {
            if (this.device.last_seen != "" && this.device.last_seen != null) {
                return moment(this.device.last_seen).format("DD-MMM-YYYY h:mm A zz");
            } else {
                return "N/A";
            }
        },

        last_active_color: function () {
            let l = moment();
            let c = moment(this.device.last_seen);
            let diff = l.diff(c, "days");
            if (diff <= 1) {
                return "rgba(143, 247, 143, 0.2)";
            } else if (diff <= 2) {
                return "rgba(245, 245, 178, 0.2)";
            } else {
                return "rgba(249, 207, 205, 0.2)";
            }
        },

        status_color: function () {
            if (this.device.subscription_status == "Subscribed" || this.device.subscription_status == "Free") {
                return "rgba(143, 247, 143, 0.2)";
            } else {
                return "rgba(249, 207, 205, 0.2)";
            }
        },

        online: function(){
            if(this.device.online){
                return 'text-success'
            }else{
                return 'text-danger'
            }
        },

        getWaterMarkText: function(){
            if(this.device.free){
                return 'Complementary'
            }else if(this.device.preview_mode){
                return 'Preview Mode'
            }
        }
    },

    methods: {
        save_rotation: function (rotation) {
            let _this = this;
            this.device.rotation = rotation[0];
            this.device.rotation_text = rotation[1];

            DeviceService.update(_this.device.id, {
                    device: { rotation: rotation[0] },
                })
                .then((response) => {
                    _this.device.rotation = response.data.rotation;
                })
                .catch((error) => {
                    _this.handleError(error);
                });
        },
        refresh: function (device) {
            let _this = this;
            DeviceService.sendNotification(_this.device.id)
                .then((response) => {
                    this.$eventBus.$emit("alert", {
                        variant: "success",
                        message: "Refresh command sent",
                    });
                })
                .catch((error) => {
                    _this.handleError(error);
                });
        },
        delete_device: function () {
            let _this = this;
            showDeleteConfirmation(
                _this,
                _this.$t("confirmation_alert.device_delete_message")
            ).then((result) => {
                if (result.value) {
                    _this.$emit("progress", true);
                    DeviceService.destroy(this.device.id)
                        .then((response) => {
                            this.$eventBus.$emit("alert", {
                                variant: "success",
                                message: "Screen deleted",
                            });
                            _this.$emit("deleted");
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                }
            });
        },

        rename_device: function (device) {
            this.$emit("rename-device", device);
        },

        live_mode: function(device){
            let _this = this
            DeviceService.change_to_live_mode(_this.device.id)
            .then((response) => {
                _this.device.preview_mode = response.data.device.preview_mode
                _this.device.subscription_status = response.data.device.subscription_status
            })
            .catch((error) => {
                _this.handleError(error);
            });
        }
    },
};
</script>

<style lang="scss">
.bg-green {
    background-color: #e7fddf;
}

.bg-red {
    background-color: rgba(241, 76, 67, 0.3);
}

.col {
    flex: 0 0 32%;
    max-width: 32%;
    margin-bottom: -20px;
}

.orientation {
    top: 0px;
    right: 0px;
    margin-top: -30px;
}

.dropdown-btn {
    width: 175px;
    background: #ffffff !important;
    border: 0px;
    text-align: left;
    margin: 0px 1.5em;

    &:focus {
        outline: 0px none;
    }
}

.border-top {
    border-top: 1px solid #dfdfdf;
}

.border-bottom {
    border-bottom: 1px solid #dfdfdf;
}

.dd-actions {
    position: absolute;
    background: no-repeat;
    border: navajowhite;
    right: 15px;
    top: 15px;
    font-size: 20px;
}

.watermarked {
  position: relative;
  overflow: hidden;
}

.watermarked::before {
    position: absolute;
    top: 80px;
    left: 57px;
    display: block;
    transform: rotate(-45deg);
    content: attr(data-watermark);
    opacity: 0.7;
    line-height: 3em;
    letter-spacing: 2px;
    color: #d0cece;
    font-size: 30px;
}
</style>
