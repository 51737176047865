var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screen-zone-container",on:{"click":_vm.select_zone_pressed}},[_c('b-button',{staticClass:"btn float-left btn-secondary btn-sm",class:{
            selected:
                this.channel.layout != null &&
                this.channel.layout.id == this.layout.id,
        }},[(
                this.channel.layout == null ||
                this.channel.layout.id != this.layout.id
            )?_c('span',[_vm._v("\n            Select\n        ")]):_vm._e(),_vm._v(" "),(
                this.channel.layout != null &&
                this.channel.layout.id == this.layout.id
            )?_c('span',{staticStyle:{"font-weight":"600"}},[_c('i',{staticClass:"zmdi zmdi-check pr-2"}),_vm._v("Selected\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"screen-layout-section"},[_c('div',{staticClass:"zone-layout-box-container",style:('width:' +
                _vm.layout.width +
                'px ; height:' +
                _vm.layout.height +
                'px ;')},_vm._l((_vm.layout.zones),function(zone){return _c('zone-division-box',{key:zone.id,attrs:{"zoneDivisionBox":zone}})}),1)]),_vm._v(" "),_c('div',{staticClass:"screen-metadata-section"},[_c('span',[_vm._v(_vm._s(_vm.layout.name))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }