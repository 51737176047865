<template>
  <drag class="file float-left rounded shadow-sm drag p-10 cont-preview"
       :transfer-data="{ media }" :draggable="draggable">
    <div class="p-0 m-0 col-lg-12" @click="$emit('click')">
        <file :content="media"
              v-if="media.type == 'File'"/>

        <widget :content="media"
                v-if="media.type == 'Widget'"/>
        <playlist :playlist="media"
                v-if="media.type == null"/>
    </div>
  </drag>
</template>

<script>
  import File              from './File'
  import Widget            from './Widget'
  import Playlist          from './PlaylistContent'
  export default {

    props:{
      media: {},
      draggable: {
        type: Boolean,
        default: true
      }
    },

    components:{
      File,
      Widget,
      Playlist
    },

  }
</script>
