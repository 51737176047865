<template>
    <b-modal
        :id="modal_id"
        :title="$t('schedules.event_dialog.title')"
        class="modal-container"
        @shown="showModal()"
    >
        <form ref="form" method="post" @submit.prevent="validate">
            <div class="form-group" v-if="error">
                <p class="text-danger text-center">{{ error }}</p>
            </div>

            <div class="form-group">
                <label>
                    {{ $t("schedules.select_playlist_label") }}
                </label>
                <b-form-select
                    v-model="schedule.playlist_id"
                    :options="this.media_options"
                />
            </div>
            <div
                class="form-group"
                v-if="schedule.schedule_type == 'week_view'"
            >
                <label>
                    {{ $t("schedules.start_time") }}
                </label>
                <div class="col-lg-13">
                    <time-picker
                        input-class="form-control col-lg-12"
                        class="col-lg-12"
                        :hide-clear-button="true"
                        format="hh:mm a"
                        v-model="start_time"
                    />
                </div>
                <span
                    v-show="errors.has('schedule.start_time')"
                    class="text-danger"
                    >{{ errors.first("schedule.start_time") }}</span
                >
            </div>

            <div
                class="form-group"
                v-if="schedule.schedule_type == 'date_view'"
            >
                <label>
                    {{ $t("schedules.start_date") }}
                </label>
                <div class="w-100 form-control">
                    <VueMaterialDateTimePicker
                        v-model="schedule.start_date"
                        :valueFormatted="formated_start_date"
                    />
                </div>
                <span
                    v-show="errors.has('schedule.start_date')"
                    class="text-danger"
                    >{{ errors.first("schedule.start_date") }}</span
                >
            </div>

            <div
                class="form-group"
                v-if="schedule.schedule_type == 'week_view'"
            >
                <label>
                    {{ $t("schedules.end_time") }}
                </label>
                <div class="w-100">
                    <time-picker
                        input-class="form-control col-lg-12"
                        class="col-lg-12"
                        :hide-clear-button="true"
                        format="hh:mm a"
                        v-model="end_time"
                    />
                </div>
                <span
                    v-show="errors.has('schedule.end_time')"
                    class="text-danger"
                    >{{ errors.first("schedule.end_time") }}</span
                >
            </div>

            <div
                class="form-group"
                v-if="schedule.schedule_type == 'date_view'"
            >
                <label>
                    {{ $t("schedules.end_date") }}
                </label>
                <div class="w-100 form-control">
                    <VueMaterialDateTimePicker
                        v-model="schedule.end_date"
                        :valueFormatted="formated_end_date"
                    />
                </div>
                <span
                    v-show="errors.has('schedule.end_date')"
                    class="text-danger"
                    >{{ errors.first("schedule.end_date") }}</span
                >
            </div>
        </form>
        <div slot="modal-footer" class="w-100 text-right">
            <b-button size="sm" variant="primary" @click="save()">
                {{ $t("common_words.save") }}
            </b-button>
            <b-button size="sm" variant="outline-default" @click="hideModal()">
                {{ $t("common_words.cancel") }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import GroupService from "../services/GroupService";
    import ContentService from "../services/ContentService";
    import ScheduleService from "../services/ScheduleService";
    import PlaylistService from "../services/PlaylistService";
    import TimePicker from "vue2-timepicker";
    import VueMaterialDateTimePicker from "vue-material-date-time-picker";

    // CSS
    import "vue2-timepicker/dist/VueTimepicker.css";
    export default {
        props: {
            modal_id: null,
            schedule: {
                id: null,
                name: "",
                start_date: "",
                end_date: "",
                start_time: "",
                end_time: "",
                playlist_id: null,
                Playlist: null,
                weekday: null,
                grouped_schedule_id: null,
                schedule_type: "week_view",
                formated_start_date: "",
                formated_end_date: "",
            },
        },

        data() {
            return {
                success_message: "",
                error: null,
                media_type: "content",
                media: "",
                media_options: [],
                playlists: [],
                start_time: "",
                end_time: "",
                start_date: "",
                end_date: "",
            };
        },

        components: {
            TimePicker,
            VueMaterialDateTimePicker,
        },

        computed: {
            formated_start_date: function () {
                return this.schedule.start_date
                    .toLocaleString("en-US", {
                        dateStyle: "medium",
                        timeStyle: "medium",
                        hour12: true,
                    })
                    .split("GMT")[0];
            },

            formated_end_date: function () {
                return this.schedule.end_date
                    .toLocaleString("en-US", {
                        dateStyle: "medium",
                        timeStyle: "medium",
                        hour12: true,
                    })
                    .split("GMT")[0];
            },
        },

        methods: {
            find() {
                let _this = this;
                if (this.schedule.id != null) {
                    PlaylistService.get(this.schedule.playlist_id)
                        .then((response) => {
                            _this.media_options.push({
                                value: response.data.id,
                                text: response.data.name,
                            });
                        })
                        .catch((error) => {
                            _this.handleError(error);
                        });
                }
            },

            save() {
                let _this = this;
                if (_this.schedule.schedule_type == "week_view") {
                    _this.schedule.start_time = _this.convertToNumber(
                        _this.start_time
                    );
                    _this.schedule.end_time = _this.convertToNumber(_this.end_time);
                } else {
                    _this.schedule.start_date = new Date(
                        this.formated_start_date + " GMT"
                    );
                    _this.schedule.end_date = new Date(
                        this.formated_end_date + " GMT"
                    );
                }
                if (_this.schedule.id == null) {
                    _this.create_schedule();
                } else {
                    _this.edit_schedule();
                }
            },

            create_schedule() {
                let _this = this;
                if (this)
                    ScheduleService.create({ schedule: this.schedule })
                        .then((response) => {
                            _this.$emit("eventCreated");
                            _this.hideModal();
                            _this.error = null;
                        })
                        .catch((error) => {
                            _this.handleError(error);
                        });
            },

            edit_schedule() {
                let _this = this;
                ScheduleService.update(this.schedule.id, {
                    schedule: this.schedule,
                })
                    .then((response) => {
                        _this.$emit("eventUpdated");
                        _this.hideModal();
                        _this.error = null;
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            get_contents: function () {
                let _this = this;
                ContentService.list()
                    .then((response) => {
                        _this.contents = response.data;
                        _this.set_media_list();
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            get_playlist: function () {
                let _this = this;
                PlaylistService.list()
                    .then((response) => {
                        _this.playlists = response.data.playlists;
                        _this.set_media_list();
                    })
                    .catch((error) => {
                        _this.handleError(error);
                    });
            },

            set_media_list: function () {
                this.media_options = [
                    {
                        value: "",
                        text: this.$t(
                            "screen_group.media_options." + this.media_type
                        ),
                    },
                ];
                this.media = "";
                let _this = this;
                this.playlists.forEach((playlist) => {
                    _this.media_options.push({
                        value: playlist.id,
                        text: playlist.name,
                    });
                });
            },

            showModal: function () {
                this.get_playlist();
                this.find();
                if (this.schedule.schedule_type == "week_view") {
                    let start_time = this.schedule.start_time.split(":");
                    this.start_time = {
                        hh: start_time[0],
                        mm: start_time[1].split(" ")[0],
                        a: start_time[1].split(" ")[1],
                    };
                    let end_time = this.schedule.end_time.split(":");
                    this.end_time = {
                        hh: end_time[0],
                        mm: end_time[1].split(" ")[0],
                        a: end_time[1].split(" ")[1],
                    };
                } else {
                    this.schedule.start_date = new Date(this.schedule.start_date);
                    this.schedule.end_date = new Date(this.schedule.end_date);
                }
            },

            hideModal() {
                this.resetForm();
                this.$bvModal.hide(this.modal_id);
            },

            resetForm() {
                this.media_id = "";
                this.success_message = "";
                this.error = null;
            },

            convertToNumber(time) {
                let hh = parseInt(time.hh);
                if (time.a == "pm") {
                    if (hh < 12) {
                        hh = hh + 12;
                    }
                } else {
                    if (hh == 12) {
                        hh = 0;
                    }
                }
                let mm = parseInt(time.mm);
                let timeNumber = hh * 60 * 60 + mm * 60;
                return timeNumber;
            },
        },
    };
</script>
<style lang="scss">
.vue__time-picker {
    width: 100%;
    padding: 0px;
    input {
        width: 100% !important;
    }
}
</style>
