<template>
  <div class="reports-dashboard-container">
    <div class="reports-header-container">
      <record-header />
    </div>
    <div class="reports-items-container">
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
      <record />
    </div>
  </div>
</template>

<script>
import Record from "./Record.vue";
import RecordHeader from "./RecordHeader.vue";
export default {
  components: { RecordHeader, Record },
};
</script>

<style scoped>
.reports-dashboard-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.reports-header-container {
  display: flex;
  flex: 0;
}
.reports-items-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  overflow: scroll;
  flex-direction: column;
}
</style>
