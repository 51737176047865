<template>
  <div class="reports-main-container">
    <div class="reports-form-container">
      <report-form :content_id="content_id" :device_id="device_id" />
    </div>
    <div class="reports-listings-container">
      <reports />
    </div>
  </div>
</template>

<script>
import ReportForm from './ReportForm.vue';
import Reports from "./Reports";
export default {
  props: {
    device_id: {
      type: Number,
      default: null,
    },
    content_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {};
  },

  components: {
    Reports,
    ReportForm,
  },

  created() {},

  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.reports-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.reports-form-container {
  display: flex;
  flex: 0;
}
.reports-listings-container {
  display: flex;
  flex: 1.0;
}
</style>
