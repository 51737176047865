<template>
  <div class="col-4">
    <b-card no-body>
      <template #header>
        <h4 class="col-10 float-left pl-0 m-0">
          <b-badge variant="success">ME</b-badge> &nbsp;{{ user["email"] }}
        </h4>
        <div class="float-right">
          <b-link id="pp-1-me" variant="">
              <i class="zmdi zmdi-menu"></i>
          </b-link>
          <b-popover target="pp-1-me" triggers="click blur"
                      ref="popover" placement="bottom">
              <b-list-group>
                <b-link class="list-group-item "
                          variant="none"
                          @click.prevent="editProfile">
                  {{$t('user.edit_profile')}}
                </b-link>
                <b-link class="list-group-item "
                          variant="none"
                          @click.prevent="changePassword">
                  {{$t('user.change_password')}}
                </b-link>
                <b-link class="list-group-item "
                          variant="none"
                          @click.prevent="deleteAccount">
                  <span style="color:red;">{{$t('user.delete_account')}}</span>
                </b-link>
              </b-list-group>
          </b-popover>
        </div>
          
      </template>
      <b-list-group flush>
        <b-list-group-item>Role: Owner</b-list-group-item>
      </b-list-group>
      <edit-profile :user="this.user"/>
      <change-password :user="this.user"/>
    </b-card>
  </div>
</template>

<script>
import EditProfile              from './EditProfile'
import ChangePassword           from './ChangePassword.vue'
import {showInformation} from "../utils/DeleteConfirmation"

export default {
  props: {
    user: {
      type: Object,
    },
  },

  components:{
    EditProfile,
    ChangePassword
  },

  methods: {
    editProfile: function(){
      this.$bvModal.show("edit_profile")
    },
    changePassword: function(){
      this.$bvModal.show("change_password")
    },
    deleteAccount: function(){
      let _this = this;
      showInformation(
        _this,
        _this.$t("user.delete_account_title"),
        _this.$t("user.delete_account_message")
      ).then((result) => {
      });
    }
  }
}
</script>
