<template>
  <div class="col-md-12 col-lg-12 d-flex p-0">
    <div class="card col-md-5 col-lg-6  shadow-sm p-0">
      <div class="header shadow-sm col-md-12 text-right p-2 pl-0 border-bottom">
        <input v-model="playlist.name"
               class=" p-2 ml-3 rounded border float-left"/>

        <b-button variant="primary"
                  size="sm"
                  class="btn btn float-right btn-primary ml-3 mt-1"
                  @click="save_playlist">
          {{ $t('common_words.done')}}
        </b-button>
      </div>


       <draggable class="pl-file-previews"
                  :list="contents_playlists"
                  :options="{group: 'media'}"
                  @change="updateIndex">
          <playlist-media  v-for="(playlist_media,index) in this.contents_playlists"
                    v-show="contents_playlists.length"
                    :key="'asdf' + playlist_media.id + playlist_media.position"
                    :contents_playlist="playlist_media"
                    :hide_action="false"
                    :index="index+1"
                    @updated="update"
                    @deleted="remove"/>
        <no-content :message="no_content_msg" v-if="this.loaded && !this.contents_playlists.length"/>
      </draggable>


    </div>
    <div class="card col-md-7 col-lg-6 ml-2 float-right shadow-sm p-0">
        <div class="header shadow-sm col-md-12 p-2 text-right border-bottom">
            <h3 class='col-7 float-left text-left'>
                      <b-form-select v-model="selected"
                                    :options="['Content', 'Playlist']"
                                    @change="change_selection"/>
            </h3>
            <search :searchText="query" :media_type="selected" @change="load_content_playlist" />
        </div>
        <div class="pl-file-previews content-div">
          <draggable  class="" :list="contents"
                      :clone="clone"
                      :options="{group:{ name: 'media',  pull: 'clone', put: false }}">
            <user-content v-for="content in contents"
                     v-show="contents.length"
                     :key="'device' + content.id"
                     :media="content"
                     />
          </draggable>
        </div>

    </div>
    <b-alert variant="success"
             :show="dismissCountDown"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged">
      {{$t('common_words.success_msg')}}
    </b-alert>

  </div>
</template>

<script>
  import ContentService            from '../services/ContentService'
  import PlaylistService           from '../services/PlaylistService'
  import UserContent               from './Content'
  import PlaylistMedia             from './PlaylistMedia'
  import NoContent                 from './NoPlaylistContents'
  import InlineInput               from '../components/InlineInput'
  import Draggable                 from "vuedraggable";
  import Search from "../components/Search"

  export default {
    props: {
      id: {
        type: Number,
        default: null
      }
    },

    data() {
      return {
        playlist: {
          id: null,
          name: ''
        },
        selected: 'Content',
        query: '',
        current: null,
        loaded: false,
        contents_playlists: [],
        no_content_msg: this.$t('playlist.contents.no_data'),
        success_message: "",
        error: null,
        contents: [],
        deleted_contents: [],
        dismissSecs: 4,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        page: 1,
        total_pages: 0,
        query: ''
      }
    },

    components:{
      UserContent,
      PlaylistMedia,
      NoContent,
      Draggable,
      InlineInput,
      Search
    },

    mounted(){
        this.find()
        this.load_content_playlist()
        let scrollElement = document.querySelector('.content-div');
        let _this = this
        scrollElement.addEventListener('scroll', e => {
          if(scrollElement.scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight) {
            _this.page = _this.page + 1
            if(_this.total_pages >= _this.page){
              if(this.selected == 'Content'){
                this.get_contents()
              }else{
                this.get_playlists()
              }
            }
          }
        });
    },

    methods: {
        find(){
          let _this = this
          PlaylistService.get(this.$route.params.id)
                         .then(response => {
                            _this.playlist = response.data
                            response.data.contents_playlists.forEach(
                              function(playlist_content, index){
                                _this.contents_playlists.push(playlist_content)
                              }
                            );

                            _this.loaded = true
                         })
                         .catch(error => {
                           _this.handleError(error)
                         })
        },
        get_contents: function(){
          let _this = this
          let query_params = { page: this.page, query: this.query}
          ContentService.list(query_params)
                        .then(response => {
                            response.data.contents.forEach(function(content, index){
                              _this.contents.push(content)
                            });
                            _this.loaded = true
                            _this.total_pages = response.data.total_pages
                        })
                        .catch(error => {
                            _this.handleError(error)
                        })
        },

        getTotalDuration: function(){
          let total_duration = 0
          if(this.contents_playlists != null){
            this.contents_playlists.reduce((total_duration,content) => total_duration + content.duration, 0)
          }
          return total_duration;
        },

        navigate_to_playlist: function(){
          this.$router.push({name:'playlists'})
        },

        updateIndex: function(added) {
          let contents_playlists = []
          let _this = this
          this.contents_playlists.every(function(pl_content, position, array) {
              pl_content.position = position
          })
        },

        updatePlaylistContents: function(added) {
            let contents_playlists = []
            let _this = this
            this.contents_playlists.forEach(function(pl_contents, position) {
                contents_playlists.push({
                    'id': pl_contents.id,
                    'playlist_id': _this.playlist.id,
                    'media_id': pl_contents.media.id,
                    'media_type': pl_contents.media_type,
                    'duration': pl_contents.duration,
                    'resize_mode': pl_contents.resize_mode,
                    'position': position
                })
            })
          return contents_playlists
        },

        save_playlist: function(){
          let contents_playlists = this.updatePlaylistContents()
          contents_playlists = contents_playlists.concat(this.deleted_contents)
          let _this = this
          PlaylistService.update(_this.playlist.id,
                                 {name: _this.playlist.name,
                                        contents_playlists_attributes: contents_playlists
                                 })
                         .then(response => {
                            _this.navigate_to_playlist()
                         })
                         .catch(error => {
                            _this.handleError(error)
                         })
        },

        update_content: function(contents){
          this.contents = contents;
        },

        clone: function(content) {
          let duration =  10
          if(content.content_type != null && (content.content_type.startsWith("video") ||  content.content_type.startsWith("audio"))) {
            duration = content.duration
          }
          return {
            playlist_id: this.playlist.id,
            position: this.contents_playlists.length,
            duration: duration,
            resize_mode: content.resize_mode,
            media_id: content.id,
            media_type: (this.selected == 'Content' ? 'User::Content' : 'User::Playlist'),
            media: content
          };
        },

        update: function(playlist_content){
          if(playlist_content.position != null){
            this.contents_playlists[playlist_content.position] = playlist_content;
          }
        },

        remove: function(position){
          let _this = this
          if(position != null){
            this.contents_playlists.forEach(function(pl_content, index) {
              if(pl_content.position == position && pl_content.id != null){
                _this.deleted_contents.push({id: pl_content.id,
                                            '_destroy': true})
              }
            })
            this.contents_playlists = this.contents_playlists.filter((item) => item.position != position)
            this.updateIndex()
          }
        },

        countDownChanged: function(dismissCountDown) {
          this.dismissCountDown = dismissCountDown
        },

        load_content_playlist: function(query){
          this.contents = []
          this.page = 1
          this.query = query
          if(this.selected == 'Content'){
            this.get_contents()
          }else{
            this.get_playlists()
          }
        },

        change_selection: function(){
          this.load_content_playlist()
        },

        get_playlists: function(){
          let _this = this
          let query_params = { except_id: _this.playlist.id, page: _this.page, query: this.query}
          PlaylistService.list(query_params)
                         .then(response => {
                           if(_this.page == 1) {
                              _this.contents = []
                           }
                           _this.contents = _this.contents.concat(response.data.playlists)
                           _this.total_pages = response.data.total_pages
                           _this.loaded = true
                         })
                         .catch(error => {
                            _this.handleError(error)
                         })
        }

    },
  }
</script>
<style lang="scss">
  h3{
    .custom-select{
      width: 50%;
      height: 35px;
      margin: -3px 0px;
    }
  }

 .pl-file-previews{
   height: 91vh;
   overflow: auto;
 }
 .playlist-content{
   height: 110px;
   width: 100%;
   .dz-image{
     position: absolute;
     left: 0;
     top: 0;
  }
 }
</style>
