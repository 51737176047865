<template>
<div class="scroll-container" :id="this.getId(0)">
    <slot></slot>
</div>
</template>

<script>
export default {
    props: {
        scrollFunction: {
            type: Function,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        total_pages: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        }
    },

    data: function () {
        return {
            container_id: null
        }
    },

    mounted: function () {
        this.initScroll()
    },

    methods: {
        initScroll: function () {
            let _this = this
            let scrollElement = document.getElementById(this.container_id);
            scrollElement.onscroll = function () {
                if ((scrollElement.scrollTop + scrollElement.clientHeight) >= (scrollElement.scrollHeight - 50) && !_this.loading) {
                    if (_this.total_pages > _this.page) {
                        _this.scrollFunction()
                    }
                }
            }
        },

        getId: function (indentifier) {
            let id = 'scrollElem_' + indentifier
            let scrollElement = document.getElementById(id);
            if (this.container_id == null) {
                if (scrollElement == null) {
                    this.container_id = id
                } else {
                    indentifier++
                    this.container_id = this.getId(indentifier)
                }
            }
            return this.container_id
        },

        resetScroll:function(){
            if(this.page == 1){
                let scrollElement = document.getElementById(this.container_id);
                scrollElement.scrollTo(0, 0)
            }
        }
    },
    watch: {
        page: function(val){
            this.resetScroll()
        }
    }

}
</script>
