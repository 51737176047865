<template>
  <b-modal :id="modal_id"
           :title="title"
           class="modal-container"
           @shown="showModal()">
      <form ref="form"  method="post" @submit.prevent="validate">
      sfasdfsdf
          <div class="alert-danger p-2 mb-3" v-if="error">
              {{error}}
          </div>
          <div class="form-group">
              <label>
                {{$t('schedules.schedule_name')}}
              </label>
              <div class="col-lg-13">
                <input type="text"
                       class="form-control"
                       :class="{'is-invalid': errors.has('name') }"
                       placeholder="name"
                       v-model="schedule.name"
                       v-validate="'required'"
                       data-vv-name="name">
              </div>
              <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
          </div>
      </form>
      <div slot="modal-footer" class="w-100 text-right">
        <b-button size="sm" class="float-right ml-3" variant="primary" @click="save()">
          {{$t('common_words.save')}}
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="hideModal()">
          {{$t('common_words.cancel')}}
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import PlaylistService    from '../services/PlaylistService';
import GroupedScheduleService  from '../services/GroupedScheduleService'
import Multiselect        from 'vue-multiselect';
export default {

  props: {
    schedule_id: {
      type: Number,
      default: null
    },

    title: {
      type: String,
      default: "Create Schedule"
    },

    modal_id:{
      type: String,
      default: "create_schedules"
    }
  },

  data() {
    return {
      schedule:{
        id: null,
        name: '',

      },
      selected_playlist: null,
      success_message: "",
      error: null
    }
  },

  components:{
    Multiselect
  },

  methods: {
    hideModal() {
      this.$bvModal.hide(this.modal_id)
    },

    showModal(){
      this.resetForm()
      if(this.schedule_id != null){
        this.getSchedule()
      }
    },

    save(){
      let _this = this
      this.$validator
          .validateAll()
          .then((result) => {
              if(_this.schedule.id == null){
                _this.create_schedule()
              }else{
                _this.edit_schedule()
              }

          }).catch((error) => {
            _this.handleError(error)
          });

    },

    create_schedule(){
      let _this = this
      GroupedScheduleService.create({ schedule: this.schedule })
                            .then(response => {
                               _this.$emit("created")
                               _this.hideModal()
                               _this.error = null
                            })
                            .catch(error => {
                               _this.handleError(error)
                            })
    },

    edit_schedule(){
      let _this = this
      GroupedScheduleService.update(this.schedule.id, { schedule: this.schedule })
                            .then(response => {
                              _this.$emit("updated")
                              _this.hideModal()
                              _this.error = null
                            })
                            .catch(error => {
                              _this.handleError(error)
                            })
    },

    getSchedule() {
      let _this = this;
      ScheduleService.get(this.schedule_id)
                     .then(response => {
                        _this.schedule = response.data
                     })
                     .catch(error => {
                        _this.handleError(error)
                     })
    },

    resetForm() {
      if(this.playlist != null){
        Object.keys(this.playlist).forEach(key => {
          this.playlist[key] = null
        })
        this.$validator.reset()
      }
    }

  },
}
</script>
