<template>
  <div class="list-view-item">
    <div class="filter-item">
      <div><span class="filter-bolder-text">{{ $t('reports.contents')}} </span>{{ report.content_ids.join(", ") || "All Contents" }}</div>
      <div><span class="filter-bolder-text">{{ $t('reports.screens')}} </span>{{ report.device_ids.join(", ") || "All Screens" }}</div>
      <div><span class="filter-bolder-text">{{ $t('reports.date_range')}} </span>{{ report.date_range_from | formatDateOnly }} 
        <span class="filter-bolder-text">{{ $t('common_words.to')}}</span>
        {{ report.date_range_to | formatDateOnly }}
      </div>
    </div>
    <div class="created-at">
      <span>{{ formatTime(report.created_at) }}</span>
    </div>
    <div class="download-button">
      <b-button
        @click="download_file(report.download_url)"
        variant="primary"
        size="sm"
        class="ml-4 mr-4"
        v-if="report.download_url != null"
      >
        <i class="zmdi zmdi-cloud-download download-icon"></i>
      </b-button>
      <span v-else>
        {{ $t("reports.not_generated") }}
      </span>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    report: {
      type: Object,
      default: {},
    },
  },
  methods: {
    formatTime: function (time) {
      return moment(time).format("DD-MMM-YYYY h:mm A zz");
    },
    download_file: function(download_url){
        let iframe = document.createElement('iframe');
        iframe.id = "hiddenDownloader";
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = download_url;
      },
  },
};
</script>
<style scoped>
.filter-bolder-text {
  font-weight: 600;
}
</style>
