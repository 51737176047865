<template>
<div class="float-right mt-2 mr-2">
    <b-button v-b-tooltip.hover :title="$t('common_words.duplicate')" class="btn float-left btn-secondary btn-sm mr-3" @click="duplicate">
        <i class="zmdi zmdi-copy"></i>
    </b-button>
    <b-button v-b-tooltip.hover :title="$t('common_words.edit')" class="btn float-left btn-secondary btn-sm mr-3" @click="edit">
        <i class="zmdi zmdi-edit"></i>
    </b-button>
    <b-button v-b-tooltip.hover :title="$t('common_words.delete')" class="btn float-left btn-secondary btn-sm" @click.prevent="delete_playlist($event)">
        <i class="zmdi zmdi-delete"></i>
    </b-button>
</div>
</template>

<script>
import PlaylistService from "../services/PlaylistService"
import { showDeleteConfirmation } from "../utils/DeleteConfirmation"

export default {
    props: {
        playlist: {
            type: Object,
        },
    },

    data() {
        return {
            success_message: "",
            error: null,
        }
    },

    methods: {
        duplicate() {
            //this.$router.push({name:'contents', params: {id: this.playlist.id}})
            let _this = this
            PlaylistService.duplicate({ name: "Duplicate of " + this.playlist.name, playlist_id: this.playlist.id })
                .then((response) => {
                    this.$router.push({ name: 'contents', params: { id: response.data.id } })
                })
                .catch((error) => {
                    _this.handleError(error)
                })
        },
        edit() {
            this.$router.push({ name: 'contents', params: { id: this.playlist.id } })
        },
        delete_playlist() {
            let _this = this
            showDeleteConfirmation(_this, _this.$t("confirmation_alert.playlist_delete_message")).then((result) => {
                if (result.value) {
                    _this.$emit("progress", true)
                    PlaylistService.destroy(this.playlist.id)
                        .then((response) => {
                            this.$eventBus.$emit("alert", {
                                variant: "success",
                                message: _this.$t("playlist.deleted_successfully"),
                            })
                            _this.$emit("deleted")
                        })
                        .catch((error) => {
                            this.handleError(error)
                        })
                }
            })
        },
    },
}
</script>
