<template>
  <div class="form-element-container">
    <label class="form-element-label">
      {{ $t(element.field_label_text) }}
    </label>
    <div class="form-element-control-container">
      <div class="form-element-required-container">
        <b-form-select
          @change="onChange()"
          class="form-element-field-dropdown"
          v-model="element.model"
          :tabindex="this.index"
        >
          <option
            v-for="b in element.brandings"
            :key="b.value"
            :value="b.value"
          >
            {{ b.text }}
          </option>
        </b-form-select>
        <span v-if="isRequiredElement" class="form-element-required-text">*</span>
      </div>
      <span v-if="isValid == false" class="text-danger">
        {{ $t(element.validation_error_message) }}
      </span>
    </div>
  </div>
</template>

<script>
import Element       from '../form_elements/helper/Element'
import EventBus from "../../../core/event-bus";
import { isElmentInputValid } from "./helper/validations";

export default {
  mixins: [Element],
  
  data() {
    return {
      isValid: true,
    };
  },
  computed: {
    isRequiredElement() {
      return false
    },
  },
  created: function() {
    let _this = this;
    EventBus.$on("validate_widget_form", function() {
      _this.checkValidation();
    });
  },
  mounted() {
    EventBus.$emit("branding_element_mounted");
  },
  beforeDestroy(){
    EventBus.$off('validate_widget_form')
  },
  methods: {
    checkValidation: function() {
      this.isValid = isElmentInputValid(this.element);
    },
  },
};
</script>

<style></style>
