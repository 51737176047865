<template>
    <div class="form-element-container">
        <label class="form-element-label">
            {{ $t(element.field_label_text) }}
        </label>
        <div class="form-element-control-container">
            <div class="form-element-required-container">
                <datetime-input
                    class="form-control form-element-field"
                    :tabindex="this.index"
                    v-model="element.model"
                    :datetime="element.model"
                />

                <span
                    v-if="isRequiredElement"
                    class="form-element-required-text"
                    >*</span
                >
            </div>
            <span v-if="isValid == false" class="text-danger">
                {{ $t(element.validation_error_message) }}
            </span>
        </div>
    </div>
</template>

<script>
    import Element from "../form_elements/helper/Element";
    import DatetimeInput from "../../../components/DatetimeInput";
    import EventBus from "../../../core/event-bus";
    import { isElmentInputValid } from "./helper/validations";

    export default {
        mixins: [Element],

        components: {
            DatetimeInput,
        },
        data() {
            return {
                isValid: true,
            };
        },
        computed: {
            isRequiredElement: function () {
                return this.element.validation === "required";
            },
        },
        created: function () {
            let _this = this;
            EventBus.$on("validate_widget_form", function () {
                _this.checkValidation();
            });

            EventBus.$on("date_input_change", function () {
                _this.onChange();
            });
        },
        beforeDestroy() {
            EventBus.$off("validate_widget_form");
            EventBus.$off("date_input_change");
        },
        methods: {
            checkValidation: function () {
                this.isValid = isElmentInputValid(this.element);
            },
        },
    };
</script>

<style></style>
