<template>
    <div class="dd-actions">
        <b-link
            :id="link_id"
            variant=""
            @click.stop="$event.preventDefault()"
        >
            <i class="zmdi zmdi-menu"></i>
        </b-link>

        <b-popover
            :target="link_id"
            triggers="click blur"
            ref="popover"
        >
            <b-list-group>
                <b-link
                    class="list-group-item"
                    variant="none"
                    v-for="link in dropdownLinks"
                    :key="link['name']"
                    @click.prevent="triggerClickEvent($event, link)"
                >
                    <i
                        :class="link['icon_class']"
                        v-show="link['icon_class'] != null"
                        >&nbsp;
                    </i>
                    <span :class="link['link_class']">{{ link['name'] }}</span>
                </b-link>
            </b-list-group>
        </b-popover>
    </div>
</template>

<script>
    export default {
        props: {
            link_id: {
                type: String,
                default: null
            },
            dropdownLinks: {
                type: Array,
                default: null
            },
        },

        methods: {
            triggerClickEvent: function (event, link) {
                this.$emit('click', link)
                this.$refs.popover.$emit('close')
            },

        }
    }
</script>
