<template>
  <div class="bg-white col-lg-5 col-xl-4 p-4 border rounded m-auto">
    <router-view></router-view>

    <notifications
      group="alert"
      position="top left"
      width="450px"
      animation-name="v-fade-left"
      :speed="1000"
    >
      <template slot="body" slot-scope="props">
        <div class="d-flex alert" :class="props.item.type">
          <div class="notification-content">
            <div class="notification-text" v-html="props.item.text"></div>
          </div>
          <div class="notification-close" @click="props.close">
            <i class="zmdi zmdi-close"></i>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "./core/event-bus";
export default {
  metaInfo: {
    title: 'CMS Signage',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Digital signage CMS software' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  
  data() {
    return {};
  },

  mounted: function () {},

  created: function () {
    EventBus.$on("alert", function (options) {
      let duration = 5000;
      if (options["duration"] != null) {
        duration = options["duration"];
      }
      Vue.notify({
        group: "alert",
        text: options["message"],
        type: "alert-" + options["variant"],
        duration: duration,
      });
    });
  },
};
</script>
