import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Widgets from './Widgets'

import CreateApp from './CreateApp'

let routes = [
	{
		path: '/widgets',
		name: 'widgets',
		component: Widgets
	},
	{
		path: '/widgets/createapp/:widget_id',
		name: 'createapp',
		component: CreateApp,
		meta: {
			layout: 'NoSidebarLayout'
		  }
	},
	{
		path: '/widgets/editapp/:content_id',
		name: 'editapp',
		component: CreateApp,
		meta: {
			layout: 'NoSidebarLayout'
		  }
	}
];

export default routes;
