<template>
  <div class="col-xl-3 col-lg-5 col-md-4 p-2 m-0 float-left">
    <b-card footer-tag="footer" class="plan">
      <div class="body p-3">
        <h2 class="card-title text-center font-weight-bold">
          {{ $t("plan.custom_plan_title") }}
        </h2>
        <div class="mt-3 mb-3 pt-3 pb-3">
          <h2 class="text-center mt-3 mb-2 font-weight-bold">
            {{ $t("plan.custom_plan_screen_count") }}
          </h2>
          <p class="text-center mb-3 mb-0">
            {{ $t("plan.custom_plan_message") }}
          </p>
        </div>
        <div class="text-center mt-3">
          <b-button variant="primary" @click="sendEmail()">
            {{ $t("plan.contact") }}
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  methods: {
    sendEmail() {
      window.location = "mailto:ratnasinghlhs@gmail.com";
    },
  },
};
</script>
