<template>
  <div ref="uploadingWindow" class="uploading-window-container" :class="`${isWindowExpanded ? 'expanded' : 'collapsed'}`">
    <div class="header-box">
      <div class="header-name-container">
        <span v-if="collectiveUploadState == 0" class="header-title-label">
          <b-spinner
        small
        variant="success"
        label="Small Spinning"
      ></b-spinner>
          {{
            $t("upload.uploading_count", {
              uploaded_count: successfulUploadCount,
              total_files: files.length,
            })
          }}
        </span>
        <span v-if="collectiveUploadState == 1" class="header-title-label">
          <i class="zmdi zmdi-check-circle zmdi-hc-lg file-upload-success-icon"></i>
          {{
            $t("upload.all_files_uploaded")
          }}
        </span>
        <span v-if="collectiveUploadState == 2" class="header-title-label">
          <i class="zmdi zmdi-alert-circle zmdi-hc-lg file-upload-failed-icon"></i>

          {{
            $t("upload.few_files_upload_failed",{failed_count: failedUploadCount})
          }}
        </span>
      </div>
      <div class="header-buttons-container">
        <b-button @click="toggleMinimzeModel">
          <i :class="`${isWindowExpanded ? 'zmdi zmdi-caret-down-circle zmdi-hc-lg' : 'zmdi zmdi-caret-up-circle zmdi-hc-lg'}`"></i>
      </b-button>
        <b-button @click="hideModel" variant="danger">
        <i class="zmdi zmdi-close"></i>
      </b-button>
      </div>
    </div>
    <div class="content-box">
      <div class="files">
        <file-item-uploading
          :file="file"
          :index="index"
          v-for="(file, index) in this.files"
          :key="`${index}${file.name}${shouldReload}`"
        />
      </div>
    </div>
  </div>
</template>
<script>
//collectiveUploadState => 0 upload pending, 1 upload success, 2 upload failed

import EventBus from "../core/event-bus";
import FileItemUploading from "./uploadType/fileType/FileItemUploading.vue";

export default {
  components: { FileItemUploading },

  data() {
    return {
      files: [],
      isWindowExpanded:true,
      shouldReload:false,
      successfulUploadCount:0,
      failedUploadCount:0,
      collectiveUploadState:0,
    };
  },

  created: function () {
    let _this = this;
    EventBus.$on("uploadFiles", function (files) {
      _this.files = files;
      _this.$refs.uploadingWindow.style.display = "block";

      _this.shouldReload = !_this.shouldReload
      _this.successfulUploadCount = 0
      _this.failedUploadCount = 0
      _this.collectiveUploadState = 0

    });
    EventBus.$on("uploadSuccess", function (fileIndex) {   
      _this.successfulUploadCount = _this.successfulUploadCount + 1  
      _this.updateCollectiveUploadState()
    });
    EventBus.$on("uploadFailed", function (fileIndex) {   
      _this.failedUploadCount = _this.failedUploadCount + 1   
      _this.updateCollectiveUploadState()
    });
  },

  beforeDestroy() {
    EventBus.$off("uploadFiles");
    EventBus.$off("uploadSuccess");
    EventBus.$off("uploadFailed");
  },

  methods: {
    toggleMinimzeModel: function () {
      this.isWindowExpanded = !this.isWindowExpanded
    },

    hideModel: function () {
      this.$refs.uploadingWindow.style.display = "none";
      this.files = []
      
    },

    updateCollectiveUploadState: function() {
      let currentUploadCount = this.failedUploadCount + this.successfulUploadCount
      if(currentUploadCount  == this.files.length) {
        EventBus.$emit("newFilesAvailable");
        if(this.successfulUploadCount == this.files.length) {
          this.collectiveUploadState = 1
        }else {
          this.collectiveUploadState = 2
        }
      }
    }
  },
};
</script>

<style scoped>
.uploading-window-container {
  z-index: 10;
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 350px;
  display: none;
  box-shadow: -12px 10px 20px -12px rgb(112 112 112);

}

.uploading-window-container.expanded {
  height: 200px;
}

.uploading-window-container.collapsed {
  height: 48px;
}

.header-box {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 48px;
  background-color: #f7f7f7;
  border: 1px solid #686666;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-box {
  position: absolute;
  top: 48px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;


}

.files {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.header-name-container {
  margin-left: 16px;
  align-items: center;
  justify-content: flex-start;
}


.header-buttons-container {
  margin-right: 16px;
  align-items: center;
  justify-content: flex-start;
}

.header-title-label {
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}
.file-upload-success-icon {
  color: green;
}
.file-upload-failed-icon {
  color: red;
}
</style>
