<template>
  <div class="h-100">
    <section class="p-0 h-100">

      <div>
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>
<script>
    // const Navbar = () =>import("./components/Navbar.vue").then(m => m.default);
    // const Sidebar = () =>import("./components/Sidebar.vue").then(m => m.default);

    import { mapGetters } from 'vuex'
    export default {

    }
</script>
