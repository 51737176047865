<template>
    <div class="pdf-previewer">
        <iframe :src="this.content.file_url" style="width:100%;height:100%;"></iframe>
    </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style scoped>

.pdf-previewer {
  position: relative;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
}
</style>