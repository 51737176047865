<template>
    <div class="media" :class="selected ? 'media-chosen' : ''">
        <div class="left-section">
            <div class="text-truncate pb-2">
                <b-badge pill variant="success">{{ this.index }}</b-badge>
                <span class="content-title-text">{{
                    media.name
                }}</span>
            </div>
            <div class="dz-image">
                <div
                    data-dz-thumbnail-bg=""
                    class="thumbnail success"
                    :style="'background-image: url(' + this.get_img(media) + ');'"
                ></div>
            </div>
            <div
                class="expand-collapse"
                v-if="media.item_type == 'Account::Channel'"
            >
                <b-button
                    variant="outline-primary"
                    size="sm"
                    class=""
                    @click="show_media_list()"
                    v-show="!expand"
                >
                    Show media list
                    <i class="zmdi zmdi-chevron-right" v-if="!expand"></i>
                </b-button>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    class=""
                    @click="show_media_list()"
                    v-show="expand"
                >
                    Hide media list
                    <i class="zmdi zmdi-chevron-down" v-if="expand"></i>
                </b-button>
            </div>
        </div>
        <div class="middle-section dz-details">
            <div class="" v-if="!readOnly">
                <div class="duration">
                    <label
                        v-b-tooltip.hover
                        :title="$t('playlist.duration_help_text')"
                    >
                        {{ $t("playlist.duration") }}:
                    </label>
                    <duration-input
                        class="form-control form-control-sm"
                        v-model="media.duration"
                        :duration="media.duration"
                    />
                </div>
                <div class="resize-mode">
                    <label>
                        {{ $t("playlist.resize_mode") }}
                    </label>
                    <b-form-select
                        v-model="media.resize_mode"
                        :options="options"
                        class="custom-select-sm"
                    />
                </div>

                <div class="right-section">
                    <selected-media-action
                        :media="media"
                        @delete="deleteMedia"
                    />
                </div>
            </div>
            <div class="ma-auto">
                <label
                    v-b-tooltip.hover
                    :title="$t('playlist.duration_help_text')"
                >
                    Schedule:
                </label>
                <div class="schedule-container">
                    <schedule
                        v-for="(schedule, index) in media.schedules"
                        :key="'schedule' + media.id + index"
                        :schedule="schedule"
                        :readOnly="readOnly"
                        :canDelete="canDelete(media)"
                        @change="editSchedule(schedule, index)"
                    />
                    <div class="add_schedule">
                        <button class="btn" @click="createSchedule">
                            <i class="zmdi zmdi-plus-circle"></i>
                            {{ $t("common_words.add") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ScheduleFormWizard
            :schedule="selected_schedule"
            v-if="selected_schedule != null"
            @close="deSelectSchedule"
            @submit="saveSchedule"
        />

        <ChannelMediaListReadOnly
            :channel="media"
            :readOnly="true"
            v-if="expand"
        />
    </div>
</template>

<script>
    import ContentVue from "../../library/LibraryContent.vue";
    import ContentService from "../../services/ContentService";
    import DurationInput from "../../components/DurationInput";
    import SelectedMediaAction from "./SelectedMediaAction.vue";
    import Schedule from "../ScheduleComposition/Schedule.vue";
    import EventBus from "../../core/event-bus";
    import ScheduleFormWizard from "../ScheduleComposition/ScheduleFormWizard.vue";
    import ChannelMediaListReadOnly from "./ChannelMediaListReadOnly.vue";
    import ContentThumbnailImg from "../../mixins/content_thumbnail_img";

    export default {
        props: {
            index: {
                type: Number,
            },
            media: {
                type: Object,
                default: {},
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                options: ["cover", "center", "contain", "stretch"],
                selected: false,
                selected_schedule: null,
                selected_index: null,
                expand: false,
            };
        },

        mixins: [ContentThumbnailImg],

        components: {
            ContentVue,
            DurationInput,
            Schedule,
            SelectedMediaAction,
            ScheduleFormWizard,
            ChannelMediaListReadOnly,
        },

        mounted() {
            EventBus.$on("closeDialog", this.deSelect);
        },

        methods: {
            deleteMedia: function () {
                this.$emit("delete", this.media);
            },

            createSchedule: function () {
                this.selected = true;
                this.selected_schedule = {
                    week_days: {},
                    dates: [],
                    times: [],
                };
            },

            deSelect: function () {
                this.selected = false;
            },

            stop: function (event) {
                event.stopPropagation();
                return false;
            },

            deSelectSchedule: function () {
                EventBus.$emit("closeDialog", true);
                this.selected_schedule = null;
                this.selected_index = null;
            },

            saveSchedule: function (schedule) {
                if (this.selected_index == null) {
                    this.addSchedule(schedule);
                } else {
                    this.updateSchedule(schedule);
                }
                this.selected_index = null
            },

            addSchedule: function (schedule) {
                this.selected = true;
                this.media.schedules.push(schedule);
                this.selected_schedule = null;
            },

            editSchedule: function (schedule, index) {
                this.selected_schedule = schedule;
                this.selected_index = index;
                this.selected = true;
            },

            updateSchedule: function (schedule) {
                this.media.schedules[this.selected_index] = schedule;
                this.selected = false;
            },

            show_media_list: function () {
                this.expand = !this.expand;
            },

            canDelete: function(media) {
                return media.schedules.filter(function(s) { return s["_destroy"] == null }).length > 1
            }
        },
    };
</script>

<style lang="scss" scoped>
.media {
    display: flex;
    align-items: flex-start;
    margin: 10px;
    padding: 10px 10px 0px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    font-size: 16px;
    opacity: 1;
    flex-wrap: wrap;
    .dz-image {
        display: flex;
        flex: 0.3 1;
        height: 100px;
    }
    .left-section {
        flex: 0.1;
        min-width: 120px;
        .expand-collapse {
            margin: 10px 0px;
        }
    }
    .middle-section {
        flex: 0.9;
        & > div {
            flex: 1;
            display: flex;
            align-content: flex-start;
            align-content: flex-end;
            flex-wrap: wrap;
            padding-bottom: 5px;
        }
        & > div:first-child {
            & > div.duration {
                flex: 0.4;
                label {
                    min-width: 60px;
                }
            }
            & > div.resize-mode {
                flex: 0.5;
                justify-content: flex-end;
                label {
                    min-width: 86px;
                }
            }
            & > div {
                display: flex;
                label {
                    flex: 0.4 1;
                    margin: 0;
                    line-height: 38px;
                }

                input {
                    flex: 0.5;
                    margin: auto 5px;
                }
                select {
                    flex: 0.5;
                    margin: auto 5px;
                    padding: 5px;
                    line-height: 12px;
                }
            }
        }
        .schedule-container {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            b-button {
                padding: 5px;
            }
        }
    }
    .right-section {
        display: flex;
        flex: 0.15;
    }
    .add_schedule {
        flex-basis: 100%;
        margin-top: -8px;
        button {
            margin-left: -5px;
        }
        flex: 1;
        cursor: pointer;
        height: 100%;
    }
    .thumbnail {
        flex: 1;
        height: 100%;
    }
    .thumbnail.success {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .thumbnail.processing {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

    .thumbnail.failed {
        color: red;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
}
.media-chosen {
    background: #c8ebfb;
}
</style>