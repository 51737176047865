import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router/dist/vue-router.esm'

import Templates from './Templates'
import AddTemplate from './add_widget_forms/AddTemplate.vue'

let routes = [
  {
    path: '/templates',
    name: 'templates',
    component: Templates
  },
  {
    path: '/widgets/addTemplate/:widget_id/create',
    name: 'addtemplate',
    component: AddTemplate,
    meta: {
      layout: 'NoSidebarLayout'
    }
  },
  {
    path: '/widgets/addTemplate/:content_id/update',
    name: 'editTemplate',
    component: AddTemplate,
    meta: {
      layout: 'NoSidebarLayout'
    }
  }
]

export default routes
