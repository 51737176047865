<template>
  <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <b-tabs
      card
      v-model="tabIndex"
      active-nav-item-class="font-weight-bold text-uppercase h-100"
      nav-class="col-lg-10"
      content-class="col-lg-12 p-0"
    >
      <b-tab class="p-0 h-100" active>
        <template #title>
          {{ $t("user.notification.title") }}
        </template>
        <div class="tab-item-container">
          <notifications-home ref="notifications" :key="notificationsTabKey" />
        </div>
      </b-tab>

      <b-tab class="p-0 h-100">
        <template #title>
          {{ $t("custom_alert.title") }}
        </template>
        <div class="tab-item-container">
          <set-alert-home :key="setAlertTabKey" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import NotificationsHome from "./notifications/NotificationsHome";
import SetAlertHome from "./set_alerts/SetAlertHome.vue";

export default {
  props: {},

  data() {
    return {
      tabIndex: 0,
      notificationsTabKey: true,
      setAlertTabKey: true,
    };
  },
  watch: {
    tabIndex: {
      immediate: false,
      handler(val, oldVal) {
        if (this.tabIndex == 0) {
          this.notificationsTabKey = !this.notificationsTabKey;
        } else {
          this.setAlertTabKey = !this.setAlertTabKey;
        }
      },
    },
  },
  components: {
    NotificationsHome,
    SetAlertHome,
  },
};
</script>
