<template>
    <div class="time-input-box" :class="{ 'error': error != null }">
        <VueMaterialDateTimePicker
            v-model="content"
            @input="updateValue"
            :is-time-only="true"
            :valueFormatted="formatted_time"
        />
    </div>
</template>

<script>
    import VueMaterialDateTimePicker from "vue-material-date-time-picker";

    export default {
        props: {
            value: {
                type: Number,
                default: null,
            },
            error: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                content: new Date(this.value),
            };
        },

        components: {
            VueMaterialDateTimePicker,
        },
        computed: {
            formatted_time: function () {
                let timeStr = this.content
                    .toLocaleString("en-US", {
                        dateStyle: "short",
                        timeStyle: "medium",
                        hour12: true,
                    })
                    .split(",")[1];
                return (timeStr || "").trim();
            },
        },

        mounted() {},

        methods: {
            updateValue(value) {
                this.$emit("input", Date.parse(value));
            },
        },

    };
</script>
<style lang="scss" >
div.time-input-box {
    flex: 1;
    display: flex;
    label {
        flex: 0.9;
        display: flex;
        border: none;

        .vmdtp_input {
            box-sizing: border-box;
            line-height: 12px;
            border: 1px solid #d2d2d2 !important;
            border-radius: 5px !important;
            margin-left: 2px !important;
        }
    }
}
div.error {
    label {
        .vmdtp_input {
            border: 1px solid red !important;
        }
    }
}
</style>