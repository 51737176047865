<template>
  <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <div class="header shadow-sm col-lg-12 text-right border-bottom">
      <div class="row p-1">
        <div class="col-4 d-flex align-items-center">
          <h3 class="float-left m-0">
            {{ $t("playlist.title") }} ({{ total_count }})
          </h3>
        </div>
        <div class="col-8 d-flex align-items-center justify-content-end">
          <search :media_type="media_type" @change="getSearch" />
          <sorting
            class="ml-2"
            :sort_list="this.sort_options"
            :sort_order="this.sort_order"
            @change="getSortedPlaylists"
          />
          <b-button variant="primary" class="ml-2" @click="create_playlist">
            {{ $t("playlist.new_playlist") }}
          </b-button>
        </div>
      </div>
    </div>
    <scroll-pagination
      :scrollFunction="handleScroll"
      :loading="loading"
      :page="page"
      :total_pages="total_pages"
    >
      <Playlist
        v-for="playlist in this.playlists"
        :playlist="playlist"
        :key="playlist.id"
        @updated="reload_playlist()"
        @deleted="reload_playlist()"
      />
    </scroll-pagination>
    <no-content
      :title="$t('playlist.no_data.title')"
      :imageName="'no-playlist.png'"
      :actionText="$t('playlist.no_data.action')"
      :nextSteptText="$t('playlist.no_data.no_playlist_next_step')"
      v-if="!loading && !this.playlists.length && !this.isSearchActive"
      @clicked="create_playlist"
    />

    <no-search-result
      :searchText="this.searchText"
      v-if="!loading && !this.playlists.length && this.isSearchActive"
    />

    <create-edit-playlist
      id="create_playlist"
      @created="reload_playlist"
      @updated="reload_playlist"
    />
  </div>
</template>

<script>
import Playlist from "./Playlist";
import CreateEditPlaylist from "./CreateEdit";
import PlaylistService from "../services/PlaylistService";
import NoContent from "../components/NoContent";
import NoSearchResult from "../components/NoSearchResult";
import Search from "../components/Search";
import Sorting from "../components/Sorting";
import {
  getPlaylistsSortingField,
  setPlaylistsSortingField,
  getPlaylistsSortingOrderBy,
  setPlaylistsSortingOrderBy,
} from "../core/UserPreferences";

import { getSortingLabel } from "../core/HelperMethods";

export default {
  data() {
    return {
      playlists: [],
      selectMode: "single",
      in_progress: true,
      total_pages: 0,
      page: 1,
      loading: false,
      isSearchActive: false,
      searchText: "",
      sort_options: [
        { value: "name", label: "Name" },
        { value: "updated_at", label: "Last updated" },
      ],
      sort_order: {
        order: {
          value: getPlaylistsSortingField(),
          label: getSortingLabel(getPlaylistsSortingField()),
        },
        order_by: getPlaylistsSortingOrderBy(),
      },
      media_type: "Playlist",
      total_count: null,
    };
  },

  components: {
    CreateEditPlaylist,
    NoContent,
    NoSearchResult,
    Playlist,
    Search,
    Sorting,
  },

  mounted() {
    this.getPlaylist();
  },

  methods: {
    getSearch(searchText) {
      this.searchText = searchText;
      if (searchText.length >= 1) {
        this.isSearchActive = true;
      } else {
        this.isSearchActive = false;
      }
      this.page = 1;
      this.getPlaylist();
    },

    getSortedPlaylists(sort_option) {
      this.sort_option = sort_option;
      setPlaylistsSortingField(sort_option.order.value);
      setPlaylistsSortingOrderBy(sort_option.order_by);
      this.page = 1;
      this.getPlaylist();
    },

    getPlaylist() {
      let _this = this;
      _this.loading = true;
      let query_params = { page: this.page, query: this.searchText };
      let order = { order: this.sort_order['order']['value'], order_by: this.sort_order['order_by'] }
      PlaylistService.list(Object.assign(query_params, order))
        .then((response) => {
          if (_this.page == 1) {
            _this.playlists = [];
          }
          _this.playlists = _this.playlists.concat(response.data.playlists);
          _this.total_pages = response.data.total_pages;
          _this.total_count = response.data.total_count;
          _this.loading = false;
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },

    update_playlist: function (playlists) {
      this.playlists = playlists;
    },

    reload_playlist() {
      this.page = 1;
      this.getPlaylist();
    },
    create_playlist() {
      let _this = this;
      PlaylistService.create({ name: "Playlist" })
        .then((response) => {
          this.$router.push({
            name: "contents",
            params: { id: response.data.id },
          });
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },

    handleScroll: function () {
      this.page++;
      this.getPlaylist();
    },
  },
};
</script>

<style lang="scss">
td {
  .pl-name {
    width: 90%;
  }
}

td.groups-col {
  width: 20%;
}

td.contents-col {
  width: 15%;
}

td.actions-col {
  width: 200px;
}

.file-previews .drag {
  background-color: #ffffff;
}

.name-col {
  width: 40%;
}
</style>
