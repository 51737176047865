<template>
<b-list-group-item class="p-0">
    <b-button variant="outline" @click="drawCircle" :class="{ btn_selected: this.isDraw['circle'] }">
        <i class="zmdi zmdi zmdi-circle"></i>
        <div>Circle</div>
    </b-button>
</b-list-group-item>
</template>

<script>
import Drawing from '../Drawing.js'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} },
        selectedTool: { type: String, default: null }
    },


    data() {
        return {
            isDraw: {
                circle: false,
            }
        }
    },

    mixins: [Drawing],

    methods: {
        drawCircle: function () {
            this.canvas.discardActiveObject();
            this.canvas.renderAll();
            this.drawObj('circle');
            this.canvas.defaultCursor = 'crosshair';
        },
    }
}
</script>
