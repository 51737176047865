<template>
  <div class="reports-home-container">
    <div class="reports-header-container">
      <ReportDownloadableHeader :filtersCount="reports.length" />
    </div>
    <div class="reports-items-container" v-if="reports.length > 0">
      <ReportDownloadableItem
        v-for="report in reports"
        :report="report"
        :key="report.id"
      />
    </div>
    <div class="reports-items-container" v-else>
      <span>{{ $t("reports.no_data") }}</span>
    </div>
  </div>
</template>

<script>
import ActionCableListener from "../../mixins/ActionCableListener";
import moment from "moment";
import ReportService from "../../services/ReportService";
import ReportDownloadableItem from "./ReportDownloadableItem.vue";
import ReportDownloadableHeader from "./ReportDownloadableHeader.vue";
export default {
  mixins: [ActionCableListener],

  data() {
    return {
      reports: [],
    };
  },
 
  mounted() {
    this.getReports();
    let _this = this;
    this.startActionCableListener(function(data) {
       let report = data["report"];
        if (report != null) {
            _this.getReports();
        }
    });
  },

  beforeDestroy() {
    this.stopActionCableListener();
  },
  
  methods: {
    getReports: function () {
      ReportService.list()
        .then((response) => {
          this.reports = response.data;
        })
        .catch((error) => {
          _this.handleError(error);
        });
    },
    formatTime: function (time) {
      return moment(time).format("DD-MMM-YYYY h:mm A zz");
    },

    download_file: function (download_url) {
      let iframe = document.createElement("iframe");
      iframe.id = "hiddenDownloader";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.src = download_url;
    },
  },
  components: { ReportDownloadableItem, ReportDownloadableHeader },
};
</script>
<style scoped>
.reports-home-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.reports-header-container {
  display: flex;
  flex: 0;
}
.reports-items-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  overflow: scroll;
  flex-direction: column;
}
</style>
