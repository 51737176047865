<template>
  <div class="text-center no-content w-100">
    <div class="">
      <div class="text-center">
          <img :src="img" class="m-2"/>
      </div>
      <div class="col-lg-12 pb-4 p-0 text-left mt-3">
        <ol>
          <li>
            {{$t('playlist_content.no_content.msg1')}}
          </li>
          <li>
            {{$t('playlist_content.no_content.msg2')}}
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  props:{
    message: ''
  },

  data() {
      return {
          img: require('../images/no_content_images/new_playlist_without_item.png'),
      }
  },

  mounted() {
  },

  methods: {

  }
}
</script>

<style>

</style>
