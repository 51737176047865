<template>
    <div class="content-thumbnail-container">
        <div
            class="thumbnail success"
            :style="'background-image: url(' + this.getThumbnail + ');'"
        ></div>
    </div>
</template>

<script>
    export default {
        props: {
            channel: {
                type: Object,
            },
        },

        data() {
            return {
                no_image: require("../images/no_content_images/no-playlist.png"),
            };
        },
        computed: {
            getThumbnail: function () {
                if (this.channel.img_url == null) {
                    return this.no_image;
                } else {
                    return this.channel.img_url;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.no-content-channel {
    background-image: url("../images/no_content_images/no-playlist.png");
}
.content-thumbnail-container {
    display: flex;
    flex: 1;
    height: 100%;
}
.thumbnail {
    flex: 1;
}
.thumbnail.success {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.thumbnail.processing {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.thumbnail.failed {
    color: red;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
</style>

