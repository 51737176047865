<template>
  <div>
    <b-button class="btn float-left btn-secondary btn-sm"
            @click="delete_content">
       <i class="zmdi zmdi-delete"></i>
    </b-button>

    <edit-content :playlist_content="playlist_content" />
  </div>
</template>

<script>
import EditContent                 from './EditContent'

export default {
  props: {
    playlist_content: {
      type: Object,
      default:{
        id: null,
        playlist_id: null,
        resize_mode: 'cover',
        duration: 0,
        position: 0,
        content: {}
      }
    },
  },

  data() {
    return {
      success_message: "",
      error: null,
    }
  },

  components:{
    EditContent
  },

  methods: {
    show_modal(playlist_content_id){
      this.$bvModal.show("edit_playlist_content" + playlist_content_id)
    },

    delete_content(){
      let _this = this;
      _this.$emit("progress", true)
      _this.$emit("deleted", _this.playlist_content.position)
    },

    update(playlist_content){
      this.$emit('updated', playlist_content)
    }

  },
}
</script>
<style lang="scss">
  .a-width{
    width: 50px !important;
  }
</style>
