
export const userStore = {
  namespaced: true,

  state: {
    user: null,
    is_admin: false,
  },

  getters: {
    user: state => {
      return state.user
    },
    is_admin: state => {
      return state.is_admin
    }
  },

  mutations: {
    user(state, user) {
      state.user = user
    }
  },

  actions: {
    setUser(context, user) {
      context.commit('user', user)
    }
  }
};

