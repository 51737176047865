<template>
    <div class="time-box">
        <div>
            <label>
                {{ $t("channel.media.schedule.start_time") }}
            </label>
            <TimeVue v-model="newTime.start" @input="updateValue" />
        </div>
        <div>
            <label>
                {{ $t("channel.media.schedule.end_time") }}
            </label>
            <TimeVue
                v-model="newTime.end"
                :error="errorTime"
                @input="updateValue"
            />
        </div>
        <div class="action">
            <button @click="deleteTime" v-if="canDelete">
                <i class="zmdi zmdi-delete"></i>
            </button>
        </div>
    </div>
</template>
<script>
    import TimeVue from "../../../components/Time.vue";
    export default {
        props: {
            value: {
                type: Object,
                default: {},
            },
            canDelete: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                newTime: {},
            };
        },

        components: {
            TimeVue,
        },

        computed: {
            errorTime: function () {
                return this.newTime["error"];
            },
        },

        beforeMount() {
            this.newTime['start'] = this.getTime(this.value.start)
            this.newTime['end'] = this.getTime(this.value.end)
        },

        methods: {
            updateValue() {
                let time = this.setTimeIntegerhash()
                this.$emit("input", time);
            },

            deleteTime: function () {
                this.$emit("delete");
            },

            setTimeIntegerhash: function(){
                let time = {}
                if(this.newTime['start'] > 86400){
                    time['start'] = new Date(this.newTime['start']).getHours() * 60 * 60 + new Date(this.newTime['start']).getMinutes() * 60
                }
                if(this.newTime['end'] > 86400){
                    time['end'] = new Date(this.newTime['end']).getHours() * 60 * 60 + new Date(this.newTime['end']).getMinutes() * 60
                }
                return time
            },

            getTime(time) {
                let dt = new Date()
                let hrs = parseInt(time/(60 * 60))
                let mins = parseInt((time % (60 * 60))/ 60)

                dt.setHours(hrs,mins,0, 0)
                return new Date(dt).getTime()
            }
        },
    };
</script>
<style lang="scss">
.time-box {
    flex: 1;
    display: flex;
    flex-basis: 100%;
    border: 1px solid #d2d2d2ba;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 5px;
    > div {
        flex: 0.5;
        display: flex;
        label {
            padding-right: 8px;
            margin: 0;
        }
    }
    .action {
        flex: 0;
        margin: -2px 0px 2px -10px;
        button {
            border: 0px none;
            border-radius: 3px;
            background: none;
        }
    }
}
</style>
