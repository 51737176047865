<template>
    <div class="header-box">
        <div class="header-content-name-container">
            <h3 class="content-previewing-label">
                {{ $t("common_words.previewing") }}
                {{ this.channel.name }}
            </h3>
        </div>
        <b-button class="close-b-button" @click="$emit('close')" variant="danger">
            <i class="zmdi zmdi-close zmdi-hc-lg"></i>
        </b-button>
    </div>
</template>
<script>
    export default {
        props: {
            channel: {
                type: Object,
            },
        },

        methods: {
        },
    };
</script>
<style lang="scss" scoped>
.header-box{
    display: flex;
    flex: 1;
    > div{
        display: flex;
        flex: 1;
    }
}
</style>