<template>
<div :class="cssClass" class="p-2 mb-3">
    <div class="grp-block shadow-sm rounded border p-0 m-0 ">
        <div class="header  col-lg-12 text-left p-3 border-bottom">
            <h4 class="">{{ group.name }}</h4>
            <div class="p-0">
                {{ $t("screen_group.total_screen") }} {{ device_count }}
            </div>

            <group-drop-down :group="group" cssClass="dropdown-action" @rename="rename_group" @delete="delete_group" />
        </div>
        <div class="col-lg-12 p-0 ">
            <b-button @click="show_dialog()" class="col-lg-12 rounded-0" variant="primary">
                <i class="zmdi zmdi-plus-circle "></i>
                {{ $t("screen_group.add_device") }}
            </b-button>
        </div>
        <div class="card-block">
            <div :class="{ 'text-danger': this.group.media == null, 'text-success': this.group.media != null, }" class="col-lg-12 p-0 float-left font-weight-bold cursor-pointer" @click="show_media_dialog()">
                <span class="mr-1 float-left">
                    {{ $t("screen_group.media_title") }}:
                </span>
                <span class="media-title text-truncate float-left" v-b-tooltip.hover :title="group_media_name()">
                    {{ group_media_name() }}
                </span>
                <div class="edit-media float-right p-0">
                    <b-link class="color-inherit p-2" @click="show_media_dialog()">
                        <i class="zmdi zmdi-play-circle zmdi-hc-lg"></i><br />
                    </b-link>
                </div>
            </div>
        </div>
        <change-media :group="group" @updated="group_updated" />
        <add-device :group="group" @alert="show_message" @created="$emit('added')" />
    </div>
</div>
</template>

<script>
import ChangeMedia from "./ChangeMedia";
import GroupService from "../services/GroupService";
import AddDevice from "./AddDevice";
import GroupDropDown from "./GroupDropDown";
import { showDeleteConfirmation } from "../utils/DeleteConfirmation";

export default {
    props: {
        group: {
            type: Object,
            default: function () {
              return { name: '', media: '',}
            },
        },
        cssClass: {
            type: String,
            default: "col-sm-12 col-md-6 col-lg-6 col-xl-3",
        },
    },

    components: {
        ChangeMedia,
        AddDevice,
        GroupDropDown,
    },

    computed: {
        device_count() {
            return this.group != null ? this.group.device_count : 0;
        },

        created_at() {
            return this.group != null ? this.group.created_at : "N/A";
        },
    },
    methods: {
        group_media_name() {
            if (this.group.media != null) {
                return this.group.media.name
            }
            return this.$t("screen_group.media_not_available");
        },

        show_media_dialog() {
            this.$bvModal.show("change_media_" + this.group.id);
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs;
        },

        show_dialog() {
            this.$bvModal.show("add_device_" + this.group.id);
        },

        group_updated() {
            this.$emit("updated");
        },

        show_message(message) {
            this.$emit("update");
            this.$eventBus.$emit("alert", { variant: "success", message: message });
        },

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs;
        },

        delete_group: function () {
            let _this = this;
            showDeleteConfirmation(
                _this,
                _this.$t("confirmation_alert.screen_group_delete_message")
            ).then((result) => {
                if (result.value) {
                    GroupService.destroy(this.group.id)
                        .then((response) => {
                            _this.$emit("deleted");
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                }
            });
        },

        rename_group: function (group) {
            this.$emit("rename", group);
        },
    },
};
</script>

<style lang="scss">
div.grp-block {
    margin-right: 15px;

    .header {
        height: auto !important;
        display: flex;
        flex-wrap: wrap;
        div{
            flex-basis: 100%;
        }
    }
}

div.grp-block .card-block {
    padding: 15px 10px;
    display: inline-block;
    width: 100%;
}

div.grp-block .zmdi-plus-circle {
    color: #25e425;
}

.gr-name {
    width: 58%;
}

.qrcode canvas {
    width: 90% !important;
    height: 90% !important;
}

.media-title {
    font-weight: 400;
    width: 50%;
    line-height: 26px;
}

.delete-grp {
    position: absolute;
    top: 20px;
    right: 10px;
}

.edit-media {
    position: absolute;
    top: 0px;
    right: -8px;
}

.dropdown-action {
    position: absolute;
    top: 20px;
    right: 15px;
}
</style>
