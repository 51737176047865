<template>
<div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
    <div class="header shadow-sm col-lg-12 p-2 text-right border-bottom">
        <h3 class='float-left'> {{$t('widget.apps_title')}} ({{total_count}})</h3>
    </div>
    <scroll-pagination :scrollFunction="handleScroll" :loading="loading" :page="page" :total_pages="total_pages" v-show="!loading && widgets.length > 1">
        <widget :widget="widget" :token="token" v-for="widget in this.widgets" :key="'widget' + widget.id" />
    </scroll-pagination>

</div>
</template>

<script>
import WidgetService from '../services/WidgetService'
import Widget from './Widget'

export default {

    data() {
        return {
            widgets: [],
            loading: false,
            token: '',
            total_count: null,
            total_pages: 0,
            page: 1
        }
    },

    components: {
        Widget,
    },

    mounted() {
        this.getWidgets()
    },

    methods: {
        getWidgets() {
            let _this = this;
            _this.loading = true
            WidgetService.list({page: this.page, template: false})
                .then(response => {
                    var widgetsSorted = response.data['widgets']
                    widgetsSorted.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
                    _this.widgets = widgetsSorted
                    _this.token = response.data['meta']['token']
                    _this.loading = false
                    _this.total_pages = response.data.total_pages
                    _this.total_count = response.data.total_count
                })
                .catch(error => {
                    _this.handleError(error)
                })
        },

        handleScroll: function () {
            this.page++
            this.getWidgets()
        }
    }
}
</script>
