<template>
<div class="col-12 p-4 " v-observe-visibility="visibilityChanged">
    <label class="float-left"> Stroke: </label>
    <div class="d-flex">
        <color-picker v-model="stroke_color" @input="changeStrokeColor" />
    </div>
</div>
</template>

<script>
import ColorPicker from '../../../components/ColorPicker'
export default {
    props: {
        canvas: { type: Object, default: {} },
        properties: { type: Object, default: {} },
    },

    data() {
        return {
            stroke_color: this.properties.stroke_color
        }
    },
    components: {
        ColorPicker
    },

    methods: {
        visibilityChanged: function (isVisible) {
            if (isVisible && this.canvas.freeDrawingBrush != null) {
                this.stroke_color = { hex8: this.properties.stroke_color }
                // this.canvas.freeDrawingBrush.color = this.stroke_color.hex8
            }
        },

        changeStrokeColor: function (color) {
            this.canvas.freeDrawingBrush.color = color.hex8
            this.canvas.renderAll()
            this.canvas.fire("object:statechange");
        }
    }
}
</script>
