<template>
  <div :class="cssClass">
    <b-link :id="'group-pp-1-' + this.group.id" variant="">
        <i class="zmdi zmdi-menu"></i>
    </b-link>
    <b-popover :target="'group-pp-1-' + this.group.id" triggers="click"
                ref="popover" placement="bottom">
        <template #title></template>
        <b-list-group>
          <b-link class="list-group-item "
                    variant="none"
                    @click.prevent="rename_group">
             {{$t('common_words.rename')}}
          </b-link>
          <b-link class="list-group-item text-danger"
                    variant="none"
                    @click.prevent="delete_group">
             {{$t('common_words.delete')}}
          </b-link>
        </b-list-group>

    </b-popover>
  </div>
</template>

<script>

    export default {
        props: {
          cssClass: {
            type: String,
            default: ''
          },
          group: {}
        },

        methods: {
          delete_group: function() {
            this.$emit('delete');
          },
          rename_group: function(){
            this.$emit('rename', this.group)
          }
        }
    }
</script>
<style lang="scss">
  .list-group{
    a{
      &:hover{
        background-color: #5e6ebe;
        color: #FFF;
        text-decoration: none;
      }
      .zmdi{
        font-size: 14px;
      }
    }
  }
  .popover{
    .list-group-item{
      width: auto;
      padding: 10px 20px;
    }
    .popover-body{
      padding: 0px;
    }
    .bs-popover-right{
      margin-top: -5px;
    }
  }
</style>
