<template>
  <div class="sub-popover">
    <b-link :id="'popover-1-' + this.subscription.id" variant="">
        <i class="zmdi zmdi-menu"></i>
    </b-link>
    <b-popover :target="'popover-1-' + this.subscription.id" triggers="click blur"
                ref="popover" placement="bottom">
        <template #title></template>
        <b-list-group>
          <b-link :id="'popover-2-' + this.subscription.id"
                  class="list-group-item "
                  variant="none"
                  @click.prevent="view_billing">
                {{$t('subscription.view_billing')}}
          </b-link>

           <b-link :id="'popover-3-' + this.subscription.id"
                  class="list-group-item "
                  variant="none"
                  @click.prevent="refresh">
                {{$t('subscription.refresh')}}
          </b-link>

          <b-link :id="'popover-4-' + this.subscription.id"
                  class="list-group-item "
                  variant="none"
                  @click.prevent="upgrade"
                  v-if="subscription.plan.name != 'Yearly' && subscription.metadata['upgrade_pending'] != true &&
                        subscription.metadata['pause_collection'] == null">

                {{$t('subscription.upgrade')}}
          </b-link>

          <b-link :id="'popover-4-' + this.subscription.id"
                  class="list-group-item "
                  variant="none"
                  @click.prevent="downgrade"
                  v-if="subscription.plan.name != 'Monthly' && subscription.metadata['downgrade_pending'] != true &&
                        subscription.metadata['pause_collection'] == null">
                {{$t('subscription.downgrade')}}
          </b-link>

          <b-link :id="'popover-2-' + this.subscription.id"
                  class="list-group-item "
                  variant="none"
                  @click.prevent="pause_subscription"
                  v-if="subscription.status != 'cancelled' && subscription.metadata['pause_collection'] != true">
                <span style="color:red;">{{$t('subscription.cancel_subscription')}}</span>
          </b-link>
          <b-link :id="'popover-3-' + this.subscription.id"
                  class="list-group-item "
                  variant="none"
                  @click.prevent="resume_subscription"
                  v-if="subscription.status != 'cancelled' && subscription.metadata['pause_collection'] == true">
                <span>{{$t('subscription.resume')}}</span>
          </b-link>
        </b-list-group>
      </b-popover>
  </div>
</template>
<script>
  import SubscriptionService from '../../services/SubscriptionService'
  import {showYesNoConfirmation, showYesNoConfirmationInfo} from "../../utils/DeleteConfirmation"

  export default {
    props: {
      subscription: {
        id: null,
        plan_name: 'monthly',
        quantity: 1,
        amount: { cents: null, country_iso: null },
        customer_portal_url: '',
        status: 'active',
        metadata: {}
      }
    },

    data() {
      return {
      }
    },

    components: {
    },

    methods: {
      view_billing: function(){
        SubscriptionService.get()
                           .then(response => {
                             window.location.href = response.data.customer_portal_url
                           })
                           .catch(error => {
                             _this.handleError(error)
                           })
      },

      edit: function(){
        this.$bvModal.show("subscription_form" + this.subscription.id )
      },

      pause_subscription: function(){
        let _this = this;
        showYesNoConfirmation(_this, _this.$t("confirmation_alert.subscription_delete_message")).then((result) => {
            if (result.value) {
              SubscriptionService.pause()
                          .then(response => {

                             _this.$emit("updated")
                          })
                          .catch(error => {
                            this.handleError(error)
                          })
            }
          })
      },

      resume_subscription: function(){
        let _this = this;
        showYesNoConfirmationInfo(_this, _this.$t("confirmation_alert.subscription_reactivate_message")).then((result) => {
            if (result.value) {
              SubscriptionService.resume()
                          .then(response => {

                             _this.$emit("updated")
                          })
                          .catch(error => {
                            this.handleError(error)
                          })
            }
          })
      },

      upgrade: function(){
        let _this = this;
        showYesNoConfirmationInfo(_this, _this.$t("subscription.upgrade_note")).then((result) => {
            if (result.value) {
              SubscriptionService.upgrade()
                          .then(response => {

                             _this.$emit("updated")
                          })
                          .catch(error => {
                            this.handleError(error)
                          })
            }
          })
      },

      downgrade: function(){
        let _this = this;
        showYesNoConfirmation(_this, _this.$t("subscription.downgrade_note")).then((result) => {
            if (result.value) {
              SubscriptionService.downgrade()
                          .then(response => {

                             _this.$emit("updated")
                          })
                          .catch(error => {
                            this.handleError(error)
                          })
            }
          })
      },

      refresh: function(){
        let _this = this;
        _this.loading = true;
        SubscriptionService.refresh()
                          .then((response) => {
                              _this.$emit("updated")
                              _this.loading = false;
                          })
                          .catch(error => {
                            this.handleError(error)
                          })
      }
     }
   }
</script>
<style lang="scss">
  .sub-popover{
    position: absolute;
    top: 15px;
    right: 15px;
  }
</style>
