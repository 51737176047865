import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const PlaylistService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('user/playlists.json?' + query_string);
	},

	get(id) {
		return Http.get('user/playlists/' + id + '.json');
	},

	duplicate(params) {
		return Http.post('user/playlists/duplicate.json', params);
	},

	create(params) {
		return Http.post('user/playlists.json', {
			playlist: params
		});
	},

	update(id, params) {
		return Http.put('user/playlists/' + id + '.json', {
			playlist: params
		});
	},

	addContent(id, params) {
		return Http.post('user/playlists/' + id + '/addContent.json', params);
	},

	destroy(id) {
		return Http.delete('user/playlists/' + id + '.json');
	},

	update_position(id, params) {
		return Http.post('user/playlists/' + id + '/update_position.json', params);
	},

	search(query) {
		return Http.get('user/playlists/search.json', { params: { query: query } });
	},


};

export default PlaylistService;
