<template>
    <div class="downloadable-header-main-container">
      <div class="header-item">
        <span class="header-text">
          {{ $t("dashboard.screen") }}
        </span>
      </div>
      <div class="header-item">
        <span class="header-text">{{ $t('dashboard.screens_group')}}</span>
      </div>
      <div class="header-item">
        <span class="header-text">{{ $t('dashboard.content_assigned')}}</span>
      </div>

      <div class="header-item">
        <span class="header-text">{{ $t('dashboard.last_played_content')}}</span>
      </div>
      <div class="header-item">
        <span class="header-text">{{ $t('dashboard.last_played_date')}}</span>
      </div>
      <div class="header-item">
        <span class="header-text">{{ $t('dashboard.last_seen')}}</span>
      </div>


    </div>
  </template>
  <script>
  export default {
  }
  </script>
  <style>
  .downloadable-header-main-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 16px;
    background-color: #ecf0f1;
    border-bottom: 1px solid rgb(194, 194, 194);
  }
  .header-item {
    display: flex;
    flex: 1.0;
    align-items: center;
  }

  .header-text {
    font-size: 18px;
    font-weight: 500;
  }
  </style>
  