<template>
  <div class="col-12 alert-cont d-flex">
    <b-alert :variant="variant"
             :show="dismissCountDown"
             :fade="true"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged">
      {{ alert_message}}
    </b-alert>
 </div>
</template>
<script>
  export default {

    props: {
        variant: {
          type: String,
          default: 'success'
        },
        alert_message: {
          type: String,
          default: ""
        }
    },

    data() {
      return {
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: false,
      }
    },

    methods: {

      countDownChanged: function(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert: function() {
        this.dismissCountDown = this.dismissSecs
      },
    },
  }
</script>
