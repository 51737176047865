<template>
  <div class="d-flex flex-column justify-content-center p-2" style="height:100%; width:100%;">
    
    <div class="d-flex flex-column">
      <img :src="img"  style="align-self: center;"/>
      <span class="no-content-next-step" style="align-self: center;">{{$t('common_words.no_search_result')}}&nbsp;<b>'{{searchText}}'</b></span>
    </div>
          
  </div>
</template>
<script>

export default {

  props:{
    searchText: ''
  },

  data() {
      return {
          img: require('../images/no_content_images/no-search-result.png'),
      }
  },

  mounted() {
    
  },

  methods: {
    get_img: function(){
      let widget_img = require('../images/no_content_images/' + this.imageName)
        return widget_img
    },
  }
}
</script>

<style>
 
 
</style>
