var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports-form-main-container"},[_c('div',{staticClass:"input-controls"},[_c('div',{staticClass:"input-control"},[_c('span',{staticClass:"field-label-text"},[_vm._v(_vm._s(_vm.$t('reports.content_label')))]),_vm._v(" "),_c('tags-input',{attrs:{"element-id":"tags-1","placeholder":"Filter contents (Default all)","typeahead-url":_vm.content_search_url,"id-field":"id","text-field":"name","typeahead-style":"dropdown","typeahead":true},scopedSlots:_vm._u([{key:"selected-tag",fn:function(ref){
var tag = ref.tag;
var index = ref.index;
var removeTag = ref.removeTag;
return [_c('span',[_vm._v(_vm._s(tag["name"]))]),_vm._v(" "),_c('a',{staticClass:"tags-input-remove",on:{"click":function($event){return _vm.removeSelectedTag(index, removeTag)}}})]}}]),model:{value:(_vm.selected_content),callback:function ($$v) {_vm.selected_content=$$v},expression:"selected_content"}})],1),_vm._v(" "),_c('div',{staticClass:"input-control"},[_c('span',{staticClass:"field-label-text"},[_vm._v(_vm._s(_vm.$t('reports.device_label')))]),_vm._v(" "),_c('tags-input',{attrs:{"element-id":"tags-2","placeholder":"Filter screens (Default all)","typeahead-url":_vm.device_search_url,"id-field":"id","text-field":"device_name","typeahead-style":"dropdown","typeahead":true},scopedSlots:_vm._u([{key:"selected-tag",fn:function(ref){
var tag = ref.tag;
var index = ref.index;
var removeTag = ref.removeTag;
return [_c('span',[_vm._v(_vm._s(tag["device_name"]))]),_vm._v(" "),_c('a',{staticClass:"tags-input-remove",on:{"click":function($event){return _vm.removeSelectedTag(index, removeTag)}}})]}}]),model:{value:(_vm.selected_device),callback:function ($$v) {_vm.selected_device=$$v},expression:"selected_device"}})],1),_vm._v(" "),_c('div',{staticClass:"input-control"},[_c('span',{staticClass:"field-label-text"},[_vm._v(_vm._s(_vm.$t("reports.date_range_label")))]),_vm._v(" "),_c('date-range-picker',{ref:"picker",attrs:{"opens":"left","locale-data":{ firstDay: 1, format: 'dd-mmm-yyyy' },"minDate":_vm.minDate,"maxDate":_vm.maxDate,"singleDatePicker":_vm.singleDatePicker,"ranges":_vm.range,"dateFormat":_vm.dateFormat},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)]),_vm._v(" "),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"btn btn-primary btn-lg generate-report-button",on:{"click":_vm.create_report}},[_vm._v("\n    "+_vm._s(_vm.$t('reports.button_text_generate_report'))+"\n    ")])]),_vm._v(" "),_c('hr')])}
var staticRenderFns = []

export { render, staticRenderFns }