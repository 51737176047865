<template>
<div class="row border rounded p-2 shadow-sm extra-bottom-padding m-0" style="min-width:100%;">
    <div class="row" style="min-width:100%;">
        <div class="col-md-12">
            <div class="text-truncate pb-2">
                <b-badge pill variant="success">{{ this.index }}</b-badge>
                <span class="content-title-text">{{
            playlist_content.media.name
          }}</span>
            </div>
        </div>
    </div>

    <!-- first section/column for image -->
    <div class="col-md-4 p-0">
        <div class="show_bg" :style="'background-image:url(' + this.get_img(playlist_content.media) + ')'"></div>
    </div>

    <!-- middle section/column for fields duration & resize mode -->
    <div class="col-md-7" v-if="!hide_action">
        <div class="">
            <div class="row">
                <div class="col-md-6">
                    <label>
                        {{ $t("playlist.duration") }}:
                        <i v-b-tooltip.hover :title="$t('playlist.duration_help_text')" class="zmdi zmdi-help">
                        </i>
                    </label>
                </div>
                <div class="col-md-6">
                    <duration-input class="form-control" v-model="playlist_content.duration" :duration="playlist_content.duration" />
                </div>
            </div>
            <div class="row pt-1">
                <div class="col-md-6">
                    <label>
                        {{ $t("playlist.resize_mode") }}
                    </label>
                </div>
                <div class="col-md-6">
                    <b-form-select v-model="playlist_content.resize_mode" :options="options" />
                </div>
            </div>
        </div>
    </div>

    <!-- last section/column for delete button -->
    <div class="col-md-1 actions-container" v-if="!hide_action">
        <i class="zmdi zmdi-format-line-spacing reorder-handler-icon mb-4"></i>

        <playlist-content-action :playlist_content="playlist_content" @deleted="remove" />
    </div>
</div>
</template>

<script>
import ContentThumbnailImg from '../mixins/content_thumbnail_img.js'
import PlaylistContentAction from "./PlaylistContentAction"
import DurationInput from "../components/DurationInput"
export default {
    mixins: [ContentThumbnailImg],
    props: {
        index: {
            type: Number,
        },
        playlist_content: {
            type: Object,
            default: {
                id: null,
                playlist_id: null,
                media_id: null,
                resize_mode: "cover",
                duration: 0,
                position: 0,
                media: {},
            },
        },
        hide_action: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            options: ["cover", "center", "contain", "stretch"],
        }
    },

    components: {
        PlaylistContentAction,
        DurationInput,
    },

    mounted() {},

    methods: {
        remove(position) {
            this.$emit("deleted", position)
        },
    },
}
</script>

<style lang="scss">
.show_bg {
    width: 90%;
    height: 75px;
    border-radius: 10px;
    border: 0.5px solid #e0e0da;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.content-title-text {
    font-size: 16px;
    color: #636363;
}

.reorder-handler-icon {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
