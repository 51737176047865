import Vue from 'vue';

Vue.filter('truncate', function(text, length) {
	let clamp = '...';
	text = text || '';
	length = length || 20;

	if (text.length <= length) return text;

	let tcText = text.slice(0, length);

	return tcText + clamp;
});
