<template>

    <div class="col-12 p-1" >

      <media-content :playlist_content="contents_playlist"
                     @deleted="remove"
                     :index="index"
                     :hide_action="hide_action"
                      v-if="contents_playlist.media_type == 'User::Content'"/>

      <media-playlist :playlist_content="contents_playlist"
                      :hide_action="hide_action"
                      :index="index"
                      @deleted="remove"
                       v-if="contents_playlist.media_type == 'User::Playlist'"/>
    </div>


</template>
<script>

import MediaContent              from './MediaContent';
import MediaPlaylist             from './MediaPlaylist';
export default {

  props:{
    name: 'PlaylistMedia',
    index:{
      type:Number
    },
    contents_playlist: {
      type: Object,
      default:{
        id: null,
        playlist_id: null,
        media_id: null,
        resize_mode: 'cover',
        duration: 0,
        position: 0,
        media: {},
        media_type: null,
        widget: {}
      }
    },

    hide_action: {
      type: Boolean,
      default: false
    },

  },


  components:{
    MediaContent,
    MediaPlaylist
  },

  mounted() {

  },

  methods: {

    remove(position){
      this.$emit('deleted', position)
    }
  }
}
</script>
</style>
