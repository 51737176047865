import Vue from "vue";
import Vuex from "vuex";

// Application Styles

import BootstrapVue from "bootstrap-vue";
import VeeValidate from "vee-validate";

import "../styles/index.scss";

import VueAxios from "vue-axios";
import Http from "../core/http";
import LoginRoutes from "../login/LoginRoutes";
import appRoutes from "../AppRoutes";
import i18n from "../locals/index.js";

import App from "../App";
import EventBus from "../core/event-bus";
import Login from "../Login.vue";
import clickOutside from "../directives/click-outside";
import visible from "../directives/visible";

import formatDate from "../filters/formatDate";
import formatFileSize from "../filters/formatFileSize";
import duration from "../filters/duration";
import truncate from "../filters/truncate";
import moment from "moment-timezone";
import VueDragDrop from "vue-drag-drop";
import Notifications from "vue-notification";
import vueDebounce from "vue-debounce";
import ScrollPagination from "../components/ScrollPagination.vue";
import VueObserveVisibility from "vue-observe-visibility";
import vueCountryRegionSelect from "vue-country-region-select";
import store from "../store/storeModule";
import handleErrors from "../mixins/handle_api_errors.js";

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

const dictionary = {
  custom: {
    urls: {
      required: "At least one social media url is required",
    },
  },
};

VeeValidate.Validator.localize("en", dictionary);
Vue.mixin(handleErrors);
// setup HTTP adapter
Vue.use(VueAxios, Http);
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VeeValidate, {
  fieldsBagName: "dataFields",
});
Vue.use(BootstrapVue);
Vue.use(VueDragDrop);
Vue.use(vueDebounce, {
  listenTo: ["input", "keyup"],
});
Vue.use(Notifications);
Vue.use(vueCountryRegionSelect);
Vue.use(VueObserveVisibility);
Vue.use(EventBus);

Vue.component("ScrollPagination", () =>
  import("../components/ScrollPagination.vue")
);

Vue.prototype.$eventBus = EventBus;

document.addEventListener("DOMContentLoaded", () => {
  if (typeof DATA != "undefined") {
    let router = appRoutes;
    const vueapp = new Vue({
      App,
      store,
      router,
      propsData: DATA,
      i18n,
      render: (h) => h(App),
    });
    vueapp.$mount("#app");
  } else {
    let router = LoginRoutes.create("/");
    const vueapp = new Vue({
      Login,
      router,
      i18n,
      render: (h) => h(Login),
    });
    vueapp.$mount("#login");
  }
});
